<page-atsugi-dental-message>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="dental">先輩からのメッセージ</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--gap-loose o-layout--column">
            <div each="{ message in messages }">
              <message-box data-item="{ message }" data-slug="dental"></message-box>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="dental">教師からのメッセージ</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--gap-loose o-layout--column">
            <div each="{ message in messages2 }">
              <message-box data-item="{ message }" data-slug="dental"></message-box>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiDentalMessage() {
      return {
        /**
         * @typedef {object} message
         * @property {string} [photo] 写真のURL
         * @property {string} [title] 単純文字列
         * @property {string} profile HTML文字列
         * @property {string} message HTML文字列
         */
        /**
         * @type {message[]}
         */
        messages: [
          {
            photo: '/img/dental-message-avator-6.webp',
            profile: '2024年度3年生',
            message: '私達がこの学校を選んだのは、家から近いからです！<br>同級生の中には県外から通学している人や１人暮らしをしている友達もいますが、実際、通学に時間がかかる友達はレポートを書く時間など、大変そうです…<br>入学してから気が付いたことは、思っていた以上に勉強が大変だという事です。<br>オープンキャンパスに参加した際に「勉強は大変ですよ」と言われてはいましたが、それ以上でした！でも、色々な科目で同じような内容が出てくるので、次第に覚えていきます。<br>ファントーム（マネキン）を使った実習は比較的楽しく、行っています。<br>お友達同士で実際に行う相互実習は、反復練習も多くあるので、自分の上達が判ります！<br>３年間、通う学校なので少しでも参考になれば嬉しいです。',
          },
          {
            photo: '/img/dental-message-avator-7.webp',
            profile: '2024年度2年生',
            message: 'この学校を選んだのは、家から近くて綺麗だったからです。<br>勉強は、今までの勉強では聞く事すら無かった言葉が出てくるので、思っていた以上に難しくて大変です。でも友達と助け合いながら頑張っています。<br>実習で友達同士とお互いにやり合う時は緊張するけど楽しいです。練習する時間も沢山あって、少人数制なので解らないところはすぐ聞けて、出来る事が増えてくるのが嬉しいです。',
          },
          {
            photo: '/img/dental-message-avator-5.webp',
            profile: '2024年3月卒業',
            message: '私達の学年は歯科助手経験者などが比較的多い学年で、１年生の時は歯科助手経験者が新卒の友達に実技や勉強を教えたりする場面も多くありました。<br>２年生の後期から臨地・臨床実習が始まると同じ事で悩み・相談し、３年生の国家試験対策では一緒に解らない問題について調べたり、考えたりして協力し合ってきました。<br>私達がいえることは、一人で頑張っても限界があるので、友達と協力して、先ずは国家試験合格を目指してほしいと思います。<br>頑張ってください。',
          },
        ],
        /**
         * @type {message[]}
         */
        messages2: [
          {
            profile: '教師一同',
            message: '本校歯科衛生学科は専門学校なので、卒業後に即戦力となれるように心がけてサポートしています。<br>人々の笑顔を守る歯科衛生士を目指して、一緒に頑張りましょう。',
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-dental-message>