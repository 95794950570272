<chuo-nav>
  <school-nav data-items="{ navs }" data-slug="chuo" data-where="{ props.dataWhere }"
    data-current="{ props.dataCurrent }"></school-nav>
  <script>
    export default function ChuoNav() {
      return {
        navs: [
          {
            icon: 'home',
            title: 'ホーム',
            href: '/chuo/',
          },
          {
            icon: 'study',
            title: '学校紹介',
            href: '/chuo/subject',
          },
          {
            icon: 'school',
            title: 'スクールライフ',
            href: '/chuo/campus',
          },
          {
            icon: 'message',
            title: '教師・先輩からのメッセージ',
            href: '/chuo/message',
          },
          {
            icon: 'compass',
            title: '体験入学',
            href: '/chuo/open-campus',
          },
          {
            icon: 'file',
            title: '募集要項',
            href: '/chuo/application',
          },
          {
            icon: 'questions',
            title: 'よくあるご質問',
            href: '/chuo/qa',
          },
          {
            icon: 'bell',
            title: 'お知らせ',
            href: '/chuo/news',
          },
          {
            icon: 'map',
            title: 'アクセス',
            href: '/school#map-2',
          },
          {
            icon: 'book',
            title: '資料請求',
            href: '/request',
          },
          {
            icon: 'lock',
            title: '個人情報のお取り扱いについて',
            href: '/policy',
          },
        ]
      }
    }
  </script>
</chuo-nav>