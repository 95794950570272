<page-kanagawa-highschool-campus>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="highschool">一年間の流れ</ui-heading>
          <div class="c-content">
            <p>卒業後も専門教育を極め、姉妹校への学校推薦も有り、大切な3年間を有意義に過ごせるよう教員一同が指導していきます。</p>
          </div>
        </div>
        <div>
          <event-calendar data-items="{ events }" data-slug="highschool"></event-calendar>
        </div>
      </section>
    </div>
    <div>
      <ui-heading data-tag="h1" data-theme="highschool">課外活動</ui-heading>
      <section>
        <h1 class="c-heading-4 c-heading-4--theme-highschool">部活動</h1>
        <div class="c-content">
          <ul class="c-list c-list--layout-flow c-list--theme-highschool">
            <li each="{listItem in list}">{listItem}</li>
          </ul>
        </div>
      </section>
      <section>
        <h1 class="c-heading-4 c-heading-4--theme-highschool">ボランティア活動</h1>
        <div class="c-content">
          <ul class="c-list c-list--layout-flow c-list--theme-highschool">
            <li each="{listItem in list3}">{listItem}</li>
          </ul>
          <p>など</p>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageKanagawaHighschoolCampus() {
      return {
        /**
         * @typedef {object} eventObject
         * @property {string} title
         * @property {string} photo
         * @property {string[]} events
         */
        /**
         * @type {eventObject[]}
         */
        events: [
          {
            title: '4月',
            photo: '/img/highschool-month-header-4.webp',
            events: [
              '入学式',
              '始業式・対面式',
              '身体測定',
              'オリエンテーション',
              '部活･同好会説明',
            ],
          },
          {
            title: '5月',
            photo: '/img/highschool-month-header-5.webp',
            events: [
              '1・2年生遠足･バスハイク（東京ディズニーリゾート）',
              '進路指導（3年生）',
              '母校訪問（1年生）',
              '保護者会',
              '中間試験'
            ],
          },
          {
            title: '6月',
            photo: '/img/highschool-month-header-6.webp',
            events: [
              '衣替え',
              '健康診断',
              'レタリング検定',
              'ビジネス検定',
              '修学旅行（2年生）'
            ],
          },
          {
            title: '7月',
            photo: '/img/highschool-month-header-7.webp',
            events: [
              '進路説明会（3年生）',
              '期末試験',
              '三者面談',
              '終業式・夏休み',
              '体験入学（中学3年生対象）',
              '夏期補習',
              '会社訪問（3年生）',
              '高等専修学校展（海の日）',
              '漢字能力検定',
              'ガス溶接技能講習',
            ],
          },
          {
            title: '8月',
            photo: '/img/highschool-month-header-8.webp',
            events: [
              '夏休み',
              '一斉登校日(8月10日)',
              '会社・職場見学(3年生)',
            ],
          },
          {
            title: '9月',
            photo: '/img/highschool-month-header-9.webp',
            events: [
              '始業式',
              '防災訓練',
              '就職解禁(9/16)',
            ],
          },
          {
            title: '10月',
            photo: '/img/highschool-month-header-10.webp',
            events: [
              '衣替え',
              'トレース検定',
              '豊友祭(3校合同学園祭)',
              '中間試験',
              '電気工事士校内試験（電気コース3年生）',
              '球技大会',
            ],
          },
          {
            title: '11月',
            photo: '/img/highschool-month-header-11.webp',
            events: [
              '全校ハイキング(森の里・若宮公園)',
              '漢字能力検定',
              '秘書検定',
              '学校説明・相談会',
            ],
          },
          {
            title: '12月',
            photo: '/img/highschool-month-header-12.webp',
            events: [
              '学校説明・相談会',
              'パソコン利用技術検定',
              '推薦入試',
              '工業技術学科1年コース分け説明会',
              '針供養',
              '期末試験',
              '終業式・冬休み',
            ],
          },
          {
            title: '1月',
            photo: '/img/highschool-month-header-1.webp',
            events: [
              '始業式',
              '洋裁技術検定',
              '卒業試験（3年生）',
              '一般入試',
            ],
          },
          {
            title: '2月',
            photo: '/img/highschool-month-header-2.webp',
            events: [
              '期末試験',
            ],
          },
          {
            title: '3月',
            photo: '/img/highschool-month-header-3.webp',
            events: [
              '卒業式',
              '学年末試験',
              '入学者説明会',
              '修了式・春休み',
            ],
          },
        ],
        /**
         * @type {string[]}
         */
        list: [
          '陸上競技部',
          'バスケットボール部',
          'バレーボール部',
          '卓球部',
          '硬式テニス部',
          'バトミントン同好会',
          '軟式野球同好会',
          '軽音楽部',
        ],
        /**
         * @type {string[]}
         */
        list3: [
          '防犯パトロール',
          '心と街のクリーン作戦',
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa-highschool-campus>