<page-kanagawa-car-message>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="car">先輩紹介</ui-heading>
        </div>
        <div class="o-fit">
          <h2 class="c-heading-4 c-heading-4--theme-car">就職内定率100%！<br>国内外の幅広いメーカーが未来のあなたを待っています。</h2>
          <p>国内外のメーカー系ディーラーからバス・重機関係、自動車メーカーの開発部門やバイク業界など幅広い就職実績があり、その現場で多くの先輩たちが活躍しています！</p>
        </div>
        <div>
          <div class="o-layout o-layout--wrap c-graduaters-layout">
            <div each="{ person in persons }">
              <div class="o-layout o-layout--column o-layout--gap-none c-graduater-layout">
                <div>
                  <div class="c-graduater-photo" {...getGraduaterPhotoStyle(person.photo)}></div>
                </div>
                <div class="c-graduater-layout__title">
                  <div class="c-graduater-title">
                    <div class="c-graduater-title__text">
                      <raw-html data-content="{japaneseParser.translateHTMLString(person.title)}">
                      </raw-html>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="car">教師からのメッセージ</ui-heading>
        </div>
        <div>
          <img src="/img/car-message-teachers.webp" alt="教師一同写真">
        </div>
        <div>
          <div class="o-layout o-layout--column">
            <message-box data-item="{ message2 }" data-slug="car"></message-box>
          </div>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageKanagawaCarMessage() {
      return {
        /**
         * @typedef {object} person
         * @property {string} photo 写真のパス
         * @property {string} title 卒業後の就職先
         */
        /**
         * @type {person[]}
         */
        persons: [
          {
            photo: '/img/car-message-avator-1.webp',
            title: '神奈川<wbr>トヨタ<wbr>自動車'
          },
          {
            photo: '/img/car-message-avator-2.webp',
            title: 'ホンダカーズ<wbr>中央神奈川'
          },
          {
            photo: '/img/car-message-avator-3.webp',
            title: 'ポルシェセンター<wbr>横浜'
          },
          {
            photo: '/img/car-message-avator-4.webp',
            title: 'レッドバロン'
          },
          {
            photo: '/img/car-message-avator-5.webp',
            title: 'いすゞ自動車<wbr>首都圏'
          },
          {
            photo: '/img/car-message-avator-6.webp',
            title: 'アウディ<wbr>相模原'
          },
          {
            photo: '/img/car-message-avator-7.webp',
            title: '東日本<wbr>三菱自動車'
          },
          {
            photo: '/img/car-message-avator-8.webp',
            title: 'スズキ自販<wbr>湘南'
          },
          {
            photo: '/img/car-message-avator-9.webp',
            title: '太陽建機<wbr>レンタル'
          },
          {
            photo: '/img/car-message-avator-10.webp',
            title: '神奈川<wbr>ダイハツ<wbr>販売'
          },
          {
            photo: '/img/car-message-avator-11.webp',
            title: 'ベントレー<wbr>横浜'
          },
          {
            photo: '/img/car-message-avator-12.webp',
            title: '湘南<wbr>マツダ'
          },
        ],
        /**
         * @typedef {object} message
         * @property {string} [photo] 写真のURL
         * @property {string} title 単純文字列
         * @property {string} profile HTML文字列
         * @property {string} message HTML文字列
         */
        /**
         * @type {message}
         */
        message2:
        {
          title: 'スタッフが全力で応援',
          profile: '教師一同',
          message: '｢技術者はごまかしがきかない｣この言葉は誰もが技術者としてスタートするときに耳にするもの。大切な人生の土台を作る年代に、一切ごまかしのきかない仕事に就けることは本当に素晴らしいことだと思います。私たちは｢わからなければ、わかるまで｣｢できなければ、できるまで｣を合い言葉に、若き未来の技術者たちを、全力で応援していきます。',
        },
        getGraduaterPhotoStyle(photo) {
          return { style: `background-image: url(${photo});` }
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa-car-message>