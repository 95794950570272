<page-atsugi-dental-campus>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="dental">一年間の流れ</ui-heading>
          <div class="c-content">
            <div class="c-accent-block c-accent-block--theme-dental o-cancel-first-child-gap o-space">
              <p>実際の学校の様子を見てみたいという方は、ぜひ一度オープンキャンパスにご参加ください。</p>
              <p class="o-items-center">
                <ui-button data-href="/atsugi/dental/open-campus" data-class="theme-dental">オープンキャンパス
                </ui-button>
              </p>
            </div>
          </div>
        </div>
        <div>
          <event-calendar data-items="{ events }" data-slug="dental"></event-calendar>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiDentalCampus() {
      return {
        /**
         * @typedef {object} eventObject
         * @property {string} title
         * @property {string} photo
         * @property {string[]} [events]
         */
        /**
         * @type {eventObject[]}
         */
        events: [
          {
            title: '4月',
            photo: '/img/atsugi-month-header-4.webp',
            events: [
              '入学式',
              'オリエンテーション',
              '前期授業開始',
              '臨地・臨床実習（3年生）',
            ],
          },
          {
            title: '5月',
            photo: '/img/dental-month-header-5.webp',
            events: [
              '遠足',
            ],
          },
          {
            title: '6月',
            photo: '/img/dental-month-header-6.webp',
            events: [
              '歯と口の健康週間',
              '保育園歯磨き指導実習',
            ],
          },
          {
            title: '7月',
            photo: '/img/dental-month-header-7.webp',
          },
          {
            title: '8月',
            photo: '/img/dental-month-header-8.webp',
            events: [
              '夏季休暇',
            ],
          },
          {
            title: '9月',
            photo: '/img/dental-month-header-9.webp',
            events: [
              '前期本試験',
              '国家試験対策講座（3年生）',
              '臨床実習前試験（2年生）',
              '登院式（2年生）'
            ],
          },
          {
            title: '10月',
            photo: '/img/dental-month-header-10.webp',
            events: [
              '後期授業開始',
              '臨地・臨床実習（2年生）',
              '学園祭（鈴木学園3校合同）',
              '国家試験補講',
            ],
          },
          {
            title: '11月',
            photo: '/img/dental-month-header-11.webp',
            events: [
              '卒業試験（3年生）',
            ],
          },
          {
            title: '12月',
            photo: '/img/dental-month-header-12.webp',
            events: [
              '国家試験対策',
              '冬季休暇',
            ],
          },
          {
            title: '1月',
            photo: '/img/dental-month-header-1.webp',
            events: [
              '国家試験対策',
            ],
          },
          {
            title: '2月',
            photo: '/img/dental-month-header-2.webp',
            events: [
              '国家試験対策',
              '後期本試験',
            ],
          },
          {
            title: '3月',
            photo: '/img/dental-month-header-3.webp',
            events: [
              '歯科衛生士国家試験（3年生）',
              '卒業式',
              '春季休暇',
            ],
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-dental-campus>