<page-atsugi-dental>
  <school-header data-is-school="{ true }" data-slug="atsugi" data-school-name="厚木総合専門学校" data-sub-school-slug="dental"
    data-sub-school-cource="歯科衛生学科"></school-header>
  <template if="{ isHome() }">
    <div class="c-school-hero">
      <div class="c-school-hero__heading">
        <slide-in data-theme="dental">
          <div class="c-school-hero__heading-text c-school-hero__heading-text--theme-dental o-cancel-first-child-gap">
            <p>
              歯と口の健康を基本に、
              <br>全身の健康をサポートする専門職、
              <br>歯科衛生士への夢をサポートします。
            </p>
          </div>
        </slide-in>
      </div>
      <div class="c-site-hero-photo c-site-hero-photo--atsugi-dental">
        <photo-slider data-slides="{slides}" data-theme="dental"></photo-slider>
      </div>
    </div>
    <div class="c-home-content">
      <wave-separator class="c-home-content__wave" data-slug="dental"></wave-separator>
      <div class="o-layout o-layout--column">
        <div class="c-message-text c-message-text--theme-atsugi-dental u-only-mobile">
          <p>
            歯と口の健康を基本に、
            <br>全身の健康をサポートする専門職、
            <br>歯科衛生士への夢をサポートします。
          </p>
        </div>
        <div>
          <div class="o-container">
            <div class="o-spacer o-spacer--large-2 u-only-desktop"></div>
            <home-nav-items data-items="{ homeNavItems }" data-theme="dental"></home-nav-items>
          </div>
        </div>
        <section>
          <div class="o-container">
            <ui-heading data-tag="h1" data-theme="dental">お知らせ一覧</ui-heading>
            <div class="o-layout o-layout--column o-layout--cross-axis-center">
              <div>
                <news-list data-slug="dental" data-news="{ news }"></news-list>
              </div>
              <div class="o-items-center">
                <ui-button data-href="./news" data-class="theme-dental">一覧を見る</ui-button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="o-spacer o-spacer--large-2"></div>
  </template>
  <template if="{ !isHome() }">
    <school-content-template if="{ isPage('subject') }" data-title="コース・教科・取得資格" data-slug="dental"
      data-current="/atsugi/dental/subject" data-photo="/img/photo-atsugi-dental-subject.webp">
      <page-atsugi-dental-subject></page-atsugi-dental-subject>
    </school-content-template>
    <school-content-template if="{ isPage('campus') }" data-title="キャンパス<wbr>ライフ" data-slug="dental"
      data-current="/atsugi/dental/campus" data-photo="/img/photo-atsugi-dental-campus.webp">
      <page-atsugi-dental-campus></page-atsugi-dental-campus>
    </school-content-template>
    <school-content-template if="{ isPage('message') }" data-title="教師・先輩からのメッセージ" data-slug="dental"
      data-current="/atsugi/dental/message" data-photo="/img/photo-atsugi-dental-message.webp">
      <page-atsugi-dental-message></page-atsugi-dental-message>
    </school-content-template>
    <school-content-template if="{ isPage('open-campus') }" data-title="オープン<br>キャンパス" data-slug="dental"
      data-current="/atsugi/dental/open-campus" data-photo="/img/photo-atsugi-dental-open-campus.webp">
      <page-atsugi-dental-open-campus></page-atsugi-dental-open-campus>
    </school-content-template>
    <school-content-template if="{ isPage('application') }" data-title="募集要項" data-slug="dental"
      data-current="/atsugi/dental/application" data-photo="/img/photo-4.webp">
      <page-atsugi-dental-application></page-atsugi-dental-application>
    </school-content-template>
    <school-content-template if="{ isPage('qa') }" data-title="よくあるご質問" data-slug="dental"
      data-current="/atsugi/dental/qa" data-photo="/img/photo-atsugi-dental-qa.webp">
      <page-atsugi-dental-qa></page-atsugi-dental-qa>
    </school-content-template>
    <school-content-template if="{ isPage('news') }" data-title="お知らせ" data-slug="dental"
      data-current="/atsugi/dental/news" data-photo="/img/dental-news-title.svg">
      <page-atsugi-dental-news data-news="{news}"></page-atsugi-dental-news>
    </school-content-template>
  </template>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column">
    <section class="c-footer-nav-block">
      <div class="o-container">
        <dental-nav data-where="footer"></dental-nav>
      </div>
    </section>
    <section>
      <group-footer></group-footer>
    </section>
  </div>
  <atsugi-dental-menu></atsugi-dental-menu>
  <script>
    // @ts-check
    export default function PageAtsugiDental() {
      return {
        /**
         * @typedef slide
         * @property {String} photo
         * @property {String} [position]
         */
        /**
         * @type {slide[]}
         */
        slides: [
          { photo: '/img/atsugi-dental-photo.webp', },
          { photo: '/img/atsugi-dental-photo-4.webp', },
          {
            photo: '/img/atsugi-dental-photo-3.webp',
            position: '100% 50%'
          },
        ],
        /**
         * @typedef {object} nav
         * @property {string} icon
         * @property {string} title
         * @property {string} href
         */
        /**
         * @type {nav[]}
         */
        homeNavItems: [
          {
            icon: 'study',
            title: 'コース・教科・資格検定',
            href: '/atsugi/dental/subject'
          },
          {
            icon: 'school',
            title: 'キャンパスライフ',
            href: '/atsugi/dental/campus'
          },
          {
            icon: 'message',
            title: '教師・先輩からの<br>メッセージ',
            href: '/atsugi/dental/message'
          },
          {
            icon: 'compass',
            title: 'オープンキャンパス',
            href: '/atsugi/dental/open-campus'
          },
          {
            icon: 'file',
            title: '募集要項',
            href: '/atsugi/dental/application'
          },
          {
            icon: 'questions',
            title: 'よくあるご質問',
            href: '/atsugi/dental/qa'
          },
        ],
        /**
         * @typedef {object} news
         * @property {string} title
         * @property {string} date
         * @property {string} slug
         * @property {string} [alt_link]
         * @property {string} [content]
         * @property {string} [photo]
         */
        /**
         * @type {news[]}
         */
        news: [
          {
            title: '冬季休業期間のお知らせ',
            date: '2023年12月26日',
            slug: 'notice-of-winter-holidays-2023',
            content: '<p>【資料請求について】<br>年内の資料発送は12月26日（火）にて終了になります。<br>年明けの発送は1月9日（火）からです。<br>【出願、体験入学等の各種お問合せについて】<br>12月27日（水）～ 1月8日（月）は冬季休業になります。<br>期間中、お電話等の受付もお休みさせて頂きます。</p><p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '豊友祭2023を開催します',
            date: '2023年10月14日',
            slug: 'fes-2023',
            content: '<p>今年も豊友祭（学園祭）行います。<br>楽しいイベントが盛り沢山！是非遊びに来てください！</p><ul><li>日程 2023/10/28（土）</li><li>時間 10:00〜15:00</li><li>会場 神奈川県厚木市恩名1-17-18</li></ul><p><a href="/img/2023-fes-poster.pdf" target="_blank"><img src="/img/fes-2023.webp" alt="豊友祭2023ポスター"></a></p>',
            photo: '/img/news-fes-2023.webp',
          },
          {
            title: '夏季休暇期間について',
            date: '2023年8月10日',
            slug: '2022-2023',
            content: '<p>8/11（金）～8/16（水）まで夏季休暇に伴い、頂きましたお問合せや資料請求に関しましては8/17（木）から順次ご対応させて頂きます。<br>皆様には大変ご迷惑お掛けいたしますが、ご理解賜りますよう宜しくお願い致します。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '年末年始の資料請求・お問合せについて',
            date: '2022年12月26日',
            slug: '2022-2023',
            content: '<p> <b>【資料請求について】</b> 年内の資料発送は12月26日（金）にて終了になります。<br> 年明けの発送は1月6日（金）からです。 </p> <p> <b>【出願、体験入学等の各種お問合せについて】</b> 12月27日（火）～ 1月5日（木）は年末年始は休業になります。<br> 期間中、お電話等の受付もお休みさせて頂きます。 </p> <p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '豊友祭2022を開催します',
            date: '2022年10月17日',
            slug: 'fes-2022',
            content: '<p>3年ぶりに豊友祭（学園祭）行います。<br>新型コロナ感染症拡大防止の為、ホールイベント、飲食の販売等は<br>小規模になりますが是非遊びに来てください！</p><p>日程 2022/10/29（土）<br>時間 10:00〜16:00<br>会場 神奈川県厚木市恩名1-17-18</p><p><a href="/img/2022-fes-poster.pdf" target="_blank"><img src="/img/fes-2022.png" alt="豊友祭 冷和4年10月29日（土）10:00〜16:00"></a></p>',
            photo: '/img/news-2022-fes.png',
          },
          {
            title: '歯科衛生士求人票ダウンロード',
            date: '2022年7月22日',
            slug: 'download-job-offer',
            content: '下記のリンクより歯科衛生士求人票をダウンロードいただけます。\n\nなお、メールでの求人票の受付はしておりません。郵送にてお送りください\n\n[厚木総合専門学校 歯科衛生学科 求人票A4](/img/厚木総合専門学校_歯科衛生学科_求人票A4.pdf)',
            photo: '/img/news-atsugi-dental-job-offer-thumb.webp',
          },
          {
            title: '第76回あつぎ鮎まつりに参加します',
            date: '2022年7月8日',
            slug: 'atsugiayumatsuri-2022',
            content: '<img src="/img/news-atsugi-dental-ayumatsuri-2022.webp">',
            photo: '/img/news-atsugi-dental-ayumatsuri-2022-thumb.webp',
          },
          {
            title: '2022年度オープンキャンパスについて',
            date: '2022年4月1日',
            slug: 'open-campus-2022',
            content: '2022年度オープンキャンパス実施予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [オープンキャンパスページ](/atsugi/dental/open-campus)',
            photo: '/img/photo-atsugi-dental-open-campus.webp',
          },
          {
            title: 'サイトリニューアルのお知らせ',
            date: '2022年2月28日',
            slug: 'site-renewal-2022',
            content: 'この度、Webサイトを全面リニューアルいたしました。',
            photo: '/img/news-renewal-dental.png',
          },
          {
            title: '新型コロナウイルス感染予防について',
            date: '2022年2月28日',
            slug: 'covid-19',
            content: '- 定期的な消毒、換気、教職員のマスク着用など感染症対策に配慮しながら実施しています。\n- ご来校時に検温と手指消毒にご協力頂いております。熱がある場合は、館内への入館をご遠慮頂いております。\n- 新型コロナウイルス感染拡大防止の為、予定が変更になる場合がございます。',
            photo: '/img/news-covid-19.webp',
          },
          {
            title: '2021年度オープンキャンパスについて',
            date: '2021年4月1日',
            slug: 'open-campus-2021',
            content: '2021年度オープンキャンパス実施予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [オープンキャンパスページ](/atsugi/dental/open-campus)',
            photo: '/img/photo-atsugi-dental-open-campus.webp',
          },
        ],
        /**
         * @type {string[]}
         */
        pathArray: [],
        /**
         * @return {boolean}
         */
        isHome() {
          return this.pathArray.length === 2
        },
        /**
         * @param {string} slug
         * @return {boolean}
         */
        isPage(slug) {
          const pathname = window.location.pathname.match(/^\/atsugi\/dental\/(.*)/)[1].replace(/(.*)\/$/, '$1')
          return pathname === slug
        },
        onBeforeMount() {
          this.pathArray = this.getPathNameArray()
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-dental>