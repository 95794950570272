<page-kanagawa-highschool-subject>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="highschool">選べる2つの学科</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--fill-equal">
            <div class="o-items-center">
              <a class="o-block-link o-items-center c-circle-more-link c-circle-more-link--theme-highschool"
                href="/kanagawa/highschool/subject/industrial">
                <photo-and-title data-theme="highschool" data-photo="/img/kanagawa-highschool-industrial.webp">
                  工業技術学科
                </photo-and-title>
              </a>
            </div>
            <div class="o-items-center">
              <a class=" o-block-link o-items-center c-circle-more-link c-circle-more-link--theme-highschool"
                href="/kanagawa/highschool/subject/design">
                <photo-and-title data-theme="highschool" data-photo="/img/kanagawa-highschool-design.webp">
                  生活造形学科
                </photo-and-title>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="highschool">取得可能な資格検定</ui-heading>
        </div>
        <div>
          <star-list data-items="{ list }" data-theme="highschool"></star-list>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageKanagawaHighschoolSubject() {
      return {
        /**
         * @typedef {object} listObject
         * @property {string} title
         * @property {string} subTitle
         * @property {string} [description]
         * @property {boolean} [isHighlighted]
         */
        /**
         * @type {listObject[]}
         */
        list: [
          {
            title: '第二種電気工事士',
            subTitle: '(経済産業省)',
            description: '※工業技術学科電気コース卒業と同時に取得',
            isHighlighted: true
          },
          {
            title: '第二級アナログ通信／第二級デジタル通信 工事担任者',
            subTitle: '(1科目免除)(総務大臣)',
            description: '上記は技能連携生、または専門学校神奈川総合大学校高等課程専修生が取得可能となり、厚木中央高等学校工業科だけでは取得できません。',
          },
          {
            title: '日本漢字能力検定',
            subTitle: '((公財)日本漢字能力検定協会)',
          },
          {
            title: '秘書検定',
            subTitle: '((公財)実務技能検定協会・文部科学省後援)',
          },
          {
            title: 'ビジネス能力検定(B検)ジョブパス',
            subTitle: '((一財)職業教育・キャリア教育財団・文部科学省後援)',
          },
          {
            title: 'パソコン利用技術検定',
            subTitle: '(全国工業校長協会)',
          },
          {
            title: 'レタリング技能検定',
            subTitle: '((公財)国際文化カレッジ・文部科学省後援)',
          },
          {
            title: 'トレース技能検定',
            subTitle: '((一財)中央工学校生涯学習センター・文部科学省後援)',
          },
          {
            title: '服飾技術検定',
            subTitle: '(日本ファッション教育振興会)',
          },
          {
            title: 'ファッションビジネス能力検定',
            subTitle: '(日本ファッション教育振興会)',
          },
          {
            title: 'パターンメーキング技術検定',
            subTitle: '(日本ファッション教育振興会)',
          },
          {
            title: 'ガス溶接技能講習',
            subTitle: '(厚生労働省)',
          },
          {
            title: 'フォークリフト運転特別教育',
            subTitle: '(都道府県労働局長登録教習機関・厚生労働省後援)',
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa-highschool-subject>