<page-suzuki-request>
  <div hidden="{state.pageStateSlug != 'school-select'}" aria-hidden="{state.pageStateSlug != 'school-select'}">
    <section>
      <h1 class="c-heading-4">ご希望の資料をお選びください</h1>
      <div class="o-spacer"></div>
      <div class="o-layout o-layout--wrap c-catalog-layout">
        <div each="{catalog in catalogList}">
          <div class="o-layout o-layout--column o-layout--gap-none">
            <div>
              <catalog-photo data-photo="{catalog.image}"></catalog-photo>
            </div>
            <div>
              <div class="o-layout o-layout--column o-layout--gap-none">
                <div each="{ select in catalog.select }">
                  <label class="o-block-link c-catalog-form-label">
                    <div class="o-layout o-layout--gap-smalschool-selectl">
                      <div>
                        <input type="radio" name="school" value="{select.value}"
                          onclick="{setSchoolSlug.bind(this, select.value, select.title)}">
                      </div>
                      <div>
                        <raw-html data-content="{japaneseParser.translateHTMLString(select.title)}"></raw-html>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="o-items-center">
        <p>
          <ui-button data-disabled="{!state.schoolSlug}" onclick="{goToForm}">お問い合わせフォームを開く
          </ui-button>
        </p>
        <p if="{!state.schoolSlug}">
          まず上記よりご希望の資料を選択してください。
        </p>
      </div>
    </section>
  </div>
  <template if="{state.pageStateSlug === 'form'}">
    <p>
      <ui-button onclick="{setPageStateSlug.bind(this,'school-select')}">
        資料選択に戻る
      </ui-button>
    </p>
    <div class="o-spacer"></div>
    <form action="" class="h-adr">
      <table class="c-table">
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">お名前</div>
              <div>
                <span class="c-label">必須</span>
              </div>
            </div>
          </th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['名前']}
            </template>
            <input data-ref-input="名前" type="text" class="c-input-text" hidden="{state.mode === 'confirm'}">
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">フリガナ</div>
              <div>
                <span class="c-label">必須</span>
              </div>
            </div>
          </th>
          <td>
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['フリガナ']}
            </template>
            <input data-ref-input="フリガナ" type="text" class="c-input-text" hidden="{state.mode === 'confirm'}">
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">メールアドレス</div>
              <div>
                <span class="c-label">必須</span>
              </div>
            </div>
          </th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['メールアドレス']}
            </template>
            <input data-ref-input="メールアドレス" type="email" class="c-input-text" hidden="{state.mode === 'confirm'}">
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">電話番号</div>
            </div>
          </th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['電話番号']}
            </template>
            <input data-ref-input="電話番号" type="tel" class="c-input-text" hidden="{state.mode === 'confirm'}">
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">郵便番号</div>
              <div>
                <span class="c-label">必須</span>
              </div>
            </div>
          </th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['郵便番号']}
            </template>
            <input data-ref-input="郵便番号" type="text" class="c-input-text" onblur="{setFromPostalCode}"
              hidden="{state.mode === 'confirm'}">
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">ご住所</div>
              <div>
                <span class="c-label">必須</span>
              </div>
            </div>
          </th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['住所']}
            </template>
            <input data-ref-input="住所" type="text" class="c-input-text" data-ref="address"
              hidden="{state.mode === 'confirm'}">
          </td>
        </tr>
        <tr>
          <th class="o-size-fill">学校名</th>
          <td><input data-ref-input="学校名" type="text" class="c-input-text" hidden="{state.mode === 'confirm'}">
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">学年</div>
              <div>
                <span class="c-label">必須</span>
              </div>
            </div>
          </th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              {state.dataSubmit['学年']}
            </template>
            <select data-ref-input="学年" class="c-select c-select--size-auto" name="school-year"
              hidden="{state.mode === 'confirm'}">
              <option value="" selected="selected">選択してください</option>
              <option value="高校1年生">高校1年生</option>
              <option value="高校2年生">高校2年生</option>
              <option value="高校3年生">高校3年生</option>
              <option value="高卒生">高卒生</option>
              <option value="高卒認定">高卒認定</option>
              <option value=" ">外国人留学生</option>
              <option value="小学生">小学生</option>
              <option value="中学生">中学生</option>
              <option value="大学生">大学生</option>
              <option value="大学院生">大学院生</option>
              <option value="専門学校生">専門学校生</option>
              <option value="教師・教職員">教師・教職員</option>
              <option value="学校関係者">学校関係者</option>
              <option value="社会人">社会人</option>
              <option value="無職">無職</option>
              <option value="その他">その他</option>
            </select>
          </td>
        </tr>
        <tr>
          <th>
            <div class="o-layout">
              <div class="o-size-fill">ご希望の資料</div>
            </div>
          </th>
          <td class="o-fit">
            {state.schoolName}
          </td>
        </tr>
        <tr>
          <th>その他ご質問など</th>
          <td class="o-fit">
            <template if="{state.mode === 'confirm'}">
              <raw-html data-content="{breakToBr(state.dataSubmit['その他ご質問など'])}"></raw-html>
            </template>
            <textarea data-ref-input="その他ご質問など" class="c-textarea" cols="30" rows="5"
              hidden="{state.mode === 'confirm'}"></textarea>
          </td>
        </tr>
      </table>
      <div class="o-spacer"></div>
      <div class="o-layout o-layout--main-axis-center">
        <div if="{state.mode === 'input'}">
          <ui-button data-tag="button" onclick="{clickConform}">入力内容を確認する</ui-button>
        </div>
        <template if="{state.mode === 'confirm'}">
          <div>
            <ui-button data-tag="button" onclick="{backToInput}">修正する</ui-button>
          </div>
          <div>
            <ui-button data-tag="button" onclick="{clickSubmit}" data-button-type="submit">この内容で送信する</ui-button>
          </div>
        </template>
      </div>
    </form>
  </template>
  <template if="{state.pageStateSlug === 'thanks'}">
    <section>
      <h1 class="c-heading-4">資料請求して頂きありがとうございます。</h1>
      <p>資料がお手元に届くまで、今しばらくお待ちください。</p>
      <div class="o-spacer"></div>
      <div class="o-layout o-layout--main-axis-center">
        <div>
          <ui-button data-href="./">資料請求に戻る</ui-button>
        </div>
        <div>
          <ui-button data-href="/">鈴木学園トップに戻る</ui-button>
        </div>
      </div>
    </section>
  </template>
  <script>
    // @ts-check
    export default {
      state: {
        /**
         * @type {('input'|'confirm'|'thanks')} mode
         */
        mode: 'input',
        /**
         * @type {Object} formData
         */
        formData: {
          '名前': { require: true, value: '' },
          'フリガナ': { require: true, value: '' },
          'メールアドレス': { require: true, value: '' },
          '電話番号': { require: false, value: '' },
          '郵便番号': { require: true, value: '' },
          '住所': { require: true, value: '' },
          '学校名': { require: false, value: '' },
          '学年': { require: true, value: '' },
          'その他ご質問など': { require: false, value: '' },
        },
        /**
         * フォームに送信するデータ
         */
        dataSubmit: {},
        /**
         * @type {Boolean|null} schoolSlug
         */
        schoolSlug: null,
        /**
         * @type {Boolean|null} schoolSlug
         */
        schoolName: null,
        /**
         * @type {('school-select'|'form'|'thanks')} pageStateSlug
         */
        pageStateSlug: 'school-select',
        /**
         * @type {Boolean} submitted
         */
        submitted: false
      },
      catalogList: [
        {
          image: ['/img/catalog-chuo.png', '/img/catalog-highschool.png'],
          select: [
            {
              title: '厚木中央高等学校＋専門学校神奈川総合大学校（中卒者）',
              value: 'chuo+highschool'
            }
          ]
        },
        {
          image: ['/img/catalog-atsugi.png', '/img/catalog-chuo.png'],
          select: [
            {
              title: '調理学科（中卒者）＋厚木中央高等学校',
              value: 'chuo+cook'
            }
          ]
        },
        {
          image: ['/img/catalog-highschool.png'],
          select: [
            {
              title: '専門学校神奈川総合大学校（高等課程）',
              value: 'highschool'
            }
          ]
        },
        {
          image: ['/img/catalog-chuo.png'],
          select: [
            {
              title: '厚木中央高等学校',
              value: 'chuo'
            }
          ]
        },
        {
          image: ['/img/catalog-car.png'],
          select: [
            {
              title: '専門学校神奈川総合大学校専門課程 自動車整備（高卒以上）',
              value: 'car'
            }
          ]
        },
        {
          image: ['/img/catalog-atsugi.png'],
          select: [
            {
              title: '厚木総合専門学校 調理学科（17歳以上）',
              value: 'cook'
            },
            {
              title: '厚木総合専門学校 生活デザイン学科',
              value: 'design'
            },
            {
              title: '厚木総合専門学校 歯科衛生学科',
              value: 'dental'
            }
          ]
        },
        {
          image: ['/img/catalog-garden.jpg'],
          select: [
            {
              title: '日本ガーデンデザイン専門学校',
              value: 'garden'
            }
          ]
        },
      ],
      /**
       * @param {String} slug
       */
      setSchoolSlug(slug, title) {
        this.state.schoolSlug = slug
        this.state.schoolName = title
        this.update()
      },
      setFromPostalCode(event) {
        // @ts-ignore
        new YubinBango.Core(event.target.value, address => {
          const result = `${address.region}${address.locality}${address.street}${address.extended}`
          this.$('[data-ref="address"]').value = result
        })
      },
      /**
       * @return {Boolean}
       */
      getIssetSchoolSlug() {
        const result = !!this.state.schoolSlug
        return result
      },
      /**
       * @return {Boolean}
       */
      getIsInlineForm() {
        const result = !this.state.schoolSlug.match(/^(cook|design|dental)$/)
        return result
      },
      /**
       * @param {String} slug
       */
      setPageStateSlug(slug) {
        this.state.pageStateSlug = slug
        this.update()
        window.scrollTo(0, 0)
      },
      goToForm() {
        if (this.getIsInlineForm()) {
          this.setPageStateSlug('form')
        }
        else {
          if (this.state.schoolSlug === 'cook') {
            window.open('https://www.school-go.info/f9z653/atsugi/form.php?fno=153&fsno=1&openExternalBrowser=1', '_blank')
          }
          else if (this.state.schoolSlug === 'design') {
            window.open('https://www.school-go.info/f9z653/atsugi/form.php?fno=146&fsno=1&openExternalBrowser=1', '_blank')
          }
          else if (this.state.schoolSlug === 'dental') {
            window.open('https://www.school-go.info/f9z653/atsugi/form.php?fno=83&fsno=1&openExternalBrowser=1', '_blank')
          }
        }
      },
      clickConform(event) {
        event.preventDefault()
        this.setFormData()
      },
      resetData() {
        this.state.formData = this.state.formDataDefault
        this.state.dataSubmit = {}
      },
      setFormData() {
        this.resetData()
        Object.keys(this.state.formData).forEach(key => {
          // console.log(key)
          this.state.formData[key].value = this.$(`[data-ref-input="${key}"]`).value
          this.state.dataSubmit[key] = this.state.formData[key].value
        })
        this.state.dataSubmit['ご希望の資料'] = this.state.schoolName
        let emails = []
        this.state.schoolSlug.split('+').forEach(slug => {
          emails = [...emails, ...this.dataset.emails[slug]]
        })
        emails = [... new Set(emails)]
        this.state.dataSubmit['管理先メールアドレス'] = emails
        this.checkRequiredValue()
      },
      checkRequiredValue() {
        const result = Object.keys(this.state.formData).some(key => {
          return !this.state.formData[key].value && this.state.formData[key].require
        })
        if (result) {
          alert('恐れ入りますが入力に不足があるようです。\n「必須」と表示された入力欄に正しく記入されたかご確認ください。')
        }
        else {
          this.goToConfirm()
        }
      },
      goToConfirm() {
        this.state.mode = 'confirm'
        this.update()
        window.scrollTo(0, 0)
      },
      backToInput() {
        this.state.mode = 'input'
        this.update()
        window.scrollTo(0, 0)
      },
      clickSubmit(event) {
        event.preventDefault()
        this.submitForm()
      },
      submitForm() {
        if (!this.state.submitted) {
          this.state.submitted = true
          fetch('/cockpit/api/forms/submit/request', {
            method: 'post',
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer 35caad65cd1c9add5bdeb758fd62a0',
            },
            body: JSON.stringify({
              form: this.state.dataSubmit
            })
          })
            .then(result => result.json())
            .then(result => {
              // console.log(result)
              this.state.submitted = false
              this.goToThanks()
            })
            .catch(error => {
              console.log(error)
              alert('申し訳ありませんが、送信時に予期せぬエラーが発生しました。\nしばらく経ってから再度お試しください。')
              this.state.submitted = false
            })
        }
      },
      goToThanks() {
        this.state.pageStateSlug = 'thanks'
        this.update()
        window.scrollTo(0, 0)
      },
      backupDefaultData() {
        this.state.formDataDefault = JSON.parse(JSON.stringify(this.state.formData))
      },
      onBeforeMount() {
        this.backupDefaultData()
      },
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-suzuki-request>