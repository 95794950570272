<suzuki-menu>
  <school-menu data-items="{ menus }"></school-menu>

  <script>
    export default function SuzukiMenu() {
      return {
        menus: [
          {
            icon: 'grid',
            title: '学校案内',
            slug: '/school',
          },
          {
            icon: 'history',
            title: '学校の歩み',
            slug: '/history',
          },
          {
            icon: 'book',
            title: '資料請求',
            slug: '/request',
          },
          {
            icon: 'mail',
            title: 'お問い合わせ',
            slug: '/contact',
          },
        ]
      }
    }
  </script>
</suzuki-menu>