<page-atsugi-design-campus>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="design">一年間の流れ</ui-heading>
          <div class="c-content">
            <p>資格や経験につながる様々な行事があります。夢の実現にむけて、価値ある2年間を楽しく過ごしましょう！</p>
            <div class="c-accent-block c-accent-block--theme-design o-cancel-first-child-gap o-space">
              <p>実際の学校の様子を見てみたいという方は、ぜひ一度オープンキャンパスにご参加ください。</p>
              <p class="o-items-center">
                <ui-button data-href="/atsugi/design/open-campus" data-class="theme-design">オープンキャンパス
                </ui-button>
              </p>
            </div>
          </div>
        </div>
        <div>
          <event-calendar data-items="{ events }" data-slug="design"></event-calendar>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiDesignCampus() {
      return {
        /**
         * @typedef {object} eventObject
         * @property {string} title
         * @property {string} photo
         * @property {string[]} events
         */
        /**
         * @type {eventObject[]}
         */
        events: [
          {
            title: '4月',
            photo: '/img/atsugi-month-header-4.webp',
            events: [
              '入学式',
              '始業式',
            ],
          },
          {
            title: '5月',
            photo: '/img/design-month-header-5.webp',
            events: [
              'バスハイク（東京ディズニーリゾート）',
            ],
          },
          {
            title: '6月',
            photo: '/img/design-month-header-6.webp',
            events: [
              '健康診断',
              '三者面談',
            ],
          },
          {
            title: '7月',
            photo: '/img/design-month-header-7.webp',
            events: [
              '終業式',
            ],
          },
          {
            title: '8月',
            photo: '/img/design-month-header-8.webp',
            events: [
              '夏休み',
              '自由学習活動',
            ],
          },
          {
            title: '9月',
            photo: '/img/design-month-header-9.webp',
            events: [
              '始業式',
              '企業見学',
            ],
          },
          {
            title: '10月',
            photo: '/img/design-month-header-10.webp',
            events: [
              '学園祭(3校合同学園祭)',
            ],
          },
          {
            title: '11月',
            photo: '/img/design-month-header-11.webp',
            events: [
              '課外授業',
            ],
          },
          {
            title: '12月',
            photo: '/img/design-month-header-12.webp',
            events: [
              '針供養（ファッションコース）',
              '終業式',
            ],
          },
          {
            title: '1月',
            photo: '/img/design-month-header-1.webp',
            events: [
              '始業式',
              '推薦入試',
            ],
          },
          {
            title: '2月',
            photo: '/img/design-month-header-2.webp',
            events: [
              '卒業制作発表',
            ],
          },
          {
            title: '3月',
            photo: '/img/design-month-header-3.webp',
            events: [
              '卒業式（2年生）',
              '終業式',
            ],
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-design-campus>