<page-kanagawa-car>
  <school-header data-is-school="{ true }" data-slug="kanagawa" data-school-name="専門学校神奈川総合大学校"
    data-sub-school-slug="car" data-sub-school-cource="自動車整備科"></school-header>
  <template if="{ isHome() }">
    <div class="c-school-hero">
      <div class="c-school-hero__heading">
        <div
          class="c-school-hero__heading-text c-school-hero__heading-text--theme-car c-kanagawa-car-message o-cancel-first-child-gap">
          <p>
            同じ夢を持つ仲間と共に
            <br>自動車のスペシャリストを目指そう！
          </p>
        </div>
        <div>
          <p class="c-school-hero__sub-text">
            <span class="o-layout o-layout--wrap o-layout--gap-none">
              <span>少人数制の</span>
              <span>きめ細かい指導と、</span>
            </span>
            <span class="o-layout o-layout--wrap o-layout--gap-none">
              <span>国内外の企業へ</span>
              <span>就職率100％を誇る</span>
              <span>30年以上の実績。</span>
            </span>
            <span class="o-layout o-layout--wrap o-layout--gap-none">
              <span>自動車整備士を</span>
              <span>目指す</span>
              <span>あなたの</span>
              <span>夢を</span>
              <span>力強く</span>
              <span>バックアップ</span>
              <span>します。</span>
            </span>
          </p>
        </div>
      </div>
      <div class="c-site-hero-photo c-site-hero-photo--kanagawa-car">
        <photo-slider data-slides="{slides}" data-theme="car" data-interval="4000"></photo-slider>
      </div>
    </div>
    <div class="c-home-content">
      <wave-separator class="c-home-content__wave" data-slug="car"></wave-separator>
      <div class="o-layout o-layout--column">
        <div class="u-only-mobile">
          <slide-in data-theme="car">
            <div
              class="c-message-text c-message-text--theme-kanagawa-car c-kanagawa-car-message c-kanagawa-car-message--mobile">
              <p>
                <span class="o-layout o-layout--wrap o-layout--gap-none o-layout--main-axis-center">
                  <span>同じ夢を持つ仲間と共に</span>
                </span>
                <span class="o-layout o-layout--wrap o-layout--gap-none o-layout--main-axis-center">
                  <span>自動車の</span>
                  <span>スペシャリストを</span>
                  <span>目指そう！</span>
                </span>
              </p>
            </div>
            <div class="o-container">
              <p class="c-school-intro-sub o-nowrap">
                少人数制の<wbr>きめ細かい指導と、
                <br>国内外の企業へ<wbr>就職率100％を誇る<wbr>30年以上の実績。
                <br>自動車整備士を<wbr>目指す<wbr>あなたを<wbr>夢を<wbr>力強く<wbr>バックアップ<wbr>します。
              </p>
            </div>
          </slide-in>
        </div>
        <div>
          <div class="o-container">
            <div class="o-spacer o-spacer--large-2 u-only-desktop"></div>
            <home-nav-items data-items="{ homeNavItems }" data-theme="car"></home-nav-items>
          </div>
        </div>
        <section>
          <div class="o-container">
            <ui-heading data-tag="h1" data-theme="car">お知らせ一覧</ui-heading>
            <div class="o-layout o-layout--column o-layout--cross-axis-center">
              <div>
                <news-list data-slug="car" data-news="{ news }"></news-list>
              </div>
              <div class="o-items-center">
                <ui-button data-href="./news" data-class="theme-car">一覧を見る</ui-button>
              </div>
            </div>
          </div>
        </section>
        <div>
          <div class="o-container">
            <section>
              <ui-heading data-tag="h1" data-theme="car">
                <raw-html data-content="{japaneseParser.translateHTMLString('選ばれる<wbr>5つの理由')}"></raw-html>
              </ui-heading>
              <div class="o-spacer"></div>
              <div class="o-layout o-layout--fill-equal o-layout--column-in-mobile">
                <div class="o-items-fill-vertical">
                  <photo-and-description data-title="夢を<wbr>叶えやすい<wbr>学費" data-photo="/img/car-reason-photo.webp"
                    class="o-items-fill-vertical">
                    <p>
                      あなたの夢を学費の問題で諦めて欲しくないと思っています。
                      <br>本校では、資格取得に向けたリーズナブルな学費設定だけでなく、日本学生支援機構による奨学金や国の教育ローン、多くの企業による奨学金もご案内しております。
                    </p>
                  </photo-and-description>
                </div>
                <div class="o-items-fill-vertical">
                  <photo-and-description data-title="就職内定率<wbr>100%!!<br>国内外の<wbr>幅広いメーカーへの<wbr>就職実績"
                    data-photo="/img/car-reason-photo-2.webp" class="o-items-fill-vertical">
                    <p>
                      メーカーにとらわれない多彩な就職先が本校の大きな魅力のひとつです。<br>入学後に自分に合った就職先をじっくり選ぶことが出来ます。
                    </p>
                    <p class="o-items-center">
                      <ui-button data-tag="" data-href="#result" data-class="theme-car">就職先企業一覧</ui-button>
                    </p>
                  </photo-and-description>
                </div>
              </div>
              <div class="o-spacer"></div>
              <div class="o-layout o-layout--fill-equal o-layout--column-in-mobile">
                <div class="o-items-fill-vertical">
                  <photo-and-description class="o-items-fill-vertical" data-title="少人数制で<wbr>きめ細かい<wbr>指導"
                    data-photo="/img/car-reason-photo-3.webp">
                    <p>
                      新しいことにチャレンジすれば、当然、最初は分からない事ばかりで不安になることもあると思います。
                      <br>でも大丈夫です。勉強で分からない事や悩みがあれば、少人数制の本校では、先生がきめ細かくサポートできます。
                      <br>雰囲気もとてもアットホームな学校ですので安心してご入学ください。
                    </p>
                  </photo-and-description>
                </div>
                <div class="o-items-fill-vertical">
                  <photo-and-description class="o-items-fill-vertical" data-title="高い<wbr>資格合格率"
                    data-photo="/img/car-reason-photo-4.webp">
                    <p>
                      独自のメソッドにより高い合格率を誇ります。
                      <br>国家試験前には、個別対応も行いしっかりサポートいたします。
                    </p>
                  </photo-and-description>
                </div>
                <div class="o-items-fill-vertical">
                  <photo-and-description class="o-items-fill-vertical" data-title="好立地で<wbr>通いやすい"
                    data-photo="/img/car-reason-photo-5.webp">
                    <p>
                      小田急 本厚木駅より徒歩15分。
                      <br>バイク、自転車 無料駐輪場完備。
                      <br>※バイク通学の場合は学校への届出が必要です。
                    </p>
                  </photo-and-description>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div id="result">
          <div class="o-container">
            <kanagawa-car-result></kanagawa-car-result>
          </div>
        </div>
      </div>
    </div>
    <div class="o-spacer o-spacer--large-2"></div>
  </template>
  <template if="{ !isHome() }">
    <school-content-template if="{ isPage('subject') }" data-title="学科・教科・取得資格" data-slug="car"
      data-current="/kanagawa/car/subject" data-photo="/img/photo-kanagawa-car-subject.webp">
      <page-kanagawa-car-subject></page-kanagawa-car-subject>
    </school-content-template>
    <school-content-template if="{ isPage('campus') }" data-title="キャンパス<wbr>ライフ" data-slug="car"
      data-current="/kanagawa/car/campus" data-photo="/img/photo-kanagawa-car-campus.webp">
      <page-kanagawa-car-campus></page-kanagawa-car-campus>
    </school-content-template>
    <school-content-template if="{ isPage('message') }" data-title="先輩紹介・教師からのメッセージ" data-slug="car"
      data-current="/kanagawa/car/message" data-photo="/img/photo-kanagawa-car-message.webp">
      <page-kanagawa-car-message></page-kanagawa-car-message>
    </school-content-template>
    <school-content-template if="{ isPage('open-campus') }" data-title="オープン<wbr>キャンパス" data-slug="car"
      data-current="/kanagawa/car/open-campus" data-photo="/img/photo-kanagawa-car-open-campus.webp">
      <page-kanagawa-car-open-campus></page-kanagawa-car-open-campus>
    </school-content-template>
    <school-content-template if="{ isPage('application') }" data-title="募集要項" data-slug="car"
      data-current="/kanagawa/car/application" data-photo="/img/photo-4.webp">
      <page-kanagawa-car-application></page-kanagawa-car-application>
    </school-content-template>
    <school-content-template if="{ isPage('qa') }" data-title="よくあるご質問" data-slug="car" data-current="/kanagawa/car/qa"
      data-photo="/img/photo-kanagawa-car-qa.webp">
      <page-kanagawa-car-qa></page-kanagawa-car-qa>
    </school-content-template>
    <school-content-template if="{ isPage('news') }" data-title="お知らせ" data-slug="car" data-current="/kanagawa/car/news"
      data-photo="/img/car-news-title.svg">
      <page-kanagawa-car-news data-news="{news}"></page-kanagawa-car-news>
    </school-content-template>
  </template>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column">
    <section>
      <div class="o-container">
        <car-nav data-where="footer"></car-nav>
      </div>
    </section>
    <section>
      <group-footer></group-footer>
    </section>
  </div>
  <kanagawa-car-menu></kanagawa-car-menu>
  <script>
    // @ts-check
    export default function PageKanagawaCar() {
      return {
        /**
         * @typedef slide
         * @property {String} photo
         * @property {String} [position]
         */
        /**
         * @type {slide[]}
         */
        slides: [
          { photo: '/img/kanagawa-car-photo.webp', },
          { photo: '/img/kanagawa-car-photo-5.webp', },
          { photo: '/img/kanagawa-car-photo-4.webp', },
          { photo: '/img/kanagawa-car-photo-3.webp', },
        ],
        /**
         * @typedef {object} nav
         * @property {string} icon
         * @property {string} title
         * @property {string} href
         */
        /**
         * @type {nav[]}
         */
        homeNavItems: [
          {
            icon: 'study',
            title: '学科・<wbr>教科・<wbr>資格検定',
            href: '/kanagawa/car/subject'
          },
          {
            icon: 'school',
            title: 'キャンパス<wbr>ライフ',
            href: '/kanagawa/car/campus'
          },
          {
            icon: 'message',
            title: '教師・<wbr>先輩からの<br>メッセージ',
            href: '/kanagawa/car/message'
          },
          {
            icon: 'compass',
            title: 'オープン<wbr>キャンパス',
            href: '/kanagawa/car/open-campus'
          },
          {
            icon: 'file',
            title: '募集要項',
            href: '/kanagawa/car/application'
          },
          {
            icon: 'questions',
            title: 'よくあるご質問',
            href: '/kanagawa/car/qa'
          },
        ],
        /**
         * @typedef {object} news
         * @property {string} title
         * @property {string} date
         * @property {string} slug
         * @property {string} content
         * @property {string} [photo]
         */
        /**
         * @type {news[]}
         */
        news: [
          {
            title: '冬季休業期間のお知らせ',
            date: '2023年12月26日',
            slug: 'notice-of-winter-holidays-2023',
            content: '<p>【資料請求について】<br>年内の資料発送は12月26日（火）にて終了になります。<br>年明けの発送は1月9日（火）からです。<br>【出願、体験入学等の各種お問合せについて】<br>12月27日（水）～ 1月8日（月）は冬季休業になります。<br>期間中、お電話等の受付もお休みさせて頂きます。</p><p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '豊友祭2023を開催します',
            date: '2023年10月14日',
            slug: 'fes-2023',
            content: '<p>今年も豊友祭（学園祭）行います。<br>楽しいイベントが盛り沢山！是非遊びに来てください！</p><ul><li>日程 2023/10/28（土）</li><li>時間 10:00〜15:00</li><li>会場 神奈川県厚木市恩名1-17-18</li></ul><p><a href="/img/2023-fes-poster.pdf" target="_blank"><img src="/img/fes-2023.webp" alt="豊友祭2023ポスター"></a></p>',
            photo: '/img/news-fes-2023.webp',
          },
          {
            title: '夏季休暇期間について',
            date: '2023年8月10日',
            slug: '2022-2023',
            content: '<p>8/11（金）～8/16（水）まで夏季休暇に伴い、頂きましたお問合せや資料請求に関しましては8/17（木）から順次ご対応させて頂きます。<br>皆様には大変ご迷惑お掛けいたしますが、ご理解賜りますよう宜しくお願い致します。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '年末年始の資料請求・お問合せについて',
            date: '2022年12月26日',
            slug: '2022-2023',
            content: '<p> <b>【資料請求について】</b> 年内の資料発送は12月26日（金）にて終了になります。<br> 年明けの発送は1月6日（金）からです。 </p> <p> <b>【出願、体験入学等の各種お問合せについて】</b> 12月27日（火）～ 1月5日（木）は年末年始は休業になります。<br> 期間中、お電話等の受付もお休みさせて頂きます。 </p> <p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '11月5日開催のあつぎ鮎まつりに参加します',
            date: '2022年10月26日',
            slug: 'ayumatsuri-2022',
            content: '<p>延期されていた「第76回あつぎ鮎まつり」の開催日が11月5日に決定しました。<br>ファッションショーは下記の時間に開催致します。</p><p>日程：2022/11/5（土）<br>時間：10:30〜11:00<br>会場：厚木中央公園　特設ステージ</p><p>マドレーヌ販売、チョークアート体験、<br>カークラフト作成等のイベントもありますので<br>是非、遊びに来て下さい！</p><p><img src="/img/ayumatsuri-2022.png" alt="鈴木学園3校合同 和装アレンジファッションショー"></p>',
            photo: '/img/news-ayumatsuri-2022.png',
          },
          {
            title: '豊友祭2022を開催します',
            date: '2022年10月17日',
            slug: 'fes-2022',
            content: '<p>3年ぶりに豊友祭（学園祭）行います。<br>新型コロナ感染症拡大防止の為、ホールイベント、飲食の販売等は<br>小規模になりますが是非遊びに来てください！</p><p>日程 2022/10/29（土）<br>時間 10:00〜16:00<br>会場 神奈川県厚木市恩名1-17-18</p><p><a href="/img/2022-fes-poster.pdf" target="_blank"><img src="/img/fes-2022.png" alt="豊友祭 冷和4年10月29日（土）10:00〜16:00"></a></p>',
            photo: '/img/news-2022-fes.png',
          },
          {
            title: '第76回あつぎ鮎まつりに参加します',
            date: '2022年7月8日',
            slug: 'atsugiayumatsuri-2022',
            content: '<img src="/img/news-kanagawa-car-ayumatsuri-2022.webp">',
            photo: '/img/news-kanagawa-car-ayumatsuri-2022-thumb.webp',
          },
          {
            title: '2022年度オープンキャンパスについて',
            date: '2022年4月1日',
            slug: 'open-campus-2022',
            content: '2022年度オープンキャンパス実施予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [オープンキャンパスページ](/kanagawa/car/open-campus)',
            photo: '/img/photo-kanagawa-car-open-campus.webp',
          },
          {
            title: 'サイトリニューアルのお知らせ',
            date: '2022年2月28日',
            slug: 'site-renewal-2022',
            content: 'この度、Webサイトを全面リニューアルいたしました。',
            photo: '/img/news-renewal-car.png',
          },
          {
            title: '新型コロナウイルス感染予防について',
            date: '2022年2月28日',
            slug: 'covid-19',
            content: '- 定期的な消毒、換気、教職員のマスク着用など感染症対策に配慮しながら実施しています。\n- ご来校時に検温をお願いしています。熱がある場合は恐れ入りますが、参加をご遠慮頂いております。\n- 新型コロナウイルス感染拡大防止の為、予定が変更になる場合がございます。',
            photo: '/img/news-covid-19.webp',
          },
          {
            title: '2021年度オープンキャンパスについて',
            date: '2021年4月1日',
            slug: 'open-campus-2021',
            content: '2021年度オープンキャンパス実施予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [オープンキャンパスページ](/kanagawa/car/open-campus)',
            photo: '/img/photo-kanagawa-car-open-campus.webp',
          },
        ],
        /**
         * @type {string[]}
         */
        pathArray: [],
        /**
         * @return {boolean}
         */
        isHome() {
          return this.pathArray.length === 2
        },
        /**
         * @param {string} slug
         * @return {boolean}
         */
        isPage(slug) {
          const pathname = window.location.pathname.match(/^\/kanagawa\/car\/(.*)/)[1].replace(/(.*)\/$/, '$1')
          return pathname === slug
        },
        onBeforeMount() {
          this.pathArray = this.getPathNameArray()
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa-car>