<page-atsugi-home>
  <div class="o-block-stack-layout">
    <div>
      <div class="c-hero">
        <app-hero data-riot></app-hero>
      </div>
    </div>
    <div>
      <section class="c-section c-section--cook" id="cook">
        <div class="c-section__title">
          <img src="/img/atsugi/heading-text-cook-license.svg" alt="Cook License" data-aos="fade-up">
        </div>
        <div class="c-section__container">
          <div class="o-layout o-layout--main-axis-center o-layout--cross-axis-start c-section__layout">
            <div class="o-size-hug c-section__photos">
              <div class="c-section__photo c-section__photo--cook" data-aos="zoom-in" data-aos-offset="200">
                <img src="/img/atsugi/photo-cook.webp"
                  srcset="/img/atsugi/photo-cook.webp 1x, /img/atsugi/photo-cook@2x.webp 2x" alt="">
              </div>
              <div class="c-section__photo c-section__photo--cook" data-aos="zoom-in" data-aos-offset="200">
                <img src="/img/atsugi/photo-cook-2.webp"
                  srcset="/img/atsugi/photo-cook-2.webp 1x, /img/atsugi/photo-cook-2@2x.webp 2x" alt="">
              </div>
              <div class="c-section__photo c-section__photo--cook" data-aos="zoom-in" data-aos-offset="200">
                <img src="/img/atsugi/photo-cook-3.webp"
                  srcset="/img/atsugi/photo-cook-3.webp 1x, /img/atsugi/photo-cook-3@2x.webp 2x" alt="">
              </div>
            </div>
            <div class="c-section__content">
              <div class="o-layout o-layout--column">
                <div>
                  <div class="c-section__heading-block c-section__heading-block--cook" data-aos="fade-up">
                    <div class="c-heading">
                      <div class="o-layout o-layout--wrap o-layout--cross-axis-baseline o-layout--gap-none">
                        <h2 class="c-heading__title">調理学科</h2>
                        <p class="c-heading__sub-title">（1年制）</p>
                      </div>
                    </div>
                    <p class="c-section__heading-text">調理師免許取得</p>
                    <p class="c-section__heading-text-2">（旧）厚木調理師学校</p>
                  </div>
                </div>
                <div>
                  <p>
                    <a href="cook" class="c-button c-button--cook" data-aos="fade-up">
                      <span class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                        <span class="o-items-fill-vertical">
                          <ui-icon data-icon="home" class="c-atsugi-home-button-icon"></ui-icon>
                        </span>
                        <span>
                          調理学科サイト
                        </span>
                      </span>
                    </a>
                  </p>
                </div>
                <div>
                  <div if="{false}">
                    <a href="cook/open-campus" class="c-button c-button--cook" data-aos="fade-up">
                      <span class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                        <span class="o-items-fill-vertical">
                          <ui-icon data-icon="compass" class="c-atsugi-home-button-icon"></ui-icon>
                        </span>
                        <span>
                          オープンキャンパス
                        </span>
                      </span>
                    </a>
                  </div>
                  <div class="c-section__body" data-aos="fade-up">
                    <p>
                      中学卒業から、すべての大人の方まで　調理経験のない方でも安心して学べます。
                      <br>厚木総合専門学校では、料理の基礎や技術の他、食の大切さや自分だけの技を身に付けてもらうため２つの放課後クラブ（カービング、チョークアート）と食育講座という他にない特別授業を行っています。先生との距離が近く、和気あいあいとした雰囲気の中楽しく授業を行っています。
                    </p>
                    <ul>
                      <li>高校コース（高卒資格が取れる）</li>
                      <li>一般コース（17才から大人）</li>
                    </ul>
                    <p>
                      全ての方が勉強できる学科
                      <br>1年制で国家試験を受けずに卒業と同時に調理師免許が取得できます。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section class="c-section c-section--design" id="design">
        <div class="c-section__title">
          <img src="/img/atsugi/heading-text-it-and-fasion.svg" alt="IT and Fashion" data-aos="fade-up">
        </div>
        <div class="c-section__container">
          <div class="o-layout o-layout--main-axis-center o-layout--cross-axis-start c-section__layout">
            <div class="o-size-hug c-section__photos">
              <div class="c-section__photo c-section__photo--design" data-aos="zoom-in" data-aos-offset="200">
                <img src="/img/atsugi/photo-design.webp"
                  srcset="/img/atsugi/photo-design.webp 1x, /img/atsugi/photo-design@2x.webp 2x" alt="">
              </div>
              <div class="c-section__photo c-section__photo--design" data-aos="zoom-in" data-aos-offset="200">
                <img src="/img/atsugi/photo-design-2.webp"
                  srcset="/img/atsugi/photo-design-2.webp 1x, /img/atsugi/photo-design-2@2x.webp 2x" alt="">
              </div>
              <div class="c-section__photo c-section__photo--design" data-aos="zoom-in" data-aos-offset="200">
                <img src="/img/atsugi/photo-design-3.webp"
                  srcset="/img/atsugi/photo-design-3.webp 1x, /img/atsugi/photo-design-3@2x.webp 2x" alt="">
              </div>
            </div>
            <div class="c-section__content">
              <div class="o-layout o-layout--column">
                <div>
                  <div class="c-section__heading-block c-section__heading-block--design" data-aos="fade-up">
                    <div class="c-heading">
                      <div class="o-layout o-layout--wrap o-layout--cross-axis-baseline o-layout--gap-none">
                        <h2 class="c-heading__title">生活デザイン学科</h2>
                        <p class="c-heading__sub-title">（2年制）</p>
                      </div>
                    </div>
                    <p class="c-section__heading-text">ITコース/ファッションコース</p>
                    <p class="c-section__heading-text-2">（旧）厚木文化専門学校</p>
                  </div>
                </div>
                <div>
                  <p>
                    <a href="design" class="c-button c-button--design" data-aos="fade-up">
                      <span class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                        <span class="o-items-fill-vertical">
                          <ui-icon data-icon="home" class="c-atsugi-home-button-icon"></ui-icon>
                        </span>
                        <span>
                          生活デザイン学科サイト
                        </span>
                      </span>
                    </a>
                  </p>
                </div>
                <div if="{false}">
                  <a href="design/open-campus" class="c-button c-button--design" data-aos="fade-up">
                    <span class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                      <span class="o-items-fill-vertical">
                        <ui-icon data-icon="compass" class="c-atsugi-home-button-icon"></ui-icon>
                      </span>
                      <span>
                        オープンキャンパス
                      </span>
                    </span>
                  </a>
                </div>
                <div>
                  <div class="c-section__body" data-aos="fade-up">
                    <p>
                      ITコースとファッションコース２つのコースがあります。
                      <br>基礎からプロ技術まで少人数例で一人ひとりの特性に合わせたきめ細やかに指導をして、2年後の進路選択の幅を広げます。初めての方でも安心して通えます。
                      <br>多様性に対応しています。留学生コースも用意しています。
                      <br>留学生に対しては特別にJLPT資格取得のための日本語授業を行い、就職をサポートしています。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section class="c-section c-section--dental" id="dental">
        <div class="c-section__title">
          <img src="/img/atsugi/heading-text-dental-hygienist.svg" alt="dental hygienist" data-aos="fade-up">
        </div>
        <div class="c-section__container">
          <div class="o-layout o-layout--main-axis-center o-layout--cross-axis-start c-section__layout">
            <div class="o-size-hug c-section__photos">
              <div class="c-section__photo c-section__photo--dental" data-aos="zoom-in" data-aos-offset="200">
                <img src="/img/atsugi/photo-dental.webp"
                  srcset="/img/atsugi/photo-dental.webp 1x, /img/atsugi/photo-dental@2x.webp 2x" alt="">
              </div>
              <div class="c-section__photo c-section__photo--dental" data-aos="zoom-in" data-aos-offset="200">
                <img src="/img/atsugi/photo-dental-2.webp"
                  srcset="/img/atsugi/photo-dental-2.webp 1x, /img/atsugi/photo-dental-2@2x.webp 2x" alt="">
              </div>
              <div class="c-section__photo c-section__photo--dental" data-aos="zoom-in" data-aos-offset="200">
                <img src="/img/atsugi/photo-dental-3.webp"
                  srcset="/img/atsugi/photo-dental-3.webp 1x, /img/atsugi/photo-dental-3@2x.webp 2x" alt="">
              </div>
            </div>
            <div class="c-section__content">
              <div class="o-layout o-layout--column">
                <div>
                  <div class="c-section__heading-block c-section__heading-block--dental" data-aos="fade-up">
                    <div class="c-heading">
                      <div class="o-layout o-layput--wrap o-layout--cross-axis-baseline o-layout--gap-none">
                        <h2 class="c-heading__title">歯科衛生学科</h2>
                        <p class="c-heading__sub-title">（3年制）</p>
                      </div>
                    </div>
                    <p class="c-section__heading-text">歯科衛生士（国）受験資格</p>
                    <p class="c-section__heading-text-2">（旧）関東歯科衛生士専門学校</p>
                  </div>
                </div>
                <div>
                  <p>
                  <div class="o-layout o-layout--column">
                    <div>
                      <a href="dental" class="c-button c-button--dental" data-aos="fade-up">
                        <span class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                          <span class="o-items-fill-vertical">
                            <ui-icon data-icon="home" class="c-atsugi-home-button-icon"></ui-icon>
                          </span>
                          <span>
                            歯科衛生学科サイト
                          </span>
                        </span>
                      </a>
                    </div>
                    <div if="{false}">
                      <a href="dental/open-campus" class="c-button c-button--dental" data-aos="fade-up">
                        <span class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                          <span class="o-items-fill-vertical">
                            <ui-icon data-icon="compass" class="c-atsugi-home-button-icon"></ui-icon>
                          </span>
                          <span>
                            オープンキャンパス
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                  </p>
                </div>
                <div>
                  <div class="c-section__body" data-aos="fade-up">
                    <p>
                      自分の歯で美味しく食べる事、楽しく会話する事、お口の健康を基本に全身の健康をサポートする専門職としての歯科衛生士を目指します。
                      <br>厚生労働大臣指定校なので卒業時には国家試験の受験資格が取得できます。全員合格を目指してきめ細かい指導を行っています。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="c-school-photos-block">
    <div class="c-school-photos">
      <div class="c-school-photos__box">
        <img src="/img/atsugi/photo.webp" srcset="/img/atsugi/photo.webp 1x, /img/atsugi/photo@2x.webp 2x" alt="">
        <p class="c-school-photos__text">新しい校舎は明るくてキレイ！</p>
      </div>
      <div class="c-school-photos__box-2">
        <img src="/img/atsugi/photo-2.webp" srcset="/img/atsugi/photo-2.webp 1x, /img/atsugi/photo-2@2x.webp 2x" alt="">
        <p class="c-school-photos__text c-school-photos__text--type-2">調理実習棟</p>
      </div>
    </div>
  </div>
  <section id="open-campus">
    <div class="c-school-info-block">
      <div class="o-layout o-layout--column o-layout--cross-axis-center o-layout--gap-large">
        <div>
          <div class="c-atsugi-container">
            <div class="o-layout o-layout--column o-layout--cross-axis-center o-layout--gap-small">
              <div class="o-cancel-first-child-gap">
                <h2 class="c-heading-2">オープンキャンパス実施中！</h2>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="c-atsugi-container">
            <div class="o-layout o-layout--wrap o-layout--gap-large o-layout--main-axis-center c-school-info-layout">
              <section>
                <div class="c-school-info c-school-info--cook">
                  <div class="o-layout o-layout--column o-layout--gap-small o-layout--cross-axis-start">
                    <div class="o-cancel-first-child-gap">
                      <h2 class="c-school-info__name">調理学科</h2>
                    </div>
                    <div>
                      <a href="cook/open-campus" class="c-button c-button--cook" data-aos="fade-up">
                        <span class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                          <span class="o-items-fill-vertical">
                            <ui-icon data-icon="compass" class="c-atsugi-home-button-icon"></ui-icon>
                          </span>
                          <span>
                            詳細を見る
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div class="c-school-info c-school-info--design">
                  <div class="o-layout o-layout--column o-layout--gap-small o-layout--cross-axis-start">
                    <div class="o-cancel-first-child-gap">
                      <h2 class="c-school-info__name">生活デザイン学科</h2>
                    </div>
                    <div>
                      <a href="design/open-campus" class="c-button c-button--design" data-aos="fade-up">
                        <span class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                          <span class="o-items-fill-vertical">
                            <ui-icon data-icon="compass" class="c-atsugi-home-button-icon"></ui-icon>
                          </span>
                          <span>
                            詳細を見る
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div class="c-school-info c-school-info--dental">
                  <div class="o-layout o-layout--column o-layout--gap-small o-layout--cross-axis-start">
                    <div class="o-cancel-first-child-gap">
                      <h2 class="c-school-info__name">歯科衛生学科</h2>
                    </div>
                    <div>
                      <a href="dental/open-campus" class="c-button c-button--dental" data-aos="fade-up">
                        <span class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                          <span class="o-items-fill-vertical">
                            <ui-icon data-icon="compass" class="c-atsugi-home-button-icon"></ui-icon>
                          </span>
                          <span>
                            詳細を見る
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="c-map-block" id="access">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3250.5957092114772!2d139.36007221526103!3d35.440043850769044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019aab27ade64b3%3A0xa52bb342f5e50b8a!2z44CSMjQzLTAwMTgg56We5aWI5bed55yM5Y6a5pyo5biC5Lit55S677yT5LiB55uu77yU4oiS77yR77yR!5e0!3m2!1sja!2sjp!4v1609860662440!5m2!1sja!2sjp"
            width="100%" height="600" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
            tabindex="0" class="c-map-block__map"></iframe>
        </div>
        <section>
          <div class="c-footer">
            <div class="c-atsugi-container">
              <div class="o-layout o-layout--column o-layout--cross-axis-center">
                <div>
                  <p>
                    <img src="/img/atsugi/school-name.svg" alt="厚木総合専門学校" class="c-footer-school-name">
                  </p>
                </div>
                <div>
                  <div class="o-layout o-layout--cross-axis-center">
                    <div>
                      <span class="c-school-info__tel">Tel</span>
                    </div>
                    <div>
                      <span class="c-school-info__tel-number">046-224-6311</span>
                    </div>
                  </div>
                </div>
                <div>
                  <p>〒243-0018 神奈川県厚木市中町3-4-11（本厚木駅より徒歩2分）</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
  <script>
    export default {
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-atsugi-home>