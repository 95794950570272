<page-kanagawa-highschool-open-campus>
  <section>
    <ui-heading data-tag="h1" data-theme="highschool">
      <small>2024年度 専門学校神奈川総合大学校 高等課程</small>
      <br>体験入学
    </ui-heading>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-highschool">体験入学&amp;学校説明会</h1>
      <p>
        体験入学&amp;学校説明会
        <br>10:00〜12:00
      </p>
      <table class="c-table c-table--keep-layout o-space">
        <tr>
          <th style="width:8em;">内容</th>
          <th>日付</th>
          <th>体験授業の内容</th>
        </tr>
        <tr>
          <td rowspan="10">
            <ul>
              <li>学校説明会</li>
              <li>施設見学</li>
              <li>入試内容</li>
              <li>国県支援金</li>
              <li>個別相談</li>
              <li>体験授業</li>
            </ul>
          </td>
          <td>6月29日（土）</td>
          <td>
            <ul>
              <li>電気基礎実習体験</li>
              <li>電気工事士体験</li>
              <li>消しゴムはんこ制作体験</li>
              <li>図形制作体験</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>7月13日（土）</td>
          <td>
            <ul>
              <li>消しゴムはんこ制作体験</li>
              <li>暗算の力を引き出そう</li>
              <li>色鉛筆絵画体験</li>
              <li>浴衣にぴったり花巾着バック作り</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>7月27日（土）</td>
          <td>
            <ul>
              <li>電気工事士体験</li>
              <li>図形制作体験</li>
              <li>消しゴムはんこ制作体験</li>
              <li>自動車整備士実習体験</li>
              <li>プリンセス体験＆折り紙レジン作り体験</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>8月1日（木）</td>
          <td>
            <ul>
              <li>電気基礎実習体験</li>
              <li>英語の豆知識</li>
              <li>消しゴムはんこ制作体験</li>
              <li>自動車整備士実習体験</li>
              <li>浴衣にぴったり花巾着バック作り</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>8月3日（土）</td>
          <td>
            <ul>
              <li>電気基礎実習体験</li>
              <li>電気工事士体験</li>
              <li>消しゴムはんこ制作体験</li>
              <li>英語の豆知識</li>
              <li>浴衣洋風コーデ体験</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>8月28日（水）</td>
          <td>
            <ul>
              <li>電気基礎実習体験</li>
              <li>暗算の力を引き出そう</li>
              <li>英語の豆知識</li>
              <li>自動車整備士実習体験</li>
              <li>浴衣洋風コーデ体験</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>8月29日（木）</td>
          <td>
            <ul>
              <li>電気基礎実習体験</li>
              <li>電気工事士体験</li>
              <li>消しゴムはんこ制作体験</li>
              <li>英語の豆知識</li>
              <li>プリンセス体験＆耳カチューシャづくり</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>9月14日（土）</td>
          <td>
            <ul>
              <li>電気基礎実習体験</li>
              <li>図形制作体験</li>
              <li>消しゴムはんこ制作体験</li>
              <li>プリンセス体験＆折り紙レジン作り体験</li>
              <li>色鉛筆絵画体験</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>9月28日（土）</td>
          <td>
            <ul>
              <li>電気基礎実習体験</li>
              <li>電気工事士体験</li>
              <li>消しゴムはんこ制作体験</li>
              <li>暗算の力を引き出そう</li>
              <li>プリンセス体験＆耳カチューシャづくり</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>10月5日（土）</td>
          <td>
            <ul>
              <li>図形制作体験</li>
              <li>色鉛筆絵画体験</li>
              <li>消しゴムはんこ制作体験</li>
              <li>プリンセス体験＆折り紙レジン作り体験</li>
            </ul>
          </td>
        </tr>
        <template if="{false}">
          <tr>
            <th rowspan="3">
              入学説明会
              <br>（学校説明のみ）
            </th>
            <td rowspan="3">
              10:00〜12:00
              <ul>
                <li>学校説明会</li>
                <li>施設見学</li>
                <li>入試内容</li>
                <li>国県支援金</li>
                <li>個別相談</li>
              </ul>
              ※実習はありません。
            </td>
            <td>11月19日（土）</td>
          </tr>
          <tr>
            <td>11月26日（土）</td>
          </tr>
          <tr>
            <td>12月3日（土）</td>
          </tr>
        </template>
      </table>
      <ul class="c-note-list">
        <li>日付によって体験授業の内容が異なりますので、注意してお申込み下さい。</li>
      </ul>
    </section>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-highschool">当日の持ち物</h1>
      <ul>
        <li>上履き</li>
        <li>筆記用具</li>
      </ul>
    </section>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-highschool">参加費用</h1>
      <div class="c-content">
        <p>無料</p>
      </div>
    </section>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-highschool" id="form">お申し込みフォーム</h1>
      <template if="{state.mode === 'thanks'}">
        <section>
          <h1 class="c-heading-5 c-heading-5--theme-highschool">お問い合わせ頂きありがとうございます。</h1>
        </section>
      </template>
      <template if="{state.mode != 'thanks'}">
        <div class="c-content">
          <p>各日程前日までに、下記の申し込みフォームよりお申し込みください。</p>
        </div>
        <div class="o-space">
          <table class="c-table">
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">お名前</div>
                  <div>
                    <span class="c-label c-label--theme-highschool">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['名前']}
                </template>
                <input data-ref-input="名前" hidden="{state.mode === 'confirm'}" type="text" class="c-input-text">
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">フリガナ</div>
                  <div>
                    <span class="c-label c-label--theme-highschool">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['フリガナ']}
                </template>
                <input data-ref-input="フリガナ" hidden="{state.mode === 'confirm'}" type="text" class="c-input-text">
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">メールアドレス</div>
                  <div>
                    <span class="c-label c-label--theme-highschool">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['メールアドレス']}
                </template>
                <input data-ref-input="メールアドレス" hidden="{state.mode === 'confirm'}" type="email" class="c-input-text">
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">中学校名</div>
                  <div>
                    <span class="c-label c-label--theme-highschool">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['中学校名']}
                </template>
                <input data-ref-input="中学校名" hidden="{state.mode === 'confirm'}" type="text" class="c-input-text">
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">参加日</div>
                  <div>
                    <span class="c-label c-label--theme-highschool">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['参加日']}
                </template>
                <select name="date" id="list1" onchange="{changeList2}" hidden="{state.mode === 'confirm'}"
                  data-ref-input="参加日">
                  <option value="">選択してください</option>
                  <option value="体験入学&学校説明会 2024年6月29日(土) 10:00〜">体験入学&amp;学校説明会 2024年6月29日(土) 10:00〜</option>
                  <option value="体験入学&学校説明会 2024年7月13日(土) 10:00〜">体験入学&amp;学校説明会 2024年7月13日(土) 10:00〜</option>
                  <option value="体験入学&学校説明会 2024年7月27日(土) 10:00〜">体験入学&amp;学校説明会 2024年7月27日(土) 10:00〜</option>
                  <option value="体験入学&学校説明会 2024年8月1日(木) 10:00〜">体験入学&amp;学校説明会 2024年8月1日(木) 10:00〜</option>
                  <option value="体験入学&学校説明会 2024年8月3日(土) 10:00〜">体験入学&amp;学校説明会 2024年8月3日(土) 10:00〜</option>
                  <option value="体験入学&学校説明会 2024年8月28日(水) 10:00〜">体験入学&amp;学校説明会 2024年8月28日(水) 10:00〜</option>
                  <option value="体験入学&学校説明会 2024年8月29日(木) 10:00〜">体験入学&amp;学校説明会 2024年8月29日(木) 10:00〜</option>
                  <option value="体験入学&学校説明会 2024年9月14日(土) 10:00〜">体験入学&amp;学校説明会 2024年9月14日(土) 10:00〜</option>
                  <option value="体験入学&学校説明会 2024年9月28日(土) 10:00〜">体験入学&amp;学校説明会 2024年9月28日(土) 10:00〜</option>
                  <option value="体験入学&学校説明会 2024年10月5日(土) 10:00〜">体験入学&amp;学校説明会 2024年10月5日(土) 10:00〜</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">体験授業</div>
                  <div>
                    <span class="c-label c-label--theme-highschool">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['体験授業']}
                </template>
                <select name="date" id="list2" hidden="{state.mode === 'confirm'}" data-ref-input="体験授業">
                  <option value="">選択してください</option>
                  <!-- <option value="電気基礎実験">電気基礎実験</option>
                  <option value="電気工事士体験 or 金属管加工体験">電気工事士体験 or 金属管加工体験</option>
                  <option value="消しゴムはんこ">消しゴムはんこ</option>
                  <option value="色鉛筆で描画">色鉛筆で描画</option>
                  <option value="英語の豆知識">英語の豆知識</option>
                  <option value="図形作成">図形作成</option>
                  <option value="プリンセス体験 or 耳カチューシャ">プリンセス体験 or 耳カチューシャ</option>
                  <option value="頭の中でそろばん">頭の中でそろばん</option>
                  <option value="自動車整備とは">自動車整備とは</option> -->
                </select>
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">参加人数</div>
                  <div>
                    <span class="c-label c-label--theme-highschool">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['参加人数']}
                </template>
                <input data-ref-input="参加人数" hidden="{state.mode === 'confirm'}" type="number" min="1" length="2"
                  class="c-input-text c-input-text--size-auto">
              </td>
            </tr>
            <tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">電話番号</div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['電話番号']}
                </template>
                <input data-ref-input="電話番号" hidden="{state.mode === 'confirm'}" type="tel" class="c-input-text">
              </td>
            </tr>
            <tr>
              <th>備考</th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  <raw-html data-content="{breakToBr(state.dataSubmit['備考'])}"></raw-html>
                </template>
                <textarea data-ref-input="備考" hidden="{state.mode === 'confirm'}" class="c-textarea" cols="30"
                  rows="5"></textarea>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div class="o-items-center o-space">
            <div class="o-layout">
              <div if="{state.mode !== 'confirm'}">
                <ui-button data-tag="button" data-class="theme-highschool" onclick="{clickConform}">入力内容を確認する
                </ui-button>
              </div>
              <template if="{state.mode === 'confirm'}">
                <div>
                  <ui-button data-tag="button" data-class="theme-highschool" onclick="{backToInput}">修正する</ui-button>
                </div>
                <div>
                  <ui-button data-tag="button" data-class="theme-highschool" onclick="{clickSubmit}"
                    data-button-type="submit">この内容で送信する</ui-button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </section>
  </section>
  </div>
  <script>
    export default {
      state: {
        /**
         * @type {('input'|'confirm'|'thanks')} mode
         */
        mode: 'input',
        /**
         * @type {Object} formData
         */
        formData: {
          '名前': { require: true, value: '' },
          'フリガナ': { require: true, value: '' },
          'メールアドレス': { require: true, value: '' },
          '中学校名': { require: true, value: '' },
          '参加日': { require: true, value: '' },
          '体験授業': { require: true, value: '' },
          '参加人数': { require: true, value: '' },
          '電話番号': { require: false, value: '' },
          '備考': { require: false, value: '' },
        },
        /**
         * フォームに送信するデータ
         */
        dataSubmit: {},
      },
      /**
       * @return {Boolean}
       */
      getIsInlineForm() {
        const result = !this.state.schoolSlug.match(/(cook|design|dental)/)
        return result
      },
      goToForm() {
        if (this.getIsInlineForm()) {
          this.setPageStateSlug('form')
        }
        else {
          window.open('https://www.school-go.info/f9z653/atsugi/form.php?fno=23&fsno=1&openExternalBrowser=1', '_blank')
        }
      },
      /**
       * @param {String} slug
       */
      setPageStateSlug(slug) {
        this.state.pageStateSlug = slug
        this.update()
        window.scrollTo(0, 0)
      },
      clickConform(event) {
        event.preventDefault()
        this.setFormData()
      },
      resetData() {
        this.state.formData = this.state.formDataDefault
        this.state.dataSubmit = {}
      },
      setFormData() {
        this.resetData()
        Object.keys(this.state.formData).forEach(key => {
          // console.log(key)
          this.state.formData[key].value = this.$(`[data-ref-input="${key}"]`).value
          this.state.dataSubmit[key] = this.state.formData[key].value
        })
        this.state.dataSubmit['問い合わせ先の学校名'] = '神奈川総合大学校 高等課程'
        this.state.dataSubmit['タイトル'] = '体験入学'
        this.state.dataSubmit['管理先メールアドレス'] = this.dataset.emails.highschool
        this.checkRequiredValue()
      },
      checkRequiredValue() {
        const result = Object.keys(this.state.formData).some(key => {
          return !this.state.formData[key].value && this.state.formData[key].require
        })
        if (result) {
          alert('恐れ入りますが入力に不足があるようです。\n「必須」と表示された入力欄に正しく記入されたかご確認ください。')
        }
        else {
          this.goToConfirm()
        }
      },
      goToConfirm() {
        this.state.mode = 'confirm'
        this.update()
        window.location.href = '#form'
      },
      backToInput() {
        this.state.mode = 'input'
        this.update()
        window.location.href = '#form'
      },
      clickSubmit(event) {
        event.preventDefault()
        this.submitForm()
      },
      submitForm() {
        if (!this.state.submitted) {
          this.state.submitted = true
          fetch('/cockpit/api/forms/submit/opencampus', {
            method: 'post',
            credentials: "same-origin",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer 35caad65cd1c9add5bdeb758fd62a0',
            },
            body: JSON.stringify({
              form: this.state.dataSubmit
            })
          })
            .then(result => result.json())
            .then(result => {
              console.log(result)
              this.state.submitted = false
              this.goToThanks()
            })
            .catch(error => {
              console.log(error)
              alert('申し訳ありませんが、送信時に予期せぬエラーが発生しました。\nしばらく経ってから再度お試しください。')
              this.state.submitted = false
            })
        }
      },
      goToThanks() {
        this.state.mode = 'thanks'
        this.update()
        window.location.href = '#form'
      },
      backupDefaultData() {
        this.state.formDataDefault = JSON.parse(JSON.stringify(this.state.formData))
      },
      onBeforeMount() {
        this.backupDefaultData()
      },
      onMounted() {
        this.scrollToHashedElement()
      },
      changeList2() {
        var list1 = document.getElementById("list1");
        var list2 = document.getElementById("list2");
        list2.innerHTML = "";

        switch (list1.value) {
          case "体験入学&学校説明会 2024年6月29日(土) 10:00〜":
            var options = ["電気基礎実習体験", "電気工事士体験", "消しゴムはんこ制作体験", "図形制作体験"];
            break;
          case "体験入学&学校説明会 2024年7月13日(土) 10:00〜":
            var options = ["消しゴムはんこ制作体験", "暗算の力を引き出そう", "色鉛筆絵画体験", "浴衣にぴったり花巾着バック作り"];
            break;
          case "体験入学&学校説明会 2024年7月27日(土) 10:00〜":
            var options = ["電気工事士体験", "図形制作体験", "消しゴムはんこ制作体験", "自動車整備士実習体験", "プリンセス体験＆折り紙レジン作り体験"];
            break;
          case "体験入学&学校説明会 2024年8月1日(木) 10:00〜":
            var options = ["電気基礎実習体験", "英語の豆知識", "消しゴムはんこ制作体験", "自動車整備士実習体験", "浴衣にぴったり花巾着バック作り"];
            break;
          case "体験入学&学校説明会 2024年8月3日(土) 10:00〜":
            var options = ["電気基礎実習体験", "電気工事士体験", "消しゴムはんこ制作体験", "英語の豆知識", "浴衣洋風コーデ体験"];
            break;
          case "体験入学&学校説明会 2024年8月28日(水) 10:00〜":
            var options = ["電気基礎実習体験", "暗算の力を引き出そう", "英語の豆知識", "自動車整備士実習体験", "浴衣洋風コーデ体験"];
            break;
          case "体験入学&学校説明会 2024年8月29日(木) 10:00〜":
            var options = ["電気基礎実習体験", "電気工事士体験", "消しゴムはんこ制作体験", "英語の豆知識", "プリンセス体験＆耳カチューシャづくり"];
            break;
          case "体験入学&学校説明会 2024年9月14日(土) 10:00〜":
            var options = ["電気基礎実習体験", "図形制作体験", "消しゴムはんこ制作体験", "プリンセス体験＆折り紙レジン作り体験", "色鉛筆絵画体験"];
            break;
          case "体験入学&学校説明会 2024年9月28日(土) 10:00〜":
            var options = ["電気基礎実習体験", "電気工事士体験", "消しゴムはんこ制作体験", "暗算の力を引き出そう", "プリンセス体験＆耳カチューシャづくり"];
            break;
          case "体験入学&学校説明会 2024年10月5日(土) 10:00〜":
            var options = ["図形制作体験", "色鉛筆絵画体験", "消しゴムはんこ制作体験", "プリンセス体験＆折り紙レジン作り体験"];
            break;
          default:
            var options = ["選択してください"];
        }

        // 新しいオプションをドロップダウンリストに 追加
        for (var i = 0; i < options.length; i++) {
          var option = document.createElement("option");
          option.text = options[i];
          list2.add(option);
        }
      }
    }
  </script>
</page-kanagawa-highschool-open-campus>