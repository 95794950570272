<page-atsugi-design-it>
  <div class="o-layout o-layout--column">
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="design">ファッション業界で働くための基礎と専門知識が身につきます！</ui-heading>
        <p>
          ファッション･モードの発信基地をめざすわが国の服飾界をリードする人材には、日々個性化･多様化し続ける時代のニーズを鋭く見抜く感性と、大胆な発想を活かすパワーが不可欠です。
          <br>本校では、ファッションに対する｢興味｣を｢感性｣に高め、さらに｢創造力｣として磨き上げるため、柔軟なカリキュラムで最先端の教育を実施します。
          <br>服飾技術はもちろん、ビジネスや販売に関する資格取得もしっかりサポートしますので、ミシンが初めての方でも心配ありません。
          <br>少人数制だからこそできるきめ細やかなサポートで、一人ひとりのレベルに合わせて丁寧に教えています。
        </p>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="design">カリキュラム</ui-heading>
        <div class="o-layout o-layout--column">
          <div>
            <h2 class="c-heading-4 c-heading-4--theme-design">進路選択の幅を広げる「ファッション&ビジネス」の併修システム</h2>
          </div>
          <div>
            <div class="o-layout o-layout--fill-equal o-layout--column-in-mobile">
              <div>
                <ui-box data-theme="design" data-heading-tag="h1" data-heading="1年次">
                  <div class="o-cancel-first-child-gap">
                    <p>
                      1年次には、製図をはじめとして、縫製デザイン、カッティング等の基礎技術の修得を中心に専門科目も学びます。
                      <br>実際にブラウスやスカートの制作も行います。
                    </p>
                  </div>
                </ui-box>
              </div>
              <div>
                <ui-box data-theme="design" data-heading-tag="h1" data-heading="2年次">
                  <div class="o-cancel-first-child-gap">
                    <p>
                      2年次には各科目の応用としての理論と実技、ファッションビジネス学やコンピュータ等、幅広く専門知識を身につけます。
                      <br>社会に出てから役立つビジネスソフトの習得やスーツの作製、自由制作としてウェディングドレス作製にもチャレンジできます。
                    </p>
                  </div>
                </ui-box>
              </div>

            </div>
          </div>
        </div>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-design">専門科目</h1>
          <table class="c-table o-space">
            <tr>
              <th>ファッション造形</th>
              <td>洋裁の基礎として作図から縫製方法までを学びます。</td>
            </tr>
            <tr>
              <th>カラーコーディネート</th>
              <td>色彩により印象は大きく変わります。色彩の基本を学びます。</td>
            </tr>
            <tr>
              <th>ファッションデザイン</th>
              <td>デザイン画の基本技術を学び、表現方法を習得します。</td>
            </tr>
            <tr>
              <th>パターンメーキング</th>
              <td>パターンメーキングを学習することにより、体にあった型紙作りが可能になります。</td>
            </tr>
            <tr>
              <th>手芸</th>
              <td>小物作りなどや制作物の価値を上げる刺繍なども学びます。</td>
            </tr>
            <tr>
              <th>ファッションビジネス</th>
              <td>ファッション業界、流通、マーチャンダイジングを学習します。</td>
            </tr>
          </table>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-design">一般教養科目</h1>
          <table class="c-table o-space">
            <tr>
              <th>国語・英語</th>
              <td>ビジネスで必要な文章の書き方や簡単な英会話を、初めての方でも安心して学べます。</td>
            </tr>
            <tr>
              <th>CGデザイン</th>
              <td>アプリケーションソフトのイラストレーターの使い方からデザインの方法を学びます。</td>
            </tr>
            <tr>
              <th>ビジネス</th>
              <td>就職試験に向けて対策や、履歴書の作成・面接の受け方を学びます。</td>
            </tr>
            <tr>
              <th>ビジネスアプリケーション</th>
              <td>ExcelやWordなどのビジネスアプリの使い方を、資格取得を目指し基礎から学びます。</td>
            </tr>
          </table>
        </section>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiDesignIt() {
      return {
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-design-it>