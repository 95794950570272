<school-menu>
  <div class="c-school-menu o-space-large" data-ref="menu">
    <div class="o-layout o-layout--gap-none o-layout--fill c-school-menu__layout">
      <div each="{ item in props.dataItems }">
        <a href="{ item.slug }" class="c-school-menu__link" target="{item.target}">
          <div class="o-layout o-layout--column o-layout--gap-none o-layout--cross-axis-center">
            <div>
              <ui-icon data-icon="{ item.icon }" class="{ getIconClass() }"></ui-icon>
            </div>
            <div>
              <div class="c-school-menu__title">
                <raw-html data-content="{ item.title }"></raw-html>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <script>
    export default function SchoolMenu() {
      return {
        getIconClass() {
          const result = ['c-school-menu__icon']
          if (this.props.dataSlug) result.push(`c-school-menu__icon--theme-${this.props.dataSlug}`)
          return result.join(' ')
        },
        setHeightToVariable() {
          let height = Number(this.$('[data-ref="menu"]').offsetHeight)
          document.documentElement.style.setProperty('--mobile-mobile-footer-menu-height', `${height}px`)
        },
        onMounted() {
          this.setHeightToVariable()
          window.addEventListener('resize', this.setHeightToVariable)
        }
      }
    }
  </script>
</school-menu>