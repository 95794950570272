<suzuki-header class="c-suzuki-header-layout">
  <div class="c-suzuki-header">
    <div class="o-layout o-layout--cross-axis-center">
      <div class="o-size-fill">
        <a href="/">
          <logo-suzuki class="c-suzuki-header__logo" data-width="100%"></logo-suzuki>
        </a>
      </div>
      <div class="o-size-hug c-suzuki-header__toggle">
        <button class="c-suzuki-header__toggle-button" onclick="{ toggle }">
          <ui-icon data-icon="hamburger"></ui-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="c-school-header-nav" data-is-opened="{ state.isOpened }">
    <div class="c-school-header-nav__scroll-area">
      <suzuki-nav data-where="header"></suzuki-nav>
    </div>
    <button class="c-school-header-nav__button" onclick="{ toggle }" data-is-opened="{ state.isOpened }">
      <ui-icon data-icon="x"></ui-icon>
    </button>
  </div>
  <script>
    // @ts-check
    export default function SuzukiHeader() {
      return {
        state: {
          /** @type {boolean} */
          isOpened: false,
        },
        toggle() {
          this.state.isOpened = !this.state.isOpened
          this.update()
        }
      }
    }
  </script>
</suzuki-header>