<page-kanagawa-car-qa>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column c-q-and-a-layout">
    <div each="{ message in messages }">
      <q-and-a data-item="{ message }" data-slug="car"></q-and-a>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageKanagawaCarQa() {
      return {
        /**
         * @typedef {object} qAndA
         * @property {string} question 単純文字列
         * @property {string} answer HTML文字列
         */
        /**
         * @type { qAndA[] }
         */
        messages: [
          {
            question: '機械は詳しくないけれど、大丈夫ですか？',
            answer: 'はい、大丈夫です。授業では、工具の使い方や、ねじの締め方などの整備作業の基礎から学んでいきます。車が好きなら誰でもチャレンジできます。',
          },
          {
            question: '女性も入学できますか？',
            answer: 'はい。もちろん入学可能です。本校を卒業し国内外のメーカー系ディーラーに就職した実績もあり、また学校生活でも男女関係なく楽しく過ごしています。<br>また、現在では企業側でも性別に関係なく働きやすい職場環境を整えており、以前に比べて女性の整備士も有望視されています。',
          },
          {
            question: 'どうすれば推薦がもらえますか？',
            answer: '本校の定める基準を満たしている方は、在籍している高等学校に申請を行ってください。また、企業による推薦も受け付けております。',
          },
          {
            question: '自動車メーカー系列の整備専門学校との違いは何ですか？',
            answer: '様々なメーカーの教材を取り揃えておりますので、より幅広い技術を習得する事が可能です。<br>また、就職先もおいても、幅広く様々な企業から求人を頂いております。国内外のメーカー系ディーラーはもちろん、バイク関係や研究開発、バス、トラック、重機など、多彩な企業から将来を選択することが出来ます。',
          },
          {
            question: '学費が心配です。',
            answer: 'ご安心ください。本校は、学びやすい学費設定をしています。奨学金については、日本学生支援機構の奨学金や国の教育ローンがあります。また、内定先による企業独自の奨学金システムも多数あります。お気軽にご相談ください。',
          },
          {
            question: '勉強が苦手です。授業についていけますか？',
            answer: '本校では、できる限りわかりやすい言葉を使ったり、徐々に現場の専門的な言葉を取り入れるなど、スムーズに即戦力として働く事ができるスキルを身につけるため、スタッフもわかりやすい教育を目指して日々努力しています。<br>わからない点があれば、理解できるまで時間をかけてお教えしますので、いつでも気軽に質問してください。',
          },
          {
            question: '一級自動車整備士と二級自動車整備士の違いは何ですか？',
            answer: '現在、二級自動車整備士でも十分に活躍することはできます。しかし、自動運転や、電気自動車などのシステムの高度化により、一級整備士が社会から必要とされています。<br>さらに、2020年度からスタートした<strong>「特定整備制度」</strong>に対応できる資格として、自動車業界において欠かせない存在となりました。<br>就職後でも取得は可能ですが、学生に比べ合格率に大きな差があります。勉強に専念できる在学中に取得することをお勧めします。',
          },
          {
            question: '就職先はどのような所がありますが？',
            answer: '就職先は様々な企業から求人を頂いております。国内外のメーカー系ディーラーはもちろん、バイク関係や研究開発、バス、トラック、重機など、多彩な企業から将来を選択することが出来ます。また、卒業後に就職した企業を事情により退職した場合も、新たな就職先をご紹介いたします。'
          },
          {
            question: '社会人でも入学できますか？',
            answer: 'はい、大丈夫です。年齢を問わずご入学できます。<br>（※高卒以上の学歴のみが必要となります。）',
          },
          {
            question: '専門士の称号はもらえますか？',
            answer: '国の認可を受けた専修学校の専門課程ですので、卒業すれば二級は専門士、一級は高度専門士になれます。<br>また大学に編入も可能ですが、何年生に編入されるかは大学の単位認定の違いにより差が生じます。 ちなみに、自動車系の大学（学部・学科）であれば３年生からになります。一級課程修了者は大学院に進学できます。'
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa-car-qa>