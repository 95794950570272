<page-atsugi-design-message>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="design">先輩からのメッセージ</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--gap-loose o-layout--column">
            <div each="{ message in messages }">
              <message-box data-item="{ message }" data-slug="design"></message-box>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="design">教師からのメッセージ</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--gap-loose o-layout--column">
            <div each="{ message in messages2 }">
              <message-box data-item="{ message }" data-slug="design"></message-box>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiDesignMessage() {
      return {
        /**
         * @typedef {object} message
         * @property {string} [photo] 写真のURL
         * @property {string} [title] 単純文字列
         * @property {string} profile HTML文字列
         * @property {string} message HTML文字列
         */
        /**
         * @type {message[]}
         */
        messages: [
          {
            profile: 'ITコース卒業',
            message: '授業はわかりやすく楽しかったです。<br>検定対策の授業も充実していました。検定試験は学校で受験することができたので安心して受験することができました。<br>先生方は授業に対してはもちろん、何事に対しても丁寧に教えてくれて優しいし、面白かったです。<br>数多くの資格が取れて非常に満足して卒業することができました。<br>卒業式では取得した資格を丁寧に読み上げてもらったのも印象に残っています。<br>この学校を選んでよかったです。',
          },
          {
            profile: 'ファッションコース卒業',
            message: '色彩、パターンメーキング、ファッションビジネスや服飾造形と理論や座学から製作までファッションについて幅広く学べるところに魅力を感じ、入学しました。<br>文化祭でのファッションショーでは自分で作った衣装を着て歩くのはとても幸せな気持ちでした。<br>難しかったけど知識も増え、成長した自分を感じることができました。<br>また卒業式は自分の作ったスーツを着て出席するというところも忘れられない最高の思い出です。',
          },
        ],
        /**
         * @type {message[]}
         */
        messages2: [
          {
            profile: '教師一同',
            message: '厚木総合専門学校 生活デザイン学科では、 教員一人ひとりが皆様一人ひとりを心を込めてサポートいたします。<br>教員と学生皆様が連携しあい、お互いを尊重し、何が良いかを一緒に考えていける校風です。<br>期待に添えるよう、全力で指導してまいります。皆様に会える日をこころよりお待ちしております。<br>厚木総合専門学校 生活デザイン学科で自分の夢に向かって一緒に勉強しませんか？',
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-design-message>