<ui-heading>
  <slide-in data-theme="{ props.dataTheme }">
    <heading-tag is="heading-tag" data-tag="h1" data-class="{ getClassName() }">
      <logo-suzuki-s class="c-heading-3__mark" data-theme="{ props.dataTheme }"></logo-suzuki-s>
      <div class="c-heading-3__content">
        <slot />
      </div>
    </heading-tag>
  </slide-in>
  <script>
    export default function UiHeading() {
      return {
        getClassName() {
          const result = ['c-heading-3']
          if (this.props.dataTheme) result.push(`c-heading-3--theme-${this.props.dataTheme}`)
          return result.join(' ')
        }
      }
    }
  </script>
</ui-heading>