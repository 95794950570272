<school-nav>
  <div class="{getNavClass()}">
    <div class="o-layout o-layout--column c-school-nav-layout">
      <div>
        <div class="{ getNavListBlockClass() }">
          <ul class="{ getNavListClass() }">
            <li each="{ nav in props.dataItems }" data-href="{nav.href}">
              <a href="{ nav.href }" class="{ getLinkClass() }" data-current="{ getIsCurrent(nav.href) }"
                target="{nav.target ? nav.target : false}">
                <div class="o-layout o-layout--gap-tight c-school-nav__link-layout">
                  <div class="c-school-nav__icon-layout">
                    <ui-icon data-icon="{ nav.icon }" class="{ getIconClass() }"></ui-icon>
                  </div>
                  <div>
                    { nav.title }
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div if="{ !props.dataSlug || props.dataSlug === 'suzuki' }" class="{getFacebookButtonClass()}">
        <div class="o-items-center">
          <facebook-page-button></facebook-page-button>
        </div>
      </div>
    </div>
  </div>
  <script>
    export default function SchoolNav() {
      return {
        getIconClass() {
          const result = ['c-school-nav__icon']
          if (this.props.dataSlug) result.push(`c-school-nav__icon--theme-${this.props.dataSlug}`)
          return result.join(' ')
        },
        getLinkClass() {
          const result = ['c-school-nav__link']
          if (this.props.dataSlug) result.push(`c-school-nav__link--theme-${this.props.dataSlug}`)
          if (this.props.dataWhere) result.push(`c-school-nav__link--in-${this.props.dataWhere}`)
          return result.join(' ')
        },
        getNavListClass() {
          const result = ['o-layout', 'o-layout--column', 'o-layout--gap-none', 'c-school-nav']
          if (this.props.dataWhere) result.push(`c-school-nav--in-${this.props.dataWhere}`)
          return result.join(' ')
        },
        getNavListBlockClass() {
          const result = ['c-school-nav-list-block']
          if (this.props.dataWhere) result.push(`c-school-nav-list-block--in-${this.props.dataWhere}`)
          return result.join(' ')
        },
        getNavClass() {
          const result = ['c-school-nav-block']
          if (this.props.dataWhere) result.push(`c-school-nav-block--in-${this.props.dataWhere}`)
          return result.join(' ')
        },
        getIsCurrent(href) {
          if (!this.props.dataSlug && !this.props.dataCurrent) return false
          const pattern = new RegExp(this.props.dataSlug + '\/?$')
          return !!this.props.dataCurrent?.match(href) && !href.match(pattern)
        },
        getFacebookButtonClass() {
          const result = ['c-school-header-nav__facebook']
          if (this.props.dataWhere === 'header') result.push('c-school-header-nav__facebook--in-header')
          return result.join(' ')
        }
      }
    }
  </script>
</school-nav>