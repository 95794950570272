<intersection-area>
  <div data-ref="content">
    <slot />
  </div>
  <style>
    :host {
      display: block;
    }
  </style>
  <script>
    export default function IntersectionArea() {
      return {
        handleShow(entries) {
          if (entries[0].isIntersecting && !this.props.dataDisable) {
            this.props.dataIntersectionHandler(this.props.dataId)
          }
        },
        onMounted() {
          this.options = {
            rootMargin: "-50% 0px",
            threshold: 0,
          }
          this.observer = new IntersectionObserver(this.handleShow, this.options)
          this.observer.observe(this.$('[data-ref="content"]'))
        }
      }
    }
  </script>
</intersection-area>