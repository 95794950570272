<page-atsugi-cook-subject>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="cook">2つのコース</ui-heading>
          <div class="o-layout o-layout--fill-equal o-layout--column-in-mobile">
            <section>
              <h1 class="c-heading-4 c-heading-4--theme-cook">一般コース（1年制）</h1>
              <p class="c-heading-5 c-heading-5--theme-cook">大人の方もたくさん勉強しています！</p>
              <p>
                高校卒業から大人の方まで年齢層が広いのが特徴です。
                <br>他業種から転職、定年退職してからなど、さまざまな方が勉強しています。
                <br>年令や立場関係なく和気あいあいできる環境で、1年間で楽しく料理の基礎と資格を取得できます！
              </p>
              <table class="c-table o-space">
                <tr>
                  <th>対象</th>
                  <td class="o-nowrap">17才～すべての大人の方まで<wbr>（高卒・大卒含む）</td>
                </tr>
              </table>
            </section>
            <section>
              <h1 class="c-heading-4 c-heading-4--theme-cook">高校コース（3年制）</h1>
              <p class="c-heading-5 c-heading-5--theme-cook">高校との同時入学で高校卒業も調理師免許も取得！</p>
              <p>
                中学を卒業してすぐ入学する生徒のみのコースです。
                <br>高校の資格を希望される場合、厚木中央高等学校（普通科）に同時入学でき、1年目で調理学科を卒業し調理師免許が取得できます。
                <br>2〜3年は厚木中央高等学校で本格的に勉強します。
              </p>
              <table class="c-table o-space">
                <tr>
                  <th>対象</th>
                  <td>中学卒業したての方</td>
                </tr>
              </table>
            </section>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h1" data-theme="cook">カリキュラム</ui-heading>
        <div class="o-space">
          <table class="c-table c-table--keep-layout">
            <tr>
              <th>学科名</th>
              <th>総時間数</th>
            </tr>
            <tr>
              <td>食生活と健康</td>
              <td>90時間</td>
            </tr>
            <tr>
              <td>食品と栄養の特性</td>
              <td>150時間</td>
            </tr>
            <tr>
              <td>食品の安全と衛生</td>
              <td>150時間</td>
            </tr>
            <tr>
              <td>調理理論と食文化概論</td>
              <td>180時間</td>
            </tr>
            <tr>
              <td>調理実習</td>
              <td>300時間</td>
            </tr>
            <tr>
              <td>総合調理実習</td>
              <td>90時間</td>
            </tr>
            <tr>
              <th><span class="c-label c-label--theme-cook">合計</span></th>
              <td>960時間</td>
            </tr>
          </table>
        </div>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-cook">調理実習</h1>
          <div class="o-spacer"></div>
          <div class="o-layout o-layout--column">
            <section each="{ cookMenuItem in cookMenu }">
              <div class="o-layout o-layout--cross-axis-center">
                <div class="o-size-fill o-fit">
                  <h1 class="c-heading-5 c-heading-5--theme-cook">{cookMenuItem.title}</h1>
                  <p>
                    <raw-html data-content="{cookMenuItem.content}">
                    </raw-html>
                  </p>
                </div>
                <div class="o-size-hug o-order-top">
                  <img src="{cookMenuItem.photo}" alt="{cookMenuItem.title}" class="c-circle-photo-2" width="180"
                    height="180">
                </div>
              </div>
            </section>
          </div>
        </section>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h1" data-theme="cook">授業時間</ui-heading>
        <div class="o-space c-items-scroll">
          <table class="c-table c-table--keep-layout">
            <tr>
              <th>曜日</th>
              <td>月〜金</td>
            </tr>
            <tr>
              <th>時間</th>
              <td>
                <p>9:00～15:30（週1日16:30まで）</p>
                <ul class="c-note-list">
                  <li>8時30分から登校可能。<br>8時50分からホームルームがあります。</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>休み</th>
              <td>土日・祝祭日・夏季・冬季</td>
            </tr>
          </table>
        </div>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h1" data-theme="cook">取得可能な資格検定</ui-heading>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-cook">取得資格</h1>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-cook">
              <div class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                <div>調理師免許</div>
                <div>
                  <div class="c-label c-label--theme-cook c-label--block">国家資格</div>
                </div>
              </div>
            </h1>
            <p>
              卒業すれば保健所申請することで取得できます。
              <br>当然、国家試験受験や2年実務経験なども必要ありません。
            </p>
          </section>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-cook">
              <div class="o-layout o-layout--gap-small o-layout--cross-axis-center">
                <div>食品衛生責任者</div>
              </div>
            </h1>
            <p>
              調理師免許取得で有資格者です。
              <br>保健所登録することで店舗の食品衛生責任者となります。
            </p>
          </section>
        </section>
        <section>
          <h1 class="c-heading-5 c-heading-5--theme-cook">
            <div class="o-layout o-layout--gap-small o-layout--cross-axis-center">
              <div>専門調理師</div>
              <div>
                <div class="c-label c-label--theme-cook c-label--block">国家資格</div>
              </div>
            </div>
          </h1>
          <p>
            <b class="c-strong c-strong--theme-cook">6年後に実技試験を行い取得</b>
            <br>調理師免許の最高峰の国家資格。
            <br>調理師養成施設教員免許資格でもあります。
          </p>
        </section>
        <section>
          <h1 class="c-heading-5 c-heading-5--theme-cook">
            <div class="o-layout o-layout--gap-small o-layout--cross-axis-center">
              <div>食育インストラクター</div>
            </div>
          </h1>
          <p>
            <b class="c-strong c-strong--theme-cook">校内食育講座（講義・実習）を受け試験に合格して取得</b>
            <br>食の知識や技術を身に付け食育活動に参加、企画できる資格です。（希望者）
          </p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-cook">厚木中央高等学校同時入学者が取得できる資格</h1>
          <ul class="c-list c-list--theme-cook">
            <li>高校卒業資格（通信制/普通科）</li>
            <li>パン初級免状（厚木総合専門学校/独自資格）</li>
          </ul>
        </section>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default {
      /**
       * @typedef cookMenuItem
       * @property {string} photo
       * @property {string} title
       * @property {string} content
       */
      /**
       * @type {cookMenuItem[]}
       */
      cookMenu: [
        {
          photo: '/img/cook-menu-photo.webp',
          title: '日本料理',
          content: '柳刃、出刃包丁、菜切り、薄刃など、包丁の扱いもここでしっかりマスターします。素材の善し悪しを見抜く目も、微妙な味加減を見る舌もここで鍛え、おもてなしの心も大切にします。',
        },
        {
          photo: '/img/cook-menu-photo-3.webp',
          title: '西洋料理',
          content: '材料から料理をイメージするとき、肉や野菜、魚介類の素材の持つ旨味を、ソースやドレッシングによって際立たせます。',
        },
        {
          photo: '/img/cook-menu-photo-6.webp',
          title: '中国料理',
          content: '北京料理をはじめ、上海、南京、四川、広東料理の料理法を学びます。いためる。揚げる。蒸す。煮込む。切る。料理の基本を一から懇切丁寧に教えていきます。',
        },
        {
          photo: '/img/cook-menu-photo-4.webp',
          title: '菓子',
          content: 'ちょっとした配合の違いや、調理法によって、みちがえるほど違うものができるものです。そこで基礎的なテクニックを正しく身につけておくことが大切です。',
        },
        {
          photo: '/img/cook-menu-photo-2.webp',
          title: 'パン',
          content: '本格的な製パン機械を使い、小麦粉、卵、バター、砂糖などの材料をもとに、ほんの少しのテクニックで、異なった味や姿に変化します。',
        },
        {
          photo: '/img/cook-menu-photo-5.webp',
          title: '集団調理',
          content: 'おいしい食事であること。栄養的な条件を満たすこと。経済的であること。衛生的で安全なものであること。すべてが大切です。',
        },
      ],
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-atsugi-cook-subject>