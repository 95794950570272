<photo-and-title>
  <div class="c-photo-and-title-layout">
    <div class="o-items-center">
      <img src="{ props.dataPhoto }" alt="" class="{ getClass('c-circle-photo') }">
    </div>
    <div class="o-items-center { getClass('c-circle-photo-title') }">
      <slot />
    </div>
  </div>
  <script>
    // @ts-check
    export default function PhotoAndTitle() {
      return {
        /**
         * @param {string} className
         * @return {string}
         */
        getClass(className) {
          const result = [className]
          if (this.props.dataTheme) result.push(`${className}--theme-${this.props.dataTheme}`)
          return result.join(' ')
        }
      }
    }
  </script>
</photo-and-title>