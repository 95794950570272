<atsugi-school-nav>
  <div class="o-layout o-layout--fill-equal c-atsugi-nav-layout">
    <div>
      <div class="o-items-center">
        <a class="o-block-link o-items-center c-circle-more-link c-circle-more-link--theme-cook" href="/atsugi/cook">
          <photo-and-title data-theme="cook" data-photo="/img/atsugi/photo-cook@2x.webp">
            調理学科
          </photo-and-title>
        </a>
      </div>
    </div>
    <div>
      <div class="o-items-center">
        <a class=" o-block-link o-items-center c-circle-more-link c-circle-more-link--theme-design"
          href="/atsugi/design">
          <photo-and-title data-theme="design" data-photo="/img/atsugi/photo-design@2x.webp">
            生活デザイン学科
          </photo-and-title>
        </a>
      </div>
    </div>
    <div>
      <div class="o-items-center">
        <a class=" o-block-link o-items-center c-circle-more-link c-circle-more-link--theme-dental"
          href="/atsugi/dental">
          <photo-and-title data-theme="dental" data-photo="/img/atsugi/photo-dental@2x.webp">
            歯科衛生学科
          </photo-and-title>
        </a>
      </div>
    </div>
  </div>
</atsugi-school-nav>