<page-chuo-news>
  <div class="o-spacer o-spacer--large-2"></div>
  <template if="{Object.keys(state.getParams).length < 1}">
    <news-list data-is-archive="{ true }" data-slug="chuo" data-news="{props.dataNews}"></news-list>
  </template>
  <template if="{Object.keys(state.getParams).length > 0}">
    <news-detail data-post="{getDetailData()}" data-theme="chuo"></news-detail>
  </template>
  <script>
    export default {
      state: {
        getParams: {},
      },
      getDetailData() {
        const result = this.props.dataNews.filter(news => news.slug === this.state.getParams.slug)[0]
        return result
      },
      onBeforeMount() {
        this.state.getParams = this.getObjectFromLocationSearch()
      },
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-chuo-news>