<page-atsugi-cook-open-campus>
  <section>
    <ui-heading data-tag="h1" data-theme="cook">
      <small>2023年度 厚木総合専門学校<br>調理学科</small>
      <br>オープンキャンパス
    </ui-heading>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-cook">
        体験入学
      </h1>
      <table class="c-table o-space c-content">
        <tbody>
          <tr>
            <th class="narrow">第一回</th>
            <td>
              2024年4月13日(土)
              <br><span class="c-label c-label--theme-cook">高校生〜大人用</span>
            </td>
            <td>
              <div class="o-layout o-layout--gap-small o-layout--column o-layout--cross-axis-center">
                <div>
                  <img src="/img/atsugi-cook-open-campus-photo-7.webp" alt="シーザーサラダ" width="80" class="c-circle-image">
                </div>
                <div>シーザーサラダ</div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="narrow">第二回</th>
            <td>
              2024年5月18日(土)
              <br><span class="c-label c-label--theme-cook">高校生〜大人用</span>
            </td>
            <td>
              <div class="o-layout o-layout--gap-small o-layout--column o-layout--cross-axis-center">
                <div>
                  <img src="/img/atsugi-cook-open-campus-photo-5.webp" alt="スパゲッティ　ナポリタン" width="80"
                    class="c-circle-image">
                </div>
                <div>スパゲッティ　ナポリタン</div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="narrow">第三回</th>
            <td>
              2024年6月15日(土)
              <br><span class="c-label c-label--theme-cook">高校生〜大人用</span>
              <span class="c-label c-label--theme-cook">中学生対象</span>
            </td>
            <td>
              <div class="o-layout o-layout--gap-small o-layout--column o-layout--cross-axis-center">
                <div>
                  <img src="/img/atsugi-cook-open-campus-photo-20.webp" alt="ハンバーグステーキ" width="80"
                    class="c-circle-image">
                </div>
                <div>ハンバーグステーキ</div>
              </div>
            </td>
          </tr>
          <tr>
            <th class="narrow">第四回</th>
            <td>
              2024年7月13日(土)
              <br><span class="c-label c-label--theme-cook">高校生〜大人用</span>
              <span class="c-label c-label--theme-cook">中学生対象</span>
            </td>
            <td>
              <div class="o-layout o-layout--gap-small o-layout--column o-layout--cross-axis-center">
                <div>
                  <img src="/img/atsugi-cook-open-campus-photo-21.webp" alt="天丼・お吸物" width="80" class="c-circle-image">
                </div>
                <div>天丼・お吸物</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <section>
        <h1 class="c-heading-5 c-heading-5--theme-cook">体験入学について</h1>
        <p>カービング体験も同時にできます。<br>都合により料理変更される場合がございます。</p>
      </section>
      <section>
        <h1 class="c-heading-5 c-heading-5--theme-cook">当日のスケジュール</h1>
        <table class="c-table o-space">
          <tr>
            <th rowspan="2">9:30</th>
            <td>集合・開始</td>
          </tr>
          <tr>
            <td>学校説明、校内見学</td>
          </tr>
          <tr>
            <th>10:30</th>
            <td>調理実習・試食</td>
          </tr>
          <tr>
            <th>12:00</th>
            <td>終了</td>
          </tr>
        </table>
      </section>
      <section>
        <h1 class="c-heading-5 c-heading-5--theme-cook">当日の持ち物</h1>
        <ul class="c-list c-list--theme-cook c-content">
          <li>エプロン、上履き、筆記用具</li>
        </ul>
      </section>
      <section>
        <h1 class="c-heading-5 c-heading-5--theme-cook">お申し込み方法</h1>
        <div class="c-content">
          <p>各日程前日までに、下記の申し込みフォームよりお申し込みください。</p>
          <div class="o-spacer"></div>
          <div class="o-layout o-layout--main-axis-center o-layout--wrap">
            <div>
              <ui-button
                data-href="https://www.school-go.info/f9z653/atsugi/form.php?fno=118&fsno=1&openExternalBrowser=1"
                data-target="_blank" data-class="theme-cook">オープンキャンパスお申し込み</ui-button>
            </div>
          </div>
        </div>
      </section>
    </section>
    <section id="for-middle-school-students" if="{null}">
      <h1 class="c-heading-4 c-heading-4--theme-cook">
        実習体験&学校説明会
        <span class="c-label c-label--theme-cook">中学生対象</span>
      </h1>
      <table class="c-table c-table--keep-layout o-space">
        <tbody>
          <tr>
            <th>項目</th>
            <th>内容</th>
            <th>日付</th>
          </tr>
          <tr>
            <th rowspan="8">
              夏・秋の体験入学
              <br>（実習体験&学校説明）
            </th>
            <td rowspan="8">
              <div class="o-layout o-layout--wrap">
                <div>
                  13:00〜15:00
                  <ul>
                    <li>学校説明会</li>
                    <li>施設見学</li>
                    <li>国県支援金</li>
                    <li>個別相談</li>
                    <li>調理実習
                      <ul>
                        <li>ハンバーグステーキ（6/17）</li>
                        <li>天丼・お吸物（7/15）</li>
                        <li>スパゲッティ カルボナーラ（7/29）</li>
                        <li>タコス（8/9）</li>
                        <li>ナポリピザ（8/26）</li>
                        <li>リゾット ペスカトーレ（9/16）</li>
                        <li>パンケーキ 又は フレンチトースト（9/30）</li>
                        <li>秋鮭の椎焼き・さつま芋御飯（10/7）</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div>
                  <div class="o-layout o-layout--column o-layout--cross-axis-center">
                    <div>
                      <img src="/img/atsugi-cook-open-campus-photo-14.webp" alt="巻き寿司イメージイラスト" width="120">
                    </div>
                    <div>
                      <img src="/img/atsugi-cook-open-campus-photo-15.webp" alt="パンケーキイメージイラスト" width="120">
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td width="180">2023年6月17日(土)</td>
          </tr>
          <tr>
            <td>2023年7月15日(土)</td>
          </tr>
          <tr>
            <td>2023年7月29日(土)</td>
          </tr>
          <tr>
            <td>2023年8月9日(土)</td>
          </tr>
          <tr>
            <td>2023年8月26日(土)</td>
          </tr>
          <tr>
            <td>2023年9月16日(土)</td>
          </tr>
          <tr>
            <td>2023年9月30日(土)</td>
          </tr>
          <tr>
            <td>2023年10月7日(土)</td>
          </tr>
          <tr>
            <th rowspan="4">入学説明会<br>（学校説明のみ）</th>
            <td rowspan="4">
              <p>13:00〜15:00</p>
              <ul>
                <li>学校説明会</li>
                <li>施設見学</li>
                <li>入試内容</li>
                <li>国県支援金</li>
                <li>個別相談</li>
              </ul>
              <p>※実習はありません。</p>
            </td>
            <td>2023年11月11日（土）</td>
          </tr>
          <tr>
            <td>2023年11月25日（土）</td>
          </tr>
          <tr>
            <td>2023年12月2日（土）</td>
          </tr>
          <tr>
            <td>2023年12月9日（土）</td>
          </tr>
        </tbody>
      </table>
      <section>
        <h1 class="c-heading-5 c-heading-5--theme-cook">お申し込み方法</h1>
        <div class="c-content">
          <p>各日程前日までに、下記の申し込みフォームよりお申し込みください。</p>
          <div class="o-spacer"></div>
          <div class="o-layout o-layout--main-axis-center o-layout--wrap">
            <div>
              <ui-button
                data-href="https://www.school-go.info/f9z653/atsugi/form.php?fno=37&fsno=1&openExternalBrowser=1"
                data-target="_blank" data-class="theme-cook">オープンキャンパスお申し込み（中学校卒業見込みの方）</ui-button>
            </div>
          </div>
        </div>
      </section>
    </section>
    <section if="{false}">
      <h1 class="c-heading-4 c-heading-4--theme-cook">お申し込み方法</h1>
      <div class="c-content">
        <p>各日程前日までに、下記の申し込みフォームよりお申し込みください。</p>
        <div class="o-spacer"></div>
      </div>
      <div class="o-space">
        <table class="c-table">
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">お名前</div>
                <div>
                  <span class="c-label c-label--theme-cook">必須</span>
                </div>
              </div>
            </th>
            <td><input type="text" class="c-input-text"></td>
          </tr>
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">フリガナ</div>
                <div>
                  <span class="c-label c-label--theme-cook">必須</span>
                </div>
              </div>
            </th>
            <td><input type="text" class="c-input-text"></td>
          </tr>
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">メールアドレス</div>
                <div>
                  <span class="c-label c-label--theme-cook">必須</span>
                </div>
              </div>
            </th>
            <td><input type="email" class="c-input-text"></td>
          </tr>
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">参加日</div>
                <div>
                  <b class="c-strong c-strong--theme-cook">
                    <span class="c-label c-label--theme-cook">必須</span>
                </div>
              </div>
            </th>
            <td>
              <div class="o-layout o-layout--gap-small o-layout--column">
                <div>
                  体験入学
                  </b>
                </div>
                <div>
                  <select name="" id="">
                    <option value="">選択してください</option>
                    <option each="{date in dateList1}" value="{date}">{date}</option>
                  </select>
                </div>
                <div>
                  <b class="c-strong c-strong--theme-cook">
                    実習体験&学校説明会
                    <span class="c-label c-label--theme-cook">中学生対象</span>
                  </b>
                </div>
                <div>
                  <select name="" id="">
                    <option value="">選択してください</option>
                    <option each="{date in dateList2}" value="{date}">{date}</option>
                  </select>
                </div>
                <div>
                  <b class="c-strong c-strong--theme-cook">
                    入学説明会（学校説明会のみ）
                    <span class="c-label c-label--theme-cook">中学生対象</span>
                  </b>
                </div>
                <div>
                  <select name="" id="">
                    <option value="">選択してください</option>
                    <option each="{date in dateList3}" value="{date}">{date}</option>
                  </select>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">同伴者</div>
                <div>
                  <span class="c-label c-label--theme-cook">必須</span>
                </div>
              </div>
            </th>
            <td>
              <select name="" id="">
                <option value=""></option>
                <option value="1名">1名</option>
                <option value="2名">2名</option>
                <option value="2名以上">2名以上</option>
                <option value="なし">なし</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">電話番号</div>
              </div>
            </th>
            <td><input type="tel" class="c-input-text"></td>
          </tr>
          <tr>
            <th>備考</th>
            <td>
              <textarea class="c-textarea" cols="30" rows="5"></textarea>
            </td>
          </tr>
        </table>
        <div class="o-items-center o-space">
          <ui-button data-tag="button" data-class="theme-cook">入力内容を確認する</ui-button>
        </div>
      </div>
    </section>
  </section>
  <script>
    export default {
      dateList1: [
        '2022年4月16日（土）',
        '2022年5月14日（土）',
        '2022年6月11日（土）',
        '2022年7月9日（土）',
        '2022年8月5日（金）',
        '2022年8月26日（金）',
        '2022年9月17日（土）',
        '2022年10月8日（土）',
        '2022年11月12日（土）',
        '2022年12月17日（土）',
        '2023年1月14日（土）',
        '2023年2月4日（土）',
        '2023年3月11日（土）',
      ],
      dateList2: [
        '2022年7月16日（土）',
        '2022年7月30日（土）',
        '2022年9月10日（土）',
        '2022年10月1日（土）',
      ],
      dateList3: [
        '2022年11月19日（土）',
        '2022年11月26日（土）',
        '2022年12月3日（土）',
      ],
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-atsugi-cook-open-campus>