<page-chuo-campus>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="chuo">一年間の流れ</ui-heading>
          <div class="c-content">
            <p>卒業後も専門教育を極め、姉妹校への学校推薦も有り、大切な3年間を有意義に過ごせるよう教員一同が指導していきます。</p>
          </div>
        </div>
        <div>
          <event-calendar data-items="{ events }" data-slug="chuo"></event-calendar>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="chuo">課外活動</ui-heading>
        </div>
        <div>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-chuo">部活動</h1>
            <div class="c-content">
              <ul class="c-list c-list--layout-flow c-list--theme-chuo">
                <li each="{listItem in list}">{listItem}</li>
              </ul>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageChuoCampus() {
      return {
        /**
         * @typedef {object} eventObject
         * @property {string} title
         * @property {string} photo
         * @property {string[]} events
         */
        /**
         * @type {eventObject[]}
         */
        events: [
          {
            title: '4月',
            photo: '/img/chuo-month-header-4.webp',
            events: [
              '入学式',
              '始業式・対面式',
              '身体測定',
              'オリエンテーション',
              '部活･同好会説明',
            ],
          },
          {
            title: '5月',
            photo: '/img/chuo-month-header-5.webp',
            events: [
              '1,2年生遠足･バスハイク(東京ディズニーリゾート)',
              '母校訪問(1年生)',
              '保護者会',
              '中間試験'
            ],
          },
          {
            title: '6月',
            photo: '/img/chuo-month-header-6.webp',
            events: [
              '健康診断',
              '修学旅行(3年生)',
            ],
          },
          {
            title: '7月',
            photo: '/img/chuo-month-header-7.webp',
            events: [
              '進路説明会(3年生)',
              '三者面談',
              '終業式・夏休み',
              '体験入学(中学3年生対象)',
              '期末試験',
              '夏期補習',
            ],
          },
          {
            title: '8月',
            photo: '/img/chuo-month-header-8.webp',
            events: [
              '一斉登校日',
              '会社・職場見学(3年生)',
            ],
          },
          {
            title: '9月',
            photo: '/img/chuo-month-header-9.webp',
            events: [
              '始業式',
              '防災訓練',
            ],
          },
          {
            title: '10月',
            photo: '/img/chuo-month-header-10.webp',
            events: [
              '球技大会',
              '豊友祭(3校合同学園祭)',
              '中間試験',
            ],
          },
          {
            title: '11月',
            photo: '/img/chuo-month-header-11.webp',
            events: [
              '全校ハイキング(森の里・若宮公園)',
              '学校説明・相談会',
            ],
          },
          {
            title: '12月',
            photo: '/img/chuo-month-header-12.webp',
            events: [
              '学校説明・相談会',
              '期末試験',
              '終業式・冬休み',
            ],
          },
          {
            title: '1月',
            photo: '/img/chuo-month-header-1.webp',
            events: [
              '始業式',
              '推薦入試',
            ],
          },
          {
            title: '2月',
            photo: '/img/chuo-month-header-2.webp',
            events: [
              '一般入試',
              '卒業試験',
            ],
          },
          {
            title: '3月',
            photo: '/img/chuo-month-header-3.webp',
            events: [
              '卒業式',
              '学年末試験',
              '入学への説明会',
              '修了式・春休み',
            ],
          },
        ],
        /**
         * @type {string[]}
         */
        list: [
          '陸上競技部',
          'バスケットボール部',
          'バレーボール部',
          '卓球部',
          '硬式テニス部',
          'バドミントン同好会',
          '軟式野球同好会',
          '軽音楽部',
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-chuo-campus>