import { register, mount, install } from 'riot'
import { loadDefaultJapaneseParser } from 'budoux'
import RawHtml from './components/src/riot/raw-html.js'
import AppHero from './components/src/riot/app-hero.js'
import LogoSuzuki from './components/src/riot/logo-suzuki.js'
import LogoSuzukiS from './components/src/riot/logo-suzuki-s.js'
import LogoAtsugi from './components/src/riot/logo-atsugi.js'
import PageSuzuki from './components/src/riot/page-suzuki.js'
import PageSuzukiHome from './components/src/riot/page-suzuki-home.js'
import SuzukiHero from './components/src/riot/suzuki-hero.js'
import WaveSeparator from './components/src/riot/wave-separator.js'
import NewsList from './components/src/riot/news-list.js'
import NewsDetail from './components/src/riot/news-detail.js'
import FacebookPageButton from './components/src/riot/facebook-page-button.js'
import GroupFooter from './components/src/riot/group-footer.js'
import GroupNavLink from './components/src/riot/group-nav-link.js'
import UiIcon from './components/src/riot/ui-icon.js'
import SchoolNav from './components/src/riot/school-nav.js'
import SuzukiNav from './components/src/riot/suzuki-nav.js'
import SuzukiMenu from './components/src/riot/suzuki-menu.js'
import SchoolMenu from './components/src/riot/school-menu.js'
import SuzukiHeader from './components/src/riot/suzuki-header.js'
import PageHeader from './components/src/riot/page-header.js'
import PageSuzukiSchool from './components/src/riot/page-suzuki-school.js'
import SchoolGuide from './components/src/riot/school-guide.js'
import PageSuzukiHistory from './components/src/riot/page-suzuki-history.js'
import PageSuzukiPolicy from './components/src/riot/page-suzuki-policy.js'
import PageSuzukiNews from './components/src/riot/page-suzuki-news.js'
import PageSuzukiNewsDetail from './components/src/riot/page-suzuki-news-detail.js'
import PageSuzukiRequest from './components/src/riot/page-suzuki-request.js'
import PageSuzukiContact from './components/src/riot/page-suzuki-contact.js'
import PageChuo from './components/src/riot/page-chuo.js'
import SchoolHeader from './components/src/riot/school-header.js'
import HomeNavItems from './components/src/riot/home-nav-items.js'
import ChuoNav from './components/src/riot/chuo-nav.js'
import ChuoMenu from './components/src/riot/chuo-menu.js'
import PageChuoSubject from './components/src/riot/page-chuo-subject.js'
import PageChuoCampus from './components/src/riot/page-chuo-campus.js'
import PageChuoMessage from './components/src/riot/page-chuo-message.js'
import PageChuoQa from './components/src/riot/page-chuo-qa.js'
import PageChuoApplication from './components/src/riot/page-chuo-application.js'
import PageChuoOpenCampus from './components/src/riot/page-chuo-open-campus.js'
import PageChuoNews from './components/src/riot/page-chuo-news.js'
import PageChuoNewsDetail from './components/src/riot/page-chuo-news-detail.js'
import StarList from './components/src/riot/star-list.js'
import EventCalendar from './components/src/riot/event-calendar.js'
import MessageBox from './components/src/riot/message-box.js'
import QAndA from './components/src/riot/q-and-a.js'
import AOrButton from './components/src/riot/a-or-button.js'
import UiButton from './components/src/riot/ui-button.js'
import PageKanagawa from './components/src/riot/page-kanagawa.js'
import PageKanagawaHighschool from './components/src/riot/page-kanagawa-highschool.js'
import PageKanagawaCar from './components/src/riot/page-kanagawa-car.js'
import KanagawaNav from './components/src/riot/kanagawa-nav.js'
import PhotoAndTitle from './components/src/riot/photo-and-title.js'
import PhotoAndDescription from './components/src/riot/photo-and-description.js'
import KanagawaSchoolNav from './components/src/riot/kanagawa-school-nav.js'
import KanagawaHighschoolMenu from './components/src/riot/kanagawa-highschool-menu.js'
import KanagawaCarMenu from './components/src/riot/kanagawa-car-menu.js'
import HighschoolNav from './components/src/riot/highschool-nav.js'
import CarNav from './components/src/riot/car-nav.js'
import CookNav from './components/src/riot/cook-nav.js'
import DesignNav from './components/src/riot/design-nav.js'
import DentalNav from './components/src/riot/dental-nav.js'
import PageKanagawaHighschoolSubject from './components/src/riot/page-kanagawa-highschool-subject.js'
import PageKanagawaHighschoolIndustrial from './components/src/riot/page-kanagawa-highschool-industrial.js'
import PageKanagawaHighschoolDesign from './components/src/riot/page-kanagawa-highschool-design.js'
import PageKanagawaHighschoolCampus from './components/src/riot/page-kanagawa-highschool-campus.js'
import PageKanagawaHighschoolOpenCampus from './components/src/riot/page-kanagawa-highschool-open-campus.js'
import PageKanagawaHighschoolMessage from './components/src/riot/page-kanagawa-highschool-message.js'
import PageKanagawaHighschoolApplication from './components/src/riot/page-kanagawa-highschool-application.js'
import PageKanagawaHighschoolQa from './components/src/riot/page-kanagawa-highschool-qa.js'
import PageKanagawaHighschoolNews from './components/src/riot/page-kanagawa-highschool-news.js'
import PageKanagawaHighschoolNewsDetail from './components/src/riot/page-kanagawa-highschool-news-detail.js'
import PageKanagawaCarSubject from './components/src/riot/page-kanagawa-car-subject.js'
import PageKanagawaCarCampus from './components/src/riot/page-kanagawa-car-campus.js'
import PageKanagawaCarMessage from './components/src/riot/page-kanagawa-car-message.js'
import PageKanagawaCarOpenCampus from './components/src/riot/page-kanagawa-car-open-campus.js'
import PageKanagawaCarApplication from './components/src/riot/page-kanagawa-car-application.js'
import PageKanagawaCarQa from './components/src/riot/page-kanagawa-car-qa.js'
import PageKanagawaCarNews from './components/src/riot/page-kanagawa-car-news.js'
import PageKanagawaCarNewsDetail from './components/src/riot/page-kanagawa-car-news-detail.js'
import SlideIn from './components/src/riot/slide-in.js'
import FacebookTimeline from './components/src/riot/facebook-timeline.js'
import UiHeading from './components/src/riot/ui-heading.js'
import HeadingTag from './components/src/riot/heading-tag.js'
import SchoolContentTemplate from './components/src/riot/school-content-template.js'
import MaskedImage from './components/src/riot/masked-image.js'
import PageAtsugi from './components/src/riot/page-atsugi.js'
import PageAtsugiHome from './components/src/riot/page-atsugi-home.js'
import PageAtsugiCook from './components/src/riot/page-atsugi-cook.js'
import PageAtsugiCookSubject from './components/src/riot/page-atsugi-cook-subject.js'
import PageAtsugiCookCampus from './components/src/riot/page-atsugi-cook-campus.js'
import PageAtsugiCookMessage from './components/src/riot/page-atsugi-cook-message.js'
import PageAtsugiCookOpenCampus from './components/src/riot/page-atsugi-cook-open-campus.js'
import PageAtsugiCookApplication from './components/src/riot/page-atsugi-cook-application.js'
import PageAtsugiCookQa from './components/src/riot/page-atsugi-cook-qa.js'
import PageAtsugiCookNews from './components/src/riot/page-atsugi-cook-news.js'
import PageAtsugiCookNewsDetail from './components/src/riot/page-atsugi-cook-news-detail.js'
import PageAtsugiDesign from './components/src/riot/page-atsugi-design.js'
import PageAtsugiDesignSubject from './components/src/riot/page-atsugi-design-subject.js'
import PageAtsugiDesignCampus from './components/src/riot/page-atsugi-design-campus.js'
import PageAtsugiDesignMessage from './components/src/riot/page-atsugi-design-message.js'
import PageAtsugiDesignOpenCampus from './components/src/riot/page-atsugi-design-open-campus.js'
import PageAtsugiDesignApplication from './components/src/riot/page-atsugi-design-application.js'
import PageAtsugiDesignQa from './components/src/riot/page-atsugi-design-qa.js'
import PageAtsugiDesignNews from './components/src/riot/page-atsugi-design-news.js'
import PageAtsugiDesignNewsDetail from './components/src/riot/page-atsugi-design-news-detail.js'
import PageAtsugiDental from './components/src/riot/page-atsugi-dental.js'
import PageAtsugiDentalSubject from './components/src/riot/page-atsugi-dental-subject.js'
import PageAtsugiDentalCampus from './components/src/riot/page-atsugi-dental-campus.js'
import PageAtsugiDentalMessage from './components/src/riot/page-atsugi-dental-message.js'
import PageAtsugiDentalOpenCampus from './components/src/riot/page-atsugi-dental-open-campus.js'
import PageAtsugiDentalApplication from './components/src/riot/page-atsugi-dental-application.js'
import PageAtsugiDentalQa from './components/src/riot/page-atsugi-dental-qa.js'
import PageAtsugiDentalNews from './components/src/riot/page-atsugi-dental-news.js'
import PageAtsugiDentalNewsDetail from './components/src/riot/page-atsugi-dental-news-detail.js'
import AtsugiNav from './components/src/riot/atsugi-nav.js'
import AtsugiSchoolNav from './components/src/riot/atsugi-school-nav.js'
import BreadcrumbsNav from './components/src/riot/breadcrumbs-nav.js'
import UiBox from './components/src/riot/ui-box.js'
import KanagawaCarResult from './components/src/riot/kanagawa-car-result.js'
import PageAtsugiDesignIt from './components/src/riot/page-atsugi-design-it.js'
import PageAtsugiDesignFashion from './components/src/riot/page-atsugi-design-fashion.js'
import UiTabs from './components/src/riot/ui-tabs.js'
import AtsugiCookMenu from './components/src/riot/atsugi-cook-menu.js'
import AtsugiDesignMenu from './components/src/riot/atsugi-design-menu.js'
import AtsugiDentalMenu from './components/src/riot/atsugi-dental-menu.js'
import IntersectionArea from './components/src/riot/intersection-area.js'
import CatalogPhoto from './components/src/riot/catalog-photo.js'
import PhotoSlider from './components/src/riot/photo-slider.js'
import UiMarkdown from './components/src/riot/ui-markdown.js'

register('raw-html', RawHtml)
register('app-hero', AppHero)
register('logo-suzuki', LogoSuzuki)
register('logo-suzuki-s', LogoSuzukiS)
register('logo-atsugi', LogoAtsugi)
register('page-suzuki', PageSuzuki)
register('page-suzuki-home', PageSuzukiHome)
register('suzuki-hero', SuzukiHero)
register('wave-separator', WaveSeparator)
register('news-list', NewsList)
register('news-detail', NewsDetail)
register('facebook-page-button', FacebookPageButton)
register('group-footer', GroupFooter)
register('group-nav-link', GroupNavLink)
register('ui-icon', UiIcon)
register('school-nav', SchoolNav)
register('suzuki-nav', SuzukiNav)
register('suzuki-menu', SuzukiMenu)
register('school-menu', SchoolMenu)
register('suzuki-header', SuzukiHeader)
register('page-header', PageHeader)
register('page-suzuki-school', PageSuzukiSchool)
register('school-guide', SchoolGuide)
register('page-suzuki-history', PageSuzukiHistory)
register('page-suzuki-policy', PageSuzukiPolicy)
register('page-suzuki-news', PageSuzukiNews)
register('page-suzuki-news-detail', PageSuzukiNewsDetail)
register('page-suzuki-request', PageSuzukiRequest)
register('page-suzuki-contact', PageSuzukiContact)
register('page-chuo', PageChuo)
register('school-header', SchoolHeader)
register('home-nav-items', HomeNavItems)
register('chuo-nav', ChuoNav)
register('chuo-menu', ChuoMenu)
register('page-chuo-subject', PageChuoSubject)
register('page-chuo-campus', PageChuoCampus)
register('page-chuo-message', PageChuoMessage)
register('page-chuo-qa', PageChuoQa)
register('page-chuo-application', PageChuoApplication)
register('page-chuo-open-campus', PageChuoOpenCampus)
register('page-chuo-news', PageChuoNews)
register('page-chuo-news-detail', PageChuoNewsDetail)
register('star-list', StarList)
register('event-calendar', EventCalendar)
register('message-box', MessageBox)
register('q-and-a', QAndA)
register('a-or-button', AOrButton)
register('ui-button', UiButton)
register('page-kanagawa', PageKanagawa)
register('page-kanagawa-highschool', PageKanagawaHighschool)
register('page-kanagawa-car', PageKanagawaCar)
register('kanagawa-nav', KanagawaNav)
register('photo-and-title', PhotoAndTitle)
register('photo-and-description', PhotoAndDescription)
register('kanagawa-school-nav', KanagawaSchoolNav)
register('kanagawa-highschool-menu', KanagawaHighschoolMenu)
register('kanagawa-car-menu', KanagawaCarMenu)
register('highschool-nav', HighschoolNav)
register('car-nav', CarNav)
register('cook-nav', CookNav)
register('design-nav', DesignNav)
register('dental-nav', DentalNav)
register('page-kanagawa-highschool-subject', PageKanagawaHighschoolSubject)
register(
  'page-kanagawa-highschool-industrial',
  PageKanagawaHighschoolIndustrial
)
register('page-kanagawa-highschool-design', PageKanagawaHighschoolDesign)
register('page-kanagawa-highschool-campus', PageKanagawaHighschoolCampus)
register(
  'page-kanagawa-highschool-open-campus',
  PageKanagawaHighschoolOpenCampus
)
register('page-kanagawa-highschool-message', PageKanagawaHighschoolMessage)
register(
  'page-kanagawa-highschool-application',
  PageKanagawaHighschoolApplication
)
register('page-kanagawa-highschool-qa', PageKanagawaHighschoolQa)
register('page-kanagawa-highschool-news', PageKanagawaHighschoolNews)
register(
  'page-kanagawa-highschool-news-detail',
  PageKanagawaHighschoolNewsDetail
)
register('page-kanagawa-car-subject', PageKanagawaCarSubject)
register('page-kanagawa-car-campus', PageKanagawaCarCampus)
register('page-kanagawa-car-message', PageKanagawaCarMessage)
register('page-kanagawa-car-open-campus', PageKanagawaCarOpenCampus)
register('page-kanagawa-car-application', PageKanagawaCarApplication)
register('page-kanagawa-car-qa', PageKanagawaCarQa)
register('page-kanagawa-car-news', PageKanagawaCarNews)
register('page-kanagawa-car-news-detail', PageKanagawaCarNewsDetail)
register('slide-in', SlideIn)
register('facebook-timeline', FacebookTimeline)
register('ui-heading', UiHeading)
register('heading-tag', HeadingTag)
register('school-content-template', SchoolContentTemplate)
register('masked-image', MaskedImage)
register('page-atsugi', PageAtsugi)
register('page-atsugi-home', PageAtsugiHome)
register('page-atsugi-cook', PageAtsugiCook)
register('page-atsugi-cook-subject', PageAtsugiCookSubject)
register('page-atsugi-cook-campus', PageAtsugiCookCampus)
register('page-atsugi-cook-message', PageAtsugiCookMessage)
register('page-atsugi-cook-open-campus', PageAtsugiCookOpenCampus)
register('page-atsugi-cook-application', PageAtsugiCookApplication)
register('page-atsugi-cook-qa', PageAtsugiCookQa)
register('page-atsugi-cook-news', PageAtsugiCookNews)
register('page-atsugi-cook-news-detail', PageAtsugiCookNewsDetail)
register('page-atsugi-design', PageAtsugiDesign)
register('page-atsugi-design-subject', PageAtsugiDesignSubject)
register('page-atsugi-design-campus', PageAtsugiDesignCampus)
register('page-atsugi-design-message', PageAtsugiDesignMessage)
register('page-atsugi-design-open-campus', PageAtsugiDesignOpenCampus)
register('page-atsugi-design-application', PageAtsugiDesignApplication)
register('page-atsugi-design-qa', PageAtsugiDesignQa)
register('page-atsugi-design-news', PageAtsugiDesignNews)
register('page-atsugi-design-news-detail', PageAtsugiDesignNewsDetail)
register('page-atsugi-dental', PageAtsugiDental)
register('page-atsugi-dental-subject', PageAtsugiDentalSubject)
register('page-atsugi-dental-campus', PageAtsugiDentalCampus)
register('page-atsugi-dental-message', PageAtsugiDentalMessage)
register('page-atsugi-dental-open-campus', PageAtsugiDentalOpenCampus)
register('page-atsugi-dental-application', PageAtsugiDentalApplication)
register('page-atsugi-dental-qa', PageAtsugiDentalQa)
register('page-atsugi-dental-news', PageAtsugiDentalNews)
register('page-atsugi-dental-news-detail', PageAtsugiDentalNewsDetail)
register('atsugi-nav', AtsugiNav)
register('atsugi-school-nav', AtsugiSchoolNav)
register('breadcrumbs-nav', BreadcrumbsNav)
register('ui-box', UiBox)
register('kanagawa-car-result', KanagawaCarResult)
register('page-atsugi-design-it', PageAtsugiDesignIt)
register('page-atsugi-design-fashion', PageAtsugiDesignFashion)
register('ui-tabs', UiTabs)
register('atsugi-cook-menu', AtsugiCookMenu)
register('atsugi-design-menu', AtsugiDesignMenu)
register('atsugi-dental-menu', AtsugiDentalMenu)
register('intersection-area', IntersectionArea)
register('catalog-photo', CatalogPhoto)
register('photo-slider', PhotoSlider)
register('ui-markdown', UiMarkdown)

/*
 * @type {number} インスタンスのID
 */
let instanceId = 0

/**
 * BudouX
 */
const parser = loadDefaultJapaneseParser()
const dataset = {}

/**
 * Convert object class constructs into strings
 * @param   {Object} classes - class list as object
 * @returns {string} return only the classes having a truthy value
 */
function classNames(classes) {
  return Object.entries(classes)
    .reduce((acc, item) => {
      const [key, value] = item
      if (value) return [...acc, key]
      return acc
    }, [])
    .join(' ')
}

/*
 * 現在の URL の pathname を配列で返す関数
 * @returns array {array}
 */
function getPathNameArray() {
  const array = window.location.pathname
    .split('/')
    .filter((item) => item !== '')
    .filter((item) => !item.match(/^index\.html?$/))
  return array
}

/**
 * GETパラメーターをObjectとして返す関数
 * @return {object}
 */
const getObjectFromLocationSearch = () => {
  const paramsString = window.location.search.replace('?', '')
  if (!paramsString) return {}
  const paramsArray = paramsString.split('&')
  const resultObject = {}
  paramsArray.forEach((param) => {
    const array = param.split('=')
    resultObject[array[0]] = array[1]
  })
  return resultObject
}

/**
 * Convert object attributes constructs into strings
 * @param   {Object} attributes - style attributes as object
 * @returns {string} a string with all the attributes and their values
 */
function styleAttribute(attributes) {
  return Object.entries(attributes)
    .reduce((acc, item) => {
      const [key, value] = item

      return [...acc, `${key}: ${value}`]
    }, [])
    .join(';')
}

function fetchContactMailTo() {
  return fetch(
    '/cockpit/api/singletons/get/contact_mail?token=35caad65cd1c9add5bdeb758fd62a0'
  )
    .then((data) => data.json())
    .then((data) => {
      return data
    })
    .catch((error) => console.error(error))
}

Promise.all([fetchContactMailTo()])
  .then((results) => {
    dataset.emails = {}
    for (const key in results[0]) {
      if (key[0] != '_') {
        const values = results[0][key].split('\n')
        dataset.emails[key] = values.filter((value) => !value.match(/^\s*$/))
      }
    }
    mountComponents()
  })
  .catch((error) => console.error(error))

function scrollToHashedElement() {
  if (!location.hash) return false
  try {
    document.querySelector(location.hash)
  } catch (error) {
    console.error(error)
    return false
  }
  const anchorElement = document.querySelector(location.hash)
  if (!anchorElement) return false
  window.requestAnimationFrame(() => {
    const style = getComputedStyle(document.body)
    const offset1 = style
      .getPropertyValue('--mobile-scroll-offset')
      .match(/[0-9]*/)[0]
    const offset2 = style
      .getPropertyValue('--mobile-scroll-offset-tab')
      .match(/[0-9]*/)[0]
    const offset = Number(offset1) + Number(offset2)
    const elementY = anchorElement.getBoundingClientRect().top + window.scrollY
    const targetY = elementY - offset
    window.scrollTo(0, targetY)
  })
}

function mountComponents() {
  install((component) => {
    component.dataset = dataset
    component.getObjectFromLocationSearch = getObjectFromLocationSearch
    component.getPathNameArray = getPathNameArray
    component.classNames = classNames
    component.styleAttribute = styleAttribute
    component.id = instanceId++
    component.japaneseParser = parser
    component.scrollToHashedElement = scrollToHashedElement
    component.breakToBr = (string) => {
      return string.replace(/\n/g, '<br>')
    }
    component

    return component
  })

  mount('[data-riot]')

  AOS.init({
    duration: 500,
  })
}
