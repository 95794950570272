<atsugi-dental-menu>
  <school-menu data-items="{ menus }" data-slug="dental"></school-menu>

  <script>
    export default function AtsugiDentalMenu() {
      return {
        menus: [
          {
            icon: 'school',
            title: 'キャンパスライフ',
            slug: '/atsugi/dental/campus',
          },
          {
            icon: 'compass',
            title: 'オープンキャンパス',
            slug: '/atsugi/dental/open-campus',
          },
          {
            icon: 'book',
            title: '資料請求',
            slug: 'https://www.school-go.info/f9z653/atsugi/form.php?fno=83&fsno=1&openExternalBrowser=1',
            target: '_blank',
          },
          {
            icon: 'mail',
            title: 'お問い合わせ',
            slug: 'https://www.school-go.info/f9z653/atsugi/form.php?fno=90&fsno=1&openExternalBrowser=1',
            target: '_blank',
          },
        ]
      }
    }
  </script>
</atsugi-dental-menu>