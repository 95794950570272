<star-list>
  <ul class="c-star-list">
    <li each="{ item in props.dataItems }" class="{getHighlightClass(item.isHighlighted)}">
      <div class="o-layout o-layout--gap-tight">
        <div class="o-size-hug">
          <ui-icon data-icon="star" class="{ getClass('c-star-list__icon') }"></ui-icon>
        </div>
        <div class="o-size-fill">
          <div class="{getTitleClass(item.isHighlighted)}">
            { item.title }
            <span class="c-star-list__title-sub">{ item.subTitle }</span>
          </div>
          <div class="c-star-list__description o-cancel-first-child-gap" if="{item.description}">
            <p>{ item.description }</p>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <script>
    // @ts-check
    export default function StarList() {
      return {
        /**
         * @param {string} className
         * @return {string}
         */
        getClass(className) {
          const result = [className]
          if (this.props.dataTheme) result.push(`${className}--theme-${this.props.dataTheme}`)
          return result.join(' ')
        },
        getHighlightClass(isHighlighted) {
          if (!isHighlighted) return false
          return this.getClass('c-star-list__item-highlighted')
        },
        getTitleClass(isHighlighted) {
          const result = ['c-star-list__title']
          if (!isHighlighted) return result.join(' ')
          result.push('c-star-list__title--is-highlighted')
          return result.join(' ')
        }
      }
    }
  </script>
</star-list>