<group-nav-link>
  <a href="{ getLink() }" class="{ getLinkClasses() }">
    <img if="{ props.dataItem.photo }" src="{ props.dataItem.photo }" alt="" class="c-group-menu__photo">
    <div class="o-layout o-layout--gap-tight o-layout--cross-axis-center">
      <div if="{ props.dataItem.isCorporate }">
        <logo-suzuki data-width="72" class="c-group-menu__suzuki-logo"></logo-suzuki>
      </div>
      <div class="o-size-fill">
        { props.dataItem.title }
      </div>
      <ui-icon data-icon="caret-right" class="{ getArrowClasses() }"></ui-icon>
    </div>
  </a>

  <script>
    // @ts-check
    export default function GroupNacLink() {
      return {
        /**
         * @return {string}
         */
        getLinkClasses() {
          /**
           * @type {string[]}
           */
          const result = ['c-group-menu__link'];
          if (this.props.dataIsChild) result.push(`c-group-menu__link--child`)
          if (this.props.dataItem.slug) result.push(`c-group-menu__link--theme-${this.props.dataItem.slug}`)
          if (this.props.dataItem.photo) result.push(`c-group-menu__link--has-photo`)
          return result.join(' ')
        },
        /**
         * @return {string}
         */
        getArrowClasses() {
          /**
           * @type {string[]}
           */
          const result = ['c-group-menu__arrow']
          if (this.props.dataItem.slug) result.push(`c-group-menu__arrow--theme-${this.props.dataItem.slug}`)
          return result.join(' ')
        },
        /**
         * @return {string}
         */
        getLink() {
          return this.props.dataItem.href ? this.props.dataItem.href : `/${this.props.dataItem.slug}`
        },
        /**
         * @typedef {object} styleObject
         * @property {string} style
         */
        /**
         * @param {string} photo 写真のURL
         * @return {styleObject}
         */
        getLinkStyle(photo) {
          /**
           * @type {string}
           */
          let result = `background-image: url("${photo}");`
          return { style: result }
        },
      }
    }
  </script>
</group-nav-link>