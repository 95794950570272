<kanagawa-car-result>
  <section>
    <ui-heading data-tag="h1" data-theme="car">就職先企業一覧</ui-heading>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-car">国産メーカー系ディーラー</h1>
      <ul class="c-list c-list--layout-flow c-list--theme-car">
        <li each="{listItem in list}">{listItem}</li>
      </ul>
    </section>
    <div class="o-layout o-layout--wrap o-layout--column-in-mobile">
      <section>
        <h1 class="c-heading-4 c-heading-4--theme-car">外車系ディーラー</h1>
        <ul class="c-list c-list--layout-flow c-list--theme-car">
          <li each="{listItem in list2}">{listItem}</li>
        </ul>
      </section>
      <section>
        <h1 class="c-heading-4 c-heading-4--theme-car">メーカー開発・生産系</h1>
        <ul class="c-list c-list--layout-flow c-list--theme-car">
          <li each="{listItem in list3}">{listItem}</li>
        </ul>
      </section>
      <section>
        <h1 class="c-heading-4 c-heading-4--theme-car">専業工場（自動車整備・板金塗装）</h1>
        <ul class="c-list c-list--layout-flow c-list--theme-car">
          <li each="{listItem in list4}">{listItem}</li>
        </ul>
      </section>
      <section>
        <h1 class="c-heading-4 c-heading-4--theme-car">兼業整備工場（バス会社などが保有する車両の整備・保守業務）</h1>
        <ul class="c-list c-list--layout-flow c-list--theme-car">
          <li each="{listItem in list5}">{listItem}</li>
        </ul>
      </section>
      <section>
        <h1 class="c-heading-4 c-heading-4--theme-car">建設機械・特殊車両</h1>
        <ul class="c-list c-list--layout-flow c-list--theme-car">
          <li each="{listItem in list6}">{listItem}</li>
        </ul>
      </section>
      <section>
        <h1 class="c-heading-4 c-heading-4--theme-car">バイク</h1>
        <ul class="c-list c-list--layout-flow c-list--theme-car">
          <li each="{listItem in list7}">{listItem}</li>
        </ul>
      </section>
    </div>
  </section>
  <script>
    export default function KanagawaCarResult() {
      return {
        list: [
          '(株)ホンダカーズ中央神奈川',
          '(株)ホンダカーズ神奈川日 ',
          '神奈川ダイハツ販売(株)',
          '(株)スズキ自販湘南',
          '(株)スズキ自販神奈川',
          '(株)湘南マツダ',
          '東日本三菱自動車販売(株)',
          '神奈川スバル（株)',
          '神奈川日産自動車(株)',
          '神奈川トヨタ自動車株式会社[トヨタモビリティ神奈川]',
          'ウェインズグループ[横浜トヨペット(株)／トヨタカローラ神奈川(株)／ネッツトヨタ神奈川(株)／ウェインズインポート横浜(株)]',
          'ネッツトヨタ多摩(株)',
          'トヨタモビリティ東京',
          'いすゞ自動車首都圏(株)',
          '横浜日野自動車(株)',
          '神奈川三菱ふそう自動車販売(株)',
        ],
        list2: [
          '(株)ヤナセ[メルセデスベンツ・BMWなど]',
          'SKY GROUP[ポルシェ・ベントレー・フェラーリ等]',
          'ニコル・カーウ合同会社[BMW・アルピナ・MINI]',
          'ファーレン九州[Audi 相模原]',
        ],
        list3: [
          'いすゞ自動車(株)',
        ],
        list4: [
          '(有)エビナ自動車工場',
          '(株)小倉自動車',
          '(有)草柳自動車',
        ],
        list5: [
          '神奈川中央交通(株)',
        ],
        list6: [
          '太陽建設レンタル(株)',
        ],
        list7: [
          '(株)レッドバロン',
        ],
      }
    }
  </script>
</kanagawa-car-result>