<news-list>
  <div class="o-layout c-news-list-layout">
    <div each="{ newsItem in getLimitedItem(props.dataNews) }">
      <slide-in data-theme="{ props.dataSlug }">
        <a href="{ newsItem.alt_link || getDetailLink(newsItem.slug) }" title="{ newsItem.title }"
          class="{ getClass('c-news-list-link') }">
          <article class="c-news-list-item">
            <h1 class="c-news-list-item__title">{ newsItem.title }</h1>
            <img src="{ newsItem.photo ? newsItem.photo : getThumbUrl() }" alt="" class="c-news-item-thumb">
            <time class="c-news-list-item__date" datetime="{ getDataTime(newsItem.date) }">{ newsItem.date }</time>
          </article>
        </a>
      </slide-in>
    </div>
  </div>

  <script>
    export default function NewsList() {
      return {
        /**
         * @param {string} className ベースのクラス名
         * @return {string} class属性に当てる文字列
         */
        getClass(className) {
          const result = [className]
          if (this.props.dataSlug) result.push(`${className}--theme-${this.props.dataSlug}`)
          return result.join(' ')
        },
        /**
         * @param {string} date
         * @return {string}
         */
        getDataTime(date) {
          return date.replace(/\./g, '-')
        },
        getThumbUrl() {
          let result = '/img/news-thumb'
          if (this.props.dataSlug) result += `-${this.props.dataSlug}`
          result += '.svg'
          return result
        },
        /**
         * @param {Array} items
         */
        getLimitedItem(items) {
          if (!this.props.dataIsArchive) {
            return items.slice(0, 3)
          }
          else {
            return items
          }
        },
        /**
         * @return {string}
         */
        getDetailLink(slug) {
          const siteRoots = {
            chuo: '/chuo',
            highschool: '/kanagawa/highschool',
            car: '/kanagawa/car',
            cook: '/atsugi/cook',
            design: '/atsugi/design',
            dental: '/atsugi/dental',
          }
          const detailPath = '/news/?slug='
          const slugString = slug ? slug : 'test'
          if (this.props.dataSlug) return siteRoots[this.props.dataSlug] + detailPath + slugString
          return detailPath + slugString
        }
      }
    }
  </script>
</news-list>