<page-atsugi-design>
  <school-header data-is-school="{ true }" data-slug="atsugi" data-school-name="厚木総合専門学校" data-sub-school-slug="design"
    data-sub-school-cource="生活デザイン学科"></school-header>
  <template if="{ isHome() }">
    <div class="c-school-hero">
      <div class="c-school-hero__heading">
        <slide-in data-theme="design">
          <div class="c-school-hero__heading-text c-school-hero__heading-text--theme-design o-cancel-first-child-gap">
            <p>
              <span class="o-layout o-layout--wrap o-layout--gap-none">
                <span>少人数制による</span>
              </span>
              <span class="o-layout o-layout--wrap o-layout--gap-none">
                <span>一人ひとりの</span>
                <span>個性に合わせた</span>
              </span>
              <span class="o-layout o-layout--wrap o-layout--gap-none">
                <span>きめ細やかな</span>
                <span>学びの場を</span>
                <span>提供し</span>
              </span>
              <span class="o-layout o-layout--wrap o-layout--gap-none">
                <span>専門知識を手に</span>
                <span>社会に羽ばたく</span>
              </span>
              <span class="o-layout o-layout--wrap o-layout--gap-none">
                <span>お手伝いをしています。</span>
              </span>
            </p>
          </div>
        </slide-in>
      </div>
      <div class="c-site-hero-photo c-site-hero-photo--atsugi-design">
        <photo-slider data-slides="{slides}" data-theme="design"></photo-slider>
      </div>
    </div>
    <div class="c-home-content">
      <wave-separator class="c-home-content__wave" data-slug="design"></wave-separator>
      <div class="o-layout o-layout--column">
        <div class="c-message-text c-message-text--theme-atsugi-design u-only-mobile">
          <p>
            <span class="o-layout o-layout--wrap o-layout--gap-none o-layout--main-axis-center">
              <span>少人数制による</span>
            </span>
            <span class="o-layout o-layout--wrap o-layout--gap-none o-layout--main-axis-center">
              <span>一人ひとりの</span>
              <span>個性に合わせた</span>
            </span>
            <span class="o-layout o-layout--wrap o-layout--gap-none o-layout--main-axis-center">
              <span>きめ細やかな</span>
              <span>学びの場を</span>
              <span>提供し</span>
            </span>
            <span class="o-layout o-layout--wrap o-layout--gap-none o-layout--main-axis-center">
              <span>専門知識を手に</span>
              <span>社会に羽ばたく</span>
            </span>
            <span class="o-layout o-layout--wrap o-layout--gap-none o-layout--main-axis-center">
              <span>お手伝いをしています。</span>
            </span>
          </p>
        </div>
        <div>
          <div class="o-container">
            <div class="o-spacer o-spacer--large-2 u-only-desktop"></div>
            <home-nav-items data-items="{ homeNavItems }" data-theme="design"></home-nav-items>
          </div>
        </div>
        <section>
          <div class="o-container">
            <ui-heading data-tag="h1" data-theme="design">お知らせ一覧</ui-heading>
            <div class="o-layout o-layout--column o-layout--cross-axis-center">
              <div>
                <news-list data-slug="design" data-news="{ news }"></news-list>
              </div>
              <div class="o-items-center">
                <ui-button data-href="./news" data-class="theme-design">一覧を見る</ui-button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="o-spacer o-spacer--large-2"></div>
  </template>
  <template if="{ !isHome() }">
    <school-content-template if="{ isPage('subject') }" data-title="コース・教科・取得資格" data-slug="design"
      data-current="/atsugi/design/subject" data-photo="/img/photo-atsugi-design-subject.webp">
      <page-atsugi-design-subject></page-atsugi-design-subject>
    </school-content-template>
    <school-content-template if="{ isPage('subject/it') }" data-title="ITコース" data-slug="design"
      data-current="/atsugi/design/subject/it" data-photo="/img/photo-atsugi-design-subject-it.webp"
      data-home-path="/atsugi/design/" data-breadcrumbs="{[{ name: 'コース・教科・取得資格',link: '../'}]}">
      <page-atsugi-design-it></page-atsugi-design-it>
    </school-content-template>
    <school-content-template if="{ isPage('subject/fashion') }" data-title="ファッションコース" data-slug="design"
      data-current="/atsugi/design/subject/fashion" data-photo="/img/photo-atsugi-design-subject-fashion.webp"
      data-home-path="/atsugi/design/" data-breadcrumbs="{[{ name: 'コース・教科・取得資格',link: '../'}]}">
      <page-atsugi-design-fashion></page-atsugi-design-fashion>
    </school-content-template>
    <school-content-template if="{ isPage('campus') }" data-title="キャンパス<wbr>ライフ" data-slug="design"
      data-current="/atsugi/design/campus" data-photo="/img/photo-atsugi-design-campus.webp">
      <page-atsugi-design-campus></page-atsugi-design-campus>
    </school-content-template>
    <school-content-template if="{ isPage('message') }" data-title="教師・先輩からのメッセージ" data-slug="design"
      data-current="/atsugi/design/message" data-photo="/img/photo-atsugi-design-message.webp">
      <page-atsugi-design-message></page-atsugi-design-message>
    </school-content-template>
    <school-content-template if="{ isPage('open-campus') }" data-title="オープン<wbr>キャンパス" data-slug="design"
      data-current="/atsugi/design/open-campus" data-photo="/img/photo-atsugi-design-open-campus.webp">
      <page-atsugi-design-open-campus></page-atsugi-design-open-campus>
    </school-content-template>
    <school-content-template if="{ isPage('application') }" data-title="募集要項" data-slug="design"
      data-current="/atsugi/design/application" data-photo="/img/photo-4.webp">
      <page-atsugi-design-application></page-atsugi-design-application>
    </school-content-template>
    <school-content-template if="{ isPage('qa') }" data-title="よくあるご質問" data-slug="design"
      data-current="/atsugi/design/qa" data-photo="/img/photo-atsugi-design-qa.webp">
      <page-atsugi-design-qa></page-atsugi-design-qa>
    </school-content-template>
    <school-content-template if="{ isPage('news') }" data-title="お知らせ" data-slug="design"
      data-current="/atsugi/design/news" data-photo="/img/design-news-title.svg">
      <page-atsugi-design-news data-news="{news}"></page-atsugi-design-news>
    </school-content-template>
  </template>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column">
    <section class="c-footer-nav-block">
      <div class="o-container">
        <design-nav data-where="footer"></design-nav>
      </div>
    </section>
    <section>
      <group-footer></group-footer>
    </section>
  </div>
  <atsugi-design-menu></atsugi-design-menu>
  <script>
    // @ts-check
    export default function PageAtsugiDesign() {
      return {
        /**
         * @typedef slide
         * @property {String} photo
         * @property {String} [position]
         */
        /**
         * @type {slide[]}
         */
        slides: [
          { photo: '/img/atsugi-design-photo.webp', },
          { photo: '/img/atsugi-design-photo-2.webp', },
          { photo: '/img/atsugi-design-photo-3.webp', },
          { photo: '/img/atsugi-design-photo-4.webp', },
        ],
        /**
         * @typedef {object} nav
         * @property {string} icon
         * @property {string} title
         * @property {string} href
         */
        /**
         * @type {nav[]}
         */
        homeNavItems: [
          {
            icon: 'study',
            title: 'コース・<wbr>教科・<wbr>資格検定',
            href: '/atsugi/design/subject'
          },
          {
            icon: 'school',
            title: 'キャンパス<wbr>ライフ',
            href: '/atsugi/design/campus'
          },
          {
            icon: 'message',
            title: '教師・<wbr>先輩からの<wbr>メッセージ',
            href: '/atsugi/design/message'
          },
          {
            icon: 'compass',
            title: 'オープン<wbr>キャンパス',
            href: '/atsugi/design/open-campus'
          },
          {
            icon: 'file',
            title: '募集要項',
            href: '/atsugi/design/application'
          },
          {
            icon: 'questions',
            title: 'よくあるご質問',
            href: '/atsugi/design/qa'
          },
        ],
        /**
         * @typedef {object} news
         * @property {string} title
         * @property {string} date
         * @property {string} slug
         * @property {string} content
         * @property {string} [photo]
         */
        /**
         * @type {news[]}
         */
        news: [
          {
            title: 'あつぎSDGsキャンパスに参加します！',
            date: '2024年2月14日',
            slug: 'atsugi-sdgs-campus',
            content: '<p>開催日時：令和6年2月17日（土曜日）～18日（日曜日）11時～18時<br>会場：小田急本厚木ミロード1 イベントスペース「ミロにわ」（4階）</p><p>不要となったネクタイ、生地の切れ端で作成した小物や厚木産のサツマイモを使用したスイートポテトを販売します。<br>リサイクル可能な歯ブラシや誤嚥性肺炎に関する予防策など、専門学校での取組も紹介します。</p><p><img src="/img/news-atsugi-sdgs-campus.webp" alt="厚木SDGsポスター" width="720" height="1016"></p>',
            photo: '/img/news-atsugi-sdgs-campus-thumb.webp',
          },
          {
            title: '冬季休業期間のお知らせ',
            date: '2023年12月26日',
            slug: 'notice-of-winter-holidays-2023',
            content: '<p>【資料請求について】<br>年内の資料発送は12月26日（火）にて終了になります。<br>年明けの発送は1月9日（火）からです。<br>【出願、体験入学等の各種お問合せについて】<br>12月27日（水）～ 1月8日（月）は冬季休業になります。<br>期間中、お電話等の受付もお休みさせて頂きます。</p><p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '豊友祭2023を開催します',
            date: '2023年10月14日',
            slug: 'fes-2023',
            content: '<p>今年も豊友祭（学園祭）行います。<br>楽しいイベントが盛り沢山！是非遊びに来てください！</p><ul><li>日程 2023/10/28（土）</li><li>時間 10:00〜15:00</li><li>会場 神奈川県厚木市恩名1-17-18</li></ul><p><a href="/img/2023-fes-poster.pdf" target="_blank"><img src="/img/fes-2023.webp" alt="豊友祭2023ポスター"></a></p>',
            photo: '/img/news-fes-2023.webp',
          },
          {
            title: '夏季休暇期間について',
            date: '2023年8月10日',
            slug: '2022-2023',
            content: '<p>8/11（金）～8/16（水）まで夏季休暇に伴い、頂きましたお問合せや資料請求に関しましては8/17（木）から順次ご対応させて頂きます。<br>皆様には大変ご迷惑お掛けいたしますが、ご理解賜りますよう宜しくお願い致します。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '年末年始の資料請求・お問合せについて',
            date: '2022年12月26日',
            slug: '2022-2023',
            content: '<p> <b>【資料請求について】</b> 年内の資料発送は12月26日（金）にて終了になります。<br> 年明けの発送は1月6日（金）からです。 </p> <p> <b>【出願、体験入学等の各種お問合せについて】</b> 12月27日（火）～ 1月5日（木）は年末年始は休業になります。<br> 期間中、お電話等の受付もお休みさせて頂きます。 </p> <p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '11月5日開催のあつぎ鮎まつりに参加します',
            date: '2022年10月26日',
            slug: 'ayumatsuri-2022',
            content: '<p>延期されていた「第76回あつぎ鮎まつり」の開催日が11月5日に決定しました。<br>ファッションショーは下記の時間に開催致します。</p><p>日程：2022/11/5（土）<br>時間：10:30〜11:00<br>会場：厚木中央公園　特設ステージ</p><p>マドレーヌ販売、チョークアート体験、<br>カークラフト作成等のイベントもありますので<br>是非、遊びに来て下さい！</p><p><img src="/img/ayumatsuri-2022.png" alt="鈴木学園3校合同 和装アレンジファッションショー"></p>',
            photo: '/img/news-ayumatsuri-2022.png',
          },
          {
            title: '豊友祭2022を開催します',
            date: '2022年10月17日',
            slug: 'fes-2022',
            content: '<p>3年ぶりに豊友祭（学園祭）行います。<br>新型コロナ感染症拡大防止の為、ホールイベント、飲食の販売等は<br>小規模になりますが是非遊びに来てください！</p><p>日程 2022/10/29（土）<br>時間 10:00〜16:00<br>会場 神奈川県厚木市恩名1-17-18</p><p><a href="/img/2022-fes-poster.pdf" target="_blank"><img src="/img/fes-2022.png" alt="豊友祭 冷和4年10月29日（土）10:00〜16:00"></a></p>',
            photo: '/img/news-2022-fes.png',
          },
          {
            title: '鈴木学園三校合同ファッションショー',
            date: '2022年9月16日',
            slug: 'pv-fashion-show-2022',
            content: '<p>8/5(金)開催の「鈴木学園三校合同ファッションショー」<br>【和洋折衷】和装アレンジファッションショーの様子をYouTubeにアップしました！</p><br><div class="c-video"><iframe width="560" height="315" src="https://www.youtube.com/embed/nMofmnoTtMw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
            photo: '/img/pv-fashion-show-2022-design.webp',
          },
          {
            title: '第76回あつぎ鮎まつりに参加します',
            date: '2022年7月8日',
            slug: 'atsugiayumatsuri-2022',
            content: '<img src="/img/news-atsugi-design-ayumatsuri-2022.webp">',
            photo: '/img/news-atsugi-design-ayumatsuri-2022-thumb.webp',
          },
          {
            title: '2022年度オープンキャンパスについて',
            date: '2022年4月1日',
            slug: 'open-campus-2022',
            content: '2022年度オープンキャンパス実施予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [オープンキャンパスページ](/atsugi/design/open-campus)',
            photo: '/img/photo-atsugi-design-open-campus.webp',
          },
          {
            title: 'サイトリニューアルのお知らせ',
            date: '2022年2月28日',
            slug: 'site-renewal-2022',
            content: 'この度、Webサイトを全面リニューアルいたしました。',
            photo: '/img/news-renewal-design.png',
          },
          {
            title: '新型コロナウイルス感染予防について',
            date: '2022年2月28日',
            slug: 'covid-19',
            content: '- 定期的な消毒、換気、教職員のマスク着用など感染症対策に配慮しながら実施しています。\n- ご来校時に検温をお願いしています。熱がある場合は恐れ入りますが、参加をご遠慮頂いております。\n- 新型コロナウイルス感染拡大防止の為、予定が変更になる場合がございます。',
            photo: '/img/news-covid-19.webp',
          },
          {
            title: '2021年度オープンキャンパスについて',
            date: '2021年4月1日',
            slug: 'open-campus-2021',
            content: '2021年度オープンキャンパス実施予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [オープンキャンパスページ](/atsugi/design/open-campus)',
            photo: '/img/photo-atsugi-design-open-campus.webp',
          },
        ],
        /**
         * @type {string[]}
         */
        pathArray: [],
        /**
         * @return {boolean}
         */
        isHome() {
          return this.pathArray.length === 2
        },
        /**
         * @param {string} slug
         * @return {boolean}
         */
        isPage(slug) {
          const pathname = window.location.pathname.match(/^\/atsugi\/design\/(.*)/)[1].replace(/(.*)\/$/, '$1')
          return pathname === slug
        },
        onBeforeMount() {
          this.pathArray = this.getPathNameArray()
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-design>