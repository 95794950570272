<page-kanagawa-highschool-application>
  <div class="c-content">
    <div class="c-box c-box--theme-highschool o-fit">
      <p>
        <b class="c-strong c-strong--theme-highschool">
          こちらのぺージは昨年度の募集要項を掲載しております。
          <br>新しい募集要項は夏頃を目処に公開を予定です。
        </b>
      </p>
    </div>
    <section>
      <ui-heading data-tag="h1" data-theme="highschool">令和6年度<br>神奈川総合大学校 高等課程<br>生徒募集要項</ui-heading>
      <table class="c-table o-space">
        <tr>
          <th>募集人員</th>
          <td class="o-cancel-first-child-gap">
            <p>
              生活造形学科＜ファッションコース・生活コース＞ 70名
              <br>工業技術学科＜電気コース＞ 70名
            </p>
            <p><small class="c-note">いずれも厚木中央高等学校との技能連携をしております</small></p>
          </td>
        </tr>
        <tr>
          <th>修業年限</th>
          <td>３年（大学入学資格取得校）</td>
        </tr>
        <tr>
          <th>入学資格</th>
          <td>中学校卒業および卒業見込みの方</td>
        </tr>
        <tr>
          <th>入学期</th>
          <td>4月</td>
        </tr>
      </table>
    </section>
    <div class="o-spacer o-spacer--large"></div>
    <div class="c-sticky-block">
      <ui-tabs data-tabs="{tabs}" data-current="{state.currentTab}" data-theme="highschool"
        data-tab-click-handler="{clickTabHandler}">
      </ui-tabs>
    </div>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="expenses"
      data-intersection-handler="{intersectionHandler}">
      <section id="expenses">
        <ui-heading data-tag="h1" data-theme="highschool">学費</ui-heading>
        <div class="o-space"></div>
        <div class="c-items-scroll">
          <table class="c-table c-table--keep-layout" style="min-width: 500px;">
            <tr>
              <th></th>
              <th>入学金</th>
              <th>教育充実費</th>
              <th>教育運営費</th>
              <th>授業料（1ヶ月）</th>
            </tr>
            <tr>
              <th>生活造形学科</th>
              <td rowspan="2">180,000円</td>
              <td rowspan="2">60,000円</td>
              <td rowspan="2">60,000円</td>
              <td rowspan="2">35,500円</td>
            </tr>
            <tr>
              <th>工業技術学科</th>
            </tr>
          </table>
        </div>
        <ul class="c-list c-list--theme-highschool">
          <li>学費は合格決定後、指定期日までに納入する。</li>
          <li>授業料は6ヶ月分を入学時と9月の2回に分納する。</li>
          <li>進級時には、教育充実費が必要となります。</li>
          <li>教科書および、教材費は別途に必要となります。</li>
          <li>一旦、提出された書類および入学検定料、入学金は返還いたしません。</li>
        </ul>
        <ul class="c-note-list">
          <li>教育ローンの方法もありますので、納入期日の前にお問い合わせ下さい。</li>
          <li><a href="#cooperation" class="c-link c-link--theme-highschool">連携型を希望される方は、別途技能校の費用が必要となります。</a></li>
        </ul>
        <section class="c-content">
          <h1 class="c-heading-4 c-heading-4--theme-highschool">就学支援金・学費補助金について</h1>
          <p>
            高等学校等就学支援金(国の制度)は、私立高校生等に対して支援金を支給(学校設置者が代理受領)し、授業料の一部を助成することにより教育費負担の軽減を図るものです。
            <br>令和5年度実績では、月額9,900円　年額118,800円支給。
            <br>（令和6年度の授業料負担額(1ヶ月)は35,500円－9,900円＝<b class="c-strong c-strong--theme-highschool">25,600円</b>の見込みです。）
          </p>
          <p>
            神奈川県より高等課程に学ぶ生徒(保護者と共に県内在住)のうち、保護者の所得が規定の金額以下の場合、申請により学費の一部が入学後補助されます。
          </p>
          <small>
            <ul class="c-note-list">
              <li>どちらも、保護者の所得に応じて申請によりさらに加算される場合があります。</li>
            </ul>
          </small>
        </section>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="recommended"
      data-intersection-handler="{intersectionHandler}">
      <section id="recommended">
        <ui-heading data-tag="h1" data-theme="highschool">出願手続および選考：<br><strong>推薦入試</strong></ui-heading>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">募集期間</h1>
          <p>
            令和5年10月2日(月)～令和5年12月14日(木)まで
            <br>午前9時00分～16時30分まで（土曜日は14時30分､最終日は正午まで）
          </p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">出願手続</h1>
          <p>
            下記書類に入学検定料20,000円を添えて出願して下さい。
          </p>
          <ol>
            <li>出身学校長の推薦書</li>
            <li>入学願書(本校所定)</li>
            <li>調査書(公立高校向けの用紙に、出身校において記入したもの)</li>
            <li>写真3枚(上半身4㎝×3㎝ 3ヶ月以内に撮影したものを願書・受験票に貼付)</li>
          </ol>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">選考日時</h1>
          <p>令和５年１２月１５日（金）午前９時００分より</p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">選考会場</h1>
          <p>専門学校 神奈川総合大学校</p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">選考方法</h1>
          <p>書類審査･個別面接</p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">合格発表</h1>
          <p>中学校に通知します</p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">入学手続き</h1>
          <p>
            合格者は令和6年1月9日（火）13時00分～15時00分までに専門学校 神奈川総合大学校へ学費を納入して下さい。納入されない場合は、入学取り消しとなります。
          </p>
        </section>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="general"
      data-intersection-handler="{intersectionHandler}">
      <section id="general">
        <ui-heading data-tag="h1" data-theme="highschool">出願手続および選考：<br><strong>一般入試</strong></ui-heading>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">募集期間</h1>
          <p>
            令和5年10月2日（月）～令和6年1月24日（水）まで
            <br>午前9時00分～16時30分まで（土曜日は14時30分まで）
          </p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">出願手続</h1>
          <p>
            下記書類に入学検定料20,000円を添えて出願して下さい。
          </p>
          <ol>
            <li>入学願書（本校所定）</li>
            <li>調査書(公立高校向けの用紙に、出身校において記入したもの)</li>
            <li>写真3枚(上半身4㎝×3㎝　3ヶ月以内に撮影したものを願書・受験票に貼付)</li>
          </ol>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">選考日時</h1>
          <p>令和6年1月26日（金）午前9時00分より</p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">選考会場</h1>
          <p>専門学校　神奈川総合大学校</p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">選考方法</h1>
          <p>
            生活造形学科： 国 語・基礎学力テスト・個別面接
            <br>工業技術学科： 国 語・数 学・個別面接
          </p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">合格発表</h1>
          <p>
            令和6年1月31日（水）午前10時00分～16時30分まで
            専門学校　神奈川総合大学校にて書類をお渡しします。
          </p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">入学手続き</h1>
          <p>
            合格者は、専門学校　神奈川総合大学校へ入学金を納入して下さい。
            <br>納入されない場合は、入学取り消しとなります。(併願の延納届は不要です。)
            <br><b class="c-strong c-strong--theme-highschool">【専願】</b>令和6年2月8日(木)の13時00分～15時00分までに納入
            <br><b class="c-strong c-strong--theme-highschool">【併願】</b>令和6年3月7日(木)の12時00分までに校納金ともに納入
          </p>
          <ul class="c-list c-list--anotation">
            <li>第二次募集は状況によって募集致しますので、お問い合わせ下さい。</li>
          </ul>
        </section>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="cooperation"
      data-intersection-handler="{intersectionHandler}">
      <section id="cooperation">
        <ui-heading data-tag="h1" data-theme="highschool">技能連携について</ui-heading>
        <p>
          技能連携制度とは、高等学校の通信制(厚木中央高等学校)または定時制の課程に在籍する生徒が、教育委員会の指定する技能教育施設(専門学校
          神奈川総合大学校　高等課程)で教育を受けている場合、その教育施設における学習を在籍高等学校における教科の一部の履修とみなす制度をいいます。(学校教育法第55条)
        </p>
        <p>
          入学資格　中学校卒業または卒業見込みで、専門学校 神奈川総合大学校高等課程の生活造形学科あるいは工業技術学科の入学試験に志願する方。
        </p>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-highschool">厚木中央高等学校　技能連携への志願</h1>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-highschool">募集人員</h1>
            <ul>
              <li>普通科:70名</li>
              <li>工業科:70名</li>
            </ul>
          </section>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-highschool">修業年限</h1>
            <p>3年</p>
          </section>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-highschool">出願手続</h1>
            <p>下記の書類に入学検定料20,000円(専門学校 神奈川総合大学校高等課程分、厚木中央高等学校分は免除)を添えて出願して下さい。</p>
            <ol>
              <li>入学願書　専門学校 神奈川総合大学校高等課程・厚木中央高等学校の連携生願書</li>
              <li>調査書(公立高等学校向けの用紙に、出身校において記載したもの)</li>
              <li>4枚(入学願書に2枚貼付、2枚ホッチキス留め)</li>
            </ol>
          </section>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-highschool">選考方法</h1>
            <p>厚木中央高等学校へは、特別推薦入試扱いとなります。</p>
          </section>
        </section>
        <section id="price">
          <h1 class="c-heading-4 c-heading-4--theme-highschool">学費一覧</h1>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-highschool">1年次</h1>
            <table class="c-table o-space">
              <tr>
                <th></th>
                <th>専門学校<wbr>神奈川総合大学校<wbr>高等課程</th>
                <th>厚木中央高等学校</th>
              </tr>
              <tr>
                <th>入学金</th>
                <td>180,000円</td>
                <td>10,000円</td>
              </tr>
              <tr>
                <th>教育充実費</th>
                <td>60,000円</td>
                <td></td>
              </tr>
              <tr>
                <th>教育運営費</th>
                <td>60,000円</td>
                <td></td>
              </tr>
              <tr>
                <th>施設費</th>
                <td></td>
                <td>
                  <div class="o-layout o-layout--gap-small">
                    <div><b class="c-label c-label--theme-highschool">免除</b></div>
                    <div><del>100,000円</del></div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>授業料（年間）</th>
                <td>426,000円</td>
                <td>
                  <div class="o-layout o-layout--gap-small">
                    <div><b class="c-label c-label--theme-highschool">減額</b></div>
                    <div><del>180,000円</del></div>
                    <div><b class="c-strong c-strong--theme-highschool">72,000円</b></div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>入学検定料 </th>
                <td>20,000円</td>
                <td>
                  <div class="o-layout o-layout--gap-small">
                    <div><b class="c-label c-label--theme-highschool">免除</b></div>
                    <div><del>20,000円</del></div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>小計</th>
                <td>746,000円</td>
                <td>82,000円</td>
              </tr>
              <tr>
                <th>合計</th>
                <td colspan="3">828,000円</td>
              </tr>
            </table>
          </section>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-highschool">2年次</h1>
            <table class="c-table o-space">
              <tr>
                <th></th>
                <th>専門学校<wbr>神奈川総合大学校<wbr>高等課程</th>
                <th>厚木中央高等学校</th>
              </tr>
              <tr>
                <th>授業料（年間）</th>
                <td>426,000円</td>
                <td>
                  <div class="o-layout o-layout--gap-small">
                    <div><b class="c-label c-label--theme-highschool">減額</b></div>
                    <div><del>120,000円</del></div>
                    <div><b class="c-strong c-strong--theme-highschool">72,000円</b></div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>小計</th>
                <td>426,000円</td>
                <td>72,000円</td>
              </tr>
              <tr>
                <td colspan="3">498,000円</td>
              </tr>
            </table>
          </section>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-highschool">3年次</h1>
            <table class="c-table o-space">
              <tr>
                <th></th>
                <th>専門学校<wbr>神奈川総合大学校<wbr>高等課程</th>
                <th>厚木中央高等学校</th>
              </tr>
              <tr>
                <th>授業料（年間）</th>
                <td>426,000円</td>
                <td>
                  <div class="o-layout o-layout--gap-small">
                    <div><b class="c-label c-label--theme-highschool">減額</b></div>
                    <div><del>120,000円</del></div>
                    <div><b class="c-strong c-strong--theme-highschool">72,000円</b></div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>小計</th>
                <td>426,000円</td>
                <td>72,000円</td>
              </tr>
              <tr>
                <td colspan="3">498,000円</td>
              </tr>
            </table>
          </section>
          <ul class="c-note-list">
            <li>別途に各学年で、教科書代・用具代、教材費および行事費が必要となります。</li>
            <li>新入生は、制服・教材費・教科書代などで、約35万円ほど必要となります。</li>
          </ul>
        </section>
      </section>
    </intersection-area>
  </div>
  <script>
    export default {
      state: {
        currentTab: 'expenses',
        disableIntersection: false
      },
      tabs: [
        {
          name: '学費',
          slug: 'expenses'
        },
        {
          name: '推薦入学',
          slug: 'recommended'
        },
        {
          name: '一般入学',
          slug: 'general'
        },
        {
          name: '技能連携について',
          slug: 'cooperation'
        },
      ],
      clickTabHandler(slug) {
        this.state.disableIntersection = true
        this.state.currentTab = slug
        this.update()
        window.requestAnimationFrame(() => {
          window.location.href = `#${slug}`
        })
      },
      intersectionHandler(slug) {
        this.state.currentTab = slug
        this.update()
      },
      onMounted() {
        document.documentElement.style.setProperty('--mobile-scroll-offset-tab', `50px`)
        this.scrollToHashedElement()
      },
      onUpdated() {
        if (this.state.disableIntersection) {
          if (this.timeoutId) window.clearTimeout(this.timeoutId)
          this.timeoutId = window.setTimeout(() => {
            this.update({ disableIntersection: false })
          }, 1000)
        }
      }
    }
  </script>
</page-kanagawa-highschool-application>