<news-detail>
  <article if="{props.dataPost}">
    <div class="o-layout o-layout--column">
      <div>
        <div class="c-news-list-item">
          {console.log(props.dataPost)}
          <h1 class="c-news-list-item__title c-news-detail-title">{props.dataPost.title}</h1>
          <time class="c-news-list-item__date" datetime="{ getDataTime(post.date) }">{props.dataPost.date}</time>
        </div>
      </div>
      <div if="{props.dataPost.content}">
        <div class="c-editor-content c-editor-content--theme-{props.dataTheme}">
          <ui-markdown data-content="{props.dataPost.content}"></ui-markdown>
        </div>
      </div>
      <div>
        <div class="o-items-center o-space-large-2">
          <ui-button data-tag="a" data-icon="caret-left" data-icon-position="start" data-href="./"
            data-class="{ getClassSlug() }">
            お知らせ一覧に戻る
          </ui-button>
        </div>
      </div>
    </div>
  </article>

  <script>
    // @ts-check
    export default function NewsDetail() {
      return {
        /**
         * @typedef {object} post
         * @property {string} title
         * @property {string} date
         */
        post: {
          title: 'お知らせ記事タイトル',
          date: '2020.11.13'
        },
        /**
         * @param {string} date
         * @return {string}
         */
        getDataTime(date) {
          return date.replace(/\./g, '-')
        },
        /**
         * @return {string}
         */
        getClassSlug() {
          return `theme-${this.props.dataTheme}`
        },
        getClassWithTheme(className) {
          const result = [className]
          if (this.props.dataTheme) result.push(`${className}--theme-${this.props.dataTheme}`)
          return result.join(' ')
        }
      }
    }
  </script>
</news-detail>