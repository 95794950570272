<chuo-menu>
  <school-menu data-items="{ menus }" data-slug="chuo"></school-menu>

  <script>
    export default function ChuoMenu() {
      return {
        menus: [
          {
            icon: 'school',
            title: 'スクールライフ',
            slug: '/chuo/campus',
          },
          {
            icon: 'compass',
            title: '体験入学',
            slug: '/chuo/open-campus',
          },
          {
            icon: 'book',
            title: '資料請求',
            slug: '/request',
          },
          {
            icon: 'mail',
            title: 'お問い合わせ',
            slug: '/contact',
          },
        ]
      }
    }
  </script>
</chuo-menu>