<suzuki-hero class="c-suzuki-hero">
  <div class="c-suzuki-hero__photo">
    <photo-slider data-slides="{slides}"></photo-slider>
  </div>
  <div class="c-suzuki-hero__heading u-only-desktop">
    <slide-in>
      <div class="c-suzuki-hero__heading-text o-cancel-first-child-gap">
        <p>
          神奈川県厚木市で89年の実績。
        </p>
        <p>
          個性と絆を大切に
          <br>社会で活躍できる人間教育を
          <br>つづけています。
        </p>
      </div>
    </slide-in>
  </div>
  <div class="c-suzuki-hero__title-block">
    <logo-suzuki class="c-suzuki-hero__logo" data-theme="suzuki"></logo-suzuki>
  </div>
  <script>
    export default function SuzukiHero() {
      return {
        /**
         * @typedef slide
         * @property {String} photo
         * @property {String} [position]
         */
        /**
         * @type {slide[]}
         */
        slides: [
          { photo: '/img/photo-1.webp' },
          {
            photo: '/img/chuo-photo.webp',
            position: '50% 10%',
          },
          { photo: '/img/kanagawa-highschool-photo.webp' },
          { photo: '/img/kanagawa-car-photo.webp' },
          { photo: '/img/atsugi-cook-photo.webp' },
          {
            photo: '/img/atsugi-design-photo.webp',
            position: '50% 10%',
          },
          { photo: '/img/atsugi-dental-photo-2.webp' },
          { photo: '/img/garden_F.jpg' },
        ]
      }
    }
  </script>
</suzuki-hero>