<logo-suzuki>
  <svg width="{ props.dataWidth ? props.dataWidth : '' }" height="{ props.dataHeight ? props.dataHeight : '' }"
    viewBox="0 0 171 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.4024 14.5037H51.4887V16.7363H52.8778V13.2584H50.6884C51.1565 12.6813 51.6548 12.013 52.0927 11.36L50.6129 10.8892C50.2657 11.603 49.6768 12.5598 49.1483 13.2584H46.5965L47.3364 12.9698C47.1401 12.3624 46.6267 11.4056 46.1133 10.7221L44.8903 11.1778C45.298 11.8156 45.7359 12.6509 45.9473 13.2584H43.1539L43.6975 13.0002C43.4408 12.4231 42.8368 11.5726 42.2932 10.9651L41.0853 11.5119C41.5231 12.0282 41.9912 12.7117 42.263 13.2584H40.0887V16.7363H41.4024V14.5037ZM53.1043 19.3029H47.1552V19.1055C48.4537 18.4372 49.7825 17.4652 50.6884 16.4933L49.7825 15.8098L49.4805 15.8858H42.3989V17.1159H48.1367C47.6233 17.526 47.0193 17.936 46.4304 18.255H45.7208V19.3029H39.832V20.609H45.7208V23.0997C45.7208 23.3275 45.6453 23.3882 45.3433 23.4034C45.0413 23.4186 43.9843 23.4186 42.9274 23.373C43.1388 23.7679 43.4106 24.345 43.5163 24.7399C44.8299 24.7399 45.7208 24.7247 46.3398 24.5273C46.9589 24.3146 47.1552 23.9198 47.1552 23.1452V20.609H53.1043V19.3029Z"
      fill="currentColor" />
    <path
      d="M68.3094 12.9547H64.8516V10.7981H63.4474V12.9547H60.0651V14.2607H68.3094V12.9547ZM65.0479 15.1416C65.969 16.144 66.9806 17.5108 67.3883 18.4068L68.5661 17.7234C68.1131 16.8122 67.0712 15.4909 66.1351 14.5341L65.0479 15.1416ZM61.9375 14.5797C61.4543 15.6428 60.5785 16.8729 59.6725 17.6323C59.9594 17.8449 60.4124 18.255 60.6238 18.5283C61.6204 17.6627 62.5716 16.3718 63.2058 15.1112L61.9375 14.5797ZM60.0953 19.0447C59.8235 18.6195 58.6005 16.797 58.1777 16.2503V15.2783H59.9141V13.9722H58.1777V10.7981H56.8641V13.9722H54.7955V15.2783H56.7433C56.2903 17.2222 55.3994 19.4699 54.4784 20.7305C54.7049 21.0494 55.022 21.5961 55.1578 21.991C55.792 21.095 56.3809 19.7281 56.8641 18.255V24.7247H58.1777V17.9816C58.6156 18.7258 59.0837 19.5914 59.3102 20.0926L60.0953 19.0447ZM65.3348 17.2678C65.0781 18.2702 64.6704 19.227 64.1118 20.0774C63.5078 19.2573 63.0246 18.3309 62.6773 17.3589L61.4694 17.7082C61.9224 18.984 62.5112 20.1534 63.2511 21.1861C62.2847 22.234 61.0164 23.0997 59.431 23.6616C59.6575 23.935 60.0047 24.4665 60.1557 24.7855C61.7714 24.178 63.0548 23.3123 64.0967 22.2492C65.0781 23.3275 66.2408 24.1932 67.6148 24.7703C67.8262 24.3906 68.2792 23.7983 68.5963 23.5249C67.192 23.0237 65.9841 22.2036 65.0026 21.1557C65.7727 20.0926 66.3465 18.8776 66.7541 17.5564L65.3348 17.2678Z"
      fill="currentColor" />
    <path
      d="M74.183 12.4231C73.5337 11.8915 72.2805 11.2385 71.2688 10.874L70.4988 11.9219C71.5104 12.332 72.7637 13.0458 73.3676 13.5773L74.183 12.4231ZM73.3525 16.3566C72.6882 15.8858 71.4047 15.2935 70.3931 14.9594L69.6683 16.0528C70.6951 16.4021 71.9634 17.0704 72.5825 17.5564L73.3525 16.3566ZM72.8241 18.8169C72.0087 20.5482 70.9216 22.4618 70.1515 23.6312L71.3594 24.5273C72.205 23.1149 73.1563 21.3228 73.881 19.7281L72.8241 18.8169ZM83.6956 24.0109C83.2124 22.7807 82.0045 20.9735 80.872 19.6522L79.6641 20.2901C80.1472 20.8824 80.6304 21.5658 81.0683 22.2644C79.498 22.3555 77.9125 22.4466 76.4932 22.5226C77.1123 21.2772 77.7767 19.7129 78.29 18.3309H83.4087V16.9944H79.2564V14.4734H82.699V13.1369H79.2564V10.7981H77.8069V13.1369H74.5152V14.4734H77.8069V16.9944H73.7602V18.3309H76.614C76.2063 19.6978 75.5872 21.3683 74.9833 22.5985L73.7753 22.6592L73.9716 24.0868C76.0251 23.935 78.9846 23.7375 81.8082 23.5097C82.0498 23.9653 82.2461 24.3906 82.382 24.7551L83.6956 24.0109Z"
      fill="currentColor" />
    <path
      d="M90.747 11.2081C90.6413 13.2128 90.7319 20.2597 84.6921 23.4945C85.1451 23.7983 85.5981 24.2387 85.8548 24.6032C89.3578 22.5833 90.9433 19.3029 91.668 16.4021C92.4532 19.3333 94.1292 22.8111 97.7833 24.588C98.0098 24.2083 98.4174 23.7375 98.8553 23.4034C93.2082 20.7912 92.423 13.9722 92.2871 11.9523C92.3173 11.6789 92.3173 11.4207 92.3324 11.2081H90.747Z"
      fill="currentColor" />
    <path
      d="M66.5126 46.2297H52.8326V48.6293H56.6678V60.1108H59.3555V48.6293H63.8853V53.9752C63.8853 54.2789 63.7645 54.37 63.4021 54.37C63.0397 54.4004 61.7714 54.4004 60.4728 54.37C60.7748 55.099 61.1372 56.1621 61.2278 56.9215C63.1907 56.9215 64.4892 56.8911 65.365 56.4963C66.3012 56.0406 66.5126 55.2813 66.5126 54.0056V46.2297ZM64.0665 41.1268H55.1579V43.5264H64.0665V41.1268ZM43.3804 39.6689C44.8903 38.0287 46.0379 36.3277 46.9136 34.8697C48.4236 36.2973 50.0845 38.3324 50.9602 39.6689H43.3804ZM58.0871 32.2272C56.8188 35.0216 54.1613 38.6058 51.4132 40.8231V39.6689H51.1112L52.8628 37.6034C51.7756 36.024 49.5409 33.8066 47.6988 32.2272H45.3131C44.2863 34.5964 42.3536 37.573 39.6055 39.8207C40.1189 40.1852 40.9645 41.0661 41.3571 41.6736C41.659 41.4002 41.961 41.1268 42.2328 40.8535V42.0381H45.4037V44.8325H40.8135V47.2625H45.4037V55.9191C43.5012 56.2533 41.7798 56.5266 40.3907 56.7392L41.0249 59.3211C44.2561 58.6832 48.6651 57.8631 52.7722 57.0126L52.591 54.6434L47.8498 55.4939V47.2625H52.1078V44.8325H47.8498V42.0381H51.4132V42.0077C51.7756 42.5241 52.138 43.1315 52.3494 43.6175C55.3088 41.3395 57.9965 37.7857 59.4763 35.0216C61.107 37.7857 63.9759 41.3091 66.6937 43.4353C67.1165 42.6152 67.7507 41.6128 68.2943 40.9142C65.516 39.031 62.5565 35.4772 60.654 32.2272H58.0871ZM50.2657 54.2789C50.779 52.9425 51.4132 50.8163 51.987 49.0242L49.8429 48.4167C49.5711 49.9961 48.9671 52.2742 48.5141 53.7626L50.2657 54.2789ZM41.2967 49.2368C41.8402 50.9378 42.3234 53.1855 42.414 54.6738L44.4071 54.0967C44.2863 52.6691 43.773 50.4518 43.169 48.7508L41.2967 49.2368Z"
      fill="currentColor" />
    <path
      d="M97.2246 42.2811V39.4866H85.6585V32.2575H82.6688V39.4866H71.1632V42.2811H81.4307C78.8638 47.3232 74.485 52.1831 69.925 54.6738C70.5894 55.2509 71.5557 56.3748 72.0087 57.0734C76.0855 54.5523 79.9208 50.2999 82.6688 45.44V60.1108H85.6585V45.3792C88.467 50.1176 92.3022 54.4612 96.2884 56.9822C96.7414 56.2229 97.7078 55.099 98.3722 54.5523C93.933 52.0312 89.4333 47.2017 86.8061 42.2811H97.2246Z"
      fill="currentColor" />
    <path
      d="M104.321 39.6385H124.494V44.1035H127.272V37.1478H122.893C123.83 35.9936 124.826 34.6571 125.702 33.351L122.742 32.4094C122.048 33.837 120.87 35.7506 119.813 37.1478H114.71L116.189 36.5707C115.797 35.3557 114.77 33.4421 113.743 32.0753L111.297 32.9865C112.113 34.2622 112.988 35.9328 113.411 37.1478H107.824L108.911 36.6314C108.398 35.4772 107.19 33.7762 106.103 32.5613L103.687 33.6548C104.563 34.6875 105.499 36.0543 106.043 37.1478H101.694V44.1035H104.321V39.6385ZM127.725 49.2368H115.827V48.8419C118.424 47.5054 121.082 45.5615 122.893 43.6175L121.082 42.2507L120.478 42.4026H106.314V44.8629H117.79C116.763 45.683 115.555 46.5031 114.377 47.141H112.958V49.2368H101.181V51.849H112.958V56.8304C112.958 57.286 112.807 57.4075 112.203 57.4379C111.599 57.4682 109.485 57.4682 107.371 57.3771C107.794 58.1668 108.338 59.3211 108.549 60.1108C111.176 60.1108 112.958 60.0804 114.196 59.6856C115.434 59.2603 115.827 58.4706 115.827 56.9215V51.849H127.725V49.2368Z"
      fill="currentColor" />
    <path
      d="M153.122 41.2483H145.724V39.6689H151.703V38.0287H145.724V36.5099H143.187V38.0287H137.51V39.6689H143.187V41.2483H135.909V42.9189H153.122V41.2483ZM145.935 49.2368H150.435C149.68 49.8443 148.804 50.4821 147.989 51.0289C147.203 50.4821 146.479 49.8746 145.935 49.2672V49.2368ZM140.016 45.6526H149.076V47.7181H140.016V45.6526ZM151.552 48.69V44.1035H137.661V49.2368H142.825C140.771 50.7859 137.963 52.0616 135.275 52.9121C135.758 53.3373 136.513 54.2789 136.845 54.7345C138.838 53.9752 140.952 52.9425 142.825 51.7275V55.585H145.301V51.3022C147.173 53.1247 149.77 54.6434 152.307 55.4332C152.639 54.9168 153.304 54.0359 153.787 53.6411C152.337 53.2766 150.888 52.6995 149.559 51.9705C150.676 51.3326 151.945 50.4821 153.032 49.6013L151.552 48.69ZM134.611 56.3748V36.024H154.421V56.3748H134.611ZM131.983 33.4725V60.1108H134.611V58.9262H154.421V60.1108H157.169V33.4725H131.983Z"
      fill="currentColor" />
    <path
      d="M25.9211 10.7776C25.7014 11.0557 25.3839 11.4815 25.0184 12.0441C24.2861 13.1715 23.3702 14.8342 22.6591 16.9464C21.2484 21.1361 20.6168 26.7758 23.9452 34.0497C27.7346 42.331 27.0367 49.8829 25.3562 54.9828C24.6255 57.2002 23.7124 59.0193 22.9127 60.369H14.2081C14.2409 60.3269 14.2771 60.28 14.3164 60.2282C14.5395 59.934 14.8613 59.4852 15.2314 58.8949C15.973 57.7123 16.8999 55.9758 17.6207 53.7884C19.0526 49.4428 19.6886 42.9031 16.3638 35.6371C12.5781 27.3638 13.255 20.7074 14.9314 15.7286C15.6352 13.6381 16.5106 11.9211 17.2902 10.631H26.0388C26.0036 10.6741 25.9643 10.7229 25.9211 10.7776Z"
      fill="url(#gradient-{ id })" />
    <defs>
      <linearGradient id="gradient-{ id }" x1="19.3951" y1="10.631" x2="19.3951" y2="60.369"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#5BC6DD" />
        <stop offset="1" stop-color="#5B7FDD" />
      </linearGradient>
    </defs>
  </svg>
  <script>
    export default function LogoSuzuki() {
      return {}
    }
  </script>
</logo-suzuki>