<heading-tag>
  <h1 if="{ props.dataTag === 'h1' }" class="{ props.dataClass }">
    <slot />
  </h1>
  <h2 if="{ props.dataTag === 'h2' }" class="{ props.dataClass }">
    <slot />
  </h2>
  <h3 if="{ props.dataTag === 'h3' }" class="{ props.dataClass }">
    <slot />
  </h3>
  <h4 if="{ props.dataTag === 'h4' }" class="{ props.dataClass }">
    <slot />
  </h4>
  <h5 if="{ props.dataTag === 'h5' }" class="{ props.dataClass }">
    <slot />
  </h5>
  <h6 if="{ props.dataTag === 'h6' }" class="{ props.dataClass }">
    <slot />
  </h6>
</heading-tag>