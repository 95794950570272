<page-atsugi-cook-message>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="cook">先輩からのメッセージ</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--gap-loose o-layout--column">
            <div each="{ message in messages }">
              <message-box data-item="{ message }" data-slug="cook"></message-box>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="cook">教師からのメッセージ</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--gap-loose o-layout--column">
            <div each="{ message in messages2 }">
              <message-box data-item="{ message }" data-slug="cook"></message-box>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiCookMessage() {
      return {
        /**
         * @typedef {object} message
         * @property {string} [photo] 写真のURL
         * @property {string} [title] 単純文字列
         * @property {string} profile HTML文字列
         * @property {string} message HTML文字列
         */
        /**
         * @type {message[]}
         */
        messages: [
          {
            photo: '/img/cook-message-avator.webp',
            title: '人生は一期一会だよ！',
            profile: '一般コース',
            message: 'この学校は生徒の年齢層が幅広いため、今まであまり接しなかった年齢の友達ができて、とても楽しいし刺激のある学校生活を送っています。調理面では色々な料理をオールマイティーに勉強できてできて、自分が全く知らなかったことに驚かされる毎日です。先生方が放課後までずっと一緒にいてくれて、いつでも声をかけても教えてもらえる環境にあるので、基礎技術が上達していくのがとても楽しいです。そして、とても新鮮で充実した毎日がいいですね。',
          },
          {
            photo: '/img/cook-message-avator-2.webp',
            title: '先生との距離が近い！',
            profile: '一般コース',
            message: '先生も含め色々な世代の方が一緒でいいですね。そして、みんなの距離がとっても近いのでとてもいいです。堅苦しさがなく、和気あいあい的な雰囲気は、居心地がとても良く感じます。勉強しやすいし、実習で難しかったり苦手だったりしてもいつでも放課後に練習できる環境もあり、先生がわかりやすく丁寧に教えてくれるので、正面から取り組めてひとつひとつの技術をしっかり学べる学校です。調理に関連する放課後クラブも2つあり、すごく魅力的です。',
          },
          {
            photo: '/img/cook-message-avator-3.webp',
            title: '放課後、思う存分調理できるのがいい！',
            profile: '高校コース',
            message: '入学してよかったと思うところは、放課後はいつでも調理実習室が使えるところです。うまく行かなかった実習の料理を復習できたら、実技試験前にも繰り返し練習して試験に臨めたり、包丁研ぎも毎日できます。わからないことがあったらいつでも実習の先生が直接、親切に教えてくれるので思う存分、放課後を活用できます。社会人になるための心構えもきっちり教えてくれるのもいいです。',
          },
          {
            photo: '/img/cook-message-avator-4.webp',
            title: '最初の1年！目標ができるのがうれしい！',
            profile: '高校コース',
            message: '1年制の調理師学校で高校も同時入学できるところが気に入って入学を決めました。16才で調理師免許が取れるなんてすごくいいと思ったからです。まず、1年やってやる！という目標がしっかりと自分の中でできることが魅力です。日本、西洋、中華、パン、お菓子など色々な料理を教わりながら、将来どういう道に進むか高校を卒業するまでゆっくり時間をかけて考えることができるところがいいなと思います。',
          },
          {
            photo: '/img/cook-message-avator-6.webp',
            title: '良い料理人は良い社会人になる事から！人格は料理に表れます!!',
            profile: 'UDS株式会社 ホテル＆事業部 料理長 田中 裕太さん',
            message: '今の職場では様々な料理を出す宿泊施設を運営している為、求められる料理の幅が大きくなっています。洋食、中華、イタリアンの技法など施設毎の厨房に会ったメニューを作る上で大きな武器になります。これは、調理師学校での実習があったおかげで対応出来ていると思っています。<br>実習で一回作るのと、一度も作った事が無いのでは大きく違うからです。<br>また、後輩への調理指導で役立つのが調理理論です。<br>理屈で教える事で効率よく調理を教えられる場合があるからです。<br>私は学校で学べた時間を今の仕事でお金にしています。<br>やりがいや楽しさは、下積みでがんばった分だけ後から返ってきます。<br>学校で学べる基礎は本当に大切です。',
          },
          {
            photo: '/img/cook-message-avator-7.webp',
            title: 'オレがオレがのがを捨てておかげおかげのげで生きる!!',
            profile: '株式会社 日影茶屋<br>日影茶屋本店 松浦 草平さん',
            message: 'この学校で、出来た仲間と将来店を持つために日本料理を学びたいと思いました。そして湘南の葉山にある、300年近くの歴史をもつ日影茶屋に就職しました。寿司屋でアルバイトしていた経験もあったので現場で早くなじめるだろうと思いました。しかし、想像以上に厳しい世界でした。今もそうなのですが、毎日失敗ばかりです。怒られてばかりです。何度も辞めたいと思いました。しかし、努力すれば報われるだろうと思い、がんばっています。最近は色んな仕事を任せてもらえるようになりました。<br>まだ、修行中の身ですが、自分の夢のために日々努力していきたいです。',
          },
          {
            photo: '/img/cook-message-avator-8.webp',
            title: 'イタリア料理のコックさんになるのが子供の頃からの夢だった。',
            profile: '株式会社フィーコディンディア シェフ 横井さん',
            message: 'イタリア料理のコックさんになるのが子どもの頃からの夢でした。お店の特徴は元気になれるシチリア料理店。お客様の笑顔がたくさん見られるのがすごく嬉しいし、全社員でイタリアのシチリア島へ研修に行って、現地の料理を食べて勉強できる職場環境を作っています。料理人って素敵な仕事ですよ！',
          },
        ],
        /**
         * @type {message[]}
         */
        messages2: [
          {
            profile: '教師一同',
            message: '人それぞれ個性があるから面白い。そして必ず長所を持っています。そこを伸ばしていけるかが私たちの仕事であり責任でもあるので、調理の世界を知らなくても力をつけるのは入学してから。やる気一つで有名なお店に就職して行った先輩も多いです。<br>毎年思うことは「目標を持って入学した生徒さんの勝ち」ということです。卒業した後、かっこいい姿を見ながら食事ができたら最高ですね。',
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-cook-message>