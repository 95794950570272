<page-suzuki-home>
  <suzuki-hero></suzuki-hero>
  <div class="c-home-content">
    <wave-separator class="c-home-content__wave"></wave-separator>
    <div class="o-container">
      <div class="o-layout">
        <div class="o-size-fill">
          <div class="o-layout o-layout--column">
            <div class="c-message-text u-only-mobile">
              <slide-in>
                <p>
                  神奈川県厚木市で89年の実績。
                  <br>個性と絆を大切に
                  <br>社会で活躍できる人間教育を
                  <br>つづけています。
                </p>
              </slide-in>
            </div>
            <section>
              <div class="o-cancel-first-child-gap">
                <ui-heading data-tag="h1">お知らせ一覧</ui-heading>
              </div>
              <div class="o-layout o-layout--column o-layout--cross-axis-center">
                <div>
                  <news-list data-news="{props.dataNews}"></news-list>
                </div>
                <div>
                  <ui-button data-href="/news">一覧を見る</ui-button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="o-size-hug c-facebook-timeline-block" if="{false}">
          <div class="o-container">
            <facebook-timeline></facebook-timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="o-spacer o-spacer--large-2"></div>
  <script>
    export default {
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-suzuki-home>