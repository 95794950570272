<page-suzuki-school>
  <section class="o-layout o-layout--column">
    <div>
      <ui-heading data-tag="h2">社会への道のり</ui-heading>
    </div>
    <div class="o-items-center">
      <school-guide></school-guide>
    </div>
    <div>
      <p></p>
    </div>
    <div>
      <ui-heading data-tag="h2">アクセス</ui-heading>
    </div>
    <section>
      <h2 class="c-heading-4" id="map-1">鈴木学園本部<br>厚木総合専門学校</h2>
      <p>
        〒243-0018 厚木市中町3-4-11
        <br>（本厚木駅より徒歩2分）
        <br>Tel：046(224)6311
      </p>
      <div class="o-spacer"></div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3250.5958856850007!2d139.36007221525216!3d35.44003948025127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019aab27ade64b3%3A0xa52bb342f5e50b8a!2z44CSMjQzLTAwMTgg56We5aWI5bed55yM5Y6a5pyo5biC5Lit55S677yT5LiB55uu77yU4oiS77yR77yR!5e0!3m2!1sja!2sjp!4v1634472901127!5m2!1sja!2sjp"
        width="100%" height="360" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      <p class="o-items-center">
        <ui-button data-href="/atsugi">厚木総合専門学校</ui-button>
      </p>
    </section>
    <section>
      <h2 class="c-heading-4" id="map-2">
        厚木中央高等学校
        <br>専門学校 神奈川総合大学校
      </h2>
      <p>
        〒243-0032 厚木市恩名1-17-18
        <br>（本厚木駅より徒歩15分）
        <br>Tel：046(221)5678
      </p>
      <div class="o-spacer"></div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3250.7208243026175!2d139.3517803152769!3d35.436945150937866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019aabab598ed49%3A0x77ed925a1071c392!2z44CSMjQzLTAwMzIg56We5aWI5bed55yM5Y6a5pyo5biC5oGp5ZCN77yR5LiB55uu77yR77yX4oiS77yR77yY!5e0!3m2!1sja!2sjp!4v1634473009227!5m2!1sja!2sjp"
        width="100%" height="360" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      <p class="o-items-center">
        <ui-button data-href="/chuo">厚木中央高等学校</ui-button>
      </p>
      <p class="o-items-center">
        <ui-button data-href="/kanagawa">専門学校 神奈川総合大学校</ui-button>
      </p>
    </section>
    <ui-heading data-tag="h2" data-theme="highschool" if="{ false }">全校アクセスマップ</ui-heading>
  </section>
  <script>
    export default function PageSuzukiSchool() {
      return {
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-suzuki-school>