<page-suzuki-policy>
  <section class="o-layout o-layout--column">
    <div>
      <p>学校法人鈴木学園は、個人情報の管理が重要な責務であるとの認識のもと、個人情報の保護に努めるため、本方針を定めます。</p>
      <h2 class="c-heading-4">法令遵守等</h2>
      <p>当法人は、「個人情報の保護に関する法律」及び関連する諸法令等を遵守するとともに、内部管理体制を強化し、これを継続的に点検し、改善してまいります。</p>
      <h2 class="c-heading-4">取得・利用等</h2>
      <p>当法人は、適正かつ公平な手段により個人情報を取得し、その利用目的の範囲内で利用するとともに、法令に定めのある場合を除き、個人データを第三者に提供いたしません。</p>
      <h2 class="c-heading-4">安全管理措置</h2>
      <p>当法人は、個人データの安全管理のために、必要かつ適切な措置を講じるとともに、委託先の監督と教職員その他関係者等への必要な教育を行います。</p>
      <h2 class="c-heading-4">開示・訂正等</h2>
      <p>当法人は、開示、訂正または削除を希望される個人情報については、ご本人であることを確認の上、当法人の定める手続きに従い、対応いたします。</p>
      <h2 class="c-heading-4">お問合せ窓口</h2>
      <p>個人情報の取扱いに関するお問合せ等に関しては、以下の窓口にご連絡ください。</p>
      <p>
        学校法人鈴木学園
        <br>TEL: 046-224-6311
        <br>E-mail: info@suzukigakuen.ac.jp
      </p>
    </div>
    <div>
      <ui-heading data-tag="h1" data-theme="highschool">特定個人情報等の適正な取扱いに関する基本方針</ui-heading>
    </div>
    <div>
      <p>「行政手続における特定の個人を識別するための番号の利用等に関する法律」に基づく、個人番号及び特定個人情報（以下「特定個人情報等」）の適正な取扱いの確保について組織として取り組むため、本基本方針を定めます。</p>
      <h2 class="c-heading-4">事業者の名称</h2>
      <p>学校法人鈴木学園</p>
      <h2 class="c-heading-4">関係法令・ガイドライン等の遵守</h2>
      <p>
        当法人は、「行政手続における特定の個人を識別するための番号の利用等に関する法律」、「個人情報の保護に関する法律」及び「特定個人情報の適正な取扱いに関するガイドライン（事業者編）」を遵守して、特定個人情報等の適正な取扱いを行います。
      </p>
      <h2 class="c-heading-4">安全管理措置に関する事項</h2>
      <p>当法人は、特定個人情報等の安全管理措置に関して、別途「特定個人情報取扱規程」を定め、遵守します。</p>
      <h2 class="c-heading-4">ご質問等の窓口</h2>
      <p>当法人における特定個人情報等の取扱いに関するご質問等に関しては、下記の窓口にご連絡ください。</p>
      <p>
        学校法人鈴木学園
        <br>TEL: 046-224-6311
        <br>E-mail: info@suzukigakuen.ac.jp
      </p>
    </div>
  </section>
  <script>
    export default {
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-suzuki-policy>