<page-chuo-message>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="chuo">先輩からのメッセージ</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--gap-loose o-layout--column">
            <div each="{ message in messages }">
              <message-box data-item="{ message }" data-slug="chuo"></message-box>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="chuo">教師からのメッセージ</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--gap-loose o-layout--column">
            <div each="{ message in messages2 }">
              <message-box data-item="{ message }" data-slug="chuo"></message-box>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageChuoMessage() {
      return {
        /**
         * @typedef {object} message
         * @property {string} [photo] 写真のURL
         * @property {string} [title] 単純文字列
         * @property {string} [profile] HTML文字列
         * @property {string} message HTML文字列
         */
        /**
         * @type {message[]}
         */
        messages: [
          {
            photo: '/img/chuo-message-1.webp',
            title: '将来を見通して',
            profile: '普通科卒業（1年次 厚木総合専門学校調理学科同時入学）',
            message: '「一生一品」。私の好きな言葉です。「料理人は一生のうち満足のできる料理を一皿でも作れば本望。一皿を作る間に新たなアイディアが浮かんでくる…そう思える間は現役だ。」一番尊敬する料理人、道場六三郎さんの言葉です。日本料理の分野に進む夢に向かって、調理師免許、高校卒業資格を取得するために、この学校を選びました。調理師免許、高校卒業をはじめ、様々な資格を手に入れることができ、多くを学べる場所です。近い将来、同じ料理の世界を志す人、料理に興味がある人はぜひ、この学校に入学し、私たちと一緒に将来の一歩を踏み出してみませんか？',
          },
          {
            title: '夢を現実に！',
            profile: '普通科卒業後、ブライダル関連会社勤務',
            photo: '/img/chuo-message-2.webp',
            message: '学生時代にファッションコースで学んだことをもとに、現在ウェディング関係の仕事をしています。まだ毎日が勉強する事ばかりですが、沢山の笑顔あふれる幸せのお手伝いができ、やりがいのある日々を過ごしています。',
          },
          {
            title: '夢を現実に！',
            profile: '工業科卒業後、専門学校神奈川総合大学校 専門課程 自動車整備科進学',
            photo: '/img/chuo-message-3.webp',
            message: '私は、工業科から姉妹校の専門課程 自動車整備科へ進学して、日々プロの整備士を目指して学んでいます。工業科で学んだ知識のおかげで授業がわかりやすく、パーツの分解などの実習も楽しくスムーズに行えます。今後は、プロの自動車整備士を目指して学校の授業を精一杯頑張って技能や知識を身につけていきたいです。',
          },
        ],
        /**
         * @type {message[]}
         */
        messages2: [
          {
            profile: '教師一同',
            message: '<p>激変する社会の中で普遍的な実力を身につける。<br>その為には、基礎学力や基本的生活習慣を含めたキャリア教育が必要です。<br>人のために創造的な活動を通じて新しい価値観を生み出す素地を育成する。</p><ul><li>視点1. 「与えられる」から「自分のモノにしていく思考回路の変換」。</li><li>視点2. 資格取得への参加を推奨し、基礎知識の定着を基に、知識とスキルを確かなモノにする。</li><li>視点3. 小さな達成感でも積み重ねることで主体性は育まれる。</li></ul><p>生徒にはそれぞれの資質がある。それを引出し、自己実現させるための後押しをさせていただきます。</p>',
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-chuo-message>