<kanagawa-nav>
  <div class="o-layout o-layout--column">
    <div class="o-items-center">
      <ui-button data-tag="a" data-href="/kanagawa" class="o-text-center">
        専門学校神奈川総合大学校
        <br>トップページ
      </ui-button>
    </div>
    <div>
      <div class="o-container">
        <kanagawa-school-nav class="o-space"></kanagawa-school-nav>
      </div>
    </div>
  </div>
</kanagawa-nav>