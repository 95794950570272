<kanagawa-school-nav>
  <div class="o-layout o-layout--main-axis-center c-kanagawa-nav-layout">
    <div>
      <div class="o-items-center">
        <a class="o-block-link o-items-center c-circle-more-link c-circle-more-link--theme-highschool"
          href="/kanagawa/highschool">
          <photo-and-title data-theme="highschool" data-photo="/img/kanagawa-highschool-photo-2.png">
            高等課程
          </photo-and-title>
        </a>
      </div>
    </div>
    <div>
      <div class="o-items-center">
        <a class=" o-block-link o-items-center c-circle-more-link c-circle-more-link--theme-car" href="/kanagawa/car">
          <photo-and-title data-theme="car" data-photo="/img/kanagawa-car-photo-2.png">
            専門課程<br>自動車整備科
          </photo-and-title>
        </a>
      </div>
    </div>
  </div>
</kanagawa-school-nav>