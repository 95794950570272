<wave-separator>
  <svg width="375" height="34" viewBox="0 0 375 34" fill="none" xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none">
    <g clip-path="url(#clip-{ id })">
      <path
        d="M189.467 16.7942C299.939 -3.9904 375 24.44 375 24.44L375 33.5L-1.32668e-06 33.5L3.05176e-05 8.99999C3.05176e-05 8.99999 78.9952 37.5789 189.467 16.7942Z"
        fill="white" />
      <path
        d="M395.089 25.6726C395.089 25.6726 312.054 -7.51951 189.844 16.7463C67.6339 41.012 -19.7545 7.64659 -19.7545 7.64659"
        stroke="url(#paint0_linear_296:10336)" stroke-width="14" stroke-miterlimit="10" stroke-linecap="square" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_296:10336" x1="395.089" y1="15.6224" x2="-19.7545" y2="15.6224"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="{ colorStop1 }" />
        <stop offset="1" stop-color="{ colorStop2 }" />
      </linearGradient>
      <clipPath id="clip-{ id }">
        <rect width="375" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <script>
    export default function WaveSeparator() {
      return {
        onBeforeMount() {
          this.bodyElement = document.querySelector('body')
          this.bodyStyles = getComputedStyle(this.bodyElement)
          this.slug = this.props.dataSlug ? this.props.dataSlug : 'suzuki'
          this.colorStop1 = this.bodyStyles.getPropertyValue(`--color-${this.slug}-2`).trim()
          this.colorStop2 = this.bodyStyles.getPropertyValue(`--color-${this.slug}-3`).trim()
        }
      }
    }
  </script>
</wave-separator>