<car-nav>
  <school-nav data-items="{ navs }" data-slug="cook" data-where="{ props.dataWhere }"
    data-current="{ props.dataCurrent }"></school-nav>
  <script>
    export default function CookNav() {
      return {
        navs: [
          {
            icon: 'home',
            title: 'ホーム',
            href: '/atsugi/cook/',
          },
          {
            icon: 'study',
            title: 'コース・教科・取得資格',
            href: '/atsugi/cook/subject',
          },
          {
            icon: 'school',
            title: 'キャンパスライフ',
            href: '/atsugi/cook/campus',
          },
          {
            icon: 'message',
            title: '教師・先輩からのメッセージ',
            href: '/atsugi/cook/message',
          },
          {
            icon: 'compass',
            title: 'オープンキャンパス',
            href: '/atsugi/cook/open-campus',
          },
          {
            icon: 'file',
            title: '募集要項',
            href: '/atsugi/cook/application',
          },
          {
            icon: 'questions',
            title: 'よくあるご質問',
            href: '/atsugi/cook/qa',
          },
          {
            icon: 'bell',
            title: 'お知らせ',
            href: '/atsugi/cook/news',
          },
          {
            icon: 'map',
            title: 'アクセス',
            href: '/school#map-1',
          },
          {
            icon: 'book',
            title: '資料請求',
            href: 'https://www.school-go.info/f9z653/atsugi/form.php?fno=153&fsno=1&openExternalBrowser=1',
            target: '_blank',
          },
          {
            icon: 'mail',
            title: 'お問い合わせ',
            href: 'https://www.school-go.info/f9z653/atsugi/form.php?fno=104&fsno=1&openExternalBrowser=1',
            target: '_blank',
          },
          {
            icon: 'lock',
            title: '個人情報のお取り扱いについて',
            href: '/policy',
          },
        ]
      }
    }
  </script>
</car-nav>