<page-kanagawa-car-application>
  <div class="c-content">
    <section>
      <ui-heading data-tag="h1" data-theme="car">2025年度 学生募集要項</ui-heading>
      <div>
        <ui-box data-heading-tag="h1" data-heading="書類提出について" data-theme="car">
          <ul class="c-list c-list--theme-car">
            <li>月曜日から金曜日は 9:00～16:30 まで。</li>
            <li>土曜日は 9:00～14:00 まで。</li>
            <li>日、祝日、夏季・冬季休暇は休校となります。</li>
          </ul>
        </ui-box>
      </div>
      <table class="c-table o-space">
        <tr>
          <th>募集人員</th>
          <td class="o-cancel-first-child-gap">
            <ul class="c-unstyled-list">
              <li>
                <div class="o-layout o-layout--gap-small">
                  <div>
                    <span class="c-label c-label--theme-car o-nowrap">一級自動車整備科</span>
                  </div>
                  <div class="o-nowrap">13名<wbr>（2年次で修了することもできます。）</div>
                </div>
              </li>
              <li>
                <div class="o-layout o-layout--gap-small">
                  <div>
                    <span class="c-label c-label--theme-car o-nowrap">二級自動車整備科</span>
                  </div>
                  <div class="o-nowrap">34名<wbr>（卒業後<wbr>一級自動車整備科に<wbr>3年次に編入する<wbr>制度があります。）</div>
                </div>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>修業年限</th>
          <td class="o-cancel-first-child-gap">
            <ul class="c-unstyled-list">
              <li>
                <div class="o-layout o-layout--gap-small">
                  <div>
                    <span class="c-label c-label--theme-car">一級自動車整備科</span>
                  </div>
                  <div>4年</div>
                </div>
              </li>
              <li>
                <div class="o-layout o-layout--gap-small">
                  <div>
                    <span class="c-label c-label--theme-car">二級自動車整備科</span>
                  </div>
                  <div>2年</div>
                </div>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>応募資格</th>
          <td class="o-fit">
            <ul class="c-list c-list--theme-car">
              <li>オープンキャンパス、又は学校説明会に参加した方</li>
              <li>カリキュラムの受講に対し、支障のない方</li>
              <li>普通自動車運転免許を取得済み、または本校卒業までに取得可能な方</li>
              <li>各入試方法の応募資格を満たした方</li>
              <li>
                下記の①～④のいずれかを満たした方
                <ol>
                  <li>高等学校、高等専修学校を2025年3月に卒業見込みの方</li>
                  <li>高等学校卒業程度認定試験合格、または合格見込みの方</li>
                  <li>高等学校、高等専修学校を卒業した方</li>
                  <li>外国において、12年間の学校教育を修了した方</li>
                </ol>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>入学期</th>
          <td>毎年4月</td>
        </tr>
      </table>
    </section>
    <div class="o-spacer"></div>
    <div class="c-sticky-block">
      <ui-tabs data-tabs="{tabs}" data-current="{state.currentTab}" data-theme="car"
        data-tab-click-handler="{clickTabHandler}">
      </ui-tabs>
    </div>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="flow"
      data-intersection-handler="{intersectionHandler}">
      <section id="flow">
        <ui-heading data-tag="h1" data-theme="car">出願方法別 入学までの流れ</ui-heading>
        <p class="o-items-center">
          <img src="/img/kanagawa-car-application-flow.svg" alt="図版：出願方法別 入学までの流れ">
        </p>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="expenses"
      data-intersection-handler="{intersectionHandler}">
      <section id="expenses">
        <ui-heading data-tag="h1" data-theme="car">学費</ui-heading>
        <section>
          <h2 class="c-heading-4 c-heading-4--theme-car">一級自動車整備科（4年制）</h2>
          <div class="c-items-scroll">
            <table class="c-table c-table--keep-layout o-space" style="min-width: 500px;">
              <tr>
                <th rowspan="2"></th>
                <th colspan="2"><span class="c-label c-label--theme-car">1年次</span></th>
                <th colspan="2"><span class="c-label c-label--theme-car">2年次</span></th>
              </tr>
              <tr>
                <th style="text-align:center">1年前期<wbr>（4月〜9月）</th>
                <th style="text-align:center">1年後期<wbr>（10月〜3月）</th>
                <th style="text-align:center">2年前期<wbr>（4月〜9月）</th>
                <th style="text-align:center">2年後期<wbr>（10月〜3月）</th>
              </tr>
              <tr>
                <th>入学金</th>
                <td style="text-align:center">200,000円</td>
                <td style="text-align:center">-</td>
                <td style="text-align:center">-</td>
                <td style="text-align:center">-</td>
              </tr>
              <tr>
                <th>授業料</th>
                <td style="text-align:center">180,000円</td>
                <td style="text-align:center">180,000円</td>
                <td style="text-align:center">180,000円</td>
                <td style="text-align:center">180,000円</td>
              </tr>
              <tr>
                <th>実習費</th>
                <td style="text-align:center">150,000円</td>
                <td style="text-align:center">150,000円</td>
                <td style="text-align:center">150,000円</td>
                <td style="text-align:center">150,000円</td>
              </tr>
              <!-- <tr>
                <th>教育運営費</th>
                <td style="text-align:center">120,000円</td>
                <td style="text-align:center">-</td>
                <td style="text-align:center">120,000円</td>
                <td style="text-align:center">-</td>
              </tr> -->
              <tr>
                <th>施設費</th>
                <td style="text-align:center">180,000円</td>
                <td style="text-align:center">-</td>
                <td style="text-align:center">180,000円</td>
                <td style="text-align:center">-</td>
              </tr>
              <tr>
                <th>学費合計</th>
                <td style="text-align:center">710,000円</td>
                <td style="text-align:center">330,000円</td>
                <td style="text-align:center">510,000円</td>
                <td style="text-align:center">330,000円</td>
              </tr>
              <tr>
                <th>学年合計</th>
                <td colspan="2" style="text-align:center"><b>1,040,000円</b></td>
                <td colspan="2" style="text-align:center"><b>840,000円</b></td>
              </tr>
            </table>
          </div>
          <div class="c-items-scroll">
            <table class="c-table c-table--keep-layout o-space" style="min-width: 500px;">
              <tr>
                <th rowspan="2"></th>
                <th colspan="2"><span class="c-label c-label--theme-car">3年次</span></th>
                <th colspan="2"><span class="c-label c-label--theme-car">4年次</span></th>
              </tr>
              <tr>
                <th style="text-align:center">3年前期<wbr>（4月〜9月）</th>
                <th style="text-align:center">3年後期<wbr>（10月〜3月）</th>
                <th style="text-align:center">4年前期<wbr>（4月〜9月）</th>
                <th style="text-align:center">4年後期<wbr>（10月〜3月）</th>
              </tr>
              <tr>
                <th>入学金</th>
                <td style="text-align:center">-</td>
                <td style="text-align:center">-</td>
                <td style="text-align:center">-</td>
                <td style="text-align:center">-</td>
              </tr>
              <tr>
                <th>授業料</th>
                <td style="text-align:center">180,000円</td>
                <td style="text-align:center">180,000円</td>
                <td style="text-align:center">180,000円</td>
                <td style="text-align:center">180,000円</td>
              </tr>
              <tr>
                <th>実習費</th>
                <td style="text-align:center">150,000円</td>
                <td style="text-align:center">150,000円</td>
                <td style="text-align:center">150,000円</td>
                <td style="text-align:center">150,000円</td>
              </tr>
              <!-- <tr>
                <th>教育運営費</th>
                <td style="text-align:center">120,000円</td>
                <td style="text-align:center">-</td>
                <td style="text-align:center">120,000円</td>
                <td style="text-align:center">-</td>
              </tr> -->
              <tr>
                <th>施設費</th>
                <td style="text-align:center">180,000円</td>
                <td style="text-align:center">-</td>
                <td style="text-align:center">180,000円</td>
                <td style="text-align:center">-</td>
              </tr>
              <tr>
                <th>学費合計</th>
                <td style="text-align:center">510,000円</td>
                <td style="text-align:center">330,000円</td>
                <td style="text-align:center">510,000円</td>
                <td style="text-align:center">330,000円</td>
              </tr>
              <tr>
                <th>学年合計</th>
                <td colspan="2" style="text-align:center"><b>840,000円</b></td>
                <td colspan="2" style="text-align:center"><b>840,000円</b></td>
              </tr>
              <tr>
                <th><span class="c-label c-label--theme-car">4年間合計</span></th>
                <td colspan="4" style="text-align:center"><b>3,560,000円</b></td>
              </tr>
            </table>
          </div>
          <section>
            <h2 class="c-heading-5 c-heading-5--theme-car">その他の費用</h2>
            <div class="c-items-scroll">
              <table class="c-table c-table--keep-layout o-space" style="min-width: 500px;">
                <tr>
                  <th><span class="c-label c-label--theme-car">1,2年次</span></th>
                  <th>納付時期</th>
                  <th style="text-align: center;">金額</th>
                  <th>備考</th>
                </tr>
                <tr>
                  <th>教科書・作業着等</th>
                  <td>入学前月</td>
                  <td style="text-align: center;">約260,000円</td>
                  <td>作業着サイズにより異なります</td>
                </tr>
                <tr>
                  <th>学校行事費</th>
                  <td>入学前月</td>
                  <td style="text-align: center;">80,000円</td>
                  <td></td>
                </tr>
                <tr>
                  <th>資格取得費</th>
                  <td>各受験前</td>
                  <td style="text-align: center;">合計42,000円</td>
                  <td class="o-fit">
                    <ul class="c-list c-list--theme-car">
                      <li>ガス溶接技能講習・アーク溶接特別教育</li>
                      <li>低圧電気取扱い特別講習・中古自動車査定士</li>
                      <li>中級ソーシャル検定</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>国家二級整備士試験</th>
                  <td>2年次3学期</td>
                  <td style="text-align: center;">14,400円</td>
                  <td class="o-fit">
                    ガソリン・ジーゼル各￥7,200
                  </td>
                </tr>
                <tr>
                  <th>研修旅行費</th>
                  <td>1年次9月〜</td>
                  <td style="text-align: center;">156,000円</td>
                  <td class="o-fit">13,000円/月×12回積立て</td>
                </tr>
                <tr>
                  <th><span class="c-label c-label--theme-car">1,2年次合計</span></th>
                  <td></td>
                  <td style="text-align:center"><b>約552,400円</b></td>
                  <td></td>
                </tr>
              </table>
            </div>
            <div class="c-items-scroll">
              <table class="c-table c-table--keep-layout o-space" style="min-width: 500px;">
                <tr>
                  <th><span class="c-label c-label--theme-car">3,4年次</span></th>
                  <th>納付時期</th>
                  <th style="text-align: center;">金額</th>
                  <th>備考</th>
                </tr>
                <tr>
                  <th>教材・作業着</th>
                  <td>入学前月</td>
                  <td style="text-align: center;">約75,000円</td>
                  <td>作業着サイズにより異なります</td>
                </tr>
                <tr>
                  <th>学校行事費</th>
                  <td>入学前月</td>
                  <td style="text-align: center;">80,000円</td>
                  <td></td>
                </tr>
                <tr>
                  <th>資格取得費用</th>
                  <td>各受験前</td>
                  <td style="text-align: center;">11,020円</td>
                  <td class="o-fit">
                    <ul class="c-list c-list--theme-car">
                      <li>上級ソーシャル検定・職業訓練指導員</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>国家一級整備士試験</th>
                  <td>4年次3学期</td>
                  <td style="text-align: center;">9,300円</td>
                  <td class="o-fit"></td>
                </tr>
                <tr>
                  <th><span class="c-label c-label--theme-car">3,4年次合計</span></th>
                  <td></td>
                  <td style="text-align:center"><b>約175,320円</b></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </section>
          <ul class="c-note-list">
            <li>その他の費用は、経済状況（受験料、購入費用、為替レートの変動）により、金額が変動する場合がございます。</li>
            <li>3年進級時に教科書、作業着、安全靴、行事費などの費用が必要となります。<br>（学年・学習内容に合わせたもので、入学時に購入するものとは別のものです。）</li>
          </ul>
          <section>
            <h2 class="c-heading-5 c-heading-5--theme-car">奨学金について</h2>
            <p>
              奨学金をご希望の方は、企業奨学金や日本学生支援機構、国の教育ローンなどのご案内が出来ます。
              <br>ご遠慮なくご相談ください。
            </p>
          </section>
        </section>
        <section>
          <h2 class="c-heading-4 c-heading-4--theme-car">二級自動車整備科（2年制）</h2>
          <div class="c-items-scroll">
            <table class="c-table c-table--keep-layout o-space" style="min-width: 500px;">
              <tr>
                <th rowspan="2"></th>
                <th colspan="2"><span class="c-label c-label--theme-car">1年次</span></th>
                <th colspan="2"><span class="c-label c-label--theme-car">2年次</span></th>
              </tr>
              <tr>
                <th style="text-align:center">1年前期<wbr>（4月〜9月）</th>
                <th style="text-align:center">1年後期<wbr>（10月〜3月）</th>
                <th style="text-align:center">2年前期<wbr>（4月〜9月）</th>
                <th style="text-align:center">2年後期<wbr>（10月〜3月）</th>
              </tr>
              <tr>
                <th>入学金</th>
                <td style="text-align: center;">200,000円</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <th>授業料</th>
                <td style="text-align: center;">180,000円</td>
                <td style="text-align: center;">180,000円</td>
                <td style="text-align: center;">180,000円</td>
                <td style="text-align: center;">180,000円</td>
              </tr>
              <tr>
                <th>実習費</th>
                <td style="text-align: center;">150,000円</td>
                <td style="text-align: center;">150,000円</td>
                <td style="text-align: center;">150,000円</td>
                <td style="text-align: center;">150,000円</td>
              </tr>
              <!-- <tr>
                <th>教育運営費</th>
                <td style="text-align: center;">180,000円</td>
                <td style="text-align: center;">-</td>
                <td style="text-align: center;">180,000円</td>
                <td style="text-align: center;">-</td>
              </tr> -->
              <tr>
                <th>施設費</th>
                <td style="text-align: center;">180,000円</td>
                <td style="text-align: center;">-</td>
                <td style="text-align: center;">180,000円</td>
                <td style="text-align: center;">-</td>
              </tr>
              <tr>
                <th>学費合計</th>
                <td style="text-align:center">710,000円</td>
                <td style="text-align:center">330,000円</td>
                <td style="text-align:center">510,000円</td>
                <td style="text-align:center">330,000円</td>
              </tr>
              <tr>
                <th>学年合計</th>
                <td colspan="2" style="text-align:center">1,040,000円</td>
                <td colspan="2" style="text-align:center">840,000円</td>
              </tr>
              <tr>
                <th><span class="c-label c-label--theme-car">2年間合計</span></th>
                <td colspan="4" style="text-align:center"><b>1,880,000円</b></td>
              </tr>
            </table>
            <section>
              <h2 class="c-heading-5 c-heading-5--theme-car">その他の費用</h2>
              <div class="c-items-scroll">
                <table class="c-table c-table--keep-layout o-space" style="min-width: 500px;">
                  <tr>
                    <th><span class="c-label c-label--theme-car">1,2年次</span></th>
                    <th>納付時期</th>
                    <th style="text-align:center">金額</th>
                    <th>備考</th>
                  </tr>
                  <tr>
                    <th>教科書・作業着等</th>
                    <td>入学前月</td>
                    <td style="text-align:center">約260,000円</td>
                    <td>作業着サイズにより異なります</td>
                  </tr>
                  <tr>
                    <th>学校行事費</th>
                    <td>入学前月</td>
                    <td style="text-align:center">80,000円</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>資格取得費</th>
                    <td>各受験前</td>
                    <td style="text-align:center">合計42,000円</td>
                    <td class="o-fit">
                      <ul class="c-list c-list--theme-car">
                        <li>ガス溶接技能講習・アーク溶接特別教育</li>
                        <li>低圧電気取扱い特別講習・中古自動車査定士</li>
                        <li>中級ソーシャル検定</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>国家二級整備士試験</th>
                    <td>2年次3学期</td>
                    <td style="text-align:center">14,400円</td>
                    <td class="o-fit">
                      ガソリン・ジーゼル各￥7,200
                    </td>
                  </tr>
                  <tr>
                    <th>研修旅行費</th>
                    <td>1年次9月〜</td>
                    <td style="text-align:center">156,000円</td>
                    <td class="o-fit">13,000円/月×12回積立て</td>
                  </tr>
                  <tr>
                    <th><span class="c-label c-label--theme-car">1,2年次合計</span></th>
                    <td></td>
                    <td style="text-align:center"><b>約552,400円</b></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </section>
            <ul class="c-note-list">
              <li>その他の費用は、経済状況（入学検定料、購入費用、為替レートの変動）により、金額が変動する場合がございます。</li>
            </ul>
          </div>
        </section>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="ao"
      data-intersection-handler="{intersectionHandler}">
      <section id="ao">
        <ui-heading data-tag="h1" data-theme="car">
          出願手続および選考
          <br><strong>AO入試（総合型選抜）</strong>
        </ui-heading>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">応募資格</h1>
          <p>応募資格を満たし、本校のアドミッションポリシーに適合する方</p>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-car">本校のアドミッションポリシー（求める学生像）</h1>
            <ul class="c-list c-list--theme-car">
              <li>自動車業界に関心があり、将来、知識と技術を活かして自動車業界に貢献する意志のある方</li>
              <li>自動車整備の学びに対し、強い意欲のある方</li>
              <li>学校生活を通して規律と協調性を重んじ、人間的成長を求める方</li>
            </ul>
          </section>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">選考方法</h1>
          <p>
            エントリー受付後に面接を行います。
            <br>AO認定後に入学願書を提出していただき、書類審査にて選考を行います。
            <br>エントリーシートの作文内容は、整備士を目指したきっかけ、将来の目標、自己アピール等、自動車や自分に関してのフリーテーマです。
          </p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">出願手続き</h1>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-car">エントリー出願</h1>
            <ul class="c-list c-list--theme-car">
              <li>AO入試エントリーシート
                <ul class="c-list c-list--theme-car">
                  <li>写真１枚添付 4cm×3cm （3ヵ月以内に撮ったもの）</li>
                </ul>
              </li>
            </ul>
          </section>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-car">入学出願</h1>
            <p>
              入学出願時に下記の書類に入学検定料￥20,000を添えて提出してください。
              <br>書類郵送の場合は郵便局にて入学検定料の郵便為替手続きを行い、普通郵便為替証書を同封してください。
            </p>
          </section>
          <ol>
            <li>入学願書</li>
            <li>写真2枚
              <ul class="c-list c-list--theme-car">
                <li>4cm×3cm 3ヵ月以内に撮ったもので、願書、受験票に貼付したほか、裏面に氏名を記入したものを2枚別に提出</li>
              </ul>
            </li>
            <li>調査書</li>
            <li>卒業証明書（既卒者のみ）</li>
          </ol>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">エントリー料</h1>
          <p>無料</p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">入学検定料</h1>
          <p>20,000円</p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">試験日程</h1>
          <div class="c-items-scroll o-space">
            <table class="c-table">
              <tr>
                <th>会場</th>
                <td>専門学校 神奈川総合大学校</td>
              </tr>
              <tr>
                <th>時間</th>
                <td>13:00までに集合してください。</td>
              </tr>
            </table>
          </div>
          <div class="c-items-scroll o-space">
            <table class="c-table c-table--keep-layout">
              <tr>
                <th>エントリー受付期間</th>
                <th>面接日</th>
                <th>認定日</th>
              </tr>
              <tr>
                <td rowspan="8" class="o-nowrap">2024年6月3日<wbr>～面接前日</td>
                <td>2024年6月8日(土)</td>
                <td>2024年6月11日(火)</td>
              </tr>
              <tr>
                <td>2024年6月22日(土)</td>
                <td>2024年6月25日(火)</td>
              </tr>
              <tr>
                <td>2024年7月13日(土)</td>
                <td>2024年7月16日(火)</td>
              </tr>
              <tr>
                <td>2024年7月27日(土)</td>
                <td>2024年7月30日(火)</td>
              </tr>
              <tr>
                <td>2024年8月2日(金)</td>
                <td>2024年8月6日(火)</td>
              </tr>
              <tr>
                <td>2024年8月24日(土)</td>
                <td>2024年8月27日(火)</td>
              </tr>
              <tr>
                <td>2024年9月14日(土)</td>
                <td>2024年9月17日(火)</td>
              </tr>
              <tr>
                <td>2024年9月28日(土)</td>
                <td>2024年10月1日(火)</td>
              </tr>
              <tr>
                <td rowspan="7" class="o-nowrap">2024年10月1日<wbr>～面接前日</td>
                <td>2024年10月5日(土)</td>
                <td>2024年10月8日(火)</td>
              </tr>
              <tr>
                <td>2024年10月19日(土)</td>
                <td>2024年10月22日(火)</td>
              </tr>
              <tr>
                <td>2024年11月16日(土)</td>
                <td>2024年11月19日(火)</td>
              </tr>
              <tr>
                <td>2024年12月14日(土)</td>
                <td>2024年12月17日(火)</td>
              </tr>
              <tr>
                <td>2025年1月18日(土)</td>
                <td>2025年1月21日(火)</td>
              </tr>
              <tr>
                <td>2025年2月1日(土)</td>
                <td>2025年2月4日(火)</td>
              </tr>
              <tr>
                <td>2025年3月1日(土)</td>
                <td>2025年3月4日(火)</td>
              </tr>
            </table>
          </div>
          <div class="c-items-scroll o-space">
            <table class="c-table c-table--keep-layout">
              <tbody>
                <tr>
                  <th>願書受付期間</th>
                  <th>審査日</th>
                  <th>合格発表日</th>
                </tr>
                <tr>
                  <td class="o-nowrap">2024年9月2日<wbr>～2024年9月13日</td>
                  <td>2024年9月14日(土)</td>
                  <td>2024年9月17日(火)</td>
                </tr>
                <tr>
                  <td class="o-nowrap">2024年9月14日<wbr>～2024年9月27日</td>
                  <td>2024年9月28日(土)</td>
                  <td>2024年10月1日(火)</td>
                </tr>
                <tr>
                  <td class="o-nowrap">2024年9月28日<wbr>～2024年10月4日</td>
                  <td>2024年10月5日(土)</td>
                  <td>2024年10月8日(火)</td>
                </tr>
                <tr>
                  <td class="o-nowrap">2024年10月5日<wbr>～2024年10月18日</td>
                  <td>2024年10月19日(土)</td>
                  <td>2024年10月22日(火)</td>
                </tr>
                <tr>
                  <td class="o-nowrap">2024年10月19日<wbr>～2024年11月15日</td>
                  <td>2024年11月16日(土)</td>
                  <td>2024年11月19日(火)</td>
                </tr>
                <tr>
                  <td class="o-nowrap">2024年11月16日<wbr>～2024年12月13日</td>
                  <td>2024年12月14日(土)</td>
                  <td>2024年12月17日(火)</td>
                </tr>
                <tr>
                  <td class="o-nowrap">2024年12月14日<wbr>～2025年1月17日</td>
                  <td>2025年1月18日(土)</td>
                  <td>2025年1月21日(火)</td>
                </tr>
                <tr>
                  <td class="o-nowrap">2025年1月18日<wbr>～2025年1月31日</td>
                  <td>2025年2月1日(土)</td>
                  <td>2025年2月4日(火)</td>
                </tr>
                <tr>
                  <td class="o-nowrap">2025年2月1日<wbr>～2025年2月28日</td>
                  <td>2025年3月1日(土)</td>
                  <td>2025年3月4日(火)</td>
                </tr>
              </tbody>
            </table>
          </div>
          <ul class="c-note-list">
            <li>合格発表日より10日以内に入学手続き（入学金・前期授業料・前期実習費・施設費710,000円納付）を行ってください。</li>
          </ul>
        </section>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="recommended"
      data-intersection-handler="{intersectionHandler}">
      <section id="recommended">
        <ui-heading data-tag="h1" data-theme="car">
          出願手続および選考
          <br><strong>指定校推薦</strong>
        </ui-heading>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">応募資格</h1>
          <p>応募資格を満たし、指定校の推薦を受けた方</p>
          <section>
            <h1 class="c-heading-5 c-heading-5--theme-car">推薦基準</h1>
            <ul class="c-list c-list--theme-car">
              <li>自動車に興味を持ち、整備士資格取得に意欲がある生徒</li>
              <li>卒業見込みである生徒</li>
              <li>学校長が推薦を認めた生徒</li>
              <li>成績基準が5段階評価で2.5以上</li>
              <li>欠席日数が1年次から2年次までの2年間において、合計20日以内</li>
            </ul>
          </section>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">選考方法</h1>
          <ul class="c-list c-list--theme-car">
            <li>面接及び書類審査（推薦書、調査書）</li>
          </ul>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">入学検定料</h1>
          <p>20,000円</p>
        </section>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="recommended-2"
      data-intersection-handler="{intersectionHandler}">
      <section id="recommended-2">
        <ui-heading data-tag="h1" data-theme="car">
          出願手続および選考
          <br><strong>公募制推薦</strong>
        </ui-heading>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">応募資格</h1>
          <p>応募資格と、①～④の推薦要件のいずれかを満たす方</p>
          <ol>
            <li>自動車に興味を持ち、整備士資格取得に意欲がある生徒</li>
            <li>欠席日数が、1年次から2年次終了までにおいて、合計30日以内</li>
            <li>成績基準が、5段階評価で平均2.3以上</li>
            <li>学校長、進路指導担当、または学級担任が推薦を認めた方</li>
          </ol>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">選考方法</h1>
          <p>面接及び書類審査（推薦書、調査書）</p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">入学検定料</h1>
          <p>20,000円</p>
        </section>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="recommended-3"
      data-intersection-handler="{intersectionHandler}">
      <section id="recommended-3">
        <ui-heading data-tag="h1" data-theme="car">
          出願手続および選考
          <br><strong>企業推薦</strong>
        </ui-heading>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">応募資格</h1>
          <ul>
            <li>応募資格を満たし、企業の代表者が推薦を認めた方</li>
            <li>卒業後、企業に就職することを前提に入学を希望される方</li>
          </ul>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">選考方法</h1>
          <p>面接及び書類審査（推薦書、調査書）</p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">入学検定料</h1>
          <p>20,000円</p>
        </section>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="general"
      data-intersection-handler="{intersectionHandler}">
      <section id="general">
        <ui-heading data-tag="h1" data-theme="car">
          出願手続および選考
          <br><strong>一般入試</strong>
        </ui-heading>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">応募資格</h1>
          <ul class="c-list c-list--theme-car">
            <li>応募資格を満たした方</li>
          </ul>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">選考方法</h1>
          <ul class="c-list c-list--theme-car">
            <li>面接</li>
            <li>学科試験（数学・一般常識）</li>
            <li>書類審査</li>
          </ul>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">入学検定料</h1>
          <p>20,000円</p>
        </section>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="schedule"
      data-intersection-handler="{intersectionHandler}">
      <section id="schedule">
        <ui-heading data-tag="h1" data-theme="car">推薦・一般入試 試験日程</ui-heading>
        <table class="c-table o-space">
          <tr>
            <th>会場</th>
            <td>専門学校 神奈川総合大学校</td>
          </tr>
          <tr>
            <th>時間</th>
            <td>13:00までに集合してください。</td>
          </tr>
        </table>
        <div class="c-items-scroll o-space">
          <table class="c-table c-table--keep-layout" style="min-width: 640px">
            <tr>
              <th>願書受付期間</th>
              <th>試験日</th>
              <th>合格発表日</th>
            </tr>
            <tr>
              <td>2024年10月1日〜10月4日</td>
              <td>2024年10月5日(土)</td>
              <td>2024年10月8日(火)</td>
            </tr>
            <tr>
              <td>2024年10月5日〜10月18日</td>
              <td>2024年10月19日(土)</td>
              <td>2024年10月22日(火)</td>
            </tr>
            <tr>
              <td>2024年10月19日〜11月15日</td>
              <td>2024年11月16日(土)</td>
              <td>2024年11月19日(火)</td>
            </tr>
            <tr>
              <td>2024年11月16日〜12月13日</td>
              <td>2024年12月14日(土)</td>
              <td>2024年12月17日(火)</td>
            </tr>
            <tr>
              <td>2024年12月14日〜2025年1月17日</td>
              <td>2025年1月18日(土)</td>
              <td>2025年1月21日(火)</td>
            </tr>
            <tr>
              <td>2025年1月18日〜1月31日</td>
              <td>2025年2月1日(土)</td>
              <td>2025年2月4日(火)</td>
            </tr>
            <tr>
              <td>2025年2月1日〜2月28日</td>
              <td>2025年3月1日(土)</td>
              <td>2025年3月4日(火)</td>
            </tr>
          </table>
        </div>
        <ul class="c-note-list">
          <li>合格発表日より10日以内に入学手続き（入学金・前期授業料・前期実習費・施設費710,000円納付）を行ってください。</li>
        </ul>
      </section>
    </intersection-area>
    <intersection-area data-disable="{ state.disableIntersection }" data-id="international"
      data-intersection-handler="{intersectionHandler}">
      <section id="international">
        <ui-heading data-tag="h1" data-theme="car"><strong>留学生</strong>：募集要項</ui-heading>
        <p>本校は、スリランカ、イラン、ネパール、モンゴル、ベトナムなど様々な国の外国籍の方が学んでおります。また、卒業後もトヨタ、日産など国内メーカー系のディーラーやトラック関係、海外メーカー系ディーラーで活躍しています。
        </p>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">入学にあたって</h1>
          <p>
            オープンキャンパスまたは学校説明会に必ずご参加ください。
            <br>授業は日本語で行われるため、日本語の能力はN2相当以上が必要となります。
          </p>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">入学試験</h1>
          <p>面接試験・書類審査・学力テスト（数学・日本語）</p>
          <ul class="c-note-list">
            <li>試験は13:00より開始いたします。</li>
          </ul>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">願書受付期間・合格発表日</h1>
          <div class="c-items-scroll o-space">
            <table class="c-table c-table--keep-layout" style="min-width: 640px">
              <tr>
                <th>願書受付期間</th>
                <th>試験日</th>
                <th>合格発表日</th>
              </tr>
              <tr>
                <td>2024年10月1日〜10月4日</td>
                <td>2024年10月5日(土)</td>
                <td>2024年10月8日(火)</td>
              </tr>
              <tr>
                <td>2024年10月5日〜10月18日</td>
                <td>2024年10月19日(土)</td>
                <td>2024年10月22日(火)</td>
              </tr>
              <tr>
                <td>2024年10月19日〜11月15日</td>
                <td>2024年11月16日(土)</td>
                <td>2024年11月19日(火)</td>
              </tr>
              <tr>
                <td>2024年11月16日〜12月13日</td>
                <td>2024年12月14日(土)</td>
                <td>2024年12月17日(火)</td>
              </tr>
              <tr>
                <td>2024年12月14日〜2025年1月17日</td>
                <td>2025年1月18日(土)</td>
                <td>2025年1月21日(火)</td>
              </tr>
              <tr>
                <td>2025年1月18日〜1月31日</td>
                <td>2025年2月1日(土)</td>
                <td>2025年2月4日(火)</td>
              </tr>
              <tr>
                <td>2025年2月1日〜2月28日</td>
                <td>2025年3月1日(土)</td>
                <td>2025年3月4日(火)</td>
              </tr>
            </table>
          </div>
          <ul class="c-note-list">
            <li>合格発表日より10日以内に入学手続き（入学金・前期授業料・前期実習費・施設費710,000円納付）を行ってください。</li>
          </ul>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">応募書類</h1>
          <ul class="c-list--theme-car">
            <li>入学願書・写真2枚貼付（4×3cm）</li>
            <li>入学検定料￥20,000</li>
            <li>最終学歴の卒業証明書</li>
            <li>在留カードのコピー</li>
          </ul>
          <ul class="c-note-list">
            <li>入学願書受付時間は平日9:00～16:30、土曜日9:00～14:00。出願時に入学検定料を持参してください。</li>
            <li>入学願書を郵送にて受付ご希望の方は、職員にお申し付けください。</li>
            <li>合格発表は、事務室で結果を受け取ってください。電話でのお問い合わせは受付けておりません。</li>
            <li>日本語学校の成績と出欠席が証明できるものを提出してください。</li>
          </ul>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-car">応募資格</h1>
          <ul class="c-list c-list--theme-car">
            <li>オープンキャンパス、又は学校説明会に参加した方</li>
            <li>カリキュラムの受講に対し、支障のない方</li>
            <li>普通自動車運転免許を取得済み、または本校卒業までに取得可能な方</li>
            <li>緊急時に連絡が取れる日本語が分かる知人のいる方</li>
            <li>下記の①～④のいずれかを満たした方
              <ol>
                <li>高等学校、高等専修学校を2025年3月に卒業見込みの方</li>
                <li>高等学校卒業程度認定試験合格、または合格見込みの方</li>
                <li>高等学校、高等専修学校を卒業した方</li>
                <li>外国において、12年間の学校教育を修了した方</li>
              </ol>
            </li>
          </ul>
        </section>
      </section>
    </intersection-area>
  </div>
  <script>
    export default {
      state: {
        currentTab: 'expenses',
        disableIntersection: false,
      },
      tabs: [
        {
          name: '入学までの流れ',
          slug: 'flow'
        },
        {
          name: '学費',
          slug: 'expenses'
        },
        {
          name: 'AO入試（総合型選抜）',
          slug: 'ao'
        },
        {
          name: '指定校推薦',
          slug: 'recommended'
        },
        {
          name: '公募制推薦',
          slug: 'recommended-2'
        },
        {
          name: '企業推薦',
          slug: 'recommended-3'
        },
        {
          name: '一般入試',
          slug: 'general'
        },
        {
          name: '推薦・一般入試 試験日程',
          slug: 'schedule'
        },
        {
          name: '留学生',
          slug: 'international'
        },
      ],
      clickTabHandler(slug) {
        this.state.disableIntersection = true
        this.state.currentTab = slug
        this.update()
        window.requestAnimationFrame(() => {
          window.location.href = `#${slug}`
        })
      },
      intersectionHandler(slug) {
        this.state.currentTab = slug
        this.update()
      },
      onMounted() {
        document.documentElement.style.setProperty('--mobile-scroll-offset-tab', `50px`)
        this.scrollToHashedElement()
      },
      onUpdated() {
        if (this.state.disableIntersection) {
          if (this.timeoutId) window.clearTimeout(this.timeoutId)
          this.timeoutId = window.setTimeout(() => {
            this.update({ disableIntersection: false })
          }, 1000)
        }
      }
    }
  </script>
</page-kanagawa-car-application>