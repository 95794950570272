<message-box>
  <section class="c-message-box">
    <div class="o-layout">
      <div>
        <div class="c-message-box__title" if="{ props.dataItem.title }">{ props.dataItem.title }</div>
        <div class="c-message-box__profile o-fit o-space" if="{ props.dataItem.profile }">
          <raw-html data-content="{ props.dataItem.profile }"></raw-html>
        </div>
      </div>
      <div if="{ props.dataItem.photo }" class="o-size-hug o-order-top">
        <img class="c-message-box__photo" src="{ props.dataItem.photo }">
      </div>
    </div>
    <div class="{ getMessageClass() } o-space o-fit">
      <raw-html data-content="{ props.dataItem.message }"></raw-html>
    </div>
  </section>
  <script>
    // @ts-check
    export default function MessageBox() {
      return {
        /** @return {string} */
        getMessageClass() {
          /** @type {string[]} */
          const result = ['c-message-box__message']
          if (this.props.dataSlug) result.push(`c-message-box__message--theme-${this.props.dataSlug}`)
          return result.join(' ')
        }
      }
    }
  </script>
</message-box>