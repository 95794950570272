<q-and-a>
  <section class="c-q-and-a">
    <button class="c-q-and-a__button" onclick="{ toggle }">
      <h1 class="{ getClass('c-q-and-a__question') }" data-is-opened="{ state.isOpened }">
        <span>{ this.props.dataItem.question }</span>
      </h1>
    </button>
    <div class="{ getClass('c-q-and-a__answer') }" data-is-opened="{ state.isOpened }">
      <div>
        <raw-html data-content="{ this.props.dataItem.answer }"></raw-html>
        <temlate if="{props.dataItem.photos}">
          <div class="o-layout o-layout--wrap o-layout--main-axis-center" each="{photo in props.dataItem.photos}">
            <div>
              <figure>
                <img src="{photo.photo}" alt="{photo.caption}" width="{photo.width}">
                <figcaption class="c-photo-caption" if="{photo.caption}">
                  {photo.caption}
                </figcaption>
              </figure>
            </div>
          </div>
        </temlate>
      </div>
    </div>
  </section>
  <script>
    // @ts-check
    export default function QAndA() {
      return {
        state: {
          /** @type {boolean} */
          isOpened: false,
        },
        toggle() {
          this.state.isOpened = !this.state.isOpened
          this.update()
        },
        /**
         * @param {string} ベースとなるクラス名
         * @return {string}
         */
        getClass(className) {
          /**
           * @type {string[]}
           */
          const result = [className]
          if (this.props.dataSlug) result.push(`${className}--theme-${this.props.dataSlug}`)
          return result.join(' ')
        },
      }
    }
  </script>
</q-and-a>