<page-kanagawa-highschool-message>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="highschool">先輩からのメッセージ</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--gap-loose o-layout--column">
            <div each="{ message in messages }">
              <message-box data-item="{ message }" data-slug="highschool"></message-box>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="highschool">教師からのメッセージ</ui-heading>
        </div>
        <div>
          <div class="o-layout o-layout--gap-loose o-layout--column">
            <div each="{ message in messages2 }">
              <message-box data-item="{ message }" data-slug="highschool"></message-box>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageKanagawaHighschoolMessage() {
      return {
        /**
         * @typedef {object} message
         * @property {string} [photo] 写真のURL
         * @property {string} [title] 単純文字列
         * @property {string} [profile] HTML文字列
         * @property {string} message HTML文字列
         */
        /**
         * @type {message[]}
         */
        messages: [
          {
            photo: '/img/highschool-message-avator.webp',
            title: '夢を叶えましょう',
            profile: '生活造形学科',
            message: '私は、ファッションコーディネータ兼デザイナーになるのが夢です。まだ将来のことは不安もありますが、そんな時は先生方が親身になって相談に乗って下さいます。<br>ファッションコースを選択すると、不器用や絵が苦手、手芸が上手く出来ないなど諦めがちですが、先輩や先生方が、分かるまで丁寧に教えてくださるので安心して一緒にファッションを学びましょう。',
          },
          {
            photo: '/img/highschool-message-avator-2.webp',
            title: '充実した学校生活',
            profile: '生活造形学科',
            message: '高校生活を送るにあたり不安でいっぱいでした。<br>いざ学校生活が始まるとたくさんの教科に不安と驚きもありましたが、その反面、知ることの楽しみを知りました。また、同じ考えや目的を持った仲間ができ、楽しい学校生活を送っています。',
          },
          {
            photo: '/img/highschool-message-avator-3.webp',
            title: '資格を糧に',
            profile: '工業技術学科',
            message: '私がこの学校を選んだ理由は、中学2年生の技術の授業で学んだ「電気基盤づくり」がとても楽しかったからです。そこで工業系、技術系を目指す中、体験入学や学園祭で興味を持った本校の「工業技術学科」に入学いたしました。<br>工業技術学科では机上の学習ばかりでなく、実習科目が充実しており、その内容も社会人としての成長・自立に役立つ事が多くあります。また、先生と生徒の距離感が非常に近く、親身に指導してくださり、自分の価値観を広げてくれる学校です。さらに、3年間の在籍期間に単位取得すれば「第二種電気工事士の国家資格」が取得できるのも最大の魅力です。これからの卒業までの日々、「フォークリフト特別教育」「パソコン技術検定」など多くの資格を取得し卒業後の将来に備えたいと思います。',
          },
        ],
        /**
         * @type {message[]}
         */
        messages2: [
          {
            profile: '教師一同',
            message: '皆様にはいろいろな夢があると思います。本校に入学して夢の実現を目指してください。我々、教職員もわかりやすい授業を心がけ、資格取得や検定合格・進路決定に向けて、精一杯応援させていただきます。',
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa-highschool-message>