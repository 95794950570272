<page-atsugi-cook-qa>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column c-q-and-a-layout">
    <div each="{ message in messages }">
      <q-and-a data-item="{ message }" data-slug="cook"></q-and-a>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiCookQa() {
      return {
        /**
         * @typedef {object} qAndA
         * @property {string} question 単純文字列
         * @property {string} answer HTML文字列
         */
        /**
         * @type { qAndA[] }
         */
        messages: [
          {
            question: '国家試験なしで、調理師免許がとれるんですか？',
            answer: 'はい。厚生労働大臣指定校ですので、卒業すれば取得ができ、また同時に食品衛生責任者になることができます。',
          },
          {
            question: '就職率について教えてください。',
            answer: '企業さまが最終選択しますので保証はできませんが、求人数は生徒比率で400%以上、地元を中心に多数の求人情報があります。<br>卒業後も、求人紹介など通して一生のお付き合いとなる方もたくさんいらっしゃいます。ぜひ卒業後も本校をご利用ください。'
          },
          {
            question: '左利きは右利きになおさなければいけないと聞いたのですが？',
            answer: '和食系は、右利きになおした方がいいと言われる場合がありますが、本校では、右利きになおす必要はありません。<br>※ただし、左利き和包丁は右利き和包丁より若干高価となります。'
          },
          {
            question: '調理師学校へいくなら、そのまま就職して腕をみがいた方がいいと聞きますが？',
            answer: '本校調理学科は国家資格を取得するための学校であり、その資格がないと就職できないところも少なくありません。<br>長年、毎日包丁をにぎっているプロを凌ぐスキルを身につけるには、それに相応しい職場とさまざまな経験を積み重ねる時間が必要です。<br>まずは専門知識と基本技術をしっかり身につけ、一生ものの国家資格を取得してからでも遅くありません。'
          },
          {
            question: '調理に興味があるのですが、就職は考えていません。入学できますか？',
            answer: '全く問題ありません。<br>本校調理学科の最大の目標は調理師免許取得であり、それをどう生かすかはあなた次第です。ぜひ安心してご入学ください。'
          },
          {
            question: '年齢的な問題は何かありますか？',
            answer: '例年、クラスのおよそ半分は20代〜60代の生徒さんです。過去には70才の生徒さんもいらっしゃいました。<br>老若男女、世代を超えて、わきあいあいとした雰囲気で学べる環境です。安心してご入学ください。'
          },
          {
            question: 'いろいろな調理師学校がありますが、どこがどう違うのですか？',
            answer: '全国規模の学校から地元志向で地域密着型の学校まで強みや特徴は学校によってさまざまです。<br>まずは体験入学など実際の学校の様子を見学・体験された上で、自分の目標にあった学校を選ぶことをおすすめします。'
          },
          {
            question: '専門調理師・調理技能士免許とはどんな資格ですか？',
            answer: '調理師免許の最高峰の国家資格です。教職員としての資格でもあります。<br>実務経験2年以上と一度の試験で合格する通常の調理師免許と差別化するために生まれた国家資格です。<br>そのための試験対策も本校卒業までに授業の中で学びますので特別な勉強は必要なく、実技試験は卒業から6年後以降に行います。<br>その実技試験合格で、晴れて専門調理師・調理技能士の資格が与えられます。'
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-cook-qa>