<dental-nav>
  <school-nav data-items="{ navs }" data-slug="dental" data-where="{ props.dataWhere }"
    data-current="{ props.dataCurrent }"></school-nav>
  <script>
    export default function DentalNav() {
      return {
        navs: [
          {
            icon: 'home',
            title: 'ホーム',
            href: '/atsugi/dental/',
          },
          {
            icon: 'study',
            title: 'コース・教科・取得資格',
            href: '/atsugi/dental/subject',
          },
          {
            icon: 'school',
            title: 'キャンパスライフ',
            href: '/atsugi/dental/campus',
          },
          {
            icon: 'message',
            title: '教師・先輩からのメッセージ',
            href: '/atsugi/dental/message',
          },
          {
            icon: 'compass',
            title: 'オープンキャンパス',
            href: '/atsugi/dental/open-campus',
          },
          {
            icon: 'file',
            title: '募集要項',
            href: '/atsugi/dental/application',
          },
          {
            icon: 'questions',
            title: 'よくあるご質問',
            href: '/atsugi/dental/qa',
          },
          {
            icon: 'bell',
            title: 'お知らせ',
            href: '/atsugi/dental/news',
          },
          {
            icon: 'map',
            title: 'アクセス',
            href: '/school#map-1',
          },
          {
            icon: 'book',
            title: '資料請求',
            href: 'https://www.school-go.info/f9z653/atsugi/form.php?fno=83&fsno=1&openExternalBrowser=1',
            target: '_blank',
          },
          {
            icon: 'mail',
            title: 'お問い合わせ',
            href: 'https://www.school-go.info/f9z653/atsugi/form.php?fno=90&fsno=1&openExternalBrowser=1',
            target: '_blank',
          },
          {
            icon: 'lock',
            title: '個人情報のお取り扱いについて',
            href: '/policy',
          },
        ]
      }
    }
  </script>
</dental-nav>