<home-nav-items>
  <div class="o-layout o-layout--wrap c-home-nav-items-layout">
    <div each="{ item in props.dataItems }">
      <a href="{ item.href }" class="o-block-link { getClass('c-home-nav-item-link') }">
        <div class="o-layout o-layout--gap-none o-layout--column o-layout--cross-axis-center">
          <div>
            <ui-icon data-icon="{ item.icon }" class="{ getClass('c-home-nav-item-icon') }"></ui-icon>
          </div>
          <div class="o-nowrap">
            <raw-html data-content="{ item.title }"></raw-html>
          </div>
        </div>
      </a>
    </div>
  </div>
  <script>
    // @ts-check
    export default function HomeNavItems() {
      return {
        getClass(className) {
          const result = [className]
          if (this.props.dataTheme) result.push(`${className}--theme-${this.props.dataTheme}`)
          return result.join(' ')
        },
      }
    }
  </script>
</home-nav-items>