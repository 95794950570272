<school-content-template>
  <div>
    <div class="o-layout o-layout--gap-none">
      <div class="{ getSideNavBlockClass() }">
        <div is="{ props.dataSlug }-nav" data-where="side" class="c-school-side-nav"
          data-current="{ props.dataCurrent }"></div>
      </div>
      <div class="o-size-fill">
        <breadcrumbs-nav data-items="{ props.dataBreadcrumbs }" data-home-path="{ props.dataHomePath }"
          data-current-page-name="{props.dataTitle}" data-theme="{ props.dataSlug }">
        </breadcrumbs-nav>
        <page-header data-title="{ props.dataTitle }" data-slug="{ props.dataSlug }" data-photo="{ props.dataPhoto}">
        </page-header>
        <div class="o-container">
          <slot />
        </div>
      </div>
    </div>
  </div>
  <script>
    export default function SchoolContentTemplate() {
      return {
        getSideNavBlockClass() {
          let result = 'o-size-hug c-school-side-nav-block'
          if (this.props.dataSlug) result += ` c-school-side-nav-block--theme-${this.props.dataSlug}`
          return result
        }
      }
    }
  </script>
</school-content-template>