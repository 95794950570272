<page-kanagawa>
  <school-header data-is-no-nav="{ true }" data-slug="kanagawa" data-school-name="専門学校神奈川総合大学校"></school-header>
  <template if="{ isHome() }">
    <div class="c-school-hero">
      <div class="c-school-hero__heading">
        <slide-in>
          <div class="c-school-hero__heading-text c-school-hero__heading-text--theme-kanagawa o-cancel-first-child-gap">
            <p>
              好きなことだからこそ、
              <br>その興味と好奇心を生かして
              <br>スペシャリストを目指そう。
            </p>
          </div>
        </slide-in>
      </div>
      <div class="c-site-hero-photo c-site-hero-photo--kanagawa">
        <photo-slider data-slides="{slides}" data-theme="kanagawa"></photo-slider>
      </div>
    </div>
    <div class="c-home-content">
      <wave-separator class="c-home-content__wave"></wave-separator>
      <div class="o-layout o-layout--column">
        <div class="u-only-mobile">
          <slide-in>
            <div class="c-message-text c-message-text--theme-kanagawa">
              <p>
                好きなことだからこそ、
                <br>その興味と好奇心を生かして
                <br>スペシャリストを目指そう。
              </p>
            </div>
            <div class="o-container">
              <p>
                専門学校神奈川総合大学校は、高等学校と同等の高等課程と、自動車整備科という専門課程をのある高等専修学校です。
              </p>
            </div>
          </slide-in>
        </div>
        <div>
          <div class="o-container">
            <div class="o-spacer o-spacer--large-2"></div>
            <kanagawa-school-nav></kanagawa-school-nav>
          </div>
        </div>
        <div>
          <section id="access">
            <div class="o-container">
              <ui-heading data-tag="h1">アクセスマップ</ui-heading>
              <div class="o-spacer"></div>
              <p>
                〒243-0032 厚木市恩名1-17-18
                <br>（本厚木駅より徒歩15分）
                <br>Tel：046(221)5678
              </p>
              <iframe class="o-space"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3250.7208243026175!2d139.3517803152769!3d35.436945150937866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019aabab598ed49%3A0x77ed925a1071c392!2z44CSMjQzLTAwMzIg56We5aWI5bed55yM5Y6a5pyo5biC5oGp5ZCN77yR5LiB55uu77yR77yX4oiS77yR77yY!5e0!3m2!1sja!2sjp!4v1634473009227!5m2!1sja!2sjp"
                width="100%" height="360" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="o-spacer o-spacer--large-2"></div>
  </template>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column">
    <section>
      <group-footer></group-footer>
    </section>
  </div>
  <script>
    // @ts-check
    export default function PageSuzuki() {
      return {
        /**
         * @typedef slide
         * @property {String} photo
         * @property {String} [position]
         */
        /**
         * @type {slide[]}
         */
        slides: [
          { photo: '/img/kanagawa-photo.webp', },
          { photo: '/img/kanagawa-highschool-photo.webp' },
          { photo: '/img/kanagawa-car-photo.webp' },
        ],
        /**
         * @typedef {object} news
         * @property {string} title
         * @property {string} date
         * @property {string} slug
         * @property {string} content
         */
        /**
         * @type {news[]}
         */
        news: [
          {
            title: 'サイトリニューアルのお知らせ',
            date: '2022年2月28日',
            slug: 'site-renewal-2022',
            content: 'この度、Webサイトを全面リニューアルいたしました。'
          },
          {
            title: '新型コロナウイルス感染予防について',
            date: '2022年2月28日',
            slug: 'covid-19',
            content: '- 定期的な消毒、換気、教職員のマスク着用など感染症対策に配慮しながら実施しています。\n- ご来校時に検温をお願いしています。熱がある場合は恐れ入りますが、参加をご遠慮頂いております。\n- 新型コロナウイルス感染拡大防止の為、予定が変更になる場合がございます。'
          },
          {
            title: '体験入学・オープンキャンパスについて',
            date: '2021年4月1日',
            slug: 'open-campus-2021',
            content: '2021年度オープンキャンパス実施予定を公開しています。  \n皆様ぜひご参加ください。'
          },
        ],
        /**
         * @type {string[]}
         */
        pathArray: [],
        /**
         * @return {boolean}
         */
        isHome() {
          return this.pathArray.length === 1
        },
        /**
         * @param {string} slug
         * @return {boolean}
         */
        isPage(slug) {
          const pathname = window.location.pathname.match(/^\/chuo\/(.*)/)[1].replace(/(.*)\/$/, '$1')
          return pathname === slug
        },
        onBeforeMount() {
          this.pathArray = this.getPathNameArray()
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa>