<atugi-nav>
  <div class="o-layout o-layout--column">
    <div class="o-items-center">
      <ui-button data-tag="a" data-href="/atsugi" data-class="theme-atsugi">
        <div class="o-layout o-layout--cross-axis-center">
          <div class="o-size-hug">
            <logo-atsugi class="c-atsugi-nav-icon"></logo-atsugi>
          </div>
          <div>
            厚木総合専門学校
            <br>トップページ
          </div>
        </div>
      </ui-button>
    </div>
    <div>
      <div class="o-container">
        <atsugi-school-nav class="o-space"></atsugi-school-nav>
      </div>
    </div>
  </div>
</atugi-nav>