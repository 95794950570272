<page-header>
  <div class="{ getHeaderClass() }">
    <div class="o-layout o-layout--gap-none">
      <div class="o-size-fill c-page-header__title o-cancel-first-child-gap">
        <h1 class="c-page-header__title">
          <raw-html data-content="{ japaneseParser.translateHTMLString(props.dataTitle) }"></raw-html>
        </h1>
      </div>
      <div class="o-size-hug c-page-header__photo">
        <img if="{ props.dataPhoto }" src="{ props.dataPhoto }" alt="" class="c-page-header__photo-image">
        <svg class="c-page-header__s-shape" width="20" height="131" viewBox="0 0 20 131" fill="none"
          xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
          <g clip-path="url(#clip-{ id })">
            <path d="M0 0H12L8 40L13.5 94.5L8 131H0V0Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14.0462 0.732071C10.6195 -1.33136 10.6185 -1.32963 10.6174 -1.3278L10.6149 -1.32364L10.6088 -1.31352L10.5925 -1.28609C10.5798 -1.26461 10.5635 -1.23676 10.5436 -1.20259C10.5039 -1.13425 10.4502 -1.04063 10.3838 -0.922123C10.2511 -0.68514 10.0677 -0.348538 9.84412 0.0843296C9.39712 0.949882 8.78909 2.20155 8.10506 3.81242C6.73757 7.03284 5.06227 11.6985 3.76518 17.592C1.16771 29.3939 0.0925555 46.1027 6.01859 65.9149C11.4942 84.2211 10.4589 99.873 8.07894 110.922C6.88795 116.452 5.3604 120.825 4.14493 123.79C3.53753 125.272 3.0092 126.399 2.64177 127.141C2.4581 127.511 2.3148 127.785 2.22214 127.958C2.17581 128.044 2.14216 128.105 2.12247 128.141L2.1066 128.169L2.10499 128.172L2.10373 128.174L2.10228 128.177L0.112305 131.636L7.04708 135.625L9.04138 132.158L5.57399 130.163C9.04138 132.158 9.04233 132.156 9.04335 132.154L9.04567 132.15L9.0514 132.14L9.06705 132.113C9.07934 132.091 9.09531 132.062 9.11479 132.027C9.15374 131.957 9.20675 131.861 9.2725 131.738C9.40398 131.493 9.58645 131.144 9.80931 130.694C10.2549 129.795 10.8626 128.495 11.5472 126.824C12.9157 123.486 14.5957 118.66 15.8996 112.607C18.5093 100.491 19.6124 83.4457 13.6831 63.6224C8.20419 45.3051 9.22673 29.9957 11.5782 19.3116C12.7555 13.9621 14.2675 9.76796 15.4687 6.93925C16.069 5.52555 16.5906 4.45534 16.9522 3.75515C17.133 3.40515 17.2736 3.14792 17.3638 2.98678C17.4089 2.90623 17.4414 2.84972 17.46 2.8177L17.4772 2.7884L17.4779 2.78714L19.5363 -0.631197L12.683 -4.75806L10.6195 -1.33136L14.0462 0.732071Z"
              fill="url(#paint0_linear_328:10330)" />
          </g>
          <defs>
            <linearGradient id="paint0_linear_328:10330" x1="8.70672" y1="-4.75806" x2="8.70672" y2="135.625"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="{ colorStop2 }" />
              <stop offset="1" stop-color="{ colorStop }" />
            </linearGradient>
            <clipPath id="clip-{ id }">
              <rect width="20" height="131" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
  <script>
    export default function PageHeader() {
      return {
        getHeaderClass() {
          const result = ['c-page-header']
          if (this.props.dataSlug) result.push(`c-page-header--theme-${this.props.dataSlug}`)
          return result.join(' ')
        },
        setCssPropertyValue() {
          this.bodyElement = document.querySelector('body')
          this.bodyStyles = getComputedStyle(this.bodyElement)
          this.slug = this.props.dataSlug ? this.props.dataSlug : 'suzuki'
          this.colorStop = this.bodyStyles.getPropertyValue(`--color-${this.slug}-2`).trim()
          this.colorStop2 = this.bodyStyles.getPropertyValue(`--color-${this.slug}-3`).trim()
        },
        onBeforeMount() {
          this.setCssPropertyValue()
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-header>