<photo-and-description>
  <div class="c-photo-and-description">
    <div class="c-photo-and-description__title o-fit">
      <p>
        <raw-html data-content="{japaneseParser.translateHTMLString(props.dataTitle)}"></raw-html>
      </p>
    </div>
    <img src="{props.dataPhoto}" alt="{getStringExcludeTag(props.dataTitle)}" class="c-photo-and-description__photo">
    <div class="c-photo-and-description__description o-fit">
      <slot />
    </div>
    <script>
      export default function PhotoAndDescription() {
        return {
          getStringExcludeTag(string) {
            return string.replace(/<[^>]*>/g, '')
          }
        }
      }
    </script>
  </div>
</photo-and-description>