<ui-tabs>
  <div class="c-tabs" data-ref="tabs">
    <button class="{getTabClass()}" each="{tab in props.dataTabs}" onclick="{changeTab.bind(this,tab.slug)}"
      data-slug="{tab.slug}" data-current="{ tab.slug === props.dataCurrent }">{tab.name}</button>
  </div>
  <script>
    export default function UiTabs() {
      return {
        state: {
          oldSlug: '',
          isChangeSlugFlag: false
        },
        changeTab(slug) {
          this.props.dataTabClickHandler(slug)
          this.setScroll()
        },
        setScroll() {
          const currentTab = this.$(`[data-slug="${this.props.dataCurrent}"]`)
          const tabs = this.$('[data-ref="tabs"]')
          const scrollOffset = currentTab.offsetLeft - (window.innerWidth / 2) + (currentTab.clientWidth / 2)
          // console.log(scrollOffset)
          tabs.scrollLeft = scrollOffset
        },
        getTabClass() {
          const result = ['c-tab']
          if (this.props.dataTheme) result.push(`c-tab--theme-${this.props.dataTheme}`)
          return result.join(' ')
        },
        onMounted() {
          this.state.oldSlug = this.props.dataCurrent
        },
        onBeforeUpdate() {
          if (this.state.oldSlug != this.props.dataCurrent) {
            this.state.isChangeSlugFlag = true
          }
        },
        onUpdated() {
          this.state.oldSlug = this.props.dataCurrent
          if (this.state.isChangeSlugFlag) {
            this.isChangeSlugFlag = false
            this.setScroll()
          }
        }
      }
    }
  </script>
</ui-tabs>