<ui-markdown>
  <span data-ref="content"></span>

  <script>
    import { marked } from 'marked'

    export default function UiMarkdown() {
      return {
        renderMd() {
          const content = this.$('[data-ref="content"]')
          content.innerHTML = marked(this.props.dataContent)
        },
        onMounted() {
          this.renderMd()
        },
        onUpdate() {
          this.renderMd()
        }
      }
    }
  </script>
</ui-markdown>