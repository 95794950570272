<kanagawa-highschool-menu>
  <school-menu data-items="{ menus }" data-slug="highschool"></school-menu>

  <script>
    export default function KanagawaHighschoolMenu() {
      return {
        menus: [
          {
            icon: 'school',
            title: 'スクールライフ',
            slug: '/kanagawa/highschool/campus',
          },
          {
            icon: 'compass',
            title: '体験入学',
            slug: '/kanagawa/highschool/open-campus',
          },
          {
            icon: 'book',
            title: '資料請求',
            slug: '/request',
          },
          {
            icon: 'mail',
            title: 'お問い合わせ',
            slug: '/contact',
          },
        ]
      }
    }
  </script>
</kanagawa-highschool-menu>