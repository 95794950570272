<photo-slider>
  <div class="{getSliderClassName()}">
    <div class="c-photo-slider__screen" data-fade="{state.fade}" data-ref="screen">
      <img each="{ (slide, index) in props.dataSlides }" class="{getPhotoClassName()}" src="{slide.photo}"
        data-is-current="{index === state.current}" {...getPhotoStyle(slide)}>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PhotoSlider() {
      return {
        state: {
          current: 0,
          fade: ''
        },
        /**
         * @typedef slide
         * @property {String} photo
         * @property {String} [position]
         */
        /**
         * @param {slide} slide
         * @return {Object}
         */
        getPhotoStyle(slide) {
          if (slide.position) {
            return {
              style: `object-position: ${slide.position}`
            }
          }
          return {}
        },
        /**
         * @return {string}
         */
        getPhotoClassName() {
          const result = ['c-photo-slider__photo']
          if (this.props.dataTheme == 'suzuki') {
            result.push('c-suzuki-hero__photo')
          }
          return result.join(' ')
        },
        /**
         * @return {string} Class 属性に入る String
         */
        getSliderClassName() {
          const result = ['c-photo-slider']
          if (this.props.dataTheme) result.push(`c-photo-slider--theme-${this.props.dataTheme}`)
          return result.join(' ')
        },
        fadeOut() {
          this.update({
            fade: 'out'
          })
        },
        fadeIn() {
          this.update({
            fade: 'in'
          })
        },
        nextSlide() {
          this.state.current++
          if (this.state.current >= this.props.dataSlides.length) {
            this.state.current = 0
          }
          this.update()
          this.fadeIn()
        },
        countSlide() {
          const interval = this.props.dataInterval ? this.props.dataInterval : 2000
          window.setTimeout(this.fadeOut, interval)
        },
        screenTransitionEndHandler() {
          if (this.state.fade === 'out') {
            this.nextSlide()
          }
          else if (this.state.fade === 'in') {
            this.countSlide()
          }
        },
        onMounted() {
          this.$('[data-ref="screen"]').addEventListener('animationend', this.screenTransitionEndHandler)
          this.countSlide()
        }
      }
    }
  </script>
</photo-slider>