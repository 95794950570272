<kanagawa-car-menu>
  <school-menu data-items="{ menus }" data-slug="car"></school-menu>

  <script>
    export default function KanagawaCarMenu() {
      return {
        menus: [
          {
            icon: 'school',
            title: 'キャンパスライフ',
            slug: '/kanagawa/car/campus',
          },
          {
            icon: 'compass',
            title: 'オープンキャンパス',
            slug: '/kanagawa/car/open-campus',
          },
          {
            icon: 'book',
            title: '資料請求',
            slug: '/request',
          },
          {
            icon: 'mail',
            title: 'お問い合わせ',
            slug: '/contact',
          },
        ]
      }
    }
  </script>
</kanagawa-car-menu>