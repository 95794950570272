<page-kanagawa-car-open-campus>
  <section>
    <ui-heading data-tag="h1" data-theme="car">
      <raw-html data-content="{japaneseParser.translateHTMLString('2024年度 神奈川総合大学校 専門課程 自動車整備科<br>オープンキャンパス')}">
      </raw-html>
    </ui-heading>
    <!-- <section>
      <ui-box data-theme="car" data-heading-tag="h1" data-heading="新型コロナウイルス感染予防について">
        <div class="o-cancel-first-child-gap">
          <ul>
            <li>定期的な消毒、換気、教職員のマスク着用など感染症対策に配慮しながら実施しています。</li>
            <li>ご来校時に検温をお願いしています。熱がある場合は恐れ入りますが、参加をご遠慮頂いております。</li>
            <li>新型コロナウイルス感染拡大防止の為、予定が変更になる場合がございます。</li>
          </ul>
        </div>
      </ui-box>
    </section> -->
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-car">オープンキャンパス日程表</h1>
      <table class="c-table o-space c-content">
        <tbody>
          <tr>
            <th>2024年4月20日</th>
            <td>
              <ul>
                <li>エンジンがかかる仕組み</li>
                <li>車はどうやって止まる？ブレーキ分解作業</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td></td>
          </tr>
          <tr>
            <th>2024年5月11日</th>
            <td>
              <ul>
                <li>タイヤ交換整備体験</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td></td>
          </tr>
          <tr>
            <th>2024年5月25日</th>
            <td>
              <ul>
                <li>日産　試乗体験</li>
                <li>どうやって車が動く？仕組みを見てみよう</li>
              </ul>
            </td>
            <td class="o-text-center"><img src="/img/kanagawa-car-open-campus-2023-1.webp" alt="" width="80"></td>
            <td class="o-text-center">
              <img src="/img/kanagawa-car-open-campus-present.webp" alt="グローブプレゼント" width="80" class="c-circle-image">
              <br>グローブプレゼント有り
            </td>
          </tr>
          <tr>
            <th>2024年6月8日</th>
            <td>
              <ul>
                <li>三菱　試乗体験</li>
                <li>かんたん！車の点検整備体験</li>
              </ul>
            </td>
            <td class="o-text-center"><img src="/img/kanagawa-car-open-campus-2023-3.webp" alt="" width="80"></td>
            <td class="o-text-center">
              <img src="/img/kanagawa-car-open-campus-present.webp" alt="グローブプレゼント" width="80" class="c-circle-image">
              <br>グローブプレゼント有り
            </td>
          </tr>
          <tr>
            <th>2024年6月22日</th>
            <td>
              <ul>
                <li>神奈川トヨタGR試乗体験</li>
                <li>エンジンがかかる仕組み</li>
              </ul>
            </td>
            <td class="o-text-center"><img src="/img/kanagawa-car-open-campus-2023-2.webp" alt="" width="80"></td>
            <td></td>
          </tr>
          <tr>
            <th>2024年7月13日</th>
            <td>
              <ul>
                <li>JAF　シートベルトの効果を体験</li>
                <li>エンジンがかかる仕組み</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td class="o-text-center">
              <img src="/img/kanagawa-car-open-campus-present.webp" alt="グローブプレゼント" width="80" class="c-circle-image">
              <br>グローブプレゼント有り
            </td>
          </tr>
          <tr>
            <th>2024年7月27日</th>
            <td>
              <ul>
                <li>SKYグループ試乗体験</li>
                <li>車はどうやって止まる？ブレーキ分解作業</li>
              </ul>
            </td>
            <td class="o-text-center"><img src="/img/kanagawa-car-open-campus-2023-5.webp" alt="" width="80"></td>
            <td class="o-text-center">
              <img src="/img/kanagawa-car-open-campus-present.webp" alt="グローブプレゼント" width="80" class="c-circle-image">
              <br>グローブプレゼント有り
            </td>
          </tr>
          <tr>
            <th>2024年8月2日</th>
            <td>
              <ul>
                <li>ウェインズトヨタ</li>
                <li>エンジン燃焼実験</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td></td>
          </tr>
          <tr>
            <th>2024年8月5日</th>
            <td>
              <ul>
                <li>車のギヤチェンジ　MT編</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td></td>
          </tr>
          <tr>
            <th>2024年8月24日</th>
            <td>
              <ul>
                <li>ALC 試乗体験</li>
                <li>エンジンがかかる仕組み</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td class="o-text-center">
              <img src="/img/kanagawa-car-open-campus-present.webp" alt="グローブプレゼント" width="80" class="c-circle-image">
              <br>グローブプレゼント有り
            </td>
          </tr>
          <tr>
            <th>2024年9月14日</th>
            <td>
              <ul>
                <li>スズキ　試乗体験</li>
                <li>タイヤの交換整備体験</li>
              </ul>
            </td>
            <td class="o-text-center"><img src="/img/kanagawa-car-open-campus-2023-6.webp" alt="" width="80"></td>
            <td></td>
          </tr>
          <tr>
            <th>2024年9月28日</th>
            <td>
              <ul>
                <li>エンジンの仕組みを理解しよう</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td class="o-text-center">
              <img src="/img/kanagawa-car-open-campus-present.webp" alt="グローブプレゼント" width="80" class="c-circle-image">
              <br>グローブプレゼント有り
            </td>
          </tr>
          <tr>
            <th>2024年10月5日</th>
            <td>
              <ul>
                <li>どうやって車が動く？仕組みを見てみよう</li>
                <li>かんたん！車の点検整備体験</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td class="o-text-center">
              <img src="/img/kanagawa-car-open-campus-present.webp" alt="グローブプレゼント" width="80" class="c-circle-image">
              <br>グローブプレゼント有り
            </td>
          </tr>
          <tr>
            <th>2024年10月19日</th>
            <td>
              <ul>
                <li>車のギヤチェンジ　MT編</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td></td>
          </tr>
          <tr>
            <th>2024年11月16日</th>
            <td>
              <ul>
                <li>車のセンサーを作ってみよう</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td></td>
          </tr>
          <tr>
            <th>2024年12月14日</th>
            <td>
              <ul>
                <li>どうやって車が動く？仕組みを見てみよう（上級編）</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td class="o-text-center">
              <img src="/img/kanagawa-car-open-campus-present.webp" alt="グローブプレゼント" width="80" class="c-circle-image">
              <br>グローブプレゼント有り
            </td>
          </tr>
          <tr>
            <th>2025年1月18日</th>
            <td>
              <ul>
                <li>どうやって車が動く？仕組みを見てみよう</li>
                <li>タイヤの交換整備体験</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td class="o-text-center">
              <img src="/img/kanagawa-car-open-campus-present.webp" alt="グローブプレゼント" width="80" class="c-circle-image">
              <br>グローブプレゼント有り
            </td>
          </tr>
          <tr>
            <th>2025年2月1日</th>
            <td>
              <ul>
                <li>車をコンピュータを使って診断</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td></td>
          </tr>
          <tr>
            <th>2025年3月1日</th>
            <td>
              <ul>
                <li>車のセンサーを作ってみよう</li>
              </ul>
            </td>
            <td class="o-text-center"></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </section>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-car">オープンキャンパスの内容</h1>
      <p>開催時間 9:00〜12:00</p>
      <ol>
        <li>校内見学、本校の案内</li>
        <li>実習授業体験、特別プログラム（連携企業や日程により異なります）</li>
        <li>学費、入学方法案内</li>
      </ol>
    </section>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-car">当日の持ち物</h1>
      <ul class="c-list c-list--theme-car c-content">
        <li>筆記用具</li>
      </ul>
      <ul class="c-note-list">
        <li>作業を行いますので動きやすい服装でお越し下さい。（サンダル不可）</li>
      </ul>
    </section>
    <section if="{false}">
      <h1 class="c-heading-4 c-heading-4--theme-car">プレゼント</h1>
      <table class="c-table o-space c-content">
        <tr>
          <th>
            グローブ
            <br><img src="/img/kanagawa-car-open-campus-2022-10-08-gift.webp" width="480" alt="">
          </th>
          <th>
            ペーパークラフト
            <br><img src="/img/kanagawa-car-open-campus-2022-10-08-gift-2.webp" width="480" alt="">
          </th>
        </tr>
      </table>
    </section>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-car">参加費用</h1>
      <div class="c-content">
        <p>無料</p>
      </div>
    </section>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-car" id="form">お申し込みフォーム</h1>
      <template if="{state.mode != 'thanks'}">
        <div class="c-content">
          <p>各日程前日までに、下記の申し込みフォームよりお申し込みください。</p>
        </div>
        <div class="o-space">
          <table class="c-table">
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">お名前</div>
                  <div>
                    <span class="c-label c-label--theme-car">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['名前']}
                </template>
                <input data-ref-input="名前" hidden="{state.mode === 'confirm'}" type="text" class="c-input-text">
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">フリガナ</div>
                  <div>
                    <span class="c-label c-label--theme-car">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['フリガナ']}
                </template>
                <input data-ref-input="フリガナ" hidden="{state.mode === 'confirm'}" type="text" class="c-input-text">
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">メールアドレス</div>
                  <div>
                    <span class="c-label c-label--theme-car">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['メールアドレス']}
                </template>
                <input data-ref-input="メールアドレス" hidden="{state.mode === 'confirm'}" type="email" class="c-input-text">
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">学校名</div>
                  <div>
                    <span class="c-label c-label--theme-car">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['学校名']}
                </template>
                <input data-ref-input="学校名" hidden="{state.mode === 'confirm'}" type="text" class="c-input-text">
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">学年</div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['学年']}
                </template>
                <select name="" id="" data-ref-input="学年" hidden="{state.mode === 'confirm'}">
                  <option value="">選択してください</option>
                  <option value="高校生1年生">高校生1年生</option>
                  <option value="高校生2年生">高校生2年生</option>
                  <option value="高校生3年生">高校生3年生</option>
                  <option value="高校生その他">高校生その他</option>
                  <option value="社会人">社会人</option>
                  <option value="保護者">保護者</option>
                  <option value="その他">その他</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">同伴者</div>
                  <div>
                    <span class="c-label c-label--theme-car">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['同伴者']}
                </template>
                <select name="" id="" data-ref-input="同伴者" hidden="{state.mode === 'confirm'}">
                  <option value="">選択してください</option>
                  <option value="1名">1名</option>
                  <option value="2名">2名</option>
                  <option value="3名以上">3名以上</option>
                  <option value="なし">なし</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">電話番号</div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['電話番号']}
                </template>
                <input data-ref-input="電話番号" hidden="{state.mode === 'confirm'}" type="tel" class="c-input-text">
              </td>
            </tr>
            <tr>
              <th>
                <div class="o-layout">
                  <div class="o-size-fill">参加日</div>
                  <div>
                    <span class="c-label c-label--theme-car">必須</span>
                  </div>
                </div>
              </th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  {state.dataSubmit['参加日']}
                </template>
                <select data-ref-input="参加日" hidden="{state.mode === 'confirm'}" name="date">
                  <option value="">選択してください</option>
                  <option each="{date in dateList}" value="{date}">{date}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>備考</th>
              <td>
                <template if="{state.mode === 'confirm'}">
                  <raw-html data-content="{breakToBr(state.dataSubmit['備考'])}"></raw-html>
                </template>
                <textarea data-ref-input="備考" hidden="{state.mode === 'confirm'}" class="c-textarea" cols="30"
                  rows="5"></textarea>
              </td>
            </tr>
          </table>
          <div class="o-items-center o-space">
            <div class="o-layout">
              <div if="{state.mode === 'input'}">
                <ui-button data-tag="button" data-class="theme-car" onclick="{clickConform}">入力内容を確認する
                </ui-button>
              </div>
              <template if="{state.mode === 'confirm'}">
                <div>
                  <ui-button data-tag="button" data-class="theme-car" onclick="{backToInput}">修正する</ui-button>
                </div>
                <div>
                  <ui-button data-tag="button" data-class="theme-car" onclick="{clickSubmit}" data-button-type="submit">
                    この内容で送信する</ui-button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template if="{state.mode === 'thanks'}">
        <section>
          <h1 class="c-heading-5 c-heading-5--theme-car">お問い合わせ頂きありがとうございます。</h1>
        </section>
      </template>
    </section>
  </section>
  <script>
    // @ts-check
    export default function PageKanagawaCarOpenCampus() {
      return {
        dateList: [
          '2024年04月20日(土)',
          '2024年05月11日(土)',
          '2024年05月25日(土)',
          '2024年06月8日(土)',
          '2024年06月22日(土)',
          '2024年07月13日(土)',
          '2024年07月27日(土)',
          '2024年08月02日(金)',
          '2024年08月05日(月)',
          '2024年08月24日(土)',
          '2024年09月14日(土)',
          '2024年09月28日(土)',
          '2024年10月05日(土)',
          '2024年10月19日(土)',
          '2024年11月16日(土)',
          '2024年12月14日(土)',
          '2025年01月18日(土)',
          '2025年02月01日(土)',
          '2025年03月01日(土)',
        ],
        state: {
          /**
           * @type {('input'|'confirm'|'thanks')} mode
           */
          mode: 'input',
          /**
           * @type {Object} formData
           */
          formData: {
            '名前': { require: true, value: '' },
            'フリガナ': { require: true, value: '' },
            'メールアドレス': { require: true, value: '' },
            '学校名': { require: true, value: '' },
            '学年': { require: false, value: '' },
            '参加日': { require: true, value: '' },
            '同伴者': { require: true, value: '' },
            '電話番号': { require: false, value: '' },
            '備考': { require: false, value: '' },
          },
          /**
           * フォームに送信するデータ
           */
          dataSubmit: {},
        },
        /**
         * @return {Boolean}
         */
        getIsInlineForm() {
          const result = !this.state.schoolSlug.match(/(cook|design|dental)/)
          return result
        },
        goToForm() {
          if (this.getIsInlineForm()) {
            this.setPageStateSlug('form')
          }
          else {
            window.open('https://www.school-go.info/f9z653/atsugi/form.php?fno=23&fsno=1&openExternalBrowser=1', '_blank')
          }
        },
        /**
         * @param {String} slug
         */
        setPageStateSlug(slug) {
          this.state.pageStateSlug = slug
          this.update()
          window.scrollTo(0, 0)
        },
        clickConform(event) {
          event.preventDefault()
          this.setFormData()
        },
        resetData() {
          this.state.formData = this.state.formDataDefault
          this.state.dataSubmit = {}
        },
        setFormData() {
          this.resetData()
          Object.keys(this.state.formData).forEach(key => {
            console.log(key)
            this.state.formData[key].value = this.$(`[data-ref-input="${key}"]`).value
            this.state.dataSubmit[key] = this.state.formData[key].value
          })
          this.state.dataSubmit['問い合わせ先の学校名'] = '神奈川総合大学校 自動車整備科'
          this.state.dataSubmit['タイトル'] = 'オープンキャンパス'
          this.state.dataSubmit['管理先メールアドレス'] = this.dataset.emails.car
          this.checkRequiredValue()
        },
        checkRequiredValue() {
          const result = Object.keys(this.state.formData).some(key => {
            return !this.state.formData[key].value && this.state.formData[key].require
          })
          if (result) {
            alert('恐れ入りますが入力に不足があるようです。\n「必須」と表示された入力欄に正しく記入されたかご確認ください。')
          }
          else {
            this.goToConfirm()
          }
        },
        goToConfirm() {
          this.state.mode = 'confirm'
          this.update()
          window.location.href = '#form'
        },
        backToInput() {
          this.state.mode = 'input'
          this.update()
          window.location.href = '#form'
        },
        clickSubmit(event) {
          event.preventDefault()
          this.submitForm()
        },
        submitForm() {
          if (!this.state.submitted) {
            this.state.submitted = true
            fetch('/cockpit/api/forms/submit/opencampus', {
              method: 'post',
              credentials: "same-origin",
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 35caad65cd1c9add5bdeb758fd62a0',
              },
              body: JSON.stringify({
                form: this.state.dataSubmit
              })
            })
              .then(result => result.json())
              .then(result => {
                console.log(result)
                this.state.submitted = false
                this.goToThanks()
              })
              .catch(error => {
                console.log(error)
                alert('申し訳ありませんが、送信時に予期せぬエラーが発生しました。\nしばらく経ってから再度お試しください。')
                this.state.submitted = false
              })
          }
        },
        goToThanks() {
          this.state.mode = 'thanks'
          this.update()
          window.location.href = '#form'
          window.open('thanks.html', 'newwindow', 'width=500, height=500, top=100, left=100')
        },
        backupDefaultData() {
          this.state.formDataDefault = JSON.parse(JSON.stringify(this.state.formData))
        },
        onBeforeMount() {
          this.backupDefaultData()
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa-car-open-campus>