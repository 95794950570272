<app-hero>
  <div class="{ state.isMounted ? 'is-mounted' : null }">
    <div class="c-hero-intro">
      <div class="o-layout o-layout--column o-layout--cross-axis-center">
        <div>
          <div class="c-hero__logo">
            <svg width="2945" height="2718" viewBox="0 0 2945 2718" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="c-hero__svg">
              <path
                d="M1591.28 1117.15C1618.88 1095.59 1631.24 1058.31 1621.85 1024.45C1615.67 1002.15 1600.59 982.856 1580.46 971.464C1550.21 954.352 1510.32 956.812 1483.11 978.697C1422.2 1026.47 1149.33 1257.34 1866 2658.03"
                stroke="#94D425" stroke-width="31.8927" stroke-linecap="round" />
              <path
                d="M1544.1 1030.92C1511.63 1017.8 1473.16 1025.73 1448.53 1050.79C1432.32 1067.3 1423.14 1090 1423.35 1113.13C1423.65 1147.88 1445.72 1181.2 1478.28 1193.82C1550.11 1222.69 1886.49 1343.56 2741.18 22.5688"
                stroke="#FDC129" stroke-width="31.8927" stroke-linecap="round" />
              <path
                d="M1492.97 1114.93C1497.84 1149.6 1523.94 1178.95 1557.97 1187.75C1580.36 1193.54 1604.61 1190.14 1624.55 1178.39C1654.49 1160.76 1672.3 1124.98 1666.95 1090.48C1656.04 1013.83 1592.53 662.086 21.1702 582.396"
                stroke="#FFA2BE" stroke-width="31.8927" stroke-linecap="round" />
            </svg>
          </div>
        </div>
        <div>
          <h1 class="c-hero__title">
            <img src="/img/atsugi/school-name.svg" alt="厚木総合専門学校" width="560" height="66">
          </h1>
        </div>
        <div>
          <div class="c-hero__description c-hero__text">
            <p>
              <raw-html
                data-content="{japaneseParser.translateHTMLString('厚木に生まれて89年、<br>総合力で皆様の夢と希望のお手伝いをさせていただきます。')}">
              </raw-html>
            </p>
            <p class="o-items-center">
              <a href="#open-campus" class="c-button c-button--atsugi">オープンキャンパス実施中！</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="c-hero__arrow">
      <img src="/img/atsugi/arrow-down.svg" alt="">
    </div>
    <div>
      <div class="o-layout o-layout--main-axis-center o-layout--gap-none c-hero__nav-layout">
        <div>
          <a href="#cook" class="o-block-link c-hero__nav-link">
            <div class="o-layout o-layout--column o-layout--cross-axis-center">
              <div><img src="/img/logo-atsugi-cook.svg" alt="" width="150" height="150" class="c-hero__nav-icon"></div>
              <div>
                <div class="c-hero__nav-name c-hero__nav-name--theme-cook">
                  <raw-html data-content="{japaneseParser.translateHTMLString('調理学科')}"></raw-html>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div>
          <a href="#design" class="o-block-link c-hero__nav-link">
            <div class="o-layout o-layout--column o-layout--cross-axis-center">
              <div><img src="/img/logo-atsugi-design.svg" alt="" width="225" height="150" class="c-hero__nav-icon">
              </div>
              <div>
                <div class="c-hero__nav-name c-hero__nav-name--theme-design">
                  <raw-html data-content="{japaneseParser.translateHTMLString('生活<wbr>デザイン<wbr>学科')}"></raw-html>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div>
          <a href="#dental" class="o-block-link c-hero__nav-link">
            <div class="o-layout o-layout--column o-layout--cross-axis-center">
              <div><img src="/img/logo-atsugi-dental.svg" alt="" width="150" height="150" class="c-hero__nav-icon">
              </div>
              <div>
                <div class="c-hero__nav-name c-hero__nav-name--theme-dental">
                  <raw-html data-content="{japaneseParser.translateHTMLString('歯科衛生<wbr>学科')}"></raw-html>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <script>
    export default {
      state: {
        isMounted: false,
      },
      onMounted() {
        this.state.isMounted = true
        this.update()
        window.scrollTo(0, 0)
      }
    }
  </script>
</app-hero>