<masked-image>
  <img src="{ props.dataPhoto }" alt="{ props.dataAlt }" { ...getImageStyle() }>

  <svg width="0" height="0">
    <clipPath clipPathUnits="objectBoundingBox">
      <path
        d="M0.75815095,0.0579477769 C0.879893708,0.187288937 0.902165272,0.677587654 0.799370955,0.785996249 C0.627963035,0.966765889 0.26163708,0.91434951 0.111342491,0.755791573 C-0.0332137967,0.603287436 -0.035795248,0.382887577 0.0965066612,0.173955315 C0.200239457,0.0101396315 0.648923894,-0.0580965318 0.75815095,0.0579477769 Z">
      </path>
    </clipPath>
    <clipPath id="mask-{ id }" clipPathUnits="objectBoundingBox">
      <path
        d="M0.984998 0H0.0396639C0.0325392 0.0217612 0.0254411 0.0469361 0.0192582 0.0752671C-0.00490504 0.185988 -0.0142704 0.341934 0.0394046 0.522907C0.0822985 0.667529 0.0739138 0.788232 0.055759 0.873233C0.0465193 0.916493 0.0345977 0.95099 0.0250167 0.974563C0.0204886 0.985703 0.0165412 0.994262 0.0137441 1H0.958538C0.966211 0.976444 0.973937 0.948852 0.980609 0.917615C1.00474 0.804625 1.01448 0.645863 0.960717 0.464593C0.917735 0.319673 0.926148 0.201958 0.943927 0.120491C0.953007 0.0788856 0.964742 0.0459737 0.974171 0.0235787C0.978492 0.0133165 0.982273 0.00539581 0.984998 0Z" />
    </clipPath>
  </svg>

  <script>
    export default function MaskedImage() {
      return {
        getImageStyle() {
          return {
            style: `clip-path: url(#mask-${this.id})`
          }
        }
      }
    }
  </script>
</masked-image>