<ui-box>
  <div class="{ getClassName('c-box') }">
    <heading-tag data-tag="{ props.dataHeadingTag }" data-class="{ getClassName('c-box__heading') }">{ props.dataHeading
      }
    </heading-tag>
    <slot />
  </div>
  <script>
    export default function UiBox() {
      // @ts-check
      return {
        /**
         * @param {string} className ベースとなるクラス名
         * @return {string}
         */
        getClassName(className) {
          const result = [className]
          if (this.props.dataTheme) result.push(`${className}--theme-${this.props.dataTheme}`)
          return result.join(' ')
        }
      }
    }
  </script>
</ui-box>