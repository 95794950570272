<school-header>
  <header class="{ getHeaderClass() }" data-ref="header">
    <div class="o-layout o-layout--gap-none">
      <div class="o-size-hug c-school-header__logo">
        <a href="/" class="c-block-link c-school-header__logo-link">
          <logo-suzuki data-width="" data-height="30" class="c-school-header__suzuki-logo"></logo-suzuki>
        </a>
      </div>
      <div class="o-size-fill { getClass('c-school-header__bar', [props.dataSlug]) }">
        <div class="o-layout o-layout--gap-small o-layout--cross-axis-center">
          <div class="o-size-hug" if="{ props.dataSlug === 'atsugi' }">
            <logo-atsugi class="c-school-header__logo-atsugi"></logo-atsugi>
          </div>
          <div class="o-size-fill">
            <a href="/{props.dataSlug}" class="o-block-link">
              <h1 class="c-school-header__title">{ props.dataSchoolName }</h1>
            </a>
          </div>
          <div class="o-size-hug" each="{nav in subHeaderNavs}" if="{getSchoolHeaderButtonVisible()}">
            <a href="{getSchoolSubHeaderButtonLink(nav.link)}"
              class="{getClass('c-school-sub-header__button', [props.dataSlug])}"
              target="{props.dataSlug === 'atsugi' && nav.link === 'request' ? '_blank' : false}">
              <div class="o-layout o-layout--gap-small-2 o-layout--cross-axis-center">
                <div class="o-items-center">
                  <ui-icon data-icon="{nav.icon}" class="c-school-sub-header__button-icon"></ui-icon>
                </div>
                <div>
                  {getSchoolSubHeaderButtonName(nav.name)}
                </div>
              </div>
            </a>
          </div>
          <div class="o-size-hug c-school-header__toggle-button-block" if="{ getSchoolNavVisible() }">
            <button class="c-school-header__toggle-button" onclick="{ toggle }">
              <ui-icon data-icon="{ getMenuIconSlug() }"></ui-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="{ getClass('c-school-sub-header', [props.dataSubSchoolSlug]) }" if="{ props.dataSubSchoolSlug }">
      <div class="o-size-fill o-layout o-layout--cross-axis-center">
        <div class="o-size-fill">
          <div class="o-layout o-layout--gap-small o-layout--cross-axis-center">
            <div class="o-size-fill">
              <h2 class="c-school-sub-header__title">{ props.dataSubSchoolCource }</h2>
            </div>
            <div class="o-size-hug" each="{nav in subHeaderNavs}">
              <a href="{getSchoolSubHeaderButtonLink(nav.link)}"
                class="{getClass('c-school-sub-header__button', [props.dataSubSchoolSlug])}"
                target="{props.dataSlug === 'atsugi' && nav.link === 'request' ? '_blank' : false}">
                <div class="o-layout o-layout--gap-small-2 o-layout--cross-axis-center">
                  <div class="o-items-center">
                    <ui-icon data-icon="{nav.icon}" class="c-school-sub-header__button-icon"></ui-icon>
                  </div>
                  <div>
                    {getSchoolSubHeaderButtonName(nav.name)}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="o-size-hug c-school-header__toggle-button-block">
          <button class="c-school-sub-header__toggle-button" onclick="{ toggleSubSchoolMenu }">
            <ui-icon data-icon="hamburger"></ui-icon>
          </button>
        </div>
      </div>
    </div>
  </header>
  <div class="c-school-header-nav c-school-header-nav--school" data-is-opened="{ state.isNavOpened }">
    <div class="c-school-header-nav__scroll-area">
      <template is="{ props.dataSlug }-nav" data-where="header"></template>
    </div>
    <button class="c-school-header-nav__button" onclick="{ toggle }" data-is-opened="{ state.isNavOpened }">
      <ui-icon data-icon="x"></ui-icon>
    </button>
  </div>
  <div class="c-school-header-nav c-school-header-nav--school" data-is-opened="{ state.isSubNavOpened }"
    if="{ props.dataSubSchoolSlug }">
    <div class="c-school-header-nav__scroll-area">
      <template is="{ props.dataSubSchoolSlug }-nav" data-where="header"></template>
    </div>
    <button class="c-school-header-nav__button" onclick="{ toggleSubSchoolMenu }"
      data-is-opened="{ state.isSubNavOpened }">
      <ui-icon data-icon="x"></ui-icon>
    </button>
  </div>
  <script>
    // @ts-check
    export default function SchoolHeader() {
      return {
        state: {
          /** @type {boolean} */
          isNavOpened: false,
          /** @type {boolean} */
          isSubNavOpened: false,
        },
        subHeaderNavs: [
          {
            name: '体験入学',
            icon: 'compass',
            link: 'open-campus'
          },
          {
            name: '資料請求',
            icon: 'book',
            link: 'request'
          }
        ],
        toggle() {
          this.state.isNavOpened = !this.state.isNavOpened
          this.update()
        },
        toggleSubSchoolMenu() {
          this.state.isSubNavOpened = !this.state.isSubNavOpened
          this.update()
        },
        /**
         * @param {string} className
         * @return {string}
         */
        getClass(className, slugArray) {
          const result = [className]
          slugArray.forEach(slug => {
            result.push(`${className}--theme-${slug}`)
          })
          return result.join(' ')
        },
        getHeaderClass() {
          let result = 'c-school-header'
          if (this.props.dataIsSchool) result += ' c-school-header--school'
          return result
        },
        /**
         * @return {string}
         */
        getMenuIconSlug() {
          return this.props.dataSubSchoolSlug || ['kanagawa'].some(slug => slug === this.props.dataSlug) ? 'grid' : 'hamburger'
        },
        /**
         * @return {boolean}
         */
        getSchoolNavVisible() {
          return !this.props.dataIsNoNav
        },
        getSchoolHeaderButtonVisible() {
          return !this.props.dataIsNoNav && !this.props.dataSubSchoolSlug
        },
        setHeightToVariable() {
          let height = Number(this.$('[data-ref="header"]').offsetHeight)
          document.documentElement.style.setProperty('--mobile-header-height', `${height}px`)
          const tabs = document.querySelector('[data-ref="tabs"]')
          if (tabs) height += tabs.offsetHeight
          height += 16
          document.documentElement.style.setProperty('--mobile-scroll-offset', `${height - 25}px`)
        },
        getSchoolSubHeaderButtonLink(link) {
          if (link === 'open-campus') {
            let result = `/${this.props.dataSlug}`
            if (this.props.dataSubSchoolSlug) result += `/${this.props.dataSubSchoolSlug}`
            result += `/${link}`
            return result
          }
          else {
            if (this.props.dataSlug === 'atsugi') {
              // console.log(this.props.dataSubSchoolSlug)
              if (this.props.dataSubSchoolSlug === 'dental') {
                return 'https://www.school-go.info/f9z653/atsugi/form.php?fno=83&fsno=1&openExternalBrowser=1'
              }
              else {
                return 'https://www.school-go.info/f9z653/atsugi/form.php?fno=1&fsno=1&openExternalBrowser=1'
              }
            }
          }
          return `/${link}`
        },
        getSchoolSubHeaderButtonName(name) {
          if (
            name === '体験入学'
            && this.props.dataSlug != 'chuo'
            && this.props.dataSubSchoolSlug != 'highschool'

          ) {
            return 'オープンキャンパス'
          }
          else {
            return name
          }
        },
        onMounted() {
          this.setHeightToVariable()
          window.addEventListener('resize', this.setHeightToVariable)
        }
      }
    }
  </script>
</school-header>