<page-kanagawa-car-campus>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="car">一年間の流れ</ui-heading>
          <div class="c-content">
            <div class="c-accent-block c-accent-block--theme-car o-cancel-first-child-gap o-space">
              <p>実際の学校の様子を見てみたいという方は、ぜひ一度オープンキャンパスにご参加ください。</p>
              <p class="o-items-center">
                <ui-button data-href="/kanagawa/car/open-campus" data-class="theme-car">オープンキャンパス
                </ui-button>
              </p>
            </div>
          </div>
        </div>
        <div>
          <event-calendar data-items="{ events }" data-slug="car"></event-calendar>
        </div>
      </section>
    </div>

  </div>
  <script>
    // @ts-check
    export default function PageKanagawaCarCampus() {
      return {
        /**
         * @typedef {object} eventObject
         * @property {string} title
         * @property {string} photo
         * @property {string[]} events
         */
        /**
         * @type {eventObject[]}
         */
        events: [
          {
            title: '1学期',
            photo: '/img/car-month-header-1.webp',
            events: [
              '入学式',
              'レクリエーション',
              'ガス溶接・アーク溶接講習（1年生）',
              'インターンシップ（4年生）',
              '環境保護活動（3,4年生）',
              '中古車査定士試験（2年生）',
            ],
          },
          {
            title: '2学期',
            photo: '/img/car-month-header-2.webp',
            events: [
              'ソーシャル検定試験',
              '研修旅行（２年生）',
              '豊友祭',
              '遠足',
              '東京モーターショー',
            ],
          },
          {
            title: '3学期',
            photo: '/img/car-month-header-3.webp',
            events: [
              '企業特別授業',
              '就職活動',
              '国家試験',
              '卒業式',
            ],
          },
        ],
        /**
         * @type {string[]}
         */
        list: [
          '防犯パトロール',
          '心と街のクリーン作戦',
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa-car-campus>