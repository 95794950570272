<ui-button>
  <template is="a-or-button" data-tag="{ props.dataTag ? props.dataTag : 'a' }" data-href="{ props.dataHref }"
    data-class="{ getClass('c-button-2')} " data-disabled="{props.dataDisabled}" data-target="{props.dataTarget}"
    data-button-type="{props.dataButtonType}">
    <div class="c-button-2__content">
      <div class="o-layout o-layout--gap-small">
        <div if="{ props.dataIcon && props.dataIconPosition !== 'last' }" class="o-size-hug">
          <ui-icon data-icon="{ props.dataIcon }"></ui-icon>
        </div>
        <div class="o-size-fill">
          <slot />
        </div>
        <div if="{ props.dataIcon && props.dataIconPosition === 'last' }" class="o-size-hug">
          <ui-icon data-icon="{ props.dataIcon }"></ui-icon>
        </div>
      </div>
    </div>
  </template>
  <script>
    // @ts-check
    export default function UiButton() {
      return {
        /**
         * @param {string} className
         * @return {string}
         */
        getClass(className) {
          const result = [className]
          if (this.props.dataClass) {
            const classArray = typeof this.props.dataClass === 'string' ? [this.props.dataClass] : this.props.dataClass
            classArray.forEach(className2 => {
              result.push(`${className}--${className2}`)
            })
          }
          return result.join(' ')
        }
      }
    }
  </script>
</ui-button>