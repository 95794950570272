<page-kanagawa-car-subject>
  <div class="o-layout o-layout--column">
    <div>
      <div class="o-layout o-layout--column">
        <section>
          <ui-heading data-tag="h1" data-theme="car">2つの学科</ui-heading>
          <div class="o-layout o-layout--fill-equal o-layout--column-in-mobile">
            <section>
              <h1 class="c-heading-4 c-heading-4--theme-car">一級自動車整備科（4年制）</h1>
              <h2 class="c-heading-5 c-heading-5--theme-car">自動車業界最高峰の資格を目指して</h2>
              <p>
                ハイブリッドや電気自動車、自動運転技術など、次世代の先進技術に対応する整備士が求められる時代です。
                <br>一級自動車整備科では、よりハイレベルな整備技術を学び、小型一級自動車整備士を目指します。
                <br>また、お客様への対応のスキルや、インターンシップ、環境保護活動などを通じて、ワンランク上の技能やスキルはもとより、一級自動車整備士に必要なマインドセットを身に付けます。
              </p>
              <table class="c-table o-space">
                <tr>
                  <th>卒業時に付与される称号</th>
                  <td>高度専門士</td>
                </tr>
                <tr>
                  <th>定員</th>
                  <td>10名</td>
                </tr>
              </table>
            </section>
            <section>
              <h1 class="c-heading-4 c-heading-4--theme-car">二級自動車整備科（2年制）</h1>
              <h2 class="c-heading-5 c-heading-5--theme-car">資格をとって即戦力として働きたい</h2>
              <p>
                2年間で、二級ガソリン自動車整備士、二級ジーゼル自動車整備士を目指します。
                <br>本校の魅力のひとつは、即戦力として活躍できる実践的なカリキュラムです。
                <br>また、卒業すると国家資格の実技試験免除となり、学科試験合格後には二級自動車整備士として活躍することが可能もなります。
                <br>また、よりステップアップしたい方は、一級自動車整備科に編入もすることもできます。
              </p>
              <table class="c-table o-space">
                <tr>
                  <th>卒業時に付与される称号</th>
                  <td>専門士</td>
                </tr>
                <tr>
                  <th>定員</th>
                  <td>40名</td>
                </tr>
              </table>
            </section>
          </div>
        </section>
        <section>
          <ui-heading data-tag="h1" data-theme="car">取得可能な資格検定</ui-heading>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-car">卒業と同時に与えられる資格</h1>
            <p>
              <span class="c-label c-label--theme-car">一級自動車整備科</span>
              1級小型自動車整備士受験資格（実技試験免除）
            </p>
            <p>
              <span class="c-label c-label--theme-car">二級自動車整備科</span>
              2級自動車整備士受験資格（ガソリン・ジーゼル実技試験免除）
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-car">在学中に受験可能な資格・試験</h1>
            <ul class="c-list c-list--theme-car">
              <li>アーク溶接特別教育</li>
              <li>ガス溶接技能講習</li>
              <li>中古自動車査定士技能検定</li>
              <li>低電圧電気取扱業務特別講習</li>
              <li>ソーシャル検定中級（JAMCA認定試験）</li>
              <li>ソーシャル検定上級（JAMCA認定試験）</li>
              <li>職業訓練指導員免許（3,4年生）</li>
            </ul>
            <p>など</p>
          </section>
        </section>
        <section>
          <ui-heading data-tag="h1" data-theme="car">多彩なメーカーによる教材と、充実した設備</ui-heading>
          <p>
            本格的な整備技術習得のために、実習においてもきめ細かい指導を行なっています。
            <br>最新技術の動向が分かるように様々な企業からエンジンやトランスミッションなどの教材や実習車が提供され、さらに各メーカーの整備マニュアルを基に授業を進めるための実践的な実習が行えます。
            <br>国家試験を念頭に置き、全員合格を目指したカリキュラムを実施しています。
          </p>
        </section>
        <section>
          <ui-heading data-tag="h1" data-theme="car">カリキュラム</ui-heading>
          <table class="c-table">
            <tr>
              <th>エンジン</th>
              <td>
                車の心臓であるエンジン構造と作動の理解
                <br>エンジン単体での分解、組立作業や車からの脱着作業まで、レベルの高い作業技術を身に付けます。
              </td>
            </tr>
            <tr>
              <th>シャシ</th>
              <td>
                トランスミッションやタイヤ、ボデーなど学びます。
                <br>分解や組み立て作業により実践的な整備技術の向上を目指します。
              </td>
            </tr>
            <tr>
              <th>電気関係</th>
              <td>
                車には不可欠な電気の基礎から部品の分解、点検作業を学びます。
              </td>
            </tr>
            <tr>
              <th>故障探求</th>
              <td>故障の状況を判断し、原因究明から適切な修理方法を学びます。</td>
            </tr>
            <tr>
              <th>自動車検査</th>
              <td>車が法定基準を満たしているか、点検と検査方法を実践で学びます。</td>
            </tr>
            <tr>
              <th>インターンシップ</th>
              <td>企業の整備工場で約１カ月間、実践での経験を積み、リアルな現場での学習。</td>
            </tr>
            <tr>
              <th>企業による特別授業</th>
              <td>様々な企業による、最新技術の特別授業。</td>
            </tr>
            <tr>
              <th>マナー教育</th>
              <td>社会人としてのマナーや一般常識を学び、検定試験を受験します。</td>
            </tr>
          </table>
        </section>
      </div>
    </div>
  </div>
  <script>
    export default {
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-kanagawa-car-subject>