<page-atsugi-dental-subject>
  <div class="o-layout o-layout--column">
    <div>
      <div class="o-layout o-layout--column">
        <div>
          <div class="o-layout o-layout--fill-equal o-layout--column-in-mobile">
            <section>
              <ui-heading data-tag="h1" data-theme="dental">ひとりひとりに、自信と責任を与えます</ui-heading>
              <p>
                授業にできるだけ集中してほしいのは、私たちが医療の一端を担うからです。
                <br>臨床に出れば、大きなミスは許されません。そのため、学生といえど医療従事者としての責任と意識を身につけて欲しいと考えています。
              </p>
            </section>
            <section>
              <ui-heading data-tag="h1" data-theme="dental">テクニックとやさしさ</ui-heading>
              <p>
                知識・技術の向上を目指すことは医療従事者として基本です。しかし、技術だけでは患者さんに満足な医療を提供することはできません。
                <br>まず、患者さんが何を望んでいるかを知り、いたわりと思いやりで接することが大切です。
                <br>知識・技術・思いやり、この３つのバランスを身につけてください。
              </p>
            </section>
          </div>
        </div>
        <section>
          <ui-heading data-tag="h1" data-theme="dental">国家試験合格を目指したカリキュラム</ui-heading>
          <p>
            本校は厚生労働省の指定校で、卒業時には国家試験の受験資格が取得できます。
            <br>国家試験合格を目標に授業が組まれているので、１年次の４月から真剣勝負です。
            <br>全員合格を目指して、きめ細かい指導を行っています。真面目に努力すれば、きっと大きな喜びが待っているはずです。
          </p>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-dental">1年生で習うこと</h1>
            <p>
              前期では主に専門基礎科目、後期には専門科目が中心となり医学・歯科医学の基礎分野を学びます。
              <br>また、2種類の実習室を使用して講義・学内実習を行い、医療従事者としての心構えや、専門知識と技術を学びます。
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-dental">2年生で習うこと</h1>
            <p>
              講義中心の授業から実習がメインとなります。
              <br>後期からは医療従事者としての心構えの大切さを学び、知識・技術の習得を目指します。
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-dental">3年生で習うこと</h1>
            <p>
              前期では2年生後期に引き続き、臨床現場での実践力を学びます。
              <br>国家試験の合格を目指し、国家試験対策として模擬試験や対策授業を行います。
            </p>
          </section>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-dental">カリキュラム</h1>
            <div class="c-items-scroll o-space">
              <table class="c-table c-table--keep-layout" style="min-width: 500px;">
                <tbody>
                  <tr>
                    <th scope="col">分野</th>
                    <th scope="col">教育内容</th>
                    <th scope="col">授業科目</th>
                  </tr>
                  <tr>
                    <td rowspan="2"><span class="c-label c-label--theme-dental">基礎分野</span></td>
                    <td>科学的思考の基盤</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>生物学</li>
                        <li>物理学</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>人間と生活</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>社会学</li>
                        <li>英語</li>
                        <li>心理学</li>
                        <li>ケア・カウンセリング</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4"><span class="c-label c-label--theme-dental">専門基礎分野</span></td>
                    <td>人体の構造と機能</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>解剖学</li>
                        <li>組織発生学</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>歯・口腔の構造と機能</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>口腔解剖学</li>
                        <li>生理学</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>疾病の成り立ち及び回復過程の促進</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>病理学</li>
                        <li>微生物学</li>
                        <li>薬理学</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      歯・口腔の健康と
                      <br>予防に関わる人間と社会の仕組
                    </td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>口腔衛生学Ⅰ・Ⅱ</li>
                        <li>衛生学・公衆衛生学</li>
                        <li>衛生行政・社会福祉行政</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="6"><span class="c-label c-label--theme-dental">専門分野</span></td>
                    <td>歯科衛生士概論</td>
                    <td class="o-fit">
                      <ul class="c-list c-list--theme-dental">
                        <li>歯科衛生士概論</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>臨床歯科医学</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>歯科臨床概論</li>
                        <li>口腔外科学</li>
                        <li>歯科保存学Ⅰ・Ⅱ</li>
                        <li>小児歯科学</li>
                        <li>歯科補綴学</li>
                        <li>歯科矯正学</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>歯科予防処置論</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>歯科予防処置実習 （歯周）ⅠⅡⅢ</li>
                        <li>歯科予防処置実習 （う蝕）ⅠⅡ</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>歯科保健指導論</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>歯科栄養指導</li>
                        <li>歯科保健指導Ⅰ・Ⅱ・Ⅲ</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>歯科診療補助論</td>
                    <td class=" o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>歯科診療講義</li>
                        <li>歯科放射線</li>
                        <li>臨床検査</li>
                        <li>歯科診療補助実習Ⅰ・Ⅱ・Ⅲ</li>
                        <li>社会保険請求事務業務</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>臨地実習</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>臨地実習Ⅰ・Ⅱ</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="c-label c-label--theme-dental">選択分野</span></td>
                    <td>選択科目</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>コミュニケーション</li>
                        <li>情報リテラシー</li>
                        <li>英会話</li>
                        <li>接遇マナーとビジネス</li>
                        <li>介護の基礎と技法</li>
                        <li>ヒューマンスキル</li>
                        <li>資格対策Ⅰ・Ⅱ</li>
                        <li>歯科予防・歯科保健指導のプレゼンテーション</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="c-label c-label--theme-dental">必修分野</span></td>
                    <td>必修科目</td>
                    <td class="o-cancel-first-child-gap">
                      <ul class="c-list c-list--theme-dental">
                        <li>臨床総合ゼミⅠ・Ⅱ・Ⅲ</li>
                        <li>卒業研究</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </section>
        <section>
          <ui-box data-theme="dental" data-heading-tag="h1" data-heading="新卒の有効求人倍率は驚きの20倍以上！">
            <div class="o-cancel-first-child-gap">
              <p>
                最終学年の夏から秋にかけて就職活動をし、年内に就職先が決まるのが理想！
                <br>大学病院や市・県の職員などからの求人は特に早いので、準備が必要です。
                <br>それでも超売り手市場！一般歯科医院なら、就職説明会の参加や医院見学などを行い、自分に合った職場を見つけることができます。
              </p>
              <ul class="c-list c-list--theme-dental">
                <li>医療機関（大学病院、総合病院など）</li>
                <li>一般歯科医院</li>
                <li>衛生医療企業</li>
                <li>歯科材料メーカー</li>
                <li>歯科衛生士学校教員</li>
                <li>保健所などの行政施設</li>
                <li>介護施設</li>
              </ul>
              <p>など</p>
              <section>
                <h1 class="c-heading-5 c-heading-5--theme-dental">就職も先生全員でサポートします！</h1>
                <p>
                  国家試験のため就職活動は秋頃からの開始になりますが、求人は全国各地にあり、学生の希望を聞いた上で、求人側の要望を参考に就職指導の指針としています。
                  <br>高い合格率から歯科医院の先生方の信頼も厚く、就職につながるよう先生や職員がサポートします。
                </p>
              </section>
            </div>
          </ui-box>
        </section>
      </div>
    </div>
  </div>
  <script>
    export default {
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-atsugi-dental-subject>