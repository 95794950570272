<page-atsugi-dental-open-campus>
  <section>
    <ui-heading data-tag="h1" data-theme="dental">
      <small>2023年度 厚木総合専門学校<br>歯科衛生学科</small>
      <br>オープンキャンパス
    </ui-heading>
    <p>1回目から8回目までのオープンキャンパスでは在学生の声が生で聞けるチャンス！<br>是非、この機会にご参加ください！</p>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-dental">オープンキャンパス日程表</h1>
      <p>オープンキャンパスは 9:30 ～ 12:00 で行いますので <b class="c-strong c-strong--theme-dental">お時間までにご来校ください</b></p>
      <div class="o-spacer"></div>
      <div class="o-items-center">
        <img src="/img/atsugi-dental-open-campus-2022.png?2023-12-28" alt="" width="480">
      </div>
      <table class="c-table c-table--keep-layout o-space">
        <thead>
          <tr>
            <th></th>
            <th>開催日</th>
            <th>テーマ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th class="narrow">第一回</th>
            <td>2024年4月13日(土)</td>
            <td>歯についた歯石　うまく取れるかな？！</td>
          </tr>
          <tr>
            <th class="narrow">第二回</th>
            <td>2024年5月18日(土)</td>
            <td>歯磨き粉を作ってみよう！</td>
          </tr>
          <tr>
            <th class="narrow">第三回</th>
            <td>2024年6月15日(土)</td>
            <td>仮封って何だろう？やってみよう！</td>
          </tr>
          <tr>
            <th class="narrow">第四回</th>
            <td>2024年7月13日(土)</td>
            <td>歯についた歯石 うまく取れるかな？！</td>
          </tr>
        </tbody>
      </table>
    </section>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-dental">当日の持ち物</h1>
      <ul class="c-list c-list--theme-dental c-content">
        <li>上履き、またはスリッパ</li>
        <li>筆記用具</li>
      </ul>
    </section>
    <section>
      <h1 class="c-heading-4 c-heading-4--theme-dental">お申し込み方法</h1>
      <div class="c-content">
        <p>各日程前日までに、下記の申し込みフォームよりお申し込みください。</p>
        <div class="o-spacer"></div>
        <div class="o-layout o-layout--main-axis-center o-layout--wrap">
          <div>
            <ui-button data-href="https://www.school-go.info/f9z653/atsugi/form.php?fno=76&fsno=1&openExternalBrowser=1"
              data-target="_blank" data-class="theme-dental">オープンキャンパスお申し込み</ui-button>
          </div>
          <div if="{false}">
            <ui-button
              data-href="https://www.school-go.info/f9z653/atsugi/form.php?fno=125&fsno=1&openExternalBrowser=1"
              data-target="_blank" data-class="theme-design">オンライン説明会お申し込み</ui-button>
          </div>
        </div>
      </div>
      <div class="o-space" if="{false}">
        <table class="c-table">
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">お名前</div>
                <div>
                  <span class="c-label c-label--theme-dental">必須</span>
                </div>
              </div>
            </th>
            <td><input type="text" class="c-input-text"></td>
          </tr>
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">フリガナ</div>
                <div>
                  <span class="c-label c-label--theme-dental">必須</span>
                </div>
              </div>
            </th>
            <td><input type="text" class="c-input-text"></td>
          </tr>
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">メールアドレス</div>
                <div>
                  <span class="c-label c-label--theme-dental">必須</span>
                </div>
              </div>
            </th>
            <td><input type="email" class="c-input-text"></td>
          </tr>
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">参加日</div>
                <div>
                  <span class="c-label c-label--theme-dental">必須</span>
                </div>
              </div>
            </th>
            <td>
              <select name="date">
                <option value="">選択してください</option>
                <option each="{date in dateList}" value="{date}">{date}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">同伴者</div>
                <div>
                  <span class="c-label c-label--theme-dental">必須</span>
                </div>
              </div>
            </th>
            <td>
              <select name="" id="">
                <option value="">選択してください</option>
                <option value="1名">1名</option>
                <option value="2名">2名</option>
                <option value="2名以上">2名以上</option>
                <option value="なし">なし</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>
              <div class="o-layout">
                <div class="o-size-fill">電話番号</div>
              </div>
            </th>
            <td><input type="tel" class="c-input-text"></td>
          </tr>
          <tr>
            <th>備考</th>
            <td>
              <textarea class="c-textarea" cols="30" rows="5"></textarea>
            </td>
          </tr>
        </table>
        <div class="o-items-center o-space">
          <ui-button data-tag="button" data-class="theme-dental">入力内容を確認する</ui-button>
        </div>
      </div>
    </section>
  </section>
  <script>
    export default {
      dateList: [
        '2022年4月16日(土)',
        '2022年5月14日(土)',
        '2022年6月11日(土)',
        '2022年7月9日(土)',
        '2022年8月4日(木)',
        '2022年8月26日(金)',
        '2022年9月17日(土)',
        '2022年10月8日(土)',
        '2022年11月12日(土)',
        '2022年12月17日(土)',
        '2023年1月14日(土)',
        '2023年2月4日(土)',
        '2023年3月18日(土)',
      ],
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-atsugi-dental-open-campus>