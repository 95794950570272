<suzuki-nav>
  <school-nav data-items="{ navs }" data-where="{ props.dataWhere }" data-current="{ props.dataCurrent }"></school-nav>
  <script>
    export default function SuzukiNav() {
      return {
        navs: [
          {
            icon: 'home',
            title: 'ホーム',
            href: '/',
          },
          {
            icon: 'grid',
            title: '学校案内',
            href: '/school',
          },
          {
            icon: 'history',
            title: '学園の歩み',
            href: '/history',
          },
          {
            icon: 'bell',
            title: 'お知らせ',
            href: '/news',
          },
          {
            icon: 'book',
            title: '資料請求',
            href: '/request',
          },
          {
            icon: 'mail',
            title: 'お問い合わせ',
            href: '/contact',
          },
          {
            icon: 'lock',
            title: '個人情報のお取り扱いについて',
            href: '/policy',
          },
        ]
      }
    }
  </script>
</suzuki-nav>