<page-atsugi-design-subject>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="design">2つのコース</ui-heading>
          <h2 class="c-heading-4 c-heading-4--theme-design">多様性に対応した少人数制で、一人ひとりの個性に合わせたきめ細やかな学びの場を提供しています。</h2>
        </div>
        <div>
          <div class="o-layout o-layout--fill-equal">
            <div class="o-items-center">
              <a class="o-block-link o-items-center c-circle-more-link c-circle-more-link--theme-design"
                href="/atsugi/design/subject/it">
                <photo-and-title data-theme="design" data-photo="/img/atsugi-design-it.webp">
                  ITコース
                </photo-and-title>
              </a>
            </div>
            <div class="o-items-center">
              <a class=" o-block-link o-items-center c-circle-more-link c-circle-more-link--theme-design"
                href="/atsugi/design/subject/fashion">
                <photo-and-title data-theme="design" data-photo="/img/atsugi-design-fashion.webp">
                  ファッションコース
                </photo-and-title>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div>
      <ui-heading data-tag="h1" data-theme="design">取得可能な資格</ui-heading>
      <div class="o-layout o-layout--fill-equal o-layout--column-in-mobile">
        <div>
          <h2 class="c-heading-4 c-heading-4--theme-design">情報系の資格</h2>
          <ul class="c-list c-list--theme-design">
            <li>基本情報技術者試験（FE）</li>
            <li>ITパスポート（iパス）</li>
            <li>Microsoft Office Specialist</li>
            <li>情報検定（J検）</li>
            <li>情報処理技能検定試験</li>
            <li>Webデザイナー検定</li>
            <li>C言語プログラミング能力認定試験</li>
            <li>Java™言語プログラミング能力検定試験</li>
          </ul>
        </div>
        <div>
          <h2 class="c-heading-4 c-heading-4--theme-design">ファッション系の資格</h2>
          <ul class="c-list c-list--theme-design">
            <li>ファッション・ビジネス能力検定</li>
            <li>洋裁技術認定試験</li>
            <li>パターンメーキング技術検定</li>
            <li>ファッション販売能力検定</li>
            <li>ファッション色彩能力検定</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <script>
    export default {
      onMounted() {
        this.scrollToHashedElement()
      }
    }
  </script>
</page-atsugi-design-subject>