<page-kanagawa-highschool-industrial>
  <div class="o-layout o-layout--column">
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="highschool">特徴</ui-heading>
        <div class="c-content c-editor-content c-editor-content--theme-highschool">
          <p>
            工業技術学科は、<b>1年次は一般教科、電気・電子の基礎や電気基礎実習を学び、2年次から、<a href="#electric-course"
                class="c-link c-link--theme-highschool">電気コース</a>で電気工事士の資格取得を目指し学んでいきます。</b>
          </p>
          <p>
            新しい技術に対応できる基礎技術者の育成を目指し、現在の電気・電子産業界の技術者ニーズに見合った人材を育成するために、基礎から応用に向け判りやすく丁寧な指導を心がけています。
          </p>
          <p>
            3年間にできるだけ多くの資格・検定を取得するために、通常の授業のほか、一月前から放課後補習を行い、難易度の高い資格・検定の取得が可能となり、就職活動の際に有利となっています。
            <br>また、大学・短大や専門学校への進学もきめ細やかな指導が得られ、自信をもって社会へ巣立てるよう十分な配慮を行っています。
          </p>
        </div>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="highschool">教育方針</ui-heading>
        <div class="c-content c-editor-content c-editor-content--theme-highschool">
          <p>それぞれのコースではキャリア教育を中心に据えて、実社会で通用する技術と技能が学べるように心がけています。</p>
          <p>どのコースでも安全教育に力を入れ、自分を大切にすることや、環境や社会を大切にする心を培っていきます。</p>
          <ul class="c-note-list">
            <li>単なる技術者の養成ではなく、人づくりを考えた技術教育をしていきます。</li>
          </ul>
        </div>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="highschool">カリキュラム</ui-heading>
        <div class="c-content c-editor-content c-editor-content--theme-highschool">
          <p>
            「学ぶことは新鮮な驚きから始まる」と言われています。
          </p>
          <p>どうしたらあなたが「やる気」を持ち、資格・検定取得に向けて、希望に満ちた学校生活が送れるようになるのか、そうした創意工夫を学習指導の場に持ち込んだのが各コースのカリキュラムです。</p>
          <p>誰にも判るような授業を心がけ、実習や補習を通じて1人ひとりに技術者として基礎をしっかりと理解してもらいます。</p>
          <p>先生の熱意を汲み取りながら、興味のある分野で自分の力を伸ばしてください。</p>
          <p>資格・検定取得には全員が参加し、競い合い助け合いながら全員の合格を目指します。</p>
        </div>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="highschool">電気コース</ui-heading>
        <div class="o-spacer"></div>
        <p>
          電気コースは、配線工事などの基礎知識や技術を学習するとともに、電気機器などの保守に必要な技術を学んでいくコースです。
          <br>この分野において、本校電気コースは神奈川県内で唯一の電気工事士養成施設です。
        </p>
        <p>第2種電気工事士の資格は、3年次の12月学校内試験が行われています。（きちんとカリキュラムをこなせば受験した生徒の100％が合格します）</p>
        <p>また、実習では毎回違う課題が与えられ、点灯試験・動作を確認します。</p>
        <div class="o-spacer"></div>
        <ui-box data-heading="学習内容の一例（2年次）" data-heading-tag="h4" data-theme="highschool">
          <div class="o-layout o-layout--gap-large o-layout--main-axis-center">
            <div class="o-cancel-first-child-gap">
              <h5 class="c-heading-6 c-heading-6--theme-highschool">専門教科</h5>
              <ul class="c-list c-list--theme-highschool">
                <li>配電、配線設計</li>
                <li>電気材料</li>
                <li>電気法規</li>
                <li>電気機器</li>
                <li>施工方法</li>
              </ul>
            </div>
            <div class="o-cancel-first-child-gap">
              <h5 class="c-heading-6 c-heading-6--theme-highschool">技能教科</h5>
              <ul class="c-list c-list--theme-highschool">
                <li>電気工事実習</li>
                <li>製図(電気製図)</li>
              </ul>
            </div>
          </div>
        </ui-box>
        <ul class="c-note-list">
          <li>その他一般教養、語学、体育などの授業を行います。</li>
        </ul>
      </section>
    </div>
  </div>
  <script>
    export default {
      state: {
        currentTab: 'denki',
        disableIntersection: false
      },
      clickTabHandler(slug) {
        this.state.disableIntersection = true
        this.state.currentTab = slug
        this.update()
        window.requestAnimationFrame(() => {
          window.location.href = `#${slug}`
        })
      },
      intersectionHandler(slug) {
        this.state.currentTab = slug
        this.update()
      },
      onMounted() {
        this.scrollToHashedElement()
      },
      onUpdated() {
        if (this.state.disableIntersection) {
          if (this.timeoutId) window.clearTimeout(this.timeoutId)
          this.timeoutId = window.setTimeout(() => {
            this.update({ disableIntersection: false })
          }, 1000)
        }
      }
    }
  </script>
</page-kanagawa-highschool-industrial>