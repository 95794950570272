<page-suzuki>
  <suzuki-header></suzuki-header>
  <page-suzuki-home if="{ isHome() }" data-news="{news}"></page-suzuki-home>
  <template if="{ !isHome() }">
    <div class="o-layout o-layout--column">
      <template if="{ isPage('school') }">
        <page-header data-title="学校案内" data-photo="/img/photo-3.webp"></page-header>
        <div>
          <div class="o-container">
            <page-suzuki-school></page-suzuki-school>
          </div>
        </div>
      </template>
      <template if="{ isPage('history') }">
        <page-header data-title="学園の歩み" data-photo="/img/photo-2.webp"></page-header>
        <div>
          <div class="o-container">
            <page-suzuki-history></page-suzuki-history>
          </div>
        </div>
      </template>
      <template if="{ isPage('policy') }">
        <page-header data-title="個人情報保護方針" data-photo="/img/privacy-title.svg"></page-header>
        <div>
          <div class="o-container">
            <page-suzuki-policy></page-suzuki-policy>
          </div>
        </div>
      </template>
      <template if="{ isPage('news') }">
        <page-header data-title="お知らせ" data-photo="/img/news-title.svg"></page-header>
        <div>
          <div class="o-container">
            <page-suzuki-news data-news="{news}"></page-suzuki-news>
          </div>
        </div>
      </template>
      <template if="{ isPage('request') }">
        <page-header data-title="資料請求" data-photo="/img/photo-4.webp"></page-header>
        <div>
          <div class="o-container">
            <page-suzuki-request></page-suzuki-request>
          </div>
        </div>
      </template>
      <template if="{ isPage('contact') }">
        <page-header data-title="お問い合わせ" data-photo="/img/contact-title.svg"></page-header>
        <div>
          <div class="o-container">
            <page-suzuki-contact></page-suzuki-contact>
          </div>
        </div>
      </template>
    </div>
  </template>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column">
    <section class="c-footer-nav-block">
      <div class="o-container">
        <suzuki-nav data-where="footer"></suzuki-nav>
      </div>
    </section>
    <section>
      <group-footer></group-footer>
    </section>
  </div>
  <suzuki-menu></suzuki-menu>
  <script>
    // @ts-check
    export default function PageSuzuki() {
      return {
        /**
         * @typedef {Object} news
         * @property {string} title
         * @property {string} date
         * @property {string} slug
         * @property {string} content
         * @property {string} [photo]
         */
        /**
         * @type {news[]} news
         */
        news: [
          {
            title: '冬季休業期間のお知らせ',
            date: '2023年12月26日',
            slug: 'notice-of-winter-holidays-2023',
            content: '<p>【資料請求について】<br>年内の資料発送は12月26日（火）にて終了になります。<br>年明けの発送は1月9日（火）からです。<br>【出願、体験入学等の各種お問合せについて】<br>12月27日（水）～ 1月8日（月）は冬季休業になります。<br>期間中、お電話等の受付もお休みさせて頂きます。</p><p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '豊友祭2023を開催します',
            date: '2023年10月14日',
            slug: 'fes-2023',
            content: '<p>今年も豊友祭（学園祭）行います。<br>楽しいイベントが盛り沢山！是非遊びに来てください！</p><ul><li>日程 2023/10/28（土）</li><li>時間 10:00〜15:00</li><li>会場 神奈川県厚木市恩名1-17-18</li></ul><p><a href="/img/2023-fes-poster.pdf" target="_blank"><img src="/img/fes-2023.webp" alt="豊友祭2023ポスター"></a></p>',
            photo: '/img/news-fes-2023.webp',
          },
          {
            title: '夏季休暇期間について',
            date: '2023年8月10日',
            slug: '2022-2023',
            content: '<p>8/11（金）～8/16（水）まで夏季休暇に伴い、頂きましたお問合せや資料請求に関しましては8/17（木）から順次ご対応させて頂きます。<br>皆様には大変ご迷惑お掛けいたしますが、ご理解賜りますよう宜しくお願い致します。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '年末年始の資料請求・お問合せについて',
            date: '2022年12月26日',
            slug: '2022-2023',
            content: '<p> <b>【資料請求について】</b> 年内の資料発送は12月26日（金）にて終了になります。<br> 年明けの発送は1月6日（金）からです。 </p> <p> <b>【出願、体験入学等の各種お問合せについて】</b> 12月27日（火）～ 1月5日（木）は年末年始は休業になります。<br> 期間中、お電話等の受付もお休みさせて頂きます。 </p> <p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '11月5日開催のあつぎ鮎まつりに参加します',
            date: '2022年10月26日',
            slug: 'ayumatsuri-2022',
            content: '<p>延期されていた「第76回あつぎ鮎まつり」の開催日が11月5日に決定しました。<br>ファッションショーは下記の時間に開催致します。</p><p>日程：2022/11/5（土）<br>時間：10:30〜11:00<br>会場：厚木中央公園　特設ステージ</p><p>マドレーヌ販売、チョークアート体験、<br>カークラフト作成等のイベントもありますので<br>是非、遊びに来て下さい！</p><p><img src="/img/ayumatsuri-2022.png" alt="鈴木学園3校合同 和装アレンジファッションショー"></p>',
            photo: '/img/news-ayumatsuri-2022.png',
          },
          {
            title: '豊友祭2022を開催します',
            date: '2022年10月17日',
            slug: 'fes-2022',
            content: '<p>3年ぶりに豊友祭（学園祭）行います。<br>新型コロナ感染症拡大防止の為、ホールイベント、飲食の販売等は<br>小規模になりますが是非遊びに来てください！</p><p>日程 2022/10/29（土）<br>時間 10:00〜16:00<br>会場 神奈川県厚木市恩名1-17-18</p><p><a href="/img/2022-fes-poster.pdf" target="_blank"><img src="/img/fes-2022.png" alt="豊友祭 冷和4年10月29日（土）10:00〜16:00"></a></p>',
            photo: '/img/news-2022-fes.png',
          },
          {
            title: 'サイトリニューアルのお知らせ',
            date: '2022年2月28日',
            slug: 'site-renewal-2022',
            content: 'この度、Webサイトを全面リニューアルいたしました。',
            photo: '/img/news-renewal-suzuki.png',
          },
          {
            title: '新型コロナウイルス感染予防について',
            date: '2022年2月28日',
            slug: 'covid-19',
            content: '- 定期的な消毒、換気、教職員のマスク着用など感染症対策に配慮しながら実施しています。\n- ご来校時に検温をお願いしています。熱がある場合は恐れ入りますが、参加をご遠慮頂いております。\n- 新型コロナウイルス感染拡大防止の為、予定が変更になる場合がございます。',
            photo: '/img/news-covid-19.webp',
          },
          {
            title: '体験入学・オープンキャンパスについて',
            date: '2021年4月1日',
            slug: 'open-campus-2021',
            content: '2021年度の各校・各学科の体験入学・オープンキャンパス実施予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [厚木中央高等学校 体験入学ページ](/chuo/open-campus)\n- [神奈川総合大学校 高等課程 体験入学ページ](/kanagawa/highschool/open-campus)\n- [神奈川総合大学校 自動車整備科 オープンキャンパスページ](/kanagawa/car/open-campus)\n- [厚木総合専門学校 調理学科 オープンキャンパスページ](/atsugi/cook/open-campus)\n- [厚木総合専門学校 生活デザイン学科 オープンキャンパスページ](/atsugi/design/open-campus)\n- [厚木総合専門学校 歯科衛生学科 オープンキャンパスページ](/atsugi/dental/open-campus)',
            photo: '/img/photo-3.webp',
          },
        ],
        /** @type {string[]} */
        pathArray: [],
        /** @return {boolean} */
        isHome() {
          return this.pathArray.length === 0
        },
        /**
         * @return {boolean}
         */
        isPage(slug) {
          /** @type {string} */
          const pathname = window.location.pathname.match(/^\/(.*)/)[1].replace(/(.*)\/$/, '$1')
          return pathname === slug
        },
        onBeforeMount() {
          this.pathArray = this.getPathNameArray()
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-suzuki>