<page-atsugi-cook-campus>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="cook">一年間の流れ</ui-heading>
          <div class="c-content">
            <div class="c-accent-block c-accent-block--theme-cook o-cancel-first-child-gap o-space">
              <p>実際の学校の様子を見てみたいという方は、ぜひ一度オープンキャンパスにご参加ください。</p>
              <p class="o-items-center">
                <ui-button data-href="/atsugi/cook/open-campus" data-class="theme-cook">オープンキャンパス
                </ui-button>
              </p>
            </div>
          </div>
        </div>
        <div>
          <event-calendar data-items="{ events }" data-slug="cook"></event-calendar>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiCookCampus() {
      return {
        /**
         * @typedef {object} eventObject
         * @property {string} title
         * @property {string} photo
         * @property {string[]} events
         */
        /**
         * @type {eventObject[]}
         */
        events: [
          {
            title: '4月',
            photo: '/img/atsugi-month-header-4.webp',
            events: [
              '鈴木学園3校合同入学式',
            ],
          },
          {
            title: '5月',
            photo: '/img/cook-month-header-5.webp',
            events: [
              '春の遠足（ディズニーリゾート）',
            ],
          },
          {
            title: '6月',
            photo: '/img/cook-month-header-6.webp',
            events: [
              '1学期末テスト（実技テスト含む）',
            ],
          },
          {
            title: '7月',
            photo: '/img/cook-month-header-7.webp',
            events: [
              '夏季企業研修（一般コースのみ）',
              '三者面談',
            ],
          },
          {
            title: '8月',
            photo: '/img/cook-month-header-8.webp',
            events: [
              '夏休み',
            ],
          },
          {
            title: '9月',
            photo: '/img/cook-month-header-9.webp',
            events: [
              '食育講座',
            ],
          },
          {
            title: '10月',
            photo: '/img/cook-month-header-10.webp',
            events: [
              '3校合同学園祭',
            ],
          },
          {
            title: '11月',
            photo: '/img/cook-month-header-11.webp',
            events: [
              'テーブルマナー',
            ],
          },
          {
            title: '12月',
            photo: '/img/cook-month-header-12.webp',
            events: [
              '期末試験',
              'オリジナルクリスマスケーキ作り',
              'おせち料理',
              '冬休み',
            ],
          },
          {
            title: '1月',
            photo: '/img/cook-month-header-1.webp',
            events: [
              '技術考査試験',
            ],
          },
          {
            title: '2月',
            photo: '/img/cook-month-header-2.webp',
            events: [
              '卒業試験（実技含む）',
              '卒業作品展',
            ],
          },
          {
            title: '3月',
            photo: '/img/cook-month-header-3.webp',
            events: [
              '卒業式',
            ],
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-cook-campus>