<page-atsugi-design-it>
  <div class="o-layout o-layout--column">
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="design">基礎からプロの技術まで、2年後の進路選択の幅を広げます。</ui-heading>
        <p>
          本校は少人数制教育を採用し、初めて触れる方でも、基礎知識からプロの技まで、しっかりと学ぶことができます。
          <br>一人ひとりの適性や課題に合わせてきめ細かに指導し、次世代を担う人材育成に全力を注いでいます。
        </p>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="design">教育方針</ui-heading>
        <p>社会に出てから役立つ、ExcelやWordなどのビジネスアプリをはじめ、Webデザイン、イラストレーターでの画像編集、C言語やJavaなどのプログラミング言語などを習得できます。</p>
        <p>また、国家資格のITパスポートやマイクロソフトオフィススペシャリスト（MOS）などの検定試験対策なども時間をかけて丁寧に指導しています。</p>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="design">カリキュラム</ui-heading>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-design">専門科目</h1>
          <table class="c-table o-space">
            <tr>
              <th>コンピュータ概論</th>
              <td>コンピュータシステムの機能と構成、情報処理に関する基礎知識、インターネットのしくみについて学びます。</td>
            </tr>
            <tr>
              <th>情報システム</th>
              <td>ネットワーク、データベース、およびコンピュータサイエンスを学ぶことにより、次世代のコンピュータ技術を体感します。</td>
            </tr>
            <tr>
              <th>ハードウェア</th>
              <td>コンピュータのしくみ、データの表現、動作原理などの基礎を学びます。</td>
            </tr>
            <tr>
              <th>ソフトウェア</th>
              <td>OS、プログラム言語、言語プロセッサなどのソフトウェアの基礎を学びます。</td>
            </tr>
            <tr>
              <th>プログラムの設計</th>
              <td>プログラムの考え方、アルゴリズム、設計手法などを学習します。</td>
            </tr>
            <tr>
              <th>プログラムの実習</th>
              <td>制御、グラフィック、ネットワークのためのいろいろなプログラム言語を学びます。</td>
            </tr>
            <tr>
              <th>Webデザイン</th>
              <td>ホームページ作成の基本から、応用的なものまで作成できるようになります。</td>
            </tr>
            <tr>
              <th>マルチメディア</th>
              <td>CG作成や動画編集、音楽編集などに取り組むことにより、マルチメディアを体験できます。</td>
            </tr>
            <tr>
              <th>課題研究</th>
              <td>自分の好きな課題を選択肢、取り組むことができます。</td>
            </tr>
          </table>
        </section>
        <section>
          <h1 class="c-heading-4 c-heading-4--theme-design">一般教養科目</h1>
          <table class="c-table o-space">
            <tr>
              <th>国語・英語</th>
              <td>ビジネスで必要な文章の書き方や簡単な英会話を、初めての方でも安心して学べます。</td>
            </tr>
            <tr>
              <th>CGデザイン</th>
              <td>アプリケーションソフトのイラストレーターの使い方からデザインの方法を学びます。</td>
            </tr>
            <tr>
              <th>ビジネス</th>
              <td>就職試験に向けて対策や、履歴書の作成・面接の受け方を学びます。</td>
            </tr>
            <tr>
              <th>ビジネスアプリケーション</th>
              <td>ExcelやWordなどのビジネスアプリの使い方を、資格取得を目指し基礎から学びます。</td>
            </tr>
          </table>
        </section>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiDesignIt() {
      return {
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-design-it>