<slide-in>
  <div class="c-slide-in" data-is-enter="{ state.isEnter }">
    <slot />
    <div class="{ getCoverClass() }" data-ref="cover"></div>
  </div>
  <script>
    // @ts-check
    export default function SlideIn() {
      return {
        state: {
          /**
           * @type {boolean}
           */
          isEnter: false,
        },
        handleShow(entries) {
          if (entries[0].isIntersecting && !this.state.isEnter) {
            this.state.isEnter = true
            window.requestAnimationFrame(() => {
              this.update()
            })
          }
        },
        getCoverClass() {
          const result = ['c-slide-in__cover']
          if (this.props.dataTheme) result.push(`c-slide-in__cover--theme-${this.props.dataTheme}`)
          return result.join(' ')
        },
        onMounted() {
          /** @type {HTMLElement} */
          this.element = this.$('[data-ref="cover"]')

          window.requestAnimationFrame(() => {
            if (this.element.getBoundingClientRect().y <= document.documentElement.clientHeight) {
              this.state.isEnter = true
              this.update()
            }
            else {
              /**
               * @type {object} options
               */
              this.options = { threshold: [0.5, 1] }

              this.observer = new IntersectionObserver(this.handleShow, this.options)
              this.observer.observe(this.element)
            }
          })
        },
      }
    }
  </script>
</slide-in>