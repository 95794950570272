<car-nav>
  <school-nav data-items="{ navs }" data-slug="car" data-where="{ props.dataWhere }"
    data-current="{ props.dataCurrent }"></school-nav>
  <script>
    export default function CarNav() {
      return {
        navs: [
          {
            icon: 'home',
            title: 'ホーム',
            href: '/kanagawa/car/',
          },
          {
            icon: 'study',
            title: '学科・教科・取得資格',
            href: '/kanagawa/car/subject',
          },
          {
            icon: 'school',
            title: 'キャンパスライフ',
            href: '/kanagawa/car/campus',
          },
          {
            icon: 'message',
            title: '先輩紹介・教師からのメッセージ',
            href: '/kanagawa/car/message',
          },
          {
            icon: 'compass',
            title: 'オープンキャンパス',
            href: '/kanagawa/car/open-campus',
          },
          {
            icon: 'file',
            title: '募集要項',
            href: '/kanagawa/car/application',
          },
          {
            icon: 'questions',
            title: 'よくあるご質問',
            href: '/kanagawa/car/qa',
          },
          {
            icon: 'bell',
            title: 'お知らせ',
            href: '/kanagawa/car/news',
          },
          {
            icon: 'map',
            title: 'アクセス',
            href: '/school#map-2',
          },
          {
            icon: 'book',
            title: '資料請求',
            href: '/request',
          },
          {
            icon: 'mail',
            title: 'お問い合わせ',
            href: '/contact',
          },
          {
            icon: 'lock',
            title: '個人情報のお取り扱いについて',
            href: '/policy',
          },
        ]
      }
    }
  </script>
</car-nav>