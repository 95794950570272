<raw-html>
  <span data-ref="content"></span>

  <script>
    // @ts-check
    export default {
      setContent() {
        this.$('[data-ref="content"]').innerHTML = String(this.props.dataContent)
      },
      onMounted() {
        this.setContent()
      },
      onUpdated() {
        this.setContent()
      },
    }
  </script>
</raw-html>