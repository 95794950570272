<page-chuo-subject>
  <div class="o-layout o-layout--column">
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="chuo">厚木中央高等学校の特色</ui-heading>
        </div>
        <div>
          <div class="o-items-center">
            <school-guide></school-guide>
          </div>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="chuo">特色</ui-heading>
        </div>
        <div>
          <p>
            厚木中央高等学校は、平成13年（2001年）創立の通信制高校です。
            <br>通信制というと、年に何回かのスクーリングと家での学習、レポート等の添削を思い浮かべる人も多いかもしれませんが、本校は、週5日（月曜日から金曜日まで）登校する全日制スタイルの学校で、「普通科」「工業科」の2つの学科を持ち、3年で卒業できます。
          </p>
          <p>
            変わっているのは、同じキャンパス内に併設されている、専門学校神奈川総合大学校と連携しているという点です。これによって普通の高校生活を送りながら、専修学校の技術も習得できるというメリットを持ち合わせています。
          </p>
          <p>
            また、厚木総合専門学校の調理学科に同時に入学することも可能で、1年目に調理師免許を取得することもできます。
          </p>
          <p>
            さまざまな資格・検定が設けてある上に、カリキュラムに資格・検定をサポートする授業もあるため、普通の高校生活を送る中で、スムーズに難易度の高い資格・検定にチャレンジすることができます。
            <br>実際、多くの学生が3年間の間に5〜7つの資格を取って卒業していきます。
          </p>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="chuo">取得可能な資格検定</ui-heading>
        </div>
        <div>
          <star-list data-items="{ list }"></star-list>
        </div>
      </section>
    </div>
    <div>
      <section class="o-layout o-layout--column">
        <div>
          <ui-heading data-tag="h1" data-theme="chuo">履修教科</ui-heading>
        </div>
        <div>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-chuo">共通教科科目</h1>
            <div class="c-content">
              <ul class="c-list c-list--layout-flow c-list--theme-chuo">
                <li each="{item in list2}">{item}</li>
              </ul>
            </div>
          </section>
        </div>
        <div>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-chuo">工業科選択専門科目</h1>
            <div class="c-content">
              <ul class="c-list c-list--layout-flow c-list--theme-chuo">
                <li each="{item in list3}">{item}</li>
              </ul>
            </div>
          </section>
        </div>
        <div>
          <section>
            <h1 class="c-heading-4 c-heading-4--theme-chuo">普通科選択専門教科</h1>
            <div class="c-content">
              <ul class="c-list c-list--layout-flow c-list--theme-chuo">
                <li each="{item in list4}">{item}</li>
              </ul>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageChuoSubject() {
      return {
        /**
         * @typedef {object} listObject
         * @property {string} title
         * @property {string} subTitle
         * @property {string} [description]
         */
        /**
         * @type {listObject[]}
         */
        list: [
          {
            title: '第二種電気工事士',
            subTitle: '(経済産業省)',
          },
          {
            title: '第二級アナログ通信／第二級デジタル通信 工事担任者',
            subTitle: '(1科目免除)(総務大臣)',
            description: '上記は技能連携生、または専門学校神奈川総合大学校高等課程専修生が取得可能となり、厚木中央高等学校工業科だけでは取得できません。',
          },
          {
            title: '日本漢字能力検定',
            subTitle: '日本漢字能力検定',
          },
          {
            title: '実用英語技能検定',
            subTitle: '((公財)日本英語検定協会)',
          },
          {
            title: '秘書検定',
            subTitle: '((公財)実務技能検定協会・文部科学省後援)',
          },
          {
            title: 'ビジネス能力検定(B検)ジョブパス',
            subTitle: '((一財)職業教育・キャリア教育財団・文部科学省後援)',
          },
          {
            title: 'パソコン利用技術検定',
            subTitle: '(全国工業校長協会)',
          },
          {
            title: 'レタリング技能検定',
            subTitle: '((公財)国際文化カレッジ・文部科学省後援)',
          },
          {
            title: 'トレース技能検定',
            subTitle: '((一財)中央工学校生涯学習センター・文部科学省後援)',
          },
          {
            title: '服飾技術検定',
            subTitle: '(日本ファッション教育振興会)',
          },
          {
            title: 'ファッションビジネス能力検定',
            subTitle: '(同上)',
          },
          {
            title: 'パターンメーキング技術検定',
            subTitle: '(同上)',
          },
          {
            title: 'ガス溶接技能講習',
            subTitle: '(厚生労働省)',
          },
          {
            title: '調理師免許',
            subTitle: '(厚生労働省)',
            description: '1年次 厚木総合専門学校 調理学科に同時入学することが条件になります',
          },
          {
            title: 'フォークリフト特別教育',
            subTitle: '(都道府県労働局長登録教習機関・厚生労働省後援)',
          },
        ],
        /**
         * @type {string[]}
         */
        list2: [
          '国語 / 現代の国語・言語文化',
          '地理歴史 / 地理総合・歴史総合',
          '公民 / 公共',
          '数学 / 数学Ⅰ',
          '理科 / 科学と人間生活・生物基礎',
          '保健体育 / 体育・保健',
          '芸術 / 美術Ⅰ',
          '外国語 / 英語コミュニケーションⅠ',
          '家庭 / 家庭基礎',
          '情報 / 情報Ⅰ',
          '総合的な探求の時間/総合'
        ],
        /**
         * @type {string[]}
         */
        list3: [
          '課題研究',
          '実習',
          '製図',
          '工業情報数理',
          '自動車工学',
          '電気回路',
          '電気機器',
          '電力技術',
          '電子技術',
        ],
        /**
         * @type {string[]}
         */
        list4: [
          '家庭総合',
          '生活産業情報',
          'フードデザイン',
          '食品衛生',
          '調理',
          '公衆衛生',
          '栄養',
          '食品',
          'ファッションデザイン',
          '課題研究',
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-chuo-subject>