<page-chuo>
  <school-header data-slug="chuo" data-is-school="{ true }" data-school-name="厚木中央高等学校"></school-header>
  <template if="{ isHome() }">
    <div class="c-school-hero">
      <div class="c-school-hero__heading">
        <slide-in data-theme="chuo">
          <div class="c-school-hero__heading-text c-school-hero__heading-text--theme-chuo">
            <p>
              大事なのは「楽しく学ぶこと」。
              <br>ひとりひとりの個性を伸ばし、
              <br>夢へ羽ばたく力を育てます。
            </p>
          </div>
        </slide-in>
      </div>
      <div class="c-site-hero-photo c-site-hero-photo--chuo">
        <photo-slider data-slides="{slides}" data-theme="chuo"></photo-slider>
      </div>
    </div>
    <div class="c-home-content">
      <wave-separator class="c-home-content__wave" data-slug="chuo"></wave-separator>
      <div class="o-layout o-layout--column">
        <div class="c-message-text c-message-text--theme-chuo u-only-mobile">
          <slide-in data-theme="chuo">
            <p>
              大事なのは「楽しく学ぶこと」。
              <br>ひとりひとりの個性を伸ばし、
              <br>夢へ羽ばたく力を育てます。
            </p>
          </slide-in>
        </div>
        <div>
          <div class="o-container">
            <div class="o-spacer o-spacer--large-2 u-only-desktop"></div>
            <home-nav-items data-items="{ homeNavItems }" data-theme="chuo"></home-nav-items>
          </div>
        </div>

        <!-- 2023/4/26 八十田 add start -->
        <div class="wrap">
          <div class="float-left">
            <div class="youtube">
              <iframe width="300" height="225" src="https://www.youtube.com/embed/nMofmnoTtMw?controls=0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
          <div class="float-right">
            <div class="youtube">
              <iframe width="300" height="225" src="https://www.youtube.com/embed/8d_lwcq5m4s?controls=0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <!-- 2023/4/26 八十田 add end -->

        <section>
          <div class="o-container">
            <ui-heading data-tag="h1" data-theme="chuo">お知らせ一覧</ui-heading>
            <div class="o-layout o-layout--column o-layout--cross-axis-center">
              <div>
                <news-list data-slug="chuo" data-news="{ news }"></news-list>
              </div>
              <div class="o-items-center">
                <ui-button data-href="/chuo/news" data-class="theme-chuo">一覧を見る</ui-button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="o-spacer o-spacer--large-2"></div>
  </template>

  <!-- 2023/4/26 八十田 add start -->
  <!-- 2023/5/15 八十田 del start
  <a href="https://docs.google.com/forms/d/1dHxk2WYxLuN-J3g61iMTSZbAUmy7qAhm7n3QrP145gk/edit?pli=1">
    <picture class="floating-banner-y">
      <source srcset="/img/floating-banner-chuo-pc.png" media="(min-width: 640px)" />
      <img class="floating-banner-image" src="/img/floating-banner-chuo-sp.png" alt="">
    </picture>
  </a>
       2023/5/15 八十田 del end -->
  <!-- 2023/4/26 八十田 add end -->

  <template if="{ !isHome() }">
    <school-content-template if="{ isPage('subject') }" data-title="学校紹介" data-slug="chuo" data-current="/chuo/subject"
      data-photo="/img/photo-5.webp">
      <page-chuo-subject></page-chuo-subject>
    </school-content-template>
    <school-content-template if="{ isPage('campus') }" data-title="スクール<wbr>ライフ" data-slug="chuo"
      data-current="/chuo/campus" data-photo="/img/photo-6.webp">
      <page-chuo-campus></page-chuo-campus>
    </school-content-template>
    <school-content-template if="{ isPage('message') }" data-title="教師・先輩からのメッセージ" data-slug="chuo"
      data-current="/chuo/message" data-photo="/img/photo-7.webp">
      <page-chuo-message></page-chuo-message>
    </school-content-template>
    <school-content-template if="{ isPage('qa') }" data-title="よくあるご質問" data-slug="chuo" data-current="/chuo/qa"
      data-photo="/img/photo-8.webp">
      <page-chuo-qa></page-chuo-qa>
    </school-content-template>
    <school-content-template if="{ isPage('application') }" data-title="募集要項" data-slug="chuo"
      data-current="/chuo/application" data-photo="/img/photo-4.webp">
      <page-chuo-application></page-chuo-application>
    </school-content-template>
    <school-content-template if="{ isPage('open-campus') }" data-title="体験入学" data-slug="chuo"
      data-current="/chuo/open-campus" data-photo="/img/photo-3.webp">
      <page-chuo-open-campus></page-chuo-open-campus>
    </school-content-template>
    <school-content-template if="{ isPage('news') }" data-title="お知らせ" data-slug="chuo" data-current="/chuo/news"
      data-photo="/img/chuo-news-title.svg">
      <page-chuo-news data-news="{news}"></page-chuo-news>
    </school-content-template>
  </template>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column">
    <section class="c-footer-nav-block">
      <div class="o-container">
        <chuo-nav data-where="footer"></chuo-nav>
      </div>
    </section>
    <section>
      <group-footer></group-footer>
    </section>
  </div>
  <chuo-menu></chuo-menu>
  <script>
    // @ts-check
    export default function PageChuo() {
      return {
        /**
         * @typedef slide
         * @property {String} photo
         * @property {String} [position]
         */
        /**
         * @type {slide[]}
         */
        slides: [
          {
            photo: '/img/chuo-photo.webp',
            position: '50% 10%'
          },
          {
            photo: '/img/chuo-photo-2.webp',
            position: '100% 40%',
          },
          {
            photo: '/img/chuo-photo-3.webp',
          },
        ],
        /**
         * @typedef {object} nav
         * @property {string} icon
         * @property {string} title
         * @property {string} href
         */
        /**
         * @type {nav[]}
         */
        homeNavItems: [
          {
            icon: 'study',
            title: '学校紹介',
            href: '/chuo/subject'
          },
          {
            icon: 'school',
            title: 'スクール<wbr>ライフ',
            href: '/chuo/campus'
          },
          {
            icon: 'message',
            title: '教師・<wbr>先輩からの<wbr>メッセージ',
            href: '/chuo/message'
          },
          {
            icon: 'compass',
            title: '体験入学',
            href: '/chuo/open-campus'
          },
          {
            icon: 'file',
            title: '募集要項',
            href: '/chuo/application'
          },
          {
            icon: 'questions',
            title: 'よくあるご質問',
            href: '/chuo/qa'
          },
        ],
        news: [
          {
            title: '豊友祭2023を開催します',
            date: '2023年10月14日',
            slug: 'fes-2023',
            content: '<p>今年も豊友祭（学園祭）行います。<br>楽しいイベントが盛り沢山！是非遊びに来てください！</p><ul><li>日程 2023/10/28（土）</li><li>時間 10:00〜15:00</li><li>会場 神奈川県厚木市恩名1-17-18</li></ul><p><a href="/img/2023-fes-poster.pdf" target="_blank"><img src="/img/fes-2023.webp" alt="豊友祭2023ポスター"></a></p>',
            photo: '/img/news-fes-2023.webp',
          },
          {
            title: '夏季休暇期間について',
            date: '2023年8月10日',
            slug: '2022-2023',
            content: '<p>8/11（金）～8/16（水）まで夏季休暇に伴い、頂きましたお問合せや資料請求に関しましては8/17（木）から順次ご対応させて頂きます。<br>皆様には大変ご迷惑お掛けいたしますが、ご理解賜りますよう宜しくお願い致します。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: 'タウンニュースに掲載されました',
            date: '2023年3月3日',
            slug: '2023-03-03',
            content: '<p><a href="https://www.townnews.co.jp/0404/2023/03/03/667670.html">タウンニュース：初のピンクシャツデー</a></p><p>2月22日に「ピンクシャツデー」のイベントを行いました。</br>当日は多くの生徒、教職員がピンクのパーカー、シャツ、ネクタイを<br>着用してイベントを盛り上げてくれました。<img src="/img/news-chuo-PINKDAY2023.jpg" alt=""></p>',
            photo: '/img/news-chuo-PINKDAY2023.jpg',
          },
          {
            title: '2次募集について',
            date: '2023年3月1日',
            slug: '2023-03-01',
            content: '<p>本学園では2次募集を行います。詳細につきましては要項にてご確認をお願い致します。</p><ul><li><a href="/img/20230301.pdf">2次募集についての詳細PDF</a></li></ul>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '年末年始の資料請求・お問合せについて',
            date: '2022年12月26日',
            slug: '2022-2023',
            content: '<p> <b>【資料請求について】</b> 年内の資料発送は12月26日（金）にて終了になります。<br> 年明けの発送は1月6日（金）からです。 </p> <p> <b>【出願、体験入学等の各種お問合せについて】</b> 12月27日（火）～ 1月5日（木）は年末年始は休業になります。<br> 期間中、お電話等の受付もお休みさせて頂きます。 </p> <p>ご不便をおかけしますが、何卒ご了承ください。</p>',
            photo: '/img/news-suzuki-logo.svg',
          },
          {
            title: '学校説明会を開催します',
            date: '2022年11月17日',
            slug: 'open-campus-2022',
            content: '<p>中学生、保護者、先生を対象にした学校説明会を下記の日程で開催致します。<br>ぜひ奮ってご参加ください。 </p><ul><li>日程：11/19(土)、11/26(土)、12/3(土)</li><li>時間：10:00 〜</li><li>内容：学校説明、受験について、施設見学、個別相談等</li></ul><p>皆さまに安心して参加していただけるよう、感染予防体制を整えております。</p><p><a href="/img/open-campass-chuo-2022.pdf"><img src="/img/open-campass-chuo-2022.png" alt=""></a></p>',
            photo: '/img/news-open-campass-chuo-2022.png',
          },
          {
            title: '11月5日開催のあつぎ鮎まつりに参加します',
            date: '2022年10月26日',
            slug: 'ayumatsuri-2022',
            content: '<p>延期されていた「第76回あつぎ鮎まつり」の開催日が11月5日に決定しました。<br>ファッションショーは下記の時間に開催致します。</p><p>日程：2022/11/5（土）<br>時間：10:30〜11:00<br>会場：厚木中央公園　特設ステージ</p><p>マドレーヌ販売、チョークアート体験、<br>カークラフト作成等のイベントもありますので<br>是非、遊びに来て下さい！</p><p><img src="/img/ayumatsuri-2022.png" alt="鈴木学園3校合同 和装アレンジファッションショー"></p>',
            photo: '/img/news-ayumatsuri-2022.png',
          },
          {
            title: '豊友祭2022を開催します',
            date: '2022年10月17日',
            slug: 'fes-2022',
            content: '<p>3年ぶりに豊友祭（学園祭）行います。<br>新型コロナ感染症拡大防止の為、ホールイベント、飲食の販売等は<br>小規模になりますが是非遊びに来てください！</p><p>日程 2022/10/29（土）<br>時間 10:00〜16:00<br>会場 神奈川県厚木市恩名1-17-18</p><p><a href="/img/2022-fes-poster.pdf" target="_blank"><img src="/img/fes-2022.png" alt="豊友祭 冷和4年10月29日（土）10:00〜16:00"></a></p>',
            photo: '/img/news-2022-fes.png',
          },
          {
            title: '鈴木学園三校合同ファッションショー',
            date: '2022年9月16日',
            slug: 'pv-fashion-show-2022',
            content: '<p>8/5(金)開催の「鈴木学園三校合同ファッションショー」<br>【和洋折衷】和装アレンジファッションショーの様子をYouTubeにアップしました！</p><br><div class="c-video"><iframe width="560" height="315" src="https://www.youtube.com/embed/nMofmnoTtMw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
            photo: '/img/pv-fashion-show-2022.webp',
          },
          {
            title: '厚木中央高等学校のPR動画を公開しました。',
            date: '2022年7月4日',
            slug: 'pv',
            content: '<div class="c-video"><iframe width="560" height="315" src="https://www.youtube.com/embed/8d_lwcq5m4s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
            photo: '/img/news-chuo-pv.webp',
          },
          {
            title: '2022年度体験入学ついて',
            date: '2022年4月1日',
            slug: 'open-campus-2022',
            content: '2022年度体験入学予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [体験入学ページ](/chuo/open-campus)',
            photo: '/img/photo-3.webp',
          },
          {
            title: 'サイトリニューアルのお知らせ',
            date: '2022年2月28日',
            slug: 'site-renewal-2022',
            content: 'この度、Webサイトを全面リニューアルいたしました。',
            photo: '/img/news-renewal-chuo.png',
          },
          {
            title: '2021年度体験入学ついて',
            date: '2021年4月1日',
            slug: 'open-campus-2021',
            content: '2021年度体験入学予定を公開しています。  \n皆様ぜひご参加ください。\n\n- [体験入学ページ](/chuo/open-campus)',
            photo: '/img/photo-3.webp',
          },
          {
            title: '新型コロナウイルス感染予防について',
            date: '2021年4月1日',
            slug: 'covid-19',
            content: '- 定期的な消毒、換気、教職員のマスク着用など感染症対策に配慮しながら実施しています。\n- ご来校時に検温をお願いしています。熱がある場合は恐れ入りますが、参加をご遠慮頂いております。\n- 新型コロナウイルス感染拡大防止の為、予定が変更になる場合がございます。',
            photo: '/img/news-covid-19.webp',
          },
        ],
        /**
         * @type {string[]}
         */
        pathArray: [],
        /**
         * @return {boolean}
         */
        isHome() {
          return this.pathArray.length === 1
        },
        /**
         * @param {string} slug
         * @return {boolean}
         */
        isPage(slug) {
          const pathname = window.location.pathname.match(/^\/chuo\/(.*)/)[1].replace(/(.*)\/$/, '$1')
          return pathname === slug
        },
        onBeforeMount() {
          this.pathArray = this.getPathNameArray()
        },
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-chuo>