<logo-suzuki-s>
  <svg width="102" height="144" viewBox="0 0 102 144" fill="none" xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none">
    <g clip-path="url(#clip-{ id })">
      <path
        d="M60.866 -7.71436C60.866 -7.71436 27.0101 24.1714 51.7612 71.0999C76.5123 118.028 42.4795 151.586 42.4795 151.586"
        stroke="url(#paint0_linear_452_10389)" stroke-width="18" stroke-miterlimit="10" stroke-linecap="square" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_452_10389" x1="50.6148" y1="-7.71436" x2="50.6148" y2="151.586"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="{ colorStop2 }" />
        <stop offset="1" stop-color="{ colorStop }" />
      </linearGradient>
      <clipPath id="clip-{ id }">
        <rect width="102" height="144" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <script>
    export default function LogoSuzukiS() {
      return {
        setCssPropertyValue() {
          this.bodyElement = document.querySelector('body')
          this.bodyStyles = getComputedStyle(this.bodyElement)
          this.slug = this.props.dataTheme ? this.props.dataTheme : 'suzuki'
          this.colorStop = this.bodyStyles.getPropertyValue(`--color-${this.slug}-2`).trim()
          this.colorStop2 = this.bodyStyles.getPropertyValue(`--color-${this.slug}-3`).trim()
        },
        onBeforeMount() {
          this.setCssPropertyValue()
        }
      }
    }
  </script>
</logo-suzuki-s>