<page-atsugi-design-qa>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column c-q-and-a-layout">
    <div each="{ message in messages }">
      <q-and-a data-item="{ message }" data-slug="design"></q-and-a>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiDesignQa() {
      return {
        /**
         * @typedef {object} qAndA
         * @property {string} question 単純文字列
         * @property {string} answer HTML文字列
         */
        /**
         * @type { qAndA[] }
         */
        messages: [
          {
            question: '何を勉強する学校ですか？',
            answer: 'ファッションと情報処理を勉強する学校です。',
          },
          {
            question: 'ファッションはどんな勉強をするのですか？',
            answer: '作品の作図から始まり、裁断、縫製、仕上げといったように、一つの作品が完成されるまでを、理論と実習で勉強していきます。',
          },
          {
            question: 'ファッションには男子も入学していますか？',
            answer: 'はい、毎年男子学生も入学しています。',
          },
          {
            question: '学校は何処にあるのですか？',
            answer: '小田急線「本厚木駅」下車、北口より徒歩1～2分のところにあります。<a href="/atsugi/#access" class="c-link c-link--theme-design">アクセスマップ</a>をご参照ください。',
          },
          {
            question: '学校見学はできますか？',
            answer: 'はい。日祝日以外でしたらいつでも可能です。ただし、事前に電話連絡だけは入れてください。<a href="/atsugi/design/open-campus" class="c-link c-link--theme-design">オープンキャンパス</a>も開催しておりますのでそちらもぜひご参加ください。',
          },
          {
            question: '学費の分割払いはできますか？',
            answer: '国民生活金融公庫など支払い方法をご用意しております。詳しくは事務局にご相談ください。',
          },
          {
            question: '奨学金を利用することができますか？',
            answer: 'はい。日本学生支援機構の奨学金制度が利用できます。',
          },
          {
            question: '専門士は取れますか？',
            answer: 'はい。専門学校として、国の認可を受けています。',
          },
          {
            question: '大学に編入できますか？',
            answer: 'はい。同じカリキュラムを持つ大学なら可能です。<br>産能大学、産能短大との併修を行なっていますので、最短の4年で、専門学校と大学の2校を卒業することができます。',
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-design-qa>