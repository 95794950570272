<page-chuo-qa>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column c-q-and-a-layout">
    <div each="{ message in messages }">
      <q-and-a data-item="{ message }" data-slug="chuo"></q-and-a>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageChuoQa() {
      return {
        /**
         * @typedef {object} photo
         * @property {string} photo
         * @property {string} [caption]
         * @property {number} [width]
         */
        /**
         * @typedef {object} qAndA
         * @property {string} question 単純文字列
         * @property {string} answer HTML文字列
         * @property {photo[]} [photos] 写真データ
         */
        /**
         * @type { qAndA[] }
         */
        messages: [
          {
            question: '入学試験はありますか？',
            answer: 'はい、あります。推薦入試は面接(保護者同席)試験のみで、一般入試は筆記試験と面接(保護者同席)試験があります。',
          },
          {
            question: '中学校では不登校気味でしたが、入学できますか？',
            answer: 'はい、問題有りません。不登校だからといって入学試験を受けられないことはありません。勉強したい熱意があれば大丈夫です。',
          },
          {
            question: '内申書の点数が気になります、推薦を受けられますか？',
            answer: 'はい、受けられます。推薦してもらいたいという熱意を担任の先生に示してください。',
          },
          {
            question: '高校を中退してしまったのですが、編入できますか？',
            answer: 'はい、可能です。学年単位制ですので、もう一度中退した学年からスタートとなります。',
          },
          {
            question: '学校見学はできますか？',
            answer: 'はい。日祭日以外ならOKですが、事前に<a href="/contact">ご連絡</a>(<a href="tel:0462246311">電話予約</a>)ください。',
          },
          {
            question: '学費の分納ができますか？',
            answer: 'はい、可能です。国民生活金融公庫、教育ローンの利用ができます、詳しくは事務局にご相談ください。',
          },
          {
            question: '補助金や奨学金制度を利用できますか？',
            answer: 'はい。国の就学支援金・県の学費補助金制度や高校生等奨学給付金などが利用できます。',
          },
          {
            question: '学校は何処にありますか？',
            answer: '小田急線「本厚木駅」北口から厚木市文化会館方面に徒歩で、15分程度の処に校舎があります。厚木中央高等学校と専門学校 神奈川総合大学校は同一の敷地内に学校があります。',
          },
          {
            question: '制服はありますか？',
            answer: 'はい、あります。男子は紺のブレザーでメタルの3つボタン、女子は紺のブレザーに、チェックのスカートまたはズボンから選択が可能です。',
            photos: [
              {
                photo: '/img/uniform-summer.webp',
                caption: '夏服',
                width: 360
              },
              {
                photo: '/img/uniform-winter.webp',
                caption: '冬服',
                width: 640,
              },
            ]
          },
          {
            question: '部活動・同好会はありますか？',
            answer: 'はい、あります。詳しくは<a href="/chuo/campus">スクールライフのページ</a>をご覧ください。',
          },
          {
            question: 'どんな学校行事がありますか？',
            answer: '詳しくは<a href="/chuo/campus">本Webサイトの「スクールライフ」のページ</a>や、学校案内をご覧ください。',
          },
          {
            question: '自転車通学ができますか？',
            answer: 'はい。ただし申告制です。自転車保険に加入した上で許可がおりればOKです。',
          },
          {
            question: 'アルバイトはできますか？',
            answer: 'はい。ただし申告制です。許可がおりればOKですが、学業が著しく落ち込んだ時は中止となります。',
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-chuo-qa>