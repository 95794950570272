<page-kanagawa-highschool-design>
  <div class="o-layout o-layout--column">
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="highschool">特徴</ui-heading>
        <div class="c-content c-editor-content c-editor-content--theme-highschool">
          <p>
            生活造形学科には、生活コースとファッションコースがあります。
            <br>どちらも実践的理論に基づいて基礎から応用まで学べるコースです。
          </p>
          <p>
            <a href="#life-course"
              class="c-link c-link--theme-highschool">生活コース</a>では、衣食住・生活産業に関する教養講座のほかにビジネスやコンピューターなど総合的に学びます。
            <br><a href="#fashion-course"
              class="c-link c-link--theme-highschool">ファッションコース</a>は服飾デザインの理論や実技を基礎から応用まで学びます。
          </p>
          <p>
            3年間にできるだけ多くの資格・検定の取得するために、通常の授業のほか、一月前から放課後補習を行い、難易度の高い資格・検定の取得が可能となり、就職活動の際に有利となっています。
          </p>
          <p>
            また、大学・短大や専門学校への進学もきめ細やかな指導が得られ、自信をもって社会へ巣立てるよう十分な配慮を行っています。
          </p>
        </div>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="highschool">教育方針</ui-heading>
        <div class="c-content c-editor-content c-editor-content--theme-highschool">
          <p>
            <a href="#life-course"
              class="c-link c-link--theme-highschool">生活コース</a>では、製造・流通・オフィス業務・画像加工業務やサービス分野で活躍できる人材を、<a
              href="#fashion-course"
              class="c-link c-link--theme-highschool">ファッションコース</a>では、アパレル・ファッション業界で活躍できる人材を育成するカリキュラム編成をしています。
          </p>
          <p>
            それぞれのコースは、自己発見の場であるとともに各自の付加価値を高める教育を実施し、親切丁寧な判りやすい授業を行っています。
          </p>
          <p>
            資格・検定取得には全員が参加し、競い合い助け合いながら全員の合格を目指します。
          </p>
        </div>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="highschool">カリキュラム</ui-heading>
        <div class="c-content c-editor-content c-editor-content--theme-highschool">
          <p>
            若い方々のエネルギーは、自分の判断基準や嗜好に合った道を見つけることでより発揮されるものです。
            そんな新時代感覚に基づく、独自の能力適正カリキュラムを実践しています。
          </p>
          <p><b>興味のある分野に一生懸命取組めば、必ず道は開けます。</b></p>
          <p>ぜひ、各教科担当の教師とともに、みなさんの｢自ら成長したい」というモチベーションを育て、社会に羽ばたいていってください。</p>
        </div>
      </section>
    </div>
    <div>
      <section>
        <ui-heading data-tag="h2" data-theme="highschool">2つのコース</ui-heading>
        <div class="o-spacer"></div>
        <div class="c-sticky-block">
          <ui-tabs data-tabs="{tabs}" data-current="{state.currentTab}" data-theme="highschool"
            data-tab-click-handler="{clickTabHandler}">
          </ui-tabs>
        </div>
        <intersection-area data-disable="{ state.disableIntersection }" data-id="life"
          data-intersection-handler="{intersectionHandler}">
          <section id="life">
            <h3 class="c-heading-4 c-heading-4--theme-highschool" id="life-course">生活コース</h3>
            <p>
              本コースは、衣食住、生活産業に関する基礎知識、デザイン、情報、ビジネス、グラフィックアーツなどを学びます。
              <br>卒業生は製造、印刷、物流、サービス、情報関連などに就職し、また、理数系、芸術系、衛生・調理系、介護系などの大学・短大や専門学校へと進学しています。
            </p>
            <div class="o-spacer"></div>
            <ui-box data-heading="学習内容の一例（2年次）" data-heading-tag="h4" data-theme="highschool">
              <div class="o-layout o-layout--fill">
                <div>
                  <h5 class="c-heading-6 c-heading-6--theme-highschool">一般教科</h5>
                  <ul class="c-list c-list--theme-highschool">
                    <li>国語</li>
                    <li>英語</li>
                    <li>数学</li>
                    <li>社会</li>
                    <li>理科</li>
                    <li>保健体育</li>
                    <li>美術</li>
                  </ul>
                </div>
                <div>
                  <h5 class="c-heading-6 c-heading-6--theme-highschool">専門教科</h5>
                  <ul class="c-list c-list--theme-highschool">
                    <li>生活産業情報</li>
                    <li>家庭総合</li>
                    <li>造形</li>
                  </ul>
                </div>
                <div>
                  <h5 class="c-heading-6 c-heading-6--theme-highschool">技能教科</h5>
                  <ul class="c-list c-list--theme-highschool">
                    <li>製図</li>
                    <li>パソコン実習</li>
                    <li>CG</li>
                  </ul>
                </div>
              </div>
            </ui-box>
          </section>
        </intersection-area>
        <intersection-area data-disable="{ state.disableIntersection }" data-id="fashion"
          data-intersection-handler="{intersectionHandler}">
          <section id="fashion">
            <h3 class="c-heading-4 c-heading-4--theme-highschool" id="fashion-course">ファッションコース</h3>
            <p>
              本コースの卒業生は、ほとんどがアパレルやファッション業界でデザイナー、パタンナー、ファッションアドバイザーや縫製工などとして働いています。
              <br>こうしたスタッフとして働くためには、ファッションビジネスを幅広く知る必要があります。
            </p>
            <p>
              また、材料学・色彩学・服装史やデザイン画の描き方、商品企画、デザイン、裁断、縫製につながるさまざまな知識と技術が必要になり、こうした一連のファッション全般について学び、感性を育てる授業構成になっています。
              <br>服飾・家政系、文化・教養系の大学・短大や専門学校へと進学しています。
            </p>
          </section>
        </intersection-area>
      </section>
    </div>
  </div>
  <script>
    export default {
      state: {
        currentTab: 'life',
        disableIntersection: false
      },
      tabs: [
        {
          name: '生活コース',
          slug: 'life'
        },
        {
          name: 'ファッションコース',
          slug: 'fashion'
        },
      ],
      clickTabHandler(slug) {
        this.state.disableIntersection = true
        this.state.currentTab = slug
        this.update()
        window.requestAnimationFrame(() => {
          window.location.href = `#${slug}`
        })
      },
      intersectionHandler(slug) {
        this.state.currentTab = slug
        this.update()
      },
      onMounted() {
        this.scrollToHashedElement()
      },
      onUpdated() {
        if (this.state.disableIntersection) {
          if (this.timeoutId) window.clearTimeout(this.timeoutId)
          this.timeoutId = window.setTimeout(() => {
            this.update({ disableIntersection: false })
          }, 1000)
        }
      }
    }
  </script>
</page-kanagawa-highschool-design>