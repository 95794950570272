<breadcrumbs-nav>
  <div class="c-breadcrumbs-nav" if="{ props.dataItems }">
    <ul class="c-breadcrumbs-nav-list o-layout">
      <li each="{ item in getItems() }">
        <a href="{ item.link }" class="c-breadcrumbs-nav-link { getClassName('c-link') }">{ item.name }</a>
      </li>
    </ul>
  </div>
  <script>
    export default function BreadcrumbsNav() {
      return {
        getItems() {
          return [{
            name: 'ホーム',
            link: this.props.dataHomePath ? this.props.dataHomePath : '/'
          }, ...this.props.dataItems, {
            name: this.props.dataCurrentPageName,
            link: '.'
          }]
        },
        getClassName(className) {
          const result = [className]
          if (this.props.dataTheme) result.push(`${className}--theme-${this.props.dataTheme}`)
          return result.join(' ')
        }
      }
    }
  </script>
</breadcrumbs-nav>