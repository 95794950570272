<catalog-photo>
  <div class="c-catalog-photos">
    <img src="{photo}" alt="" width="210" height="297" class="c-catalog-photos__photo"
      each="{(photo, index) in props.dataPhoto}" {...getPhotoStyle(index)}>
  </div>
  <script>
    // @ts-check
    export default function CatalogPhoto() {
      return {
        /**
         * @return {object}
         */
        getPhotoStyle(index) {
          const rangeMax = 30
          const resultRotate = rangeMax / (this.props.dataPhoto.length - 1) * index - rangeMax / 2
          return {
            style: `transform: rotate(${resultRotate}deg)`
          }
        }
      }
    }
  </script>
</catalog-photo>