<page-kanagawa-highschool-qa>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column c-q-and-a-layout">
    <div each="{ message in messages }">
      <q-and-a data-item="{ message }" data-slug="highschool"></q-and-a>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageKanagawaHighschoolQa() {
      return {
        /**
         * @typedef {object} photo
         * @property {string} photo
         * @property {string} [caption]
         * @property {number} [width]
         */
        /**
         * @typedef {object} qAndA
         * @property {string} question 単純文字列
         * @property {string} answer HTML文字列
         * @property {photo[]} [photos] 写真データ
         */
        /**
         * @type { qAndA[] }
         */
        messages: [
          {
            question: '入学試験はありますか？',
            answer: 'はい、あります。一般入試は筆記試験と面接(保護者同席)試験があります。推薦入試は面接(保護者同席)試験だけです。<br>詳細は<a href="/kanagawa/highschool/qa" class="c-link c-link--theme-highschool">募集要項</a>をご覧ください。',
          },
          {
            question: '勉強が苦手なんですが、大丈夫ですか？',
            answer: 'はい、大丈夫です。全く勉強をする気力のない方以外なら、授業についてこられると思います。教員一同も親身になって指導いたしますので、安心してご入学ください。',
          },
          {
            question: '高校を中退してしまったのですが、入れますか？',
            answer: 'はい、問題ありません。<be>ただし、一年生から資格を取って頂いている関係上、編入の制度はありません。',
          },
          {
            question: '中学校にはほとんど通うことができませんでしたが、入れますか？',
            answer: 'はい、不登校の方でも入学できます。<br>ただ、義務教育ではありませんので、出席すべき日数が不足したり、進級に値するが点数不足の場合は留年する場合もあります。',
          },
          {
            question: '学校見学はできますか？',
            answer: 'はい、日祭日以外ならOKですが、事前に<a href="/contact">ご連絡</a>（<a href="tel:0462215678">電話予約</a>）ください。また、<a href="/kanagawa/highschool/open-campus" class="c-link c-link--theme-highschool">オープンキャンス</a>も開催しておりますのでぜひご参加ください。',
          },
          {
            question: '学費の分納はできますか？',
            answer: 'はい、可能です。国民生活金融公庫、教育ローンの利用ができます、詳しくは<a href="/contact" class="c-link c-link--theme-highschool">事務局にご相談ください</a>。'
          },
          {
            question: '補助金や奨学金制度を利用できますか？',
            answer: 'はい、可能です。国の就学支援金・県の学費補助金制度や高校生等奨学給付金などが利用できます。<br>詳細は<a href="/contact" class="c-link c-link--theme-highschool">事務局にお問い合わせください</a>。',
          },
          {
            question: '学校は何処にありますか？',
            answer: '小田急線「本厚木駅」北口から厚木市文化会館方面に徒歩で、15分程度の処に校舎があります。厚木中央高等学校と専門学校 神奈川総合大学校は同一の敷地内に学校があります。<br>詳しくは<a href="/school/#map-2" class="c-link c-link--theme-highschool">アクセスマップ</a>を御覧ください',
          },
          {
            question: '制服はありますか？',
            answer: 'はい、あります。男子は紺のブレザーでメタルの3つボタン、女子は紺のブレザーに、チェックのスカートまたはズボンから選択が可能です。',
            photos: [
              {
                photo: '/img/uniform-summer.webp',
                caption: '夏服',
                width: 360
              },
              {
                photo: '/img/uniform-winter.webp',
                caption: '冬服',
                width: 640
              },
            ]
          },
          {
            question: '部活動・同好会はありますか？',
            answer: 'はい、あります。詳しくは<a href="/kanagawa/highschool/campus" class="c-link c-link--theme-highschool">スクールライフ</a>を御覧ください。',
          },
          {
            question: 'どんな学校行事がありますか？',
            answer: '詳しくは<a href="/kanagawa/highschool/campus" class="c-link c-link--theme-highschool">スクールライフ</a>を御覧ください。',
          },
          {
            question: '自転車通学ができますか？',
            answer: 'はい。ただし申告制で、許可がおりればOKです。',
          },
          {
            question: 'アルバイトはできますか？',
            answer: 'はい。ただし申告制です。許可がおりればOKですが、学業が著しく落ち込んだ時は中止となります。',
          },
          {
            question: '学割（通学定期券割引）が使えますか？',
            answer: 'はい、ご利用いただけます。正規の学校ですので、通学定期が使えます。<br>また、多くの公共施設を学生割引料金で利用することもできます。',
          },
          {
            question: '自動車整備科があると聞きますが、入れますか？',
            answer: 'はい、ご希望でしたら可能です。専門学校 神奈川総合大学校には、中学卒業から入れる高等課程と、高校卒業から入れる専門課程 自動車整備科があります。<br>本校の高等課程を卒業して自動車整備科に入学する場合には入学金が免除となります。',
          },
          {
            question: '高卒資格がとれますか？',
            answer: 'はい。専門学校 神奈川総合大学校高等課程を卒業すると高卒と同等の資格(大学入学資格)が取得できますので、高等学校卒業程度認定試験(旧大学入学資格検定)を受ける必要はありません。<br>また、技能連携(同一敷地内にある厚木中央高等学校)によって、高卒資格そのものを得ることもできます。<br>現在の本校の在校生は、ほぼ全員が技能連携生です。',
          },
          {
            question: '技能連携とはどういったものでしょうか？技能連携するのは大変でしょうか？',
            answer: '技能連携とは、専修学校に在学しながら、高校の単位を取得することです。<br>月曜から金曜日、一日6時間程度の授業ですので、負担はないと思います。<br>ほぼ100%に近い学生が技能連携を選択されており、全員が無理なくスクールライフを送っています。<br>詳しくは<a href="/kanagawa/highschool/application/#cooperation" class="c-link c-link--theme-highschool">募集要項の「技能連携について」</a>をご覧ください。'
          },
          {
            question: '資格が取れると聞きますが、どんな資格ですか？',
            answer: '学科・コースにより、一部制約がありますが、電気工事士、通信工事担任者教科免除(工業技術学科)、服飾技能検定(生活造形学科ファッションコース)、パソコン利用技術、ビジネス、トレース技能検定、日本漢字能力検定などがあります。<br>中には、出席日数や補習参加などを必要条件とするものもあります。<br>くわしくは<a href="/kanagawa/highschool/subject/">学科・教科・取得資格</a>を御覧ください。'
          },
          {
            question: '卒業後、進学できますか？',
            answer: 'はい、もちろん可能です。系列の専門学校をはじめ、多くの大学・短大、専門学校へ進学実績があります。'
          }
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-kanagawa-highschool-qa>