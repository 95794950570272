<page-atsugi-dental-qa>
  <div class="o-spacer o-spacer--large-2"></div>
  <div class="o-layout o-layout--column c-q-and-a-layout">
    <div each="{ message in messages }">
      <q-and-a data-item="{ message }" data-slug="dental"></q-and-a>
    </div>
  </div>
  <script>
    // @ts-check
    export default function PageAtsugiDentalQa() {
      return {
        /**
         * @typedef {object} qAndA
         * @property {string} question 単純文字列
         * @property {string} answer HTML文字列
         */
        /**
         * @type { qAndA[] }
         */
        messages: [
          {
            question: '歯科衛生士と歯科助手って何が違うのですか？',
            answer: '一番の大きな違いは国家資格があるかないかです。<br>また、直接患者さんのお口を触れることができるのは歯科衛生士で歯科助手はできません。',
          },
          {
            question: '歯科衛生士の国家資格はどうやって取るんですか？',
            answer: '高等学校を卒業後（それと同等の資格を有する者）、歯科衛生士養成機関の３年制または4年制で所定の内容を履修すると歯科衛生士国家試験の受験資格が得られ、その国家試験に合格すると晴れて歯科衛生士免許が与えられます。',
          },
          {
            question: '国家試験はどんなものですか？',
            answer: '出題はマークシート方式で、午前試験と午後試験で全220問を解き、正答率の約60％が合否ラインとなっています。<br>国家試験は、例年３月の第１日曜日です。<br>全国各地の会場で約7,000人以上の方が受験します。試験会場は毎年同じではなく、近いところでは東京や千葉で開催されます。',
          },
          {
            question: '歯科衛生士の国家試験の合格率はどのくらいですか？',
            answer: '全国平均は95%前後を推移しており、毎年３月下旬に厚生労働省のWebサイトで合格が発表されます。<br>卒業式の後に合否を知るのも、この国家試験の特徴です。<br>本校は現在の校名に変更する以前の、旧関東歯科衛生士専門学校の時代から、<b class="c-strong c-strong--theme-dental">常に高い合格率を誇っています</b>！',
          },
          {
            question: '歯科衛生学科はどんな雰囲気ですか？',
            answer: '同じ目標のもとで共に励まし合いながら学んでいます。<br>少人数制によるハイレベルできめ細やかな技術指導が個人指導に近い状態で受けられるのも大きな特徴です。<br>新入生のオリエンテーションや3年生の卒業研究などは、他学年も参加して行うので、自然と学生みんなが仲良くなり、先輩たちからのアドバイスも受けやすい環境です。',
          },
          {
            question: '歯科衛生学科の特徴を教えてください',
            answer: '歯科衛生士の活躍の場は、歯科医院や病院がほとんどですが、最近は患者さんのご自宅に訪問診療に行ったり、高齢者施設などに勤める人も増えてきました。そんな最近の社会のニーズにも対応できるようにカリキュラムを組んでいます。<br>専門的な知識と技術を身につけて卒業後は地域歯科医療の即戦力になれるよう、応援します。',
          },
          {
            question: '男子でも入学できますか？',
            answer: '歯科衛生士の資格は女性に限ったものではありませんが、本校は女子のみの入学です。男子でも入学できる学校もあります。<br>まだまだ女性の活躍の場が多いですが、今後どのように変わっていくか楽しみです。',
          },
          {
            question: '社会人も入学できますか？',
            answer: '社会人からの入学生もいます。',
          },
          {
            question: '子どももいる主婦ですが、歯科衛生士になれますか？',
            answer: '子育て中の方や、主婦として家庭を大事にされながら通われている方も多くいます。また国家試験に年齢の上限はありません。',
          },
          {
            question: '臨地実習とはどんなものですか？',
            answer: '実際に患者さんを診療している医院などで、指導を受ける実習です。<br>在学中に900時間以上行うことが法令で定められていて、個人の通学事情などを考慮し、提携先の中から学校で紹介しております。',
          },
          {
            question: 'どういったところに就職していますか？',
            answer: '歯科医院への就職が率としては一番多いですが、近年は介護施設の求人も多いです。総合病院の口腔外科、保健所、市町村保健センター、歯科材料会社、出版会社(歯科関連)などの就職もあります。',
          },
          {
            question: '大学に編入できますか？',
            answer: '歯科衛生士学校を卒業して、4年制大学の３年生に編入できます。',
          },
          {
            question: '学費の分納はできますか？',
            answer: '詳しくは事務局にご相談ください。',
          },
          {
            question: '奨学金の制度はありますか？',
            answer: '日本学生支援機構の奨学金制度があります。',
          },
        ],
        onMounted() {
          this.scrollToHashedElement()
        }
      }
    }
  </script>
</page-atsugi-dental-qa>