<event-calendar>
  <div class="c-event-calendar">
    <div class="o-layout o-layout--column c-event-calendar__item">
      <section each="{ item in props.dataItems }">
        <h1 class="{ getTitleClass() }" {...getHeadingStyle(item.photo)}>
          {item.title}
        </h1>
        <div class="c-content">
          <ul class="{ getListClass() }" if="{ item.events }">
            <li each="{ event in item.events }">{ event }</li>
          </ul>
        </div>
      </section>
    </div>
  </div>
  <script>
    //@ts-check
    export default function EventCalendar() {
      return {
        /**
         * @param {string} photo 写真のURL
         * @return {object}
         */
        getHeadingStyle(photo) {
          /**
           * @type {object}
           */
          let result = { style: `background-image: linear-gradient(90deg, white 18%, transparent 38%), url("${photo}");` }
          return result
        },
        /**
         * @return {string}
         */
        getListClass() {
          /** @type {string[]} */
          const result = ['c-list']
          if (this.props.dataSlug) result.push(`c-list--theme-${this.props.dataSlug}`)
          return result.join(' ')
        },
        /**
         * @return {string}
         */
        getTitleClass() {
          const result = ['c-event-calendar__heading']
          if (this.props.dataSlug) result.push(`c-event-calendar__heading--theme-${this.props.dataSlug}`)
          return result.join(' ')
        }
      }
    }
  </script>
</event-calendar>